<template>
  <div
    class="bg-white border border-solid border-purple-200 hover:bg-primary hover:bg-opacity-10 rounded-full h-10 px-1 py-1 pr-3 flex items-center cursor-pointer"
    @click="isPanelOpen = !isPanelOpen"
  >
    <img v-if="activeProject" :src="activeProject.icon || fallbackIcon" class="w-6 h-6 mr-2 rounded-full ml-1" />
    <span class="pointer-events-none">
      <span>{{ activeProject.name }}</span>
    </span>
    <Icon class="pointer-events-none w-4 h-4 ml-4" :name="isPanelOpen ? 'expand_less' : 'expand_more'" />
  </div>

  <div v-if="isPanelOpen" id="dropdownPanel" class="absolute z-10 bg-white shadow-mini-card top-0 rounded-b-2xl max-w-600 left-0 w-full lg:w-top-menu" v-outside-click="close">
    <div class="mb-5 border-b border-solid border-gray-200 flex items-center lg:pr-5 lg:pl-2 select-none flex-wrap lg:flex-nowrap">
      <div class="flex h-18 lg:h-23 order-1">
        <div class="mx-5 font-500 relative h-full flex items-center text-16">
          <span>{{ $t('accounts_dropdown.select_account') }}</span>
          <span class="absolute bottom-0 h-1 bg-primary rounded-t-4 w-full left-0"></span>
        </div>
      </div>
      <div class="flex-grow pl-5 pr-5 lg:pl-10 py-5 order-4 lg:order-2 w-full lg:w-auto">
        <div class="relative h-10 w-full max-w-500">
          <Icon name="search" class="absolute top-2 left-3 z-2 w-6 h-6 pointer-events-none" style="color: #c0bad9" />
          <input v-model="searchText" class="rounded-full pl-11 h-10 w-full bg-primary bg-opacity-10" :placeholder="`${$t('header.search')}...`" />
        </div>
      </div>
    </div>
    <div class="flex mb-4 flex-wrap lg:flex-nowrap">
      <div class="flex-grow w-full sm:w-1/2 py-2">
        <ul v-if="projectList.length > 0" class="overflow-y-auto max-h-58 px-6">
          <li v-for="project in projectList" :key="project.id" class="flex items-center mb-2" @click="onProjectSelect(project)">
            <div :class="[listItemClasses, project.active ? 'bg-primary text-white border-primary' : ''].join(' ')" class="flex-1 pr-3 h-10 py-2 flex mr-2">
              <img :src="project.icon || fallbackIcon" class="w-6 h-6 ml-2 mr-4 rounded-full" :alt="project.name" :class="{ 'grayscale opacity-50': !project.icon }" />
              <span class="truncate flex-1">{{ project.name }}</span>
            </div>
            <div class="cursor-pointer favourite-button ml-3">
              <icon name="favorite_star_filled"></icon>
            </div>
          </li>
        </ul>
        <div v-else>
          <span>{{ $t('accounts_dropdown.message_no_accounts') }}. </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectSelectDropdown',
  emits: ['click'],
  props: {
    activeProject: {
      type: Object,
      default: () => {
        return {
          projectId: '',
          name: '',
          icon: '',
        };
      },
    },
    projects: Object,
  },
  data() {
    return {
      searchText: '',
      isPanelOpen: false,
    };
  },
  computed: {
    listItemClasses() {
      return 'hover:bg-primary hover:bg-opacity-15 hover:text-purple-900 hover:border-purple-200 border border-solid border-gray-200 rounded-full cursor-pointer truncate overflow-hidden items-center';
    },
    fallbackIcon() {
      return process.env.VUE_APP_BUBBLE_ICON;
    },
    projectList() {
      return Object.keys(this.projects)
        .map((p) => {
          return {
            id: p,
            icon: this.projects[p]?.logos?.CONVERSATION_BUBBLE,
            active: p === this.activeProject.projectId,
            ...this.projects[p],
          };
        })
        .filter((p) => p.name.toLowerCase().includes(this.searchText.toLowerCase()));
    },
  },
  methods: {
    onProjectSelect(project) {
      this.$emit('click', project);
      this.close();
    },
    close() {
      this.isPanelOpen = false;
    },
  },
};
</script>
<style lang="scss">
.favourite-button {
  color: transparent;

  &--active,
  &:hover {
    color: #f2c94c;
  }
}
</style>
