import { createRouter, createWebHistory } from 'vue-router';
import SideLayout from '@/components/layouts/Side';
import Login from '@/components/views/Login';
import Settings from '@/components/views/user/Settings.vue';
import Security from '@/components/views/user/Security.vue';
import Subscription from '@/components/views/user/Subscription';
import AuthCallback from '@/components/views/AuthCallback';
import Onboarding from '@/components/views/Onboarding';
import Projects from '@/components/views/project/Projects';
import { FEATURES } from '@/constants';
import Help from '@/components/views/Help.vue';
import Dashboard from '@/components/views/Dashboard.vue';
import AdminDashboard from '@/components/views/admin/AdminDashboard.vue';
import ProjectFlow from '@/components/views/project/Flow.vue';
import ProjectEndpoints from '@/components/views/project/Endpoints';
import ProjectVariables from '@/components/views/project/Variables';
import ProjectSecrets from '@/components/views/project/Secrets';
import ProjectTemplates from '@/components/views/project/Templates';
import ProjectSettingsWrapper from '@/components/views/project/settings/SettingsWrapper.vue';
import AuthenticationConfig from '@/components/views/project/AuthenticationConfig';
import ProjectTeamMembers from '@/components/views/project/settings/TeamMembers.vue';
import ProjectUsers from '@/components/views/project/settings/Users.vue';
import ProjectUIFeatures from '@/components/views/project/settings/UIFeatures.vue';
import ProjectDataAdapters from '@/components/views/project/data-adapters/DataAdapters.vue';
import ProjectDataAdapter from '@/components/views/project/data-adapters/DataAdapter.vue';
import ProjectSettings from '@/components/views/project/settings/Settings.vue';
import ProjectPersonas from '@/components/views/project/Personas';
import ProjectAIProviders from '@/components/views/project/AIProviders';
import ProjectDataTypes from '@/components/views/project/DataTypes';
import ProjectFlows from '@/components/views/project/Flows';
import ProjectMonitoring from '@/components/views/project/Monitoring.vue';
import ProjectModels from '@/components/views/project/data-models/Models.vue';
import ProjectModel from '@/components/views/project/data-models/Model.vue';
import ProjectModelSettings from '@/components/views/project/data-models/ModelSettings.vue';
import ProjectDataAdapterSettings from '@/components/views/project/data-adapters/DataAdapterSettings.vue';
import ProjectDataAdapterDataTypes from '@/components/views/project/data-adapters/DataAdapterDataTypes.vue';
import ProjectModelSuggestions from '@/components/views/project/data-models/ModelSuggestions.vue';
import ProjectModelFineTune from '@/components/views/project/data-models/ModelFineTune.vue';
import ProjectModelBuild from '@/components/views/project/data-models/ModelBuild.vue';
import ProjectPublishing from '@/components/views/project/Publish';
import Users from '@/components/views/admin/Users.vue';
import AcceptInvite from '@/components/views/AcceptInvite.vue';

let store;

const router = createRouter({
  history: createWebHistory(),
  base: '/',
  routes: [
    {
      path: '/',
      component: Dashboard,
      meta: { requiresAuth: true },
    },
    {
      path: '/help/:id',
      name: 'help',
      component: Help,
      meta: { requiresAuth: true },
    },
    {
      path: '/onboarding',
      name: 'onboarding',
      component: Onboarding,
      meta: { requiresAuth: true },
    },
    {
      path: '/user',
      component: SideLayout,
      meta: { requiresAuth: true },
      children: [
        {
          path: 'subscriptions',
          name: 'subscriptions',
          component: Subscription,
          meta: { requiresAuth: true },
        },
        {
          path: 'settings',
          name: 'settings',
          component: Settings,
          meta: { requiresAuth: true },
        },
        {
          path: 'security',
          name: 'security',
          component: Security,
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: '/project',
      name: 'project',
      component: Projects,
      meta: { requiresAuth: true },
    },
    {
      path: '/project/:projectId',
      name: 'project-parent',
      component: SideLayout,
      meta: { requiresAuth: true, header: 'no_code' },
      children: [
        {
          path: '/project/:projectId/endpoints',
          name: 'project-endpoints',
          component: ProjectEndpoints,
        },
        {
          path: '/project/:projectId/variables',
          name: 'project-variables',
          component: ProjectVariables,
        },
        {
          path: '/project/:projectId/secrets',
          name: 'project-secrets',
          component: ProjectSecrets,
        },
        {
          path: '/project/:projectId/templates',
          name: 'project-templates',
          component: ProjectTemplates,
        },
        {
          path: '/project/:projectId/settings',
          component: ProjectSettingsWrapper,
          children: [
            {
              path: 'settings',
              name: 'project-settings',
              component: ProjectSettings,
              meta: { parentRouteName: 'project-settings' },
            },
            {
              path: 'authentication',
              name: 'project-authentication-config',
              component: AuthenticationConfig,
              meta: { parentRouteName: 'project-settings' },
            },
            {
              path: 'team-members',
              name: 'project-team-members',
              component: ProjectTeamMembers,
              meta: { parentRouteName: 'project-settings' },
            },
            {
              path: 'users',
              name: 'project-users',
              component: ProjectUsers,
              meta: { parentRouteName: 'project-settings' },
            },
            {
              path: 'ui-features',
              name: 'project-ui-features',
              component: ProjectUIFeatures,
              meta: { parentRouteName: 'project-settings' },
            },
          ],
        },
        {
          path: '/project/:projectId/publishing',
          name: 'project-publishing',
          component: ProjectPublishing,
        },
        {
          path: '/project/:projectId/data-adapters',
          name: 'project-data-adapters',
          component: ProjectDataAdapters,
        },
        {
          path: '/project/:projectId/data-adapter',
          component: ProjectDataAdapter,
          children: [
            {
              path: ':dataAdapter/settings',
              name: 'project-data-adapter',
              component: ProjectDataAdapterSettings,
              meta: { parentRouteName: 'project-data-adapters' },
            },
            {
              path: ':dataAdapter/tables',
              name: 'project-data-adapter-tables',
              component: ProjectDataAdapterDataTypes,
              meta: { parentRouteName: 'project-data-adapters' },
            },
          ],
        },
        {
          path: '/project/:projectId/personas',
          name: 'project-personas',
          component: ProjectPersonas,
        },
        {
          path: '/project/:projectId/ai-providers',
          name: 'project-ai-providers',
          component: ProjectAIProviders,
        },
        {
          path: '/project/:projectId/datatypes',
          name: 'project-datatypes',
          component: ProjectDataTypes,
        },
        {
          path: '/project/:projectId/flow',
          name: 'project-flows',
          component: ProjectFlows,
        },
        {
          path: '/project/:projectId/monitoring',
          name: 'project-monitoring',
          component: ProjectMonitoring,
        },
        {
          path: '/project/:projectId/models/list',
          name: 'project-models',
          component: ProjectModels,
        },
        {
          path: '/project/:projectId/models',
          component: ProjectModel,
          children: [
            {
              path: ':modelId',
              name: 'project-model',
              component: ProjectModelSettings,
              meta: { parentRouteName: 'project-models' },
            },
            {
              path: ':modelId/suggestions',
              name: 'project-model-suggestions',
              component: ProjectModelSuggestions,
              meta: { parentRouteName: 'project-models' },
            },
            {
              path: ':modelId/fine-tune',
              name: 'project-model-fine-tune',
              component: ProjectModelFineTune,
              meta: { parentRouteName: 'project-models' },
            },
            {
              path: ':modelId/build',
              name: 'project-model-build',
              component: ProjectModelBuild,
              meta: { parentRouteName: 'project-models' },
            },
          ],
        },
      ],
    },
    {
      path: '/project/:projectId/flow/:flowId',
      name: 'project-flow',
      component: ProjectFlow,
    },
    {
      path: '/admin',
      name: 'admin',
      component: AdminDashboard,
      meta: { requiresAuth: true, feature: FEATURES.ADMIN_DASHBOARD },
    },
    {
      path: '/admin',
      component: SideLayout,
      meta: { requiresAuth: true },
      children: [
        {
          path: 'users',
          name: 'admin-users',
          component: Users,
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/passwordreset',
      name: 'password-reset',
      component: Login,
    },
    {
      path: '/register',
      name: 'register',
      component: Login,
    },
    {
      path: '/authcallback',
      name: 'authcallback',
      component: AuthCallback,
    },
    {
      path: '/acceptinvite',
      name: 'accept-invite',
      component: AcceptInvite,
    },
    // pathMatch is the name of the param, e.g., going to /not/found yields
    // { params: { pathMatch: ['not', 'found'] }}
    // this is thanks to the last *, meaning repeated params and it is necessary if you
    // plan on directly navigating to the not-found route using its name
    { path: '/:pathMatch(.*)*', name: 'not-found', redirect: '/login' },
    // if you omit the last `*`, the `/` character in params will be encoded when resolving or pushing
    { path: '/:pathMatch(.*)', name: 'bad-not-found', redirect: '/login' },
  ],
});

router.setStore = (vuexStore) => {
  store = vuexStore;
};

router.beforeEach(async (to, from, next) => {
  if (to.fullPath && to.query.redirect && to.path !== '/authcallback') {
    next(to.query.redirect);
    return;
  }
  if (store.state.bootstrapped && to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.user || !store.state.user.email) {
      next({ path: '/login', params: { nextUrl: to.fullPath } });
      return;
    }
    const { users } = to.meta;
    if (users && users.length && !users.includes(store.state.user.email)) {
      // email not authorised so redirect to home page
      next('/');
      return;
    }

    if (to.matched.some((record) => record.meta.feature && !store.state.features.includes(record.meta.feature))) {
      next({ path: '/' });
      return;
    }
  }

  next();
});

router.afterEach((to, from) => {
  if (from.name === 'dashboard' && to.name === 'conversation' && to.hash === '#dashboard') {
    to.meta.transition = 'slide-up';
  }
});

router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    window.location.reload();
  }
});

export default router;
