<template>
  <div>
    <svg class="loading-circle" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <circle class="loading-circle-svg-circle" cx="50" cy="50" r="45" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
};
</script>

<style lang="css">
.loading-circle {
  animation: 2s linear infinite both loading-circle_svg;
}

.loading-circle-svg-circle {
  animation: 1.4s ease-in-out infinite both circle__svg-circle;
  fill: transparent;
  stroke-dasharray: 285;
  stroke-linecap: round;
  stroke-width: 10px;
  stroke: rgb(var(--color-primary));
  transform-origin: 50% 50%;
}

.text-indigo-500 .loading-circle-svg-circle {
  stroke: rgb(var(--color-primary));
}
.text-white .loading-circle-svg-circle {
  stroke: #fff;
}

@keyframes loading-circle_svg {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes circle__svg-circle {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}
</style>
