<template>
  <div class="flex">
    <div :class="leftColClass" class="overflow-hidden border-r border-gray-300 border-solid">
      <div class="bg-white py-2 h-13 shadow-analysisCard top-0 flex items-center pl-3 pr-5 mb-1">
        <span class="text-13 font-500 mr-auto">{{ $t('evaluate.actions') }}:</span>
        <PillButton v-if="selectedItemIds.length" :text="$t('delete')" small outlined class="mr-2" @click="onDeleteClick" />
        <PillButton v-if="selectedItemIds.length" :text="$t('flow.copy_to')" small outlined @click="onCopyToClick" />
        <div v-if="data.length" class="flex items-center ml-5">
          <span class="mr-2">{{ $t('select_all') }}</span>
          <Checkbox v-model="selectAll" @update:modelValue="$emit('selectAll', selectAll)" />
        </div>
      </div>
      <div class="flex-grow overflow-auto">
        <div class="pl-3 pr-2" v-for="(evaluateItem, index) in evaluate" :key="evaluateItem.id + index">
          <EvaluateDropZone v-if="index === 0" :evaluate="evaluate" :index="index" @evaluate-update="onDrop" />
          <EvaluateItem
            :invalid="!getValidity(evaluateItem.id)"
            :selectedEvaluateItemId="selectedEvaluateItemId"
            :evaluate="evaluate"
            :item="evaluateItem"
            selectable
            :selected="isSelected(evaluateItem)"
            @update="onEvaluateItemUpdated($event, index)"
            @item-click="onItemClicked"
            @evaluate-update="$emit('evaluate-update', $event)"
            @select="$emit('select', $event)"
            @unselect="$emit('unselect', $event)"
          />
          <EvaluateDropZone :evaluate="evaluate" :index="index + 1" @evaluate-update="onDrop" />
        </div>
        <EvaluateAdd :hiddenItems="hiddenEvaluateTypes" @add="onAddClicked" />
      </div>
    </div>
    <div :class="rightColClass" class="overflow-auto">
      <template v-if="selectedEvaluateItem">
        <div class="px-3 py-3 flex justify-between items-center border-gray-200 border-solid border-b">
          <div class="flex items-center">
            <IconButton icon="chevron_left" class="-ml-4 lg:hidden" @click="selectedEvaluateItem = null" />
            <span class="text-16 flex-grow capitalize">{{ getEvaluateTitle(selectedEvaluateItem.type) }}</span>
          </div>
          <div>
            <PillButton :text="$t('delete')" @click="deleteEvaluate" />
          </div>
        </div>
        <EvaluateSetCondition v-if="selectedEvaluateItem.type === 'condition'" :item="selectedEvaluateItem" @valid="onValidChange" @update="onSelectedEvaluateItemUpdated" />
        <EvaluateSetStatement v-else-if="selectedEvaluateItem.type === 'statement'" :item="selectedEvaluateItem" @valid="onValidChange" @update="onSelectedEvaluateItemUpdated" />
        <EvaluateSetTransition v-else-if="selectedEvaluateItem.type === 'transition'" :item="selectedEvaluateItem" @valid="onValidChange" @update="onSelectedEvaluateItemUpdated" />
        <EvaluateSetApi v-else-if="selectedEvaluateItem.type === 'api'" :item="selectedEvaluateItem" @valid="onValidChange" @update="onSelectedEvaluateItemUpdated" />
        <EvaluateSetResponse v-else-if="selectedEvaluateItem.type === 'response'" :item="selectedEvaluateItem" @valid="onValidChange" @update="onSelectedEvaluateItemUpdated" />
        <EvaluateSetCapture v-else-if="selectedEvaluateItem.type === 'capture'" :item="selectedEvaluateItem" @valid="onValidChange" @update="onSelectedEvaluateItemUpdated" />
        <EvaluateSetPrompt v-else-if="selectedEvaluateItem.type === 'prompt'" :item="selectedEvaluateItem" @valid="onValidChange" @update="onSelectedEvaluateItemUpdated" />
        <EvaluateSetAi v-else-if="selectedEvaluateItem.type === 'ai'" :item="selectedEvaluateItem" @valid="onValidChange" @update="onSelectedEvaluateItemUpdated" />
        <EvaluateSetEmail v-else-if="selectedEvaluateItem.type === 'email'" :item="selectedEvaluateItem" @valid="onValidChange" @update="onSelectedEvaluateItemUpdated" />
        <EvaluateSetParallel v-else-if="selectedEvaluateItem.type === 'parallel'" :item="selectedEvaluateItem" @valid="onValidChange" @update="onSelectedEvaluateItemUpdated" />
      </template>
    </div>
  </div>
</template>

<script>
import uuidv4 from '@/helpers/uuid';
import { removeEvaluateItem, updateEvaluateItem } from '@/helpers/flow';
import { EVALUATE_API_PAYLOAD } from '@/constants';
import DeleteModal from '@shared/components/shared-modals/DeleteModal.vue';
import EvaluateSetTransition from './EvaluateSetTransition';
import EvaluateSetStatement from './EvaluateSetStatement';
import EvaluateSetCondition from './EvaluateSetCondition';
import EvaluateSetApi from './EvaluateSetApi';
import EvaluateSetResponse from './EvaluateSetResponse';
import EvaluateSetCapture from './EvaluateSetCapture';
import EvaluateSetPrompt from './EvaluateSetPrompt';
import EvaluateSetAi from './EvaluateSetAi';
import EvaluateSetEmail from './EvaluateSetEmail';
import EvaluateSetParallel from './EvaluateSetParallel';
import EvaluateDropZone from './EvaluateDropZone';
import EvaluateAdd from './EvaluateAdd';
import EvaluateCopyModal from './EvaluateCopyModal';

export default {
  name: 'EvaluateEditor',
  components: {
    EvaluateDropZone,
    EvaluateAdd,
    EvaluateSetCondition,
    EvaluateSetStatement,
    EvaluateSetTransition,
    EvaluateSetApi,
    EvaluateSetResponse,
    EvaluateSetCapture,
    EvaluateSetPrompt,
    EvaluateSetAi,
    EvaluateSetEmail,
    EvaluateSetParallel,
  },
  props: {
    data: {
      type: Array,
    },
    selectedItemIds: {
      type: Array,
    },
    hiddenEvaluateTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      evaluate: null,
      selectedEvaluateItem: null,
      selectAll: false,
      validity: {},
    };
  },
  computed: {
    leftColClass() {
      const classes = ['flex flex-col flex-grow lg:max-w-1/2 pb-2'];
      if (this.selectedEvaluateItem) {
        classes.push('hidden lg:flex');
      }
      return classes;
    },
    rightColClass() {
      const classes = ['flex-grow pl-2 lg:max-w-1/2 border-l border-gray-200 border-solid '];
      if (this.selectedEvaluateItem) {
        classes.push('flex flex-col');
      } else {
        classes.push('hidden lg:flex');
      }
      return classes;
    },
    selectedEvaluateItemId() {
      return this.selectedEvaluateItem?.id;
    },
  },
  methods: {
    onDeleteClick() {
      this.$showModal(DeleteModal, {
        subtitle: this.$t('evaluate.confirm_remove_items_message'),
        onConfirm: () => {
          this.selectedItemIds.forEach((id) => {
            this.$emit('unselect', id);
            removeEvaluateItem(this.evaluate, id);
          });
          this.selectAll = false;
          this.updateEvaluatePayload();
        },
      });
    },
    onCopyToClick() {
      this.$showModal(
        EvaluateCopyModal,
        { selectedItemIds: this.selectedItemIds, evaluateData: this.data },
        {
          addToCurrent: (items) => {
            items.forEach((item) => {
              this.addEvaluation(item);
            });
          },
        },
      );
    },
    getValidity(id) {
      return id in this.validity ? this.validity[id] : true;
    },
    onValidChange(valid) {
      this.validity[this.selectedEvaluateItemId] = valid;
    },
    isSelected(evaluateItem) {
      return this.selectedItemIds.includes(evaluateItem.id);
    },
    onDrop($event, evaluateItem) {
      this.onItemClicked(null, evaluateItem);
      this.$emit('evaluate-update', $event);
    },
    getEvaluateTitle(type) {
      return type;
    },
    onSelectedEvaluateItemUpdated(item) {
      updateEvaluateItem(this.evaluate, item);
      this.updateEvaluatePayload();
    },
    deleteEvaluate() {
      removeEvaluateItem(this.evaluate, this.selectedEvaluateItemId);
      this.selectedEvaluateItem = null;
      this.updateEvaluatePayload();
    },
    onEvaluateItemUpdated(evaluateItem, index) {
      this.evaluate[index] = evaluateItem;
      this.updateEvaluatePayload();
    },
    onItemClicked($event, evaluateItem) {
      if (evaluateItem.id === this.selectedEvaluateItemId) {
        this.selectedEvaluateItem = null;
      } else {
        this.selectedEvaluateItem = null;
        this.$nextTick(() => {
          this.selectedEvaluateItem = evaluateItem;
        });
      }
      this.$emit('item-clicked', evaluateItem);
    },
    onAddClicked(type) {
      if (type === 'transition') {
        this.addEvaluation({
          type: 'transition',
          payload: {
            id: uuidv4(),
            type: 'end',
            value: null,
          },
        });
      } else if (type === 'statement') {
        this.addEvaluation({
          type: 'statement',
          payload: {
            variable: null,
            assignment_type: 'function',
            value: {
              arguments: [],
              function: null,
            },
          },
        });
      } else if (type === 'condition') {
        this.addEvaluation({
          type: 'condition',
          payload: {
            expressions: [
              {
                id: uuidv4(),
                value: '',
                payload: [], // operations..
              },
            ],
            else: [], // operation or empty array
          },
        });
      } else if (type === 'api') {
        this.addEvaluation(structuredClone(EVALUATE_API_PAYLOAD));
      } else if (type === 'response') {
        this.addEvaluation({
          type: 'response',
          payload: {
            text: [],
            card: {
              title: '',
              subtitle: '',
              text: '',
              image: {
                alt: '',
                url: '',
              },
              chart: [],
              table: '',
              insights: [],
            },
            prompt: {
              variable: '',
              value: {
                direction: 'vertical',
                title: '',
                subtitle: '',
                items: '',
                labelColumn: '',
                descriptionColumn: '',
                imageUrlColumn: '',
                imageAltTextColumn: '',
              },
            },
            json: [],
          },
        });
      } else if (type === 'prompt') {
        this.addEvaluation({
          type: 'prompt',
          payload: {},
        });
      } else if (type === 'capture') {
        this.addEvaluation({
          type: 'capture',
          payload: [],
        });
      } else if (type === 'parallel') {
        this.addEvaluation({
          type: 'parallel',
          payload: [],
        });
      } else if (type === 'ai') {
        this.addEvaluation({
          type: 'ai',
          payload: {
            variable: '',
            value: {
              context: '',
              datatable: '',
              max_length: '100',
            },
          },
        });
      } else if (type === 'email') {
        this.addEvaluation({
          type: 'email',
          payload: {
            email_template: '',
            from: {
              email: '',
              name: '',
            },
            to: [],
            subject: '',
            data_binding: [],
          },
        });
      }
    },
    updateEvaluation(evaluation) {
      const index = this.evaluate.findIndex((i) => i.payload.id === evaluation.id);
      this.evaluate[index].payload = evaluation;
      this.updateEvaluatePayload();
    },
    addEvaluation(evaluation) {
      evaluation.id = uuidv4();
      this.evaluate.push(evaluation);
      this.updateEvaluatePayload();
      this.onItemClicked(null, evaluation);
    },
    updateEvaluatePayload() {
      this.$emit('evaluate-update', this.evaluate);
    },
  },
  watch: {
    data: {
      handler() {
        if (this.data) {
          this.evaluate = JSON.parse(JSON.stringify(this.data));
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
