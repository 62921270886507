<template>
  <div class="h-full flex flex-col">
    <div class="flex w-full pb-4 items-center flex-shrink-0 pl-2">
      <div class="ml-auto flex flex-row justify-center">
        <VDropdown v-if="hasError" class="inline" placement="bottom" :triggers="['hover']">
          <Icon name="info" class="w-5 h-5 mr-4 mt-2 text-alert" />
          <template #popper>
            <div class="w-full max-w-300 p-2" v-html="errorDetailText"></div>
          </template>
        </VDropdown>
        <v-select
          v-model="filter"
          dense
          :reduce="(option) => option.value"
          :options="filterOptions"
          auto-select
          rounded
          elevated
          :clearable="false"
          :searchable="false"
          alternative-indicator
          class="mr-3 max-sm:w-[120px] w-200"
        />
        <v-select
          v-model="language"
          dense
          :options="selectLanguagesMobile"
          :reduce="(option) => option.value"
          auto-select
          rounded
          elevated
          :clearable="false"
          :searchable="false"
          alternative-indicator
          class="sm:hidden mr-3"
          @update:modelValue="onLanguageChange"
        />
        <v-select
          v-model="language"
          dense
          :options="selectLanguages"
          :reduce="(option) => option.value"
          auto-select
          rounded
          elevated
          :clearable="false"
          :searchable="false"
          alternative-indicator
          class="w-[120px] max-sm:hidden mr-3"
          @update:modelValue="onLanguageChange"
        />
        <PillButton :loading="saving" :disabled="saving || !dataFetched" :text="$t('save')" icon="save" text-class="max-sm:hidden" icon-class="max-sm:mr-0" primary @click="save" />
      </div>
    </div>
    <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10">
      <div class="h-full flex flex-col">
        <div class="flex flex-col relative overflow-hidden flex-grow">
          <div class="flex-grow overflow-auto border-b border-gray-300 border-solid pb-[300px]">
            <div class="w-full flex flex-wrap px-0.5">
              <Draggable
                v-model="fineTuneData"
                itemKey="id"
                v-bind="dragOptions"
                class="w-full"
                handle=".drag-handle"
                :component-data="{ tag: 'div', type: 'transition-group', name: !drag ? 'flip-list' : null }"
                @start="drag = true"
                @end="drag = false"
              >
                <template #item="{ element: item, index }">
                  <div :class="{ '!flex': getShowStatus(index), hidden: !getShowStatus(index) }" class="items-center">
                    <div class="drag-handle mr-2 cursor-grab"><Icon name="drag_indicator" class="w-4 h-4" /></div>
                    <FineTuneInput
                      :id="`fine-tune-item-${index}`"
                      :ref="`fine-tune-item-${index}`"
                      v-model="fineTuneData[index]"
                      class="w-full px-2 mb-2 hidden items-start"
                      :show="getShowStatus(index)"
                      :class="{ '!flex': getShowStatus(index), 'bg-gray-100 outline outline-1 outline-gray-200': item.expand }"
                      @update:modelValue="addNewItem"
                      @newline="onNewLine($event, index)"
                      @remove="removeItem(index)"
                      @error="onErrorChange(item, index, $event)"
                      @focus="onInputFocus(index)"
                    >
                    </FineTuneInput>
                  </div>
                </template>
              </Draggable>
            </div>
          </div>
          <div class="max-md:flex-col w-full flex justify-between px-2 sm:px-7 pt-3 items-center -mt-3 flex-shrink-0">
            <div class="flex flex-wrap items-center -mb-2 mt-3 max-md:justify-start pb-2">
              <span class="text-13 font-600 mb-2 mr-2">Legend:</span>
              <div v-for="legend in entityLegends" :key="legend.label" class="mr-2 rounded-4 px-1 py-0 text-12 text-white mb-2" :style="{ backgroundColor: legend.color }">{{ legend.label }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import FineTuneInput from '@/components/project/FineTuneInput.vue';
import uuidv4 from '@/helpers/uuid';

export default {
  name: 'FineTune',
  components: { FineTuneInput },
  props: {
    model: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      fineTuneData: [{ p: '', d: [], l: null, t: 'tra', id: uuidv4() }],
      language: 'EN',
      filter: 'tra',
      filterOptions: [
        { label: 'Training', value: 'tra' },
        { label: 'Validation', value: 'val' },
        { label: 'Out of Scope Training', value: 'otra' },
        { label: 'Out of Scope Validation', value: 'oval' },
        { label: 'Excluded Subjects', value: 'exc' },
      ],
      saving: false,
      dataFetched: false,
      drag: false,
      errors: {},
    };
  },
  created() {
    this.language = this.user?.language || 'EN';
    if (this.designTimeLanguage && this.languages.some((lang) => lang.value === this.designTimeLanguage)) {
      this.language = this.designTimeLanguage;
    } else if (!this.designTimeLanguage) {
      this.SET_DESIGN_TIME_LANGUAGE(this.language);
    }
    this.fetchData();
  },
  computed: {
    ...mapState(['user', 'designTimeLanguage']),
    ...mapGetters(['getProjectDataTypes']),
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'drag-ghost',
      };
    },
    languages() {
      return ['EN', 'TR'].map((lang) => ({ value: lang, label: lang }));
    },
    selectLanguages() {
      return ['EN', 'TR'].map((lang) => ({ value: lang, label: this.$t(`languages.${lang}`) }));
    },
    selectLanguagesMobile() {
      return ['EN', 'TR'].map((lang) => ({ value: lang, label: lang }));
    },
    entityLegends() {
      return Object.keys(this.getProjectDataTypes())
        .filter((entity) => !!this.getProjectDataTypes()[entity].colour)
        .map((entity) => {
          return {
            label: entity,
            color: this.getProjectDataTypes()[entity].colour,
          };
        });
    },
    hasError() {
      return !!Object.keys(this.errors).length;
    },
    errorDetailText() {
      const text = [];

      const errorItems = this.fineTuneData.filter((i) => Object.keys(this.errors).includes(i.id));
      const languages = errorItems.map((i) => i.l);

      const result = {};
      languages.forEach((lang) => {
        result[lang] = {};
        this.filterOptions.forEach((f) => {
          result[lang][f.value] = new Set();
        });
      });

      errorItems.forEach((errorItem) => {
        result[errorItem.l][errorItem.t].add(errorItem.p);
      });

      Object.keys(result).forEach((lang) => {
        const filter = result[lang];
        Object.keys(filter).forEach((f) => {
          if (filter[f].size) {
            const filterLabel = this.filterOptions.find((i) => i.value === f).label;
            text.push(`${result[lang][f].size} error(s) in ${filterLabel} set of ${lang}`);
          }
        });
      });
      return text.join('.<br />');
    },
    projectId() {
      return this.$route.params.projectId;
    },
    modelId() {
      return this.$route.params.modelId;
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'updateProjectModel']),
    ...mapMutations(['SET_DESIGN_TIME_LANGUAGE']),
    getShowStatus(index) {
      if (this.fineTuneData[index].l === this.language && this.fineTuneData[index].t === this.filter) {
        return true;
      }
      return false;
    },
    onLanguageChange(lang) {
      this.SET_DESIGN_TIME_LANGUAGE(lang);
    },
    onNewLine(phrases, index) {
      phrases.forEach((phrase, i) => {
        this.fineTuneData.splice(index + i + 1, 0, { p: phrase, d: [], l: this.language, t: this.filter, id: uuidv4() });
      });
    },
    addNewItem() {
      const items = this.fineTuneData.filter((i) => i.l === this.language && i.t === this.filter);
      const lastItem = items[items.length - 1];
      if (!items.length || !lastItem || lastItem?.p) {
        this.fineTuneData.push({ p: '', d: [], l: this.language, t: this.filter, id: uuidv4() });
      } else if (!lastItem?.p && lastItem.t !== this.filter) {
        lastItem.t = this.filter;
      }
    },
    removeItem(index) {
      this.fineTuneData.splice(index, 1);
      this.addNewItem();
    },
    onErrorChange(item, index, $event) {
      if ($event) {
        this.errors[item.id] = $event;
      } else {
        delete this.errors[item.id];
      }
    },
    onInputFocus(index) {
      this.fineTuneData.forEach((item, i) => {
        this.$refs[`fine-tune-item-${i}`].expand = false;
      });
      this.$refs[`fine-tune-item-${index}`].expand = true;
    },
    async save() {
      this.saving = true;
      let data = JSON.parse(JSON.stringify(this.fineTuneData)).filter((d) => d.p);
      data = data.map((d) => {
        if (['exc', 'otra', 'oval'].includes(d.t)) {
          delete d.d;
        }
        delete d.id;
        return d;
      });
      await this.updateProjectModel({ project_id: this.projectId, model_id: this.modelId, name: this.model.name, type: this.model.type, finetunedata: data, suggestions: this.model.suggestions });
      this.saving = false;
    },
    fetchData() {
      this.dataFetched = true;
      this.fineTuneData = structuredClone(this.model.finetunedata);
      this.fineTuneData.map((item) => {
        if (!item.id) item.id = uuidv4();
        if (!item.l) item.l = null;
        if (!('t' in item)) item.t = true;
        return item;
      });
      this.addNewItem();
    },
  },
  watch: {
    language() {
      this.addNewItem();
    },
    filter() {
      this.addNewItem();
    },
  },
};
</script>
