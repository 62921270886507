<template>
  <div v-html="require(`!!html-loader!@shared/assets/icons/${name}.svg`)" class="fill-current" @click="$emit('click', $event)"></div>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: String,
  },
  emits: ['click'],
  mounted() {
    const svg = this.$el.querySelector('svg');
    if (svg) {
      svg.classList.add(['fill-current']);
      svg.classList.add(['w-full']);
      svg.classList.add(['h-full']);
    }
    // if (this.$el.parentNode) {
    //   this.$el.outerHTML = this.$el.innerHTML;
    // }
  },
};
</script>
