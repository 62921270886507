<template>
  <textarea
    class="rounded-4 text-black text-13 leading-16 outline-none border border-solid p-2"
    :class="{
      'bg-gray-200 border-transparent cursor-default': disabled,
      'border-gray-300': !disabled,
    }"
    @input="updateValue($event.target.value)"
    :value="modelValue"
    :disabled="disabled"
  />
</template>

<script>
export default {
  name: 'Textarea',
  emits: ['update:modelValue'],
  props: {
    type: {
      type: String,
      default: 'text',
    },
    disabled: Boolean,
    modelValue: {
      type: String,
      default: '',
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('update:modelValue', value);
    },
  },
};
</script>
