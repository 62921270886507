<template>
  <div class="accordion-item" :class="{ 'is-active': active }">
    <div class="accordion-item-title">
      <button @click="toggle" class="w-full text-left bg-transparent border-transparent accordion-item-trigger">
        <span class="accordion-item-title-text text-13">{{ title }} <slot name="title" /></span>
      </button>
    </div>
    <transition name="accordion-item" @enter="startTransition" @after-enter="endTransition" @before-leave="startTransition" @after-leave="endTransition">
      <div v-if="active" class="overflow-hidden accordion-item-details">
        <div class="accordion-item-details-inner">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String },
    index: { type: Number },
    showArrow: {
      type: Boolean,
    },
    reversed: { type: Boolean },
  },
  computed: {
    active() {
      return this.$parent.activeIds.includes(this.index);
    },
  },
  methods: {
    toggle() {
      this.$parent.eventbus.emit('change', {
        active: !this.active,
        index: this.index,
      });
    },
    activate() {
      this.$parent.eventbus.emit('change', { active: true, index: this.index });
    },
    startTransition(el) {
      el.style.height = `${el.scrollHeight}px`;
    },
    endTransition(el) {
      el.style.height = '';
    },
  },
};
</script>
