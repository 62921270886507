<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2 h-14">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-primary font-600">{{ $t('projects.ai_providers.title') }}</div>
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <div class="pl-1 font-500 mb-2 text-13">Active AI Providers</div>
        <template v-if="Object.keys(aiProviders).length">
          <div class="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 pt-1">
            <ListCard v-for="(aiProvider, index) in aiProviders" :key="`${index}-${aiProvider}`" class="h-20 py-2 bg-white rounded-20 cursor-pointer" @click="onEditClicked(aiProvider)">
              <div class="flex pl-4 pr-2 items-center text-14 font-500 text-gray-900 truncate">
                <div class="mr-3"><img :src="`/img/providers/${getIcon(aiProvider.provider)}`" class="h-6" /></div>
                <div>
                  {{ getName(aiProvider.provider) }}
                </div>
              </div>
            </ListCard>
          </div>
        </template>
        <div class="pl-1 font-500 mb-2 mt-6 text-13">Available AI Providers</div>
        <template v-if="Object.keys(availableProviders).length">
          <div class="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 pt-1">
            <ListCard v-for="(aiProvider, index) in availableProviders" :key="`${index}-${aiProvider}`" class="h-20 py-2 bg-white rounded-20 cursor-pointer" @click="onCreateClicked(aiProvider)">
              <div class="flex pl-4 pr-2 items-center text-14 font-500 text-gray-900 truncate">
                <div class="mr-3"><img :src="`/img/providers/${getIcon(aiProvider.key)}`" class="h-6" /></div>
                <div>
                  {{ aiProvider.name }}
                </div>
              </div>
            </ListCard>
          </div>
        </template>
        <div v-else class="flex flex-col h-full flex-grow items-center justify-center pb-50">
          <div class="my-2 text-22 text-gray-700">{{ $t('projects.ai_providers.have_no_providers') }}</div>
          <div class="my-2 text-gray-600">{{ $t('projects.ai_providers.create_first_provider') }}</div>
          <PillButton class="my-3" :text="$t('projects.ai_providers.create_new')" primary @click="onCreateClicked" />
        </div>
      </div>
    </div>
    <Modal v-if="fetching">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import DeleteModal from '@shared/components/shared-modals/DeleteModal.vue';
import CreateAIProviderModal from '@/components/project/CreateAIProviderModal.vue';

export default {
  name: 'AIProviders',
  data() {
    return {
      fetching: false,
    };
  },
  computed: {
    ...mapState(['projectData', 'availableAIProviders']),
    aiProviders() {
      return Object.values(this.projectData[this.projectId]?.aiProviders || {});
    },
    projectId() {
      return this.$route.params.projectId;
    },
    availableProviders() {
      const availableProviders = structuredClone(this.availableAIProviders || {});
      this.aiProviders.forEach((provider) => {
        delete availableProviders[provider.provider];
      });
      return availableProviders;
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchProjectAIProviders', 'deleteProjectAIProvider', 'fetchProjectAvailableAIProviders']),
    getIcon(name) {
      const icon = {
        ANTHROPIC_CLAUDE: 'claude.png',
        AZURE_OPENAI: 'azure.png',
        GOOGLE_GEMINI_PRO: 'gemini.webp',
        OPENAI: 'openai.svg',
      };
      return icon?.[name] || '';
    },
    getName(provider) {
      return this.availableAIProviders[provider].name;
    },
    onCreateClicked(item) {
      this.$showModal(CreateAIProviderModal, { projectId: this.projectId, provider: item.key, providerName: item.name });
    },
    onEditClicked(item) {
      const modal = this.$showModal(CreateAIProviderModal, {
        projectId: this.projectId,
        editData: item,
        provider: item.provider,
        providerName: this.getName(item.provider),
        onDelete: async () => {
          await this.onDeleteClicked(item, modal);
        },
      });
    },
    onDeleteClicked(item, modal) {
      this.$showModal(DeleteModal, {
        subtitle: this.$t('projects.ai_providers.remove_provider', { templateName: item.name }),
        info: null,
        onConfirm: async () => {
          await this.onDeleteConfirmClicked(item);
          modal.close();
        },
      });
    },
    async onDeleteConfirmClicked(item) {
      await this.deleteProjectAIProvider({ project_id: this.projectId, provider: item.provider });
    },
  },
  watch: {
    projectId: {
      async handler() {
        this.fetching = true;
        await this.fetchProjectAvailableAIProviders();
        await this.fetchProjectAIProviders({ projectId: this.$route.params.projectId });
        this.fetching = false;
      },
      immediate: true,
    },
  },
};
</script>
