<template>
  <Modal
    container-class="!w-[95vw]"
    fullbelowsm
    :title="!editData ? $t('projects.personas.create') : $t('projects.personas.edit')"
    :primary-button="$t('save')"
    :secondary-button="$t('cancel')"
    :secondary-button-disabled="loading"
    :primary-button-loading="loading"
    :primary-button-disabled="!name || !value || loading"
    @primary="onSaveClick"
    @secondary="onCancelClick"
    @opened="onModalOpen"
  >
    <div class="w-full max-w-full min-h-full md:min-h-600 flex flex-col">
      <div class="w-full min-h-21 flex flex-col justify-start mt-3 py-3 px-10 flex-shrink-0">
        <FormInput v-model="name" ref="name" :label="$t('projects.personas.name')" :readonly="loading" />
      </div>

      <div class="w-full min-h-21 flex flex-col justify-start mt-3 py-3 px-10 flex-shrink-0 flex-grow">
        <label class="block text-13 mb-1">{{ $t('value') }}</label>
        <div class="w-full relative border flex-grow flex flex-col">
          <div class="flex h-5">
            <div class="text-13 cursor-pointer w-auto px-3 text-center border-r" :class="{ 'border-b': tab !== 'editor' }" @click="tab = 'editor'">Markdown</div>
            <div class="text-13 cursor-pointer w-auto px-3 text-center border-r" :class="{ 'border-b': tab !== 'preview' }" @click="tab = 'preview'">
              {{ $t('projects.personas.md_preview') }}
            </div>
            <div class="flex-grow border-b"></div>
          </div>
          <div class="flex-grow grid w-full">
            <div v-show="tab === 'editor'">
              <MonacoEditor v-model:value="value" :options="options" width="100%" height="100%" class="flex-grow h-full" language="markdown" @mount="monacoMounted"></MonacoEditor>
            </div>
            <div class="w-full h-full overflow-auto px-1" v-show="tab === 'preview'">
              <div ref="preview"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import MonacoEditor from '@guolao/vue-monaco-editor';
import { mapActions } from 'vuex';
import markdownit from 'markdown-it';

export default {
  name: 'CreatePersonaModal',
  components: { MonacoEditor },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    editData: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      name: '',
      nameError: '',
      text: '',
      textError: '',
      tab: 'editor',
      value: null,
      personaId: null,
      editor: null,
      options: {
        formatOnType: true,
        formatOnPaste: true,
      },
    };
  },
  created() {
    if (this.editData) {
      const { name, value, persona_id } = this.editData;
      this.name = name;
      this.value = value;
      this.personaId = persona_id;
    }
  },
  methods: {
    ...mapActions(['showToastMessage', 'createProjectPersona', 'updateProjectPersona']),
    onModalOpen() {
      this.$refs?.name?.focus();
    },
    monacoMounted(editor) {
      this.editor = editor;
    },
    async onSaveClick() {
      this.loading = true;
      if (this.editData) {
        const response = await this.updateProjectPersona({ project_id: this.projectId, persona_id: this.personaId, name: this.name, value: this.value });
        if (response) {
          this.$emit('close');
        }
      } else {
        const response = await this.createProjectPersona({ project_id: this.projectId, name: this.name, value: this.value });
        if (response) {
          this.$emit('close');
        }
      }
      this.loading = false;
    },
    onCancelClick() {
      this.$emit('close');
    },
  },
  watch: {
    async tab() {
      if (this.tab === 'preview') {
        const md = markdownit();
        this.$refs.preview.innerHTML = md.render(this.value);
      }
    },
  },
};
</script>

<style scoped></style>
