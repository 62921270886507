<template>
  <div class="pr-2 md:pr-10 pl-2">
    <div class="flex items-center pb-4">
      <div class="text-14 mr-auto font-700">{{ total }} Active users</div>
      <PillButton :text="$t('users.add_user')" icon="plus" primary @click="onAddUserClick" />
    </div>
    <div>
      <div v-if="loading">
        <div class="flex-grow flex items-center justify-center h-full">
          <Spinner class="w-8" />
        </div>
      </div>
      <div v-else-if="!users.length" class="w-full text-center text-13 text-gray-600">There is no record found!</div>
      <div v-else class="justify-items-center">
        <ListCard v-for="user in users" :key="user.email" class="mb-4 !p-1 sm:p-3 bg-white w-full">
          <div class="cursor-pointer w-6 h-6 flex-shrink-0 flex-grow-0 mr-3">
            <Avatar size="6" :picture="user.photoUrl" />
          </div>
          <div class="overflow-hidden">
            <div class="truncate">
              <span class="font-600">{{ user.displayName }}</span> {{ user.email }}
            </div>
            <div class="text-12">{{ $t('users.last_login') }} {{ user.last_login ? dayjs(parseInt(user.last_login)).format('MMM DD, YYYY HH:mm') : $t('n_a') }}</div>
          </div>
          <div>
            <Badge v-if="user.disabled" warn text="Disabled" class="ml-1 sm:ml-3" />
          </div>
          <div class="ml-auto flex">
            <IconButton :title="$t('edit')" class="text-primary" icon="edit" @click="onEditClick(user)" />
            <IconButton class="text-primary" icon="delete" :title="$t('delete')" @click="onDeleteClick(user)" />
          </div>
        </ListCard>
      </div>
      <div class="flex justify-center items-center mt-8">
        <IconButton :disabled="pageNumber === 0" icon="chevron_left" @click="prev" />
        <span class="px-3">{{ pageNumber + 1 }}</span>
        <IconButton :disabled="!showNext" icon="chevron_right" @click="next" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import dayjs from 'dayjs';
import { apiGetUserCount } from '@/helpers/api';
import AddUserModal from '@/components/admin/AddUserModal.vue';
import EditUserModal from '@/components/admin/EditUserModal.vue';

export default {
  name: 'Users',
  data() {
    return {
      pageNumber: 0,
      total: 0,
      loading: false,
      pageSize: 10,
    };
  },
  created() {
    apiGetUserCount().then((response) => {
      if (response.status === 200) {
        this.total = parseInt(response?.data?.count, 10) || 0;
      }
    });
    this.getUsers({ page_number: this.pageNumber, pagesize: this.pageSize });
  },
  beforeUnmount() {
    this.SET_USERS([]);
  },
  computed: {
    ...mapState(['users']),
    showNext() {
      return this.total > (this.pageNumber + 1) * this.pageSize;
    },
  },
  methods: {
    ...mapActions(['fetchUsers', 'showToastMessage']),
    ...mapMutations(['SET_USERS']),
    dayjs,
    onEditClick(user) {
      this.$showModal(EditUserModal, { email: user.email });
    },
    onDeleteClick() {},
    onAddUserClick() {
      this.$showModal(
        AddUserModal,
        {},
        {
          add: () => {
            this.getUsers({ page_number: this.pageNumber, pagesize: this.pageSize });
          },
        },
      );
    },
    async next() {
      const response = await this.getUsers({ page_number: this.pageNumber + 1, pagesize: this.pageSize });
      if (Array.isArray(response) && response.length) {
        this.pageNumber++;
      }
    },
    async prev() {
      if (this.pageNumber === 0) {
        return;
      }
      const response = await this.getUsers({ page_number: this.pageNumber - 1, pagesize: this.pageSize });
      if (response.length) {
        this.pageNumber--;
      }
    },
    async getUsers(params) {
      this.loading = true;
      const response = await this.fetchUsers(params);
      this.loading = false;
      return response;
    },
  },
};
</script>
