import { del, get, post, put } from './fetch';

let store;

export function setStore(vuexStore) {
  store = vuexStore;
}

async function ResponseAdapter(response) {
  switch (response.status) {
    case 200:
    case 400:
    case 401:
    case 403:
      try {
        let currentUser = response.headers.get('Current-User');
        if (currentUser) {
          currentUser = JSON.parse(currentUser);
        }
        const parsedResponse = await response.json();
        return { status: response.status, data: parsedResponse, currentUser };
      } catch (e) {
        return {
          status: response.status,
          message: e.message,
        };
      } finally {
        if (response.status === 401) {
          store.dispatch('logout');
        }
      }
    case 500:
      try {
        const parsedResponse = await response.json();
        return {
          status: response.status,
          data: {
            error: true,
            message: parsedResponse.message,
          },
        };
      } catch (e) {
        return {
          status: response.status,
          data: {
            error: true,
            message: e.message,
          },
        };
      }
    default:
      break;
  }

  return {
    status: response.status,
    message: response?.data?.message || 'UNKNOWN_DATA',
  };
}

export async function apiPostLogout() {
  return ResponseAdapter(await post('/api/v1/auth/logout'));
}
export async function apiDeleteAccount() {
  return ResponseAdapter(await del('/api/v1/auth/account'));
}
export async function apiPostChangePassword({ password }) {
  return ResponseAdapter(await post('/api/v1/auth/changepassword', { password }));
}

export async function apiGetTenant() {
  return ResponseAdapter(await get('/api/v1/tenant'));
}

export async function apiGetAddresses() {
  return ResponseAdapter(await get('/api/v1/tenant/subscription/addresslist'));
}
export async function apiPutAddress({ address_id, description, first_line, second_line, city, postal_code, region, country_code, status, custom_data }) {
  return ResponseAdapter(await put('/api/v1/tenant/subscription/address', { address_id, description, first_line, second_line, city, postal_code, region, country_code, status, custom_data }));
}
export async function apiChangeSubscriptionAddress({ address_id }) {
  return ResponseAdapter(await post('/api/v1/tenant/subscription/billingaddress', { address_id }));
}
export async function apiGetPayments() {
  return ResponseAdapter(await get('/api/v1/tenant/subscription/payments'));
}
export async function apiPostCancelSubscription() {
  return ResponseAdapter(await post('/api/v1/tenant/subscription/cancel'));
}
export async function apiPostPauseSubscription({ type, subscription_id, paused }) {
  return ResponseAdapter(await post('/api/v1/tenant/subscription/pause', { type, subscription_id, paused }));
}
export async function apiPostResumeSubscription() {
  return ResponseAdapter(await post('/api/v1/tenant/subscription/cancelscheduled'));
}
export async function apiPostUpdateSubscription({ price_id }) {
  return ResponseAdapter(await post('/api/v1/tenant/subscription/update', { price_id }));
}
export async function apiPostUpdatePreviewSubscription({ price_id }) {
  return ResponseAdapter(await post('/api/v1/tenant/subscription/preview', { price_id }));
}
export async function apiGetInvoice({ transaction_id }) {
  return ResponseAdapter(await get(`/api/v1/tenant/subscription/invoice?transaction_id=${transaction_id}`));
}

export async function apiPostConversationDefinition({ name, type, model_id, steps, transition }) {
  return ResponseAdapter(await post('/api/v1/conversation', { name, type, model_id, steps, transition }));
}

export async function apiGetDataTypeRelationship({ type, model_id }) {
  return ResponseAdapter(await get(`/api/v1/entity/relationships?type=${type}&model_id=${model_id}`));
}
export async function apiPutDataTypeRelationship({ type, model_id, relationships }) {
  return ResponseAdapter(await put('/api/v1/entity/relationships', { type, model_id, relationships }));
}

export async function apiPostSendSigninEmailLink({ email, token }) {
  const payload = { email, token };
  if (window.location.host === 'local.fidsy.com') {
    payload.local = true;
  }
  return ResponseAdapter(await post('/api/v1/auth/loginwithemail', payload));
}
export async function apiPostRegister({ password, name, timezone, picture, country, organisation, position, language }) {
  const payload = { password, name, timezone, picture, country, organisation, position, language };
  return ResponseAdapter(await post('/api/v1/auth/register', payload));
}
export async function apiPostResetPassword({ email, token, password }) {
  return ResponseAdapter(await post('/api/v1/auth/passwordreset', { email, token, password }));
}
export async function apiPostForgotPassword({ email, token, local = false }) {
  if (window.location.host === 'local.fidsy.com') {
    local = true;
  }
  return ResponseAdapter(await post('/api/v1/auth/forgotpassword', { email, token, local }));
}
export async function apiPostLoginWithPassword({ email, password, token }) {
  const payload = { email, password, token };
  return post('/api/v1/auth/loginwithpassword', payload);
}

export async function apiPostFeedback({ sentiment, message, fields, data, phrase }) {
  return ResponseAdapter(await post('/api/v1/feedback', { sentiment, message, fields, data, phrase }));
}

export async function apiPostEmailCardToUser(data) {
  return ResponseAdapter(await post('/api/v1/emailcardtouser', data));
}

export async function apiPutProfile(payload) {
  return ResponseAdapter(await put('/api/v1/tenant/profile', payload));
}

export async function apiGetFunctions() {
  return ResponseAdapter(await get(`/api/v1/projects/functions`));
}

export async function apiGetAuthProviders() {
  return ResponseAdapter(await get(`/api/v1/auth/providers`));
}

export async function apiPutFlag({ key, value }) {
  return ResponseAdapter(await put(`/api/v1/flag`, { key, value }));
}

export async function apiGetUsers({ page_number = 0, pagesize = 10 }) {
  return ResponseAdapter(await get(`/api/v1/admin/users?pagesize=${pagesize}&pagenumber=${page_number}`));
}
export async function apiGetUserCount() {
  return ResponseAdapter(await get(`/api/v1/admin/users/count`));
}
export async function apiGetUser({ email }) {
  const response = await get(`/api/v1/admin/user?email=${email}`);
  return ResponseAdapter(response);
}
export async function apiPostAddUser({ email, password, name, timezone, country, organisation, position, language, flags }) {
  const response = await post(`/api/v1/admin/user`, { email, password, name, timezone, country, organisation, position, language, flags });
  return ResponseAdapter(response);
}
export async function apiPutUser({ email, name, timezone, country, organisation, position, language, flags }) {
  const response = await put(`/api/v1/admin/user`, { email, name, timezone, country, organisation, position, language, flags });
  return ResponseAdapter(response);
}

export async function apiGetProjectData(id) {
  return ResponseAdapter(await get(`/api/v1/project/data?project_id=${id}`));
}
export async function apiGetProjects() {
  return ResponseAdapter(await get('/api/v1/project/list'));
}
export async function apiGetProject(project_id) {
  return ResponseAdapter(await get(`/api/v1/project?project_id=${project_id}`));
}
export async function apiPostProject({ name }) {
  return ResponseAdapter(await post('/api/v1/project', { name }));
}
export async function apiPutProject({ project_id, name, brand_name, disabled, theme }) {
  return ResponseAdapter(await put('/api/v1/project', { project_id, name, brand_name, disabled, theme }));
}
export async function apiPutProjectDisabled({ project_id, disabled }) {
  return ResponseAdapter(await put('/api/v1/project/disabled', { project_id, disabled }));
}
export async function apiPostCheckProjectName({ name }) {
  return ResponseAdapter(await post('/api/v1/project/checkname', { name }));
}

export async function apiDeleteProject(project_id) {
  return ResponseAdapter(await del(`/api/v1/project?project_id=${project_id}`));
}

export async function apiGetProjectPersonas(project_id) {
  return ResponseAdapter(await get(`/api/v1/project/persona/list?project_id=${project_id}`));
}
export async function apiPostProjectPersona({ project_id, name, value }) {
  return ResponseAdapter(await post('/api/v1/project/persona', { project_id, name, value }));
}
export async function apiGetProjectPersona(project_id, persona_id) {
  return ResponseAdapter(await get(`/api/v1/project/persona?project_id=${project_id}&persona_id=${persona_id}`));
}
export async function apiPutProjectPersona({ project_id, persona_id, name, value }) {
  return ResponseAdapter(await put('/api/v1/project/persona', { project_id, persona_id, name, value }));
}
export async function apiDeleteProjectPersona(project_id, persona_id) {
  return ResponseAdapter(await del(`/api/v1/project/persona?project_id=${project_id}&persona_id=${persona_id}`));
}

export async function apiGetProjectAvailableAIProviders() {
  return ResponseAdapter(await get(`/api/v1/projects/ai_providers`));
}
export async function apiGetProjectAIProviders(project_id) {
  return ResponseAdapter(await get(`/api/v1/project/ai_provider/list?project_id=${project_id}`));
}
export async function apiPostProjectAIProvider({ project_id, provider, key, url }) {
  return ResponseAdapter(await post('/api/v1/project/ai_provider', { project_id, provider, key, url }));
}
export async function apiDeleteProjectAIProvider(project_id, provider) {
  return ResponseAdapter(await del(`/api/v1/project/ai_provider?project_id=${project_id}&provider=${provider}`));
}

export async function apiGetProjectEndpoints(project_id) {
  return ResponseAdapter(await get(`/api/v1/project/endpoint/list?project_id=${project_id}`));
}
export async function apiGetProjectEndpoint(project_id, endpoint_id) {
  return ResponseAdapter(await get(`/api/v1/project/endpoint?project_id=${project_id}&endpoint_id=${endpoint_id}`));
}
export async function apiPostProjectEndpoint({ project_id, method, path, group_name, flow_id, request_params }) {
  return ResponseAdapter(await post('/api/v1/project/endpoint', { project_id, method, path, group_name, flow_id, request_params }));
}
export async function apiPutProjectEndpoint({ project_id, endpoint_id, method, path, group_name, flow_id, request_params }) {
  return ResponseAdapter(await put('/api/v1/project/endpoint', { project_id, endpoint_id, method, path, group_name, flow_id, request_params }));
}
export async function apiDeleteProjectEndpoint({ project_id, endpoint_id }) {
  return ResponseAdapter(await del(`/api/v1/project/endpoint?project_id=${project_id}&endpoint_id=${endpoint_id}`));
}

export async function apiGetProjectFlows(project_id) {
  return ResponseAdapter(await get(`/api/v1/project/flow/list?project_id=${project_id}`));
}
export async function apiGetProjectFlow(project_id, flow_id) {
  return ResponseAdapter(await get(`/api/v1/project/flow?project_id=${project_id}&flow_id=${flow_id}`));
}
export async function apiPostProjectFlow({ project_id, name, steps, transition }) {
  return ResponseAdapter(await post('/api/v1/project/flow', { project_id, name, steps, transition }));
}
export async function apiPutProjectFlow({ project_id, flow_id, name, steps, transition }) {
  return ResponseAdapter(await put('/api/v1/project/flow', { project_id, flow_id, name, steps, transition }));
}
export async function apiDeleteProjectFlow(project_id, flow_id) {
  return ResponseAdapter(await del(`/api/v1/project/flow?project_id=${project_id}&flow_id=${flow_id}`));
}

export async function apiGetProjectDatatypes(project_id) {
  return ResponseAdapter(await get(`/api/v1/project/datatype/list?project_id=${project_id}`));
}
export async function apiGetProjectDatatype(project_id, name) {
  return ResponseAdapter(await get(`/api/v1/project/datatype?project_id=${project_id}&name=${name}`));
}
export async function apiPostProjectDatatype({ project_id, name, fields, colour }) {
  return ResponseAdapter(await post('/api/v1/project/datatype', { project_id, name, fields, colour }));
}
export async function apiPutProjectDatatype({ project_id, name, fields, colour }) {
  return ResponseAdapter(await put('/api/v1/project/datatype', { project_id, name, fields, colour }));
}
export async function apiDeleteProjectDatatype(project_id, name) {
  return ResponseAdapter(await del(`/api/v1/project/datatype?project_id=${project_id}&name=${name}`));
}

export async function apiGetProjectVariables(project_id) {
  return ResponseAdapter(await get(`/api/v1/project/variable/list?project_id=${project_id}`));
}
export async function apiGetProjectVariable(project_id, name) {
  return ResponseAdapter(await get(`/api/v1/project/variable?project_id=${project_id}&name=${name}`));
}
export async function apiPostProjectVariable({ project_id, name, dataType, value }) {
  return ResponseAdapter(await post('/api/v1/project/variable', { project_id, name, dataType, value }));
}
export async function apiPutProjectVariable({ project_id, name, dataType, value }) {
  return ResponseAdapter(await put('/api/v1/project/variable', { project_id, name, dataType, value }));
}
export async function apiDeleteProjectVariable({ project_id, name }) {
  return ResponseAdapter(await del(`/api/v1/project/variable?project_id=${project_id}&name=${name}`));
}

export async function apiGetProjectAuthentication(project_id) {
  return ResponseAdapter(await get(`/api/v1/project/authentication?project_id=${project_id}`));
}
export async function apiPutProjectAuthentication({ project_id, provider, config }) {
  return ResponseAdapter(await put(`/api/v1/project/authentication`, { project_id, provider, config }));
}

export async function apiGetProjectPublishList(project_id) {
  return ResponseAdapter(await get(`/api/v1/project/publish/list?project_id=${project_id}`));
}
export async function apiPostProjectPublish({ project_id, version }) {
  return ResponseAdapter(await post(`/api/v1/project/publish`, { project_id, version }));
}
export async function apiDeleteProjectPublish({ project_id, version, user }) {
  return ResponseAdapter(await del(`/api/v1/project/publish?project_id=${project_id}&version=${version}&user=${user}`));
}

export async function apiGetProjectModelBuilds(project_id, model_id) {
  return ResponseAdapter(await get(`/api/v1/project/modelbuild/list?project_id=${project_id}&model_id=${model_id}`));
}
export async function apiGetProjectModelBuild({ project_id, model_id, version }) {
  return ResponseAdapter(await get(`/api/v1/project/modelbuild?project_id=${project_id}&model_id=${model_id}&version=${version}`));
}
export async function apiPostProjectModelBuild({ project_id, model_id, version, provider, baseModel }) {
  return ResponseAdapter(await post('/api/v1/project/modelbuild', { project_id, model_id, version, provider, baseModel }));
}
export async function apiDeleteProjectModelBuild({ project_id, model_id, version }) {
  return ResponseAdapter(await del(`/api/v1/project/modelbuild?project_id=${project_id}&model_id=${model_id}&version=${version}`));
}

export async function apiGetProjectModelBuildEvents({ project_id, model_id, version }) {
  return ResponseAdapter(await get(`/api/v1/project/modelbuild/events?project_id=${project_id}&model_id=${model_id}&version=${version}`));
}
export async function apiPostProjectModelBuildCancel({ project_id, model_id, version }) {
  return ResponseAdapter(await post(`/api/v1/project/modelbuild/cancel`, { project_id, model_id, version }));
}

export async function apiGetProjectModels(project_id) {
  return ResponseAdapter(await get(`/api/v1/project/model/list?project_id=${project_id}`));
}
export async function apiGetProjectModel({ project_id, model_id }) {
  return ResponseAdapter(await get(`/api/v1/project/model?project_id=${project_id}&model_id=${model_id}`));
}
export async function apiPostProjectModel({ project_id, name, type }) {
  return ResponseAdapter(await post('/api/v1/project/model', { project_id, name, type }));
}
export async function apiPutProjectModel({ project_id, model_id, name, type, finetunedata, suggestions }) {
  return ResponseAdapter(await put('/api/v1/project/model', { project_id, model_id, name, type, finetunedata, suggestions }));
}
export async function apiDeleteProjectModel(project_id, model_id) {
  return ResponseAdapter(await del(`/api/v1/project/model?project_id=${project_id}&model_id=${model_id}`));
}

export async function apiPostProjectModelSuggestions({ project_id, adapter_type, language, persona_id, ai_provider, ai_model, count }) {
  return ResponseAdapter(await post('/api/v1/project/model/suggestions', { project_id, adapter_type, language, persona_id, ai_provider, ai_model, count }));
}

export async function apiGetProjectSecrets(project_id) {
  return ResponseAdapter(await get(`/api/v1/project/secret/list?project_id=${project_id}`));
}
export async function apiPostProjectSecret({ project_id, name, value }) {
  return ResponseAdapter(await post('/api/v1/project/secret', { project_id, name, value }));
}
export async function apiDeleteProjectSecret({ project_id, name }) {
  return ResponseAdapter(await del(`/api/v1/project/secret?project_id=${project_id}&name=${name}`));
}

export async function apiGetProjectTemplates(project_id) {
  return ResponseAdapter(await get(`/api/v1/project/template/list?project_id=${project_id}`));
}
export async function apiGetProjectTemplate(project_id, template_id) {
  return ResponseAdapter(await get(`/api/v1/project/template?project_id=${project_id}&template_id=${template_id}`));
}
export async function apiPostProjectTemplate({ project_id, name, text, html }) {
  return ResponseAdapter(await post('/api/v1/project/template', { project_id, name, text, html }));
}
export async function apiPutProjectTemplate({ project_id, template_id, name, text, html }) {
  return ResponseAdapter(await put('/api/v1/project/template', { project_id, template_id, name, text, html }));
}
export async function apiDeleteProjectTemplate({ project_id, template_id }) {
  return ResponseAdapter(await del(`/api/v1/project/template?project_id=${project_id}&template_id=${template_id}`));
}

export async function apiPutProjectEndpointDefinition({ project_id, endpoint_id, method, path, group_name, steps, request_params, transition }) {
  return ResponseAdapter(await put('/api/v1/project/endpoint', { project_id, endpoint_id, method, path, group_name, steps, request_params, transition }));
}

export async function apiGetTenantList(project_id) {
  return ResponseAdapter(await get(`/api/v1/project/tenant/list?project_id=${project_id}`));
}
export async function apiPostTenant({ project_id, email, role }) {
  return ResponseAdapter(await post(`/api/v1/project/tenant`, { project_id, email, role }));
}
export async function apiPutTenant({ project_id, email, role }) {
  return ResponseAdapter(await put(`/api/v1/project/tenant`, { project_id, email, role }));
}
export async function apiDelTenant({ project_id, email }) {
  return ResponseAdapter(await del(`/api/v1/project/tenant?project_id=${project_id}&email=${email}`));
}
export async function apiPutTenantInvite({ project_id, email, role }) {
  return ResponseAdapter(await put(`/api/v1/project/tenant/invite`, { project_id, email, role }));
}

export async function apiGetProjectAvailableDataAdapters() {
  return ResponseAdapter(await get(`/api/v1/project/dataadapters`));
}
export async function apiGetProjectDataAdapters(project_id) {
  return ResponseAdapter(await get(`/api/v1/project/dataadapter/list?project_id=${project_id}`));
}
export async function apiGetProjectDataAdapter(project_id, adapter_type) {
  return ResponseAdapter(await get(`/api/v1/project/dataadapter?project_id=${project_id}&adapter_type=${adapter_type}`));
}
export async function apiPostProjectDataAdapter({ project_id, adapter_type, config }) {
  return ResponseAdapter(await post(`/api/v1/project/dataadapter`, { project_id, adapter_type, config }));
}
export async function apiPutProjectDataAdapter({ project_id, adapter_type, config }) {
  return ResponseAdapter(await put(`/api/v1/project/dataadapter`, { project_id, adapter_type, config }));
}
export async function apiPutProjectDataAdapterEnabled({ project_id, adapter_type, enabled }) {
  return ResponseAdapter(await put(`/api/v1/project/dataadapter/enabled`, { project_id, adapter_type, enabled }));
}
export async function apiDelProjectDataAdapter({ project_id, name }) {
  return ResponseAdapter(await del(`/api/v1/project/dataadapter?project_id=${project_id}&name=${name}`));
}
export async function apiPutProjectDataAdapterDataTypes({ project_id, adapter_type, tables }) {
  return ResponseAdapter(await put(`/api/v1/project/dataadapter/datatypes`, { project_id, adapter_type, tables }));
}
export async function apiGetProjectDataAdapterSqlTables(project_id, adapter_type) {
  return ResponseAdapter(await get(`/api/v1/project/dataadapter/sqltables?project_id=${project_id}&adapter_type=${adapter_type}`));
}

export async function apiGetProjectUserList(project_id, page_number) {
  return ResponseAdapter(await get(`/api/v1/project/user/list?project_id=${project_id}&pagesize=10&pagenumber=${page_number}`));
}
export async function apiGetProjectUser({ project_id, email }) {
  const response = await get(`/api/v1/project/user?project_id=${project_id}&email=${email}`);
  return ResponseAdapter(response);
}
export async function apiPostProjectUser({ project_id, email, password, name, timezone, country, organisation, position, language, flags }) {
  const response = await post(`/api/v1/project/user`, { project_id, email, password, name, timezone, country, organisation, position, language, flags });
  return ResponseAdapter(response);
}
export async function apiPutProjectUser({ project_id, email, name, timezone, country, organisation, position, language, flags }) {
  const response = await put(`/api/v1/project/user`, { project_id, email, name, timezone, country, organisation, position, language, flags });
  return ResponseAdapter(response);
}
export async function apiDelProjectUser({ project_id, email }) {
  return ResponseAdapter(await del(`/api/v1/project/user?project_id=${project_id}&email=${email}`));
}

export async function apiGetProjectCheckUser({ project_id, email }) {
  const response = await get(`/api/v1/project/checkuser?project_id=${project_id}&email=${email}`);
  return ResponseAdapter(response);
}

export async function apiGetProjectFeatures({ project_id }) {
  const response = await get(`/api/v1/project/features?project_id=${project_id}`);
  return ResponseAdapter(response);
}
export async function apiPutProjectFeatures({ project_id, features }) {
  const response = await put(`/api/v1/project/features`, { project_id, features });
  return ResponseAdapter(response);
}

export async function apiGetProjectLogos({ project_id }) {
  const response = await get(`/api/v1/project/logo/list?project_id=${project_id}`);
  return ResponseAdapter(response);
}
export async function apiPutProjectLogo({ project_id, file_name, file_type, file, type }) {
  const response = await put(
    `/api/v1/project/logo?project_id=${project_id}&file_name=${file_name}&file_type=${file_type}`,
    file,
    {
      'Content-Type': type,
      'x-xsrf-token': localStorage.getItem('xsrf-token'),
    },
    false,
  );
  return ResponseAdapter(response);
}
