<template>
  <table v-if="headers.length" class="data-table">
    <thead>
      <tr>
        <th class="border-b border-gray-200 text-left">{{ $t('index') }}</th>
        <th v-for="(header, index) in headers" :key="`${header}-${index}`" class="border-b border-gray-200 text-left">
          {{ header }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, index) in value" :key="index">
        <td>{{ index }}</td>
        <td v-for="(header, colIndex) in headers" :key="colIndex">
          {{ row[header] }}
        </td>
      </tr>
    </tbody>
  </table>
  <div v-else>{{ $t('no_data_to_display') }}</div>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    value: {
      type: Array,
    },
  },
  computed: {
    headers() {
      const [firstRow] = this.value;
      if (firstRow) {
        return Object.keys(firstRow);
      }
      return [];
    },
  },
};
</script>
<style lang="scss">
.data-table {
  white-space: nowrap;

  td,
  th {
    padding: 5px 10px;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}
</style>
