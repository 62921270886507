<template>
  <Modal
    sheetbelowsm
    :title="$t('projects.create')"
    :primary-button="$t('save')"
    :primary-button-disabled="!name.trim() || loading || validating"
    :primary-button-loading="loading"
    :secondary-button-disabled="loading"
    :secondary-button="$t('cancel')"
    close-button
    @primary="onSaveClick"
    @secondary="onCancelClick"
    @close="onCancelClick"
    @opened="onModalOpen"
  >
    <ValidationObserver ref="validationObserver" v-slot="{ errors }" :validateOnBlur="false" class="w-full sm:w-700">
      <div class="w-full flex flex-col justify-start mt-3 py-7 px-10 h-40">
        <FormInput
          v-model="name"
          ref="name"
          name="projectName"
          :rules="['required', 'minLength:3', 'maxLength:20']"
          :error="errors.projectName || nameError"
          :label="$t('projects.name')"
          :readonly="loading"
          @update:modelValue="onProjectNameChange"
        />

        <FormLabel v-if="projectId" class="mt-3" label="Project Id:"></FormLabel>
        <div v-if="projectId" class="text-13 text-dark-text">
          <span class="text-gray-600">https://</span><span class="font-600">{{ projectId }}</span
          ><span class="text-gray-600">.fidsy.cloud</span>
        </div>
      </div>
    </ValidationObserver>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { apiPostCheckProjectName } from '@/helpers/api';
import debounce from '@shared/helpers/debounce';

export default {
  name: 'CreateProjectModal',
  data() {
    return {
      loading: false,
      name: '',
      nameError: null,
      validating: false,
    };
  },
  computed: {
    ...mapState(['projects']),
    projectId() {
      return this.name.toLowerCase().replace(/[^a-z0-9-]/gim, '');
    },
  },
  methods: {
    ...mapActions(['createProject']),
    onModalOpen() {
      this.$refs?.name?.focus();
    },
    // eslint-disable-next-line
    validateProjectName: debounce(async function () {
      this.nameError = null;
      try {
        const response = await apiPostCheckProjectName({ name: this.name });
        if (response.data?.error && response.data?.message) {
          this.nameError = response.data.message;
        }
        this.validating = false;
      } catch {
        this.nameError = null;
        this.validating = false;
      }
    }, 500),
    async onProjectNameChange() {
      this.validating = true;
      await this.validateProjectName();
    },
    async onSaveClick() {
      const valid = await this.$refs.validationObserver.validate();
      if (!valid.valid || !!this.nameError || this.validating) return;
      this.loading = true;
      // await this.createProject({ name: this.name.trim() });
      this.loading = false;
      this.$emit('close');
    },
    onCancelClick() {
      this.$emit('close');
    },
  },
};
</script>
