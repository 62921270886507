<template>
  <VueFinalModal
    :model-value="true"
    :click-to-close="false"
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
    class="f-modal flex justify-center items-center backdrop-filter backdrop-blur-sm"
    :class="{ 'pointer-events-none': primaryButtonLoading }"
    :content-class="{
      'flex flex-col': true,
      'bg-white overflow-hidden transform transition-all duration-300 ease-in-out': true,
      'h-full sm:h-auto sm:rounded-20 sm:shadow-xl w-full': fullbelowsm,
      'rounded-20 shadow-xl max-h-screen': !fullbelowsm && !sheetbelowsm,
      'mt-auto sm:my-auto max-sm:w-full rounded-t-20 sm:rounded-t-26 sm:rounded-b-26 shadow-xl max-h-screen': sheetbelowsm,
      'max-sm:translate-y-full': !mounted && sheetbelowsm,
      [containerClass]: true,
    }"
    @opened="$emit('opened', $event)"
    @update:model-value="(val) => $emit('update:modelValue', val)"
  >
    <div v-if="title || closeButton" class="w-full pt-10 px-10 flex items-center justify-between mb-2">
      <div v-if="title" class="relative font-600 text-20">
        {{ title }}
      </div>
      <div
        v-if="closeButton"
        class="rounded-full w-6 h-6 flex justify-center items-center cursor-pointer ml-auto"
        :class="{
          'right-5': title,
          'top-5': title,
          'right-3': !title,
          'top-1': !title,
        }"
        @click.stop="$emit('close')"
      >
        <Icon name="clear" class="w-5 h-5" />
      </div>
    </div>

    <div class="overflow-auto flex-grow relative" :class="{ [overflowContainerClass]: true }">
      <slot></slot>
      <div v-if="loading" class="absolute inset-0 bg-white flex items-center justify-center h-full">
        <Spinner class="w-8" />
      </div>
    </div>

    <template v-if="footer">
      <Divider dense class="mt-3" />
      <div class="w-full">
        <slot name="footer"></slot>
      </div>
    </template>
    <template v-else-if="primaryButton || secondaryButton">
      <Divider dense class="mt-5" />
      <div class="w-full flex py-4 px-10">
        <PillButton v-if="secondaryButton" :text="secondaryButton" :disabled="primaryButtonLoading || secondaryButtonDisabled" outlined @click="$emit('secondary', $event)" />
        <PillButton
          v-if="primaryButton"
          primary
          :loading="primaryButtonLoading"
          :disabled="primaryButtonLoading || primaryButtonDisabled || loading"
          class="ml-auto"
          :text="primaryButton"
          outlined
          @click="$emit('primary', $event)"
        />
      </div>
    </template>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal';

export default {
  name: 'Modal',
  components: {
    VueFinalModal,
  },
  emits: ['close', 'after-enter', 'primary', 'secondary', 'update:modelValue', 'opened'],
  props: {
    containerClass: {
      type: String,
      default: 'sm:w-auto',
    },
    overflowContainerClass: {
      type: String,
      default: '',
    },
    fullbelowsm: Boolean,
    sheetbelowsm: Boolean,
    title: {
      type: String,
    },
    closeButton: {
      type: Boolean,
    },
    primaryButton: {
      type: String,
    },
    primaryButtonDisabled: {
      type: Boolean,
    },
    primaryButtonLoading: {
      type: Boolean,
    },
    secondaryButton: {
      type: String,
    },
    secondaryButtonDisabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      mounted: false,
    };
  },
  computed: {
    footer() {
      return this.$slots?.footer;
    },
  },
  mounted() {
    document.body.classList.add('overflow-hidden');
    this.$emit('after-enter');
    setTimeout(() => {
      this.mounted = true;
      if (document.querySelector('.bottom-navigation')) {
        document.querySelector('.bottom-navigation').classList.add('hidden');
      }
    }, 0);
  },
  beforeUnmount() {
    document.body.classList.remove('overflow-hidden');
    if (document.querySelector('.bottom-navigation')) {
      document.querySelector('.bottom-navigation').classList.remove('hidden');
    }
  },
};
</script>
<style>
.f-modal .vfm--overlay {
  background-color: #00000030;
}
</style>
