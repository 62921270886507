<template>
  <div class="h-full flex flex-col">
    <div class="w-full flex-grow overflow-auto pl-2 pb-2">
      <PillButton class="ml-auto" :loading="saving" :disabled="saving" :text="$t('save')" icon="save" text-class="max-sm:hidden" icon-class="max-sm:mr-0" primary @click="save" />

      <ValidationObserver ref="validationObserver" v-slot="{ errors }">
        <div class="pb-2 w-full sm:w-600">
          <div class="w-full flex mb-3">
            <FormInput v-model="name" name="name" class="w-full" :label="$t('name')" :rules="['required', { validate: validateModelName }]" :error="errors.name"></FormInput>
          </div>
          <div class="w-full flex mb-3">
            <FormSelect v-model="type" :options="types" :reduce="(opt) => opt.value" name="type" class="w-full" :label="$t('flow.type')" :rules="['required']" :error="errors.type"></FormSelect>
          </div>
        </div>
      </ValidationObserver>
      <div>
        <span class="hover:underline text-gray-500 cursor-pointer mr-5" @click="openDeleteModal">{{ $t('projects.models.delete') }}</span>
        <PillButton v-if="false" :text="$t('projects.delete_this')" error @click="openDeleteModal"></PillButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FINE_TUNING_TYPES } from '@/constants';
import DeleteModal from '@shared/components/shared-modals/DeleteModal.vue';

export default {
  name: 'ModelSettings',
  props: {
    model: {
      type: Object,
    },
  },
  data() {
    return {
      name: '',
      type: '',
      saving: false,
    };
  },
  created() {
    this.name = this.model.name;
    this.type = this.model.type;
  },
  computed: {
    ...mapGetters(['getProjectModels']),
    projectId() {
      return this.$route.params.projectId;
    },
    types() {
      return Object.keys(FINE_TUNING_TYPES).map((key) => ({ label: this.$t(`projects.models.fine_tune_types.${key}`), value: key }));
    },
    modelId() {
      return this.$route.params.modelId;
    },
    models() {
      return Object.values(this.getProjectModels()).map((model) => {
        return {
          label: model.name,
          value: model.model_id,
        };
      });
    },
  },
  methods: {
    ...mapActions(['updateProjectModel', 'deleteProjectModel']),
    openDeleteModal() {
      const modelName = this.getProjectModels()?.[this.modelId]?.name;
      this.$showModal(DeleteModal, {
        subtitle: this.$t('projects.models.confirm_delete', { model: modelName }),
        info: null,
        onConfirm: async () => {
          await this.deleteProjectModel({ project_id: this.projectId, model_id: this.modelId });
          this.$router.push({ name: 'project-models', params: { projectId: this.projectId } });
        },
      });
    },
    validateModelName() {
      let models = Object.values(this.getProjectModels());
      if (this.model) {
        models = models.filter((model) => model.model_id !== this.model.model_id);
      }
      if (models.some((model) => model.name === this.name)) {
        return this.$t('validation.name_unique');
      }
      return true;
    },
    async save() {
      const valid = await this.$refs.validationObserver.validate();
      if (!valid.valid) return;

      this.saving = true;
      await this.updateProjectModel({
        project_id: this.projectId,
        model_id: this.modelId,
        name: this.name,
        type: this.type,
        finetunedata: this.model.finetunedata,
        suggestions: this.model.suggestions,
      });
      this.saving = false;
    },
  },
};
</script>
