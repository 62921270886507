<template>
  <div class="flex-grow" :class="{ 'overflow-hidden': appendToBody }">
    <FormLabel v-if="label" :label="label" />
    <v-select
      v-model="variable"
      :dense="dense ? true : undefined"
      ref="select"
      :class="[selectBgColor]"
      class="flex-grow"
      :placeholder="$t('evaluate.select_variable')"
      :options="options"
      :clearable="clearable"
      :multiple="multiple"
      :appendToBody="appendToBody"
      :reduce="(option) => option.value"
      @option:selected="$emit('option:selected', $event)"
      @update:modelValue="$emit('update:modelValue', $event)"
      @open="onOpen"
      @close="onClose"
    >
      <template v-if="showCreate" v-slot:list-footer>
        <div
          style="bottom: -1px"
          class="flex sticky justify-center w-full cursor-pointer py-3 bg-gray-100 text-secondary hover:text-primary border-t border-solid border-gray-200"
          @click="createVariable"
        >
          {{ $t('collections.create_variable') }}
        </div>
      </template>
      <template v-slot:option="item">
        <div class="flex justify-between items-center">
          <div>{{ item.label }}</div>
          <Badge v-if="item.variable && item.variable.dataType" :text="item.variable.dataType" info />
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { VARIABLE_TYPE } from '@/constants';
import CreateProjectVariable from '@/components/project/CreateProjectVariable';

export default {
  name: 'VariableSelect',
  props: {
    modelValue: {},
    label: {
      type: String,
    },
    selectBgColor: {
      type: String,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    system: {
      type: Boolean,
      default: true,
    },
    showCreate: {
      type: Boolean,
      default: true,
    },
    excludes: {
      type: Array,
      default: () => [],
    },
    filterDataType: {
      type: Array,
      default: () => [],
    },
    curly: {
      type: Boolean,
    },
    filter: {
      type: Function,
    },
    multiple: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
    appendToBody: {
      type: Boolean,
      default: true,
    },
    projectId: {
      type: String,
    },
  },
  data() {
    return {
      variable: null,
    };
  },
  computed: {
    ...mapState(['projectData']),
    variables() {
      const projectId = this.projectId || this.$route.params.projectId;
      if (projectId) {
        return this.projectData?.[projectId]?.variables || {};
      }
      return {};
    },
    variablesArr() {
      const variables = Object.keys(this.variables).map((v) => ({
        name: v,
        ...this.variables[v],
      }));

      if (!this.system) {
        return variables.filter((v) => !v?.variableType || v?.variableType !== VARIABLE_TYPE.SYSTEM);
      }

      return variables;
    },
    options() {
      let variables = this.variablesArr.filter((v) => !this.excludes.includes(v.name));

      if (this.filter) {
        variables = variables.filter(this.filter);
      }

      if (this.filterDataType?.length) {
        variables = variables.filter((v) => this.filterDataType.includes(v.dataType));
      }

      variables = variables.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

      if (this.curly) {
        return variables.map((v) => ({
          label: v.name,
          value: `{${v.name}}`,
          variable: v,
        }));
      }
      return variables.map((v) => ({
        label: v.name,
        value: v.name,
        variable: v,
      }));
    },
  },
  methods: {
    ...mapActions(['fetchProjectVariables', 'showToastMessage']),
    onOpen() {
      document.body.style.setProperty('--vs-dropdown-z-index', '100000');
    },
    onClose() {
      document.body.style.removeProperty('--vs-dropdown-z-index');
    },
    createVariable() {
      this.$refs.select.open = false;
      this.$showModal(CreateProjectVariable, { projectId: this.projectId || this.$route.params.projectId }, { save: this.onVariableSaved });
    },
    async onVariableSaved(response) {
      await this.fetchProjectVariables(this.projectId || this.$route.params.projectId);
      this.variable = response.name;
    },
  },
  watch: {
    variable() {
      this.$emit('update:modelValue', this.variable);
    },
    modelValue: {
      handler() {
        this.variable = this.modelValue || null;
      },
      immediate: true,
    },
  },
};
</script>
