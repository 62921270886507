<template>
  <Modal
    title="Edit User"
    close-button
    overflowContainerClass="-mb-5"
    :primary-button="$t('update_profile')"
    :secondary-button="$t('close')"
    :primary-button-loading="profileUpdating"
    @primary="updateProfile"
    @close="close"
    @secondary="close"
  >
    <div class="sm:w-600 min-h-500 px-10 pt-6 relative overflow-auto">
      <div v-if="!tenant" class="flex flex-col font-700 items-center justify-center w-full h-full min-h-[450px] mb-5">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>

      <div v-else>
        <ValidationObserver ref="validationObserver" v-slot="{ errors }" class="w-full">
          <div class="w-full flex mb-3">
            <FormInput v-model="tenant.name" name="name" rules="required" :error="errors['name']" :label="$t('name')" class="w-full md:w-1/2 md:mr-2" />
            <FormInput v-model="tenant.email" name="email" rules="required|email" :error="errors['email']" :label="$t('email')" class="w-full md:w-1/2 md:ml-2" />
          </div>
          <div class="w-full flex mb-3">
            <FormInput v-model="tenant.organisation" :label="$t('login.organisation')" class="w-full md:w-1/2 md:mr-2" />
            <FormInput v-model="tenant.position" :label="$t('login.position')" class="w-full md:w-1/2 md:ml-2" />
          </div>
          <div class="w-full flex mb-3">
            <FormSelect
              :label="$t('login.country')"
              name="country"
              rules="required"
              :error="errors['country']"
              v-model="tenant.country"
              class="w-full md:w-1/2 md:mr-2"
              :options="countries"
              :clearable="false"
              :searchable="true"
              :reduce="(option) => option.value"
              appendToBody
            ></FormSelect>
            <FormSelect
              :label="$t('timezone')"
              name="timezone"
              rules="required"
              :error="errors['timezone']"
              v-model="tenant.timezone"
              class="w-full md:w-1/2 md:ml-2"
              :options="TIMEZONES"
              searchable
              :clearable="false"
              appendToBody
            ></FormSelect>
          </div>
          <div class="w-full mb-3">
            <FormSelect
              :label="$t('language')"
              name="language"
              rules="required"
              :error="errors['language']"
              v-model="tenant.locale"
              :options="languages"
              :clearable="false"
              :reduce="(option) => option.value"
              appendToBody
            ></FormSelect>
          </div>
          <div class="w-full mb-3 pt-2 flex flex-col items-start">
            <Checkbox v-model="tenant.flags.rc" :label="$t('flags.rc')" class="mb-3 !text-13"></Checkbox>
            <Checkbox v-model="tenant.flags.oc" :label="$t('flags.oc')" class="mb-3 !text-13"></Checkbox>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';
import { apiGetUser, apiPutUser } from '@/helpers/api';
import { languages } from '@/plugins/i18n';
import { COUNTRIES, TIMEZONES } from '@/constants';

export default {
  name: 'EditUserModal',
  props: {
    email: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      tenant: null,
      TIMEZONES,
      countries: COUNTRIES.map((c) => ({ label: c.name, value: c.name })),
      profileUpdating: false,
    };
  },
  computed: {
    languages() {
      return languages.map((lang) => ({ label: this.$t(`languages_alt.${lang}`), value: lang }));
    },
  },
  created() {
    this.getUserData();
  },
  methods: {
    ...mapActions(['showToastMessage']),
    async getUserData() {
      try {
        const res = await apiGetUser({ email: this.email });
        if (res.status === 200) {
          this.tenant = res.data;
        } else {
          this.showToastMessage({ title: res?.data?.message || this.$t('projects.iam.failed_to_get_user'), type: 'error' });
          this.$emit('close');
        }
      } catch {
        this.showToastMessage({ title: this.$t('projects.iam.failed_to_get_user'), type: 'error' });
      }
    },
    async updateProfile() {
      const validationObserver = this.$refs?.validationObserver;
      const valid = await validationObserver.validate();

      if (!valid.valid) {
        return;
      }
      this.profileUpdating = true;

      try {
        const response = await apiPutUser({
          email: this.tenant.email,
          name: this.tenant.name,
          timezone: this.tenant.timezone,
          country: this.tenant.country,
          organisation: this.tenant.organisation,
          position: this.tenant.position,
          language: this.tenant.locale,
          flags: this.tenant.flags,
        });

        if (response.status === 200) {
          this.showToastMessage({ message: this.$t('projects.iam.updated_successfully'), type: 'success' });
        } else {
          this.showToastMessage({ title: response?.data?.message || this.$t('projects.iam.failed_to_update_user'), type: 'error' });
        }
      } catch {
        this.showToastMessage({ title: this.$t('projects.iam.failed_to_update_user'), type: 'error' });
      }
      this.profileUpdating = false;
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
