<template>
  <div class="px-3 pb-2 pt-2">
    <div v-for="(item, index) in expressions" :key="`label-${index}`">
      <FormLabel class="block text-12 mt-3" :label="getExpressionLabel(index, expressions.length)"></FormLabel>
      <ExpressionEditor v-model="item.value" class="w-full" @update:modelValue="onExpressionInput($event, index)" />
    </div>
  </div>
</template>

<script>
import { getExpressionLabel } from '@/helpers/flow';
import uuidv4 from '@/helpers/uuid';
import debounce from '@shared/helpers/debounce';

export default {
  name: 'EvaluateSetCondition',
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      evaluation: {},
    };
  },
  computed: {
    expressions() {
      return this.evaluation.payload.expressions;
    },
  },
  created() {
    this.checkNewExpressionField();
  },
  methods: {
    onExpressionInput(event, index) {
      if (index + 2 === this.expressions.length && event === '') {
        this.evaluation.payload.expressions.pop();
      } else {
        this.checkNewExpressionField();
      }
      this.emitPayload();
    },
    checkNewExpressionField() {
      if (this.evaluation.payload.expressions[this.evaluation.payload.expressions.length - 1].value !== '') {
        this.evaluation.payload.expressions.push({
          id: uuidv4(),
          value: '',
          payload: [],
        });
      }
    },
    getExpressionLabel,
    removeEmptyExpressions(evaluation) {
      evaluation = JSON.parse(JSON.stringify(evaluation));
      evaluation.payload.expressions = evaluation.payload.expressions.filter((e) => e.value);
      return evaluation;
    },
    // eslint-disable-next-line
    emitPayload: debounce(function () {
      this.$emit('update', this.removeEmptyExpressions(this.evaluation));
    }, 300),
  },
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.evaluation = JSON.parse(JSON.stringify(this.item));
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
