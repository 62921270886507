<template>
  <div class="px-3 pb-2">
    <div class="pt-3">
      <RadioButton v-model="transitionType" value="step" name="transitionType" :label="$t('conversations.switch_to_step')" />
      <v-select
        v-if="transitionType === 'step'"
        class="mt-2"
        v-model="transitionValue"
        :options="steps"
        appendToBody
        :searchable="false"
        :clearable="false"
        :reduce="selectReducer"
        @update:modelValue="emitPayload"
      ></v-select>
      <RadioButton v-model="transitionType" value="end" name="transitionType" :label="endTransitionText" class="mt-3" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import uuidv4 from '@/helpers/uuid';
import debounce from '@shared/helpers/debounce';
import { getTransitionText } from '@/helpers/flow';

export default {
  name: 'EvaluateSetTransition',
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      transitionId: uuidv4(),
      transitionType: null,
      transitionValue: null,
    };
  },
  computed: {
    ...mapState(['selectedIntentDefinition']),
    endTransitionText() {
      return getTransitionText({ type: 'end' }, []);
    },
    transition() {
      return {
        id: this.item.id,
        type: 'transition',
        payload: {
          id: this.transitionId,
          type: this.transitionType,
          value: this.transitionValue,
        },
      };
    },
    steps() {
      return this.selectedIntentDefinition.steps.map((s) => ({
        value: s.id,
        label: s.name,
      }));
    },
  },
  methods: {
    // eslint-disable-next-line
    emitPayload: debounce(function () {
      this.$emit('update', this.transition);
    }, 300),
    selectReducer(option) {
      return option.value;
    },
  },
  watch: {
    transitionType() {
      this.transitionValue = null;
      this.emitPayload();
    },
    item: {
      handler() {
        if (this.item) {
          const { id, type, value } = this.item.payload;

          this.transitionType = type;

          this.$nextTick(() => {
            this.transitionId = id;
            this.transitionValue = value || null;
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
