<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2 h-14">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-primary font-600">{{ $t('projects.data_adapters.title') }}</div>
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <div v-if="projectDataAdaptersCount" class="pl-1 font-500 mb-2 text-13">Active Data Adapters</div>
        <div v-if="projectDataAdaptersCount" class="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 pt-1">
          <ListCard v-for="(dataAdapter, key) in getProjectDataAdapters()" :key="key" class="h-20 py-2 bg-white rounded-20 cursor-pointer" @click="onConnectClick(key)">
            <div class="flex justify-between items-center w-full">
              <img :src="getIcon(key)" class="w-8 h-8 mr-2 flex-shrink-0" :alt="getName(key)" />
              <div class="flex items-center truncate">
                <span class="font-600 mr-2 truncate">{{ getName(key) }}</span>
              </div>
              <Toggle :modelValue="dataAdapter.config.enabled" dense class="ml-auto flex-shrink-0" @update:modelValue="onEnableChange(dataAdapter)" />
            </div>
          </ListCard>
        </div>
        <div class="pl-1 font-500 mb-2 mt-6 text-13">Available Data Adapters</div>
        <div class="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 pt-1">
          <ListCard
            v-for="(dataAdapter, key) in availableDataAdapters"
            :key="key"
            :class="{
              hidden: key in getProjectDataAdapters(),
              'cursor-pointer': dataAdapter.enabled,
              'opacity-50': !dataAdapter.enabled,
            }"
            class="h-20 py-2 bg-white rounded-20"
            @click="onConnectClick(key)"
          >
            <div class="flex justify-between items-center w-full flex-wrap -mt-3">
              <div class="flex items-center mt-3">
                <img :src="getIcon(key)" class="w-8 h-8 mr-2 flex-shrink-0" :alt="dataAdapter.name" />
                <div class="flex items-center truncate">
                  <span class="font-600 mr-2 truncate">{{ dataAdapter.name }}</span>
                </div>
              </div>
            </div>
          </ListCard>
        </div>
      </div>
    </div>
    <Modal v-if="loading">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ConnectDataAdapter from '@/components/project/ConnectDataAdapter.vue';
import DeleteModal from '@shared/components/shared-modals/DeleteModal.vue';
import { DATA_ADAPTERS, DATA_ADAPTER_ICONS as ICONS } from '@/constants';

export default {
  name: 'DataAdapters',
  data() {
    return {
      DATA_ADAPTERS,
      loading: false,
    };
  },
  computed: {
    ...mapState(['availableDataAdapters']),
    ...mapGetters(['getProjectDataAdapters']),
    projectDataAdaptersCount() {
      return Object.keys(this.getProjectDataAdapters()).length;
    },
    projectId() {
      return this.$route.params.projectId;
    },
  },
  methods: {
    ...mapActions(['fetchAvailableDataAdapters', 'fetchProjectDataAdapters', 'setEnableProjectDataAdapter']),
    async onEnableChange(dataAdapter) {
      const subtitle = dataAdapter?.config?.enabled
        ? this.$t('projects.data_adapters.disable', { adapter: this.getName(dataAdapter.adapter_type) })
        : this.$t('projects.data_adapters.enable', { adapter: this.getName(dataAdapter.adapter_type) });
      this.$showModal(DeleteModal, {
        subtitle,
        type: 'primary',
        onConfirm: async () => {
          await this.setEnableProjectDataAdapter({ project_id: this.projectId, adapter_type: dataAdapter.adapter_type, enabled: !dataAdapter.config.enabled });
        },
      });
    },
    async onConnectClick(key) {
      if (this.getProjectDataAdapters()[key]?.config?.enabled) {
        this.$router.push({ name: 'project-data-adapter', params: { dataAdapter: key } });
      } else {
        this.$showModal(ConnectDataAdapter, { type: key, name: this.getName(key) });
      }
    },
    getIcon(dataAdapter) {
      return ICONS[dataAdapter];
    },
    getName(key) {
      return this.availableDataAdapters?.[key]?.name;
    },
  },
  watch: {
    projectId: {
      handler() {
        this.loading = true;
        const promise1 = this.fetchAvailableDataAdapters();
        const promise2 = this.fetchProjectDataAdapters({ projectId: this.projectId });
        Promise.all([promise1, promise2]).finally(() => {
          this.loading = false;
        });
      },
      immediate: true,
    },
  },
};
</script>
