<template>
  <Modal
    sheetbelowsm
    :title="title"
    :loading="fetching"
    :primary-button="$t('save')"
    :secondary-button="$t('cancel')"
    :primary-button-disabled="loading"
    :primary-button-loading="loading"
    close-button
    @primary="onSaveClicked"
    @secondary="onCancelClicked"
    @close="onCancelClicked"
    @opened="onModalOpen"
  >
    <ValidationObserver ref="validationObserver" v-slot="{ errors }" class="w-full">
      <div class="sm:w-600 px-10">
        <div class="w-full flex flex-col justify-start mt-5">
          <FormInput ref="name" v-model="name" :label="$t('projects.variables.name')" name="name" :rules="['required', { validate: nameValidation }]" :error="errors.name" :readonly="!!variableName" />
        </div>
        <div class="w-full flex flex-col justify-start mt-5">
          <FormSelect
            v-model="dataType"
            :options="dataTypes"
            name="datatype"
            :error="errors.datatype"
            appendToBody
            rules="required"
            :reduce="(option) => option.value"
            :clearable="false"
            :label="$t('projects.variables.data_type')"
            @option:selected="onDataTypeInput"
          />
        </div>
        <div class="w-full flex flex-col justify-start mt-5">
          <FormLabel :label="$t('collections.set_initial_value')"></FormLabel>
          <Toggle v-model="setInitialValue" :disabled="!hasSupportInitialValue" />
        </div>
        <div v-if="setInitialValue && hasSupportInitialValue" class="w-full flex flex-col justify-start mt-5">
          <FormLabel :label="$t('collections.initial_value')" />

          <FormInput
            v-if="['Text', 'Number'].includes(dataType)"
            v-model="initialValue"
            name="initialValue"
            rules="required"
            :error="errors.initialValue"
            :type="dataType === 'Number' ? 'number' : 'text'"
          ></FormInput>
          <FormSelect
            v-else-if="dataType === 'YesNo'"
            v-model="initialValue"
            :options="['Yes', 'No']"
            name="initialValue"
            rules="required"
            :error="errors.initialValue"
            appendToBody
            class="mt-3"
          ></FormSelect>
          <div v-else-if="dataType === 'DataTable'" class="w-full relative border h-320">
            <div class="flex h-5">
              <div class="text-13 cursor-pointer w-16 text-center border-r" :class="{ 'border-b': tab !== 'editor' }" @click="tab = 'editor'">{{ $t('editor') }}</div>
              <div class="text-13 cursor-pointer w-16 text-center border-r" :class="{ 'border-b': tab !== 'preview' }" @click="tab = 'preview'">{{ $t('preview') }}</div>
              <div class="flex-grow border-b"></div>
              <div v-show="tab === 'editor'" class="text-13 cursor-pointer border-b border-l px-2" @click="formatEditor">{{ $t('format_code') }}</div>
            </div>
            <div class="h-[298px]">
              <MonacoEditor
                v-show="tab === 'editor'"
                width="100%"
                height="298"
                class="h-[298px]"
                v-model:value="initialValue"
                language="json"
                :options="editorOptions"
                @mount="monacoMounted"
              ></MonacoEditor>
              <div class="w-full h-full overflow-auto px-1" v-show="tab === 'preview'">
                <DataTable v-if="Array.isArray(dataTableValue)" :value="dataTableValue" class="w-full" />
              </div>
            </div>
          </div>
          <div v-else-if="hasDatatypeFields">
            <FormSelect v-model="initialValue" :options="dataTypeFields" name="initialValue" rules="required" :error="errors.initialValue" appendToBody></FormSelect>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { sortByKey } from '@/helpers/array';
import MonacoEditor from '@guolao/vue-monaco-editor';
import { MONACO_EDITOR_DEFAULTS } from '@/constants';

export default {
  name: 'CreateProjectVariable',
  components: { MonacoEditor },
  props: {
    variableName: String,
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      originalName: '',
      name: '',
      dataType: '',
      fetching: false,
      loading: false,
      initialValue: null,
      setInitialValue: false,
      tab: 'editor',
      editor: null,
      editorOptions: MONACO_EDITOR_DEFAULTS,
    };
  },
  computed: {
    ...mapState(['projectData']),
    title() {
      return !this.variableName ? this.$t('projects.variables.add') : this.$t('projects.variables.edit');
    },
    variables() {
      return Object.keys(this.projectData[this.projectId].variables);
    },
    dataTypes() {
      const datatypes = Object.keys(this.projectData?.[this.projectId]?.datatypes || {});
      const list = datatypes.map((datatype) => ({ value: datatype, label: datatype }));
      return sortByKey(list, 'label');
    },
    hasSupportInitialValue() {
      return ['Text', 'Number', 'YesNo', 'DataTable'].includes(this.dataType) || this.hasDatatypeFields;
    },
    hasDatatypeFields() {
      return this.projectData?.[this.projectId]?.datatypes?.[this.dataType]?.fields;
    },
    dataTypeFields() {
      const fields = this.projectData?.[this.projectId]?.datatypes?.[this.dataType]?.fields;
      return Object.keys(fields);
    },
    dataTableValue() {
      try {
        return JSON.parse(this.initialValue);
      } catch {
        return [];
      }
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchProjectDataTypes', 'fetchProjectVariable', 'fetchProjectVariables', 'createProjectVariable', 'updateProjectVariable']),
    onModalOpen() {
      this.$refs?.name?.focus();
    },
    onDataTypeInput() {
      if (!this.hasSupportInitialValue) {
        this.setInitialValue = false;
      }
      this.initialValue = null;
    },
    formatEditor() {
      this.editor.getAction('editor.action.formatDocument').run();
    },
    monacoMounted(editor) {
      this.editor = editor;
    },
    nameValidation() {
      if (this.variableName && this.name !== this.originalName) {
        if (this.variables.includes(this.name)) {
          return this.$t('projects.variables.must_be_unique');
        }
      }
      if (!this.variableName) {
        if (this.variables.includes(this.name)) {
          return this.$t('projects.variables.must_be_unique');
        }
      }
      return true;
    },
    onCancelClicked() {
      this.$emit('close');
    },
    async onSaveClicked() {
      const valid = await this.$refs.validationObserver.validate();
      if (valid.valid) {
        this.loading = true;

        // eslint-disable-next-line
        let initialValue = this.initialValue;
        if (this.dataType === 'DataTable') {
          try {
            initialValue = JSON.parse(this.initialValue);
          } catch {
            initialValue = null;
          }
        }

        if (!this.variableName) {
          await this.createProjectVariable({ project_id: this.projectId, name: this.name, dataType: this.dataType, value: this.hasSupportInitialValue && this.setInitialValue ? initialValue : null });
          this.$emit('close');
        }
        if (this.variableName) {
          await this.updateProjectVariable({ project_id: this.projectId, name: this.name, dataType: this.dataType, value: this.hasSupportInitialValue && this.setInitialValue ? initialValue : null });
          this.$emit('close');
        }
        this.loading = false;
      }
    },
    async getVariableDefinition() {
      const response = await this.fetchProjectVariable({ name: this.variableName, projectId: this.projectId });
      this.dataType = response?.dataType || '';
      if (response.value) {
        if (this.dataType === 'DataTable') {
          this.initialValue = JSON.stringify(response.value);
        } else {
          this.initialValue = response.value;
        }
      }
      this.setInitialValue = !!response.value;
    },
  },
  async created() {
    this.fetching = true;
    const promises = [this.fetchProjectDataTypes({ projectId: this.projectId }), this.fetchProjectVariables({ projectId: this.projectId })];
    if (this.variableName && this.variableName.length) {
      this.originalName = this.variableName;
      this.name = this.variableName;
      promises.push(this.getVariableDefinition());
    } else {
      this.originalName = '';
      this.name = '';
    }
    await Promise.all(promises);
    this.fetching = false;
  },
};
</script>
