<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-3 items-center flex-shrink-0 sm:pr-10">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-primary font-600">{{ $t('navigation.flows') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <PillButton :text="$t('add_new')" primary @click="onCreateClicked" />
        </div>
      </div>
      <div class="w-full flex-grow overflow-hidden pb-2">
        <template v-if="flowList.length">
          <TableElevated :items="flowList" :headers="flowHeaders" class="text-13 pr-3 sm:pr-10">
            <template v-slot:[`item.name`]="{ value }">
              <span class="text-13 font-500 truncate">{{ value }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <IconButton icon="edit" class="mr-1 text-primary" @click="onEditClicked(item)" />
              <IconButton class="text-primary" icon="delete" @click="onDeleteClicked(item)" />
            </template>
          </TableElevated>
        </template>
        <div v-else class="flex flex-col h-full flex-grow items-center justify-center pb-50">
          <div class="my-2 text-22 text-gray-700">{{ $t('projects.flows.have_no_flows') }}</div>
          <div class="my-2 text-gray-600">{{ $t('projects.flows.create_first_flow') }}</div>
          <PillButton class="my-3" :text="$t('projects.flows.create_new')" primary @click="onCreateClicked" />
        </div>
      </div>
    </div>
    <Modal v-if="loading">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import DeleteModal from '@shared/components/shared-modals/DeleteModal.vue';
import { sortByKey } from '@/helpers/array';
import CreateProjectFlow from '@/components/project/CreateProjectFlow.vue';

export default {
  name: 'Flows',
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(['projectData']),
    ...mapGetters(['getProjectFlows']),
    projectId() {
      return this.$route.params.projectId;
    },
    flowList() {
      return sortByKey(Object.values(this.getProjectFlows()), 'name');
    },
    flowHeaders() {
      return [
        { key: 'name', title: this.$t('name') },
        { key: 'actions', title: this.$t('actions'), width: '50px' },
      ];
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchProjectFlows', 'deleteProjectFlow']),
    onCreateClicked() {
      this.$showModal(
        CreateProjectFlow,
        {},
        {
          save: (response) => {
            this.$router.push({ name: 'project-flow', params: { projectId: this.projectId, flowId: response.flow_id } });
          },
        },
      );
    },
    onEditClicked(flow) {
      this.$router.push({ name: 'project-flow', params: { projectId: this.projectId, flowId: flow.flow_id } });
    },
    onDeleteClicked(flow) {
      this.$showModal(DeleteModal, {
        title: this.$t('confirm'),
        subtitle: this.$t('projects.flows.confirm_remove_message'),
        info: this.$t('all_data_will_be_removed'),
        onConfirm: async () => {
          await this.deleteProjectFlow({ project_id: this.projectId, flow_id: flow.flow_id });
        },
      });
    },
  },
  watch: {
    projectId: {
      async handler() {
        this.loading = true;
        await this.fetchProjectFlows({ projectId: this.projectId });
        this.loading = false;
      },
      immediate: true,
    },
  },
};
</script>
