<template>
  <div class="h-full project-model">
    <div class="h-full flex flex-col">
      <div class="flex w-full border-b border-stroke pb-1 border-solid mb-4 border-opacity-70 items-center flex-shrink-0 sm:pr-10 pl-2" style="box-shadow: 0 0 5px 3px rgb(var(--color-on-tertiary))">
        <div class="text-13 font-500 flex-grow flex items-baseline">
          <div class="text-13 text-primary font-700 cursor-pointer" @click="goToModels">{{ $t('navigation.models') }}</div>
          <span class="ml-2 mr-0 text-gray-400">></span>
          <ButtonDropdown v-model="modelId" :list="models" class="ml-1" />
          <span class="ml-4 mr-5 text-gray-300">|</span>
          <ButtonDropdown :modelValue="selectedTab" :list="tabs" class="lg:hidden" @update:modelValue="onSelectTab({ value: $event })" />
          <div
            v-for="tab in tabs"
            :key="tab.value"
            :class="{ 'text-gray-800': !tabSelected(tab), 'bg-stroke text-semi-dark-text': tabSelected(tab) }"
            class="cursor-pointer mr-3 py-0.5 rounded-4 px-2 max-lg:hidden"
            @click="onSelectTab(tab)"
          >
            {{ tab.label }}
          </div>
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <RouterView v-slot="{ Component }">
          <component :is="modelData ? Component : null" :model="modelData" />
        </RouterView>
      </div>
    </div>
    <Modal v-if="fetching">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FINE_TUNING_TYPES } from '@/constants';

export default {
  name: 'Model',
  data() {
    return {
      modelData: null,
      fetching: false,
      selectedTab: 'settings',
    };
  },
  computed: {
    ...mapGetters(['getProjectModels']),
    tabs() {
      return [
        { label: this.$t('projects.models.settings'), value: 'settings' },
        { label: this.$t('projects.models.fine_tune'), value: 'fine-tune' },
        { label: this.$t('projects.models.ai_suggestions'), value: 'suggestions' },
        { label: this.$t('projects.models.build.build'), value: 'build' },
      ];
    },
    projectId() {
      return this.$route.params.projectId;
    },
    types() {
      return Object.keys(FINE_TUNING_TYPES).map((key) => ({ label: this.$t(`projects.models.fine_tune_types.${key}`), value: key }));
    },
    modelId: {
      get() {
        return this.$route.params.modelId;
      },
      set(value) {
        this.$router.push({
          name: this.$route.name,
          params: {
            ...this.$route.params,
            modelId: value,
          },
        });
      },
    },
    models() {
      return Object.values(this.getProjectModels()).map((model) => {
        return {
          label: model.name,
          value: model.model_id,
        };
      });
    },
  },
  created() {
    if (this.$route.name === 'project-model') {
      this.selectedTab = 'settings';
    } else if (this.$route.name === 'project-model-fine-tune') {
      this.selectedTab = 'fine-tune';
    } else if (this.$route.name === 'project-model-suggestions') {
      this.selectedTab = 'suggestions';
    } else if (this.$route.name === 'project-model-build') {
      this.selectedTab = 'build';
    }
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchProjectModel', 'fetchProjectModels', 'fetchProjectDataTypes']),
    goToModels() {
      this.$router.push({
        name: 'project-models',
        params: { projectId: this.$route.params.projectId },
      });
    },
    tabSelected(tab) {
      return tab.value === this.selectedTab;
    },
    onSelectTab(tab) {
      this.selectedTab = tab.value;
      if (tab.value === 'settings') {
        this.$router.push({ name: 'project-model', params: this.$route.params });
      } else if (tab.value === 'fine-tune') {
        this.$router.push({ name: 'project-model-fine-tune', params: this.$route.params });
      } else if (tab.value === 'suggestions') {
        this.$router.push({ name: 'project-model-suggestions', params: this.$route.params });
      } else if (tab.value === 'build') {
        this.$router.push({ name: 'project-model-build', params: this.$route.params });
      }
    },
    validateModelName() {
      let models = Object.values(this.getProjectModels());
      if (this.model) {
        models = models.filter((model) => model.model_id !== this.model.model_id);
      }
      if (models.some((model) => model.name === this.name)) {
        return this.$t('validation.name_unique');
      }
      return true;
    },
  },
  watch: {
    projectId: {
      async handler() {
        await this.fetchProjectModels({ projectId: this.projectId });
      },
      immediate: true,
    },
    modelId: {
      async handler() {
        this.fetching = true;
        this.modelData = null;
        await this.fetchProjectDataTypes({ projectId: this.projectId });
        const response = await this.fetchProjectModel({ project_id: this.projectId, model_id: this.modelId });
        if (response) {
          this.modelData = response;
        }
        this.fetching = false;
      },
      immediate: true,
    },
  },
};
</script>
<style>
.project-model .vs__open-indicator {
  --vs-controls-size: 0.7;
}
</style>
