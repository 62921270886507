<template>
  <div class="relative" v-outside-click="onBlur" @blur="open = false">
    <span
      class="inline-block w-full border-b-4 transition-all ease-in duration-150 relative"
      :class="{
        'border-alert': !!error,
        'border-transparent': !error && !open,
        'border-primary': open,
      }"
    >
      <button
        :data-selected="!!inputValue"
        :data-open="open"
        class="cursor-pointer border-b relative w-full pl-5 pr-8 pt-4 pb-0 h-11 text-left focus:outline-none transition ease-in-out duration-150 text-sm leading-5"
        @focus="open = !open"
      >
        <label
          v-if="label"
          class="absolute top-0 left-0 text-16 p-4 duration-300 origin-0 pointer-events-none text-gray-800"
          :class="{
            'transform scale-75 -translate-y-6 translate-x-1': open || selected,
            'text-primary': open,
          }"
          @click.stop="open = true"
          >{{ label }}</label
        >

        <div class="flex items-center space-x-3">
          <span
            class="block truncate text-16"
            :class="{
              'text-gray-300': open,
              'text-gray-800': !open,
              'opacity-0': !!searchText && searchable,
            }"
          >
            {{ selectedText }}
          </span>
        </div>

        <span v-if="!!inputValue && clearable" class="absolute bottom-2 right-8 flex items-center pr-2" @click.stop="clear">
          <Icon name="clear" class="w-4 h-4 text-gray-400" />
        </span>

        <span class="absolute bottom-2 right-0 flex items-center pr-2 pointer-events-none">
          <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none" stroke="currentColor">
            <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </span>
      </button>
      <input v-show="open && searchable" ref="searchInput" v-model="searchText" class="bg-transparent absolute top-5 left-0 right-0 px-5 outline-none" @keydown.tab="onBlur" />
    </span>

    <div v-if="error" class="inline-flex mt-2 ml-5 rounded-r-full rounded-bl-full bg-alert px-5 py-2 text-[14px] text-white">
      {{ error }}
    </div>

    <div v-if="open" class="absolute top-12 z-2 mt-1 w-full rounded-md bg-white shadow-lg max-h-300 overflow-y-auto">
      <ul tabindex="-1" class="max-h-56 rounded-md py-1 shadow-xs overflow-auto focus:outline-none text-sm leading-5">
        <!--
          Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.
        -->
        <li class="text-gray-900 select-none relative py-2 pl-3 pr-7 cursor-pointer hover:text-white hover:bg-indigo-600" v-for="(option, i) of filteredItems" :key="i" @click="onClicked(option)">
          <div class="flex items-center space-x-3">
            <span class="font-400 block truncate" :class="{ 'font-700': option.value === selected }">
              {{ option.label }}
            </span>
          </div>

          <!--
            Highlighted: "text-white", Not Highlighted: "text-indigo-600"
          -->
          <span class="absolute inset-y-0 right-0 flex items-center pr-2" v-if="option.value === selected">
            <Icon name="check" class="h-5 w-5" />
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import ValidationMixin from '../../mixins/ValidationMixin';

export default {
  name: 'MaterialSelect',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    disabled: Boolean,
    modelValue: {
      type: String,
      default: '',
    },
    name: String,
    label: String,
    clearable: Boolean,
    searchable: Boolean,
    error: String,
    items: Array,
  },
  mixins: [ValidationMixin],
  data() {
    return {
      open: false,
      searchText: '',
    };
  },
  computed: {
    filteredItems() {
      if (this.searchable) {
        return this.items.filter((item) => item.label.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase()));
      }
      return this.items;
    },
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
        this.open = false;
        this.searchText = '';
      },
    },
    selected() {
      return this.inputValue || null;
    },
    selectedText() {
      const item = this.items.find((i) => i.value === this.inputValue);
      return item?.label || null;
    },
  },
  methods: {
    clear() {
      if (this.searchable && this.open) {
        this.searchText = '';
      } else {
        this.inputValue = '';
      }
    },
    onClicked(option) {
      this.inputValue = option.value;
      this.searchText = '';
    },
    onBlur() {
      this.open = false;
      this.searchText = '';
    },
  },
  watch: {
    open() {
      if (this.open && this.searchable) {
        this.$nextTick().then(() => {
          this.$refs.searchInput.focus();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
button[data-open='true'] label,
button[data-selected='true'] label {
  @apply transform scale-75 -translate-y-6 translate-x-1;
}

button[data-open='true'] label {
  @apply text-primary;
}
</style>
