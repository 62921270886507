<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 h-14">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-primary font-600">{{ $t('projects.endpoints.title') }}</div>
        </div>

        <div class="ml-auto flex flex-row justify-center">
          <PillButton :text="$t('add_new')" primary @click="onCreateClicked" />
        </div>
      </div>
      <div class="w-full flex-grow overflow-auto pb-2 flex flex-col">
        <div class="mb-5 text-13">
          <span class="font-600 text-indigo-800">{{ $t('projects.endpoints.instance_url') }}</span>
          <div class="mt-2">{{ instanceUrl }}</div>
        </div>
        <template v-if="groupNames.length">
          <div v-for="(groupName, index) in groupNames" :key="index" class="pb-6">
            <div class="text-13 text-indigo-800 font-600 -mb-3 mt-3">{{ groupName }}</div>
            <TableElevated v-if="getEndpointsByGroupName(groupName).length" :items="getEndpointsByGroupName(groupName)" :headers="endpointHeaders" class="text-13 pr-3 sm:pr-10">
              <template v-slot:[`item.method`]="{ value }">
                <Badge class="w-16 sm:w-20" v-if="value === 'GET'" info :text="value"></Badge>
                <Badge class="w-16 sm:w-20" v-if="value === 'POST'" success :text="value"></Badge>
                <Badge class="w-16 sm:w-20" v-if="value === 'DELETE'" error :text="value"></Badge>
                <Badge class="w-16 sm:w-20" v-if="value === 'PUT'" warn :text="value"></Badge>
              </template>
              <template v-slot:[`item.actions`]="{ item: endpoint }">
                <IconButton icon="link" class="sm:ml-1 text-primary" :title="$t('projects.endpoints.copy_url')" @click="onCopyClicked(endpoint)"></IconButton>
                <IconButton icon="edit" class="sm:ml-1 text-primary" @click="onEditClicked(endpoint)"></IconButton>
                <IconButton icon="delete" class="sm:ml-1 text-primary" @click="onDeleteClicked(endpoint)"></IconButton>
              </template>
            </TableElevated>
          </div>
        </template>
        <div v-else class="flex flex-col flex-grow items-center justify-center pb-50">
          <div class="my-2 text-22 text-gray-700">{{ $t('projects.endpoints.have_no_endpoints') }}</div>
          <div class="my-2 text-gray-600">{{ $t('projects.endpoints.create_first_endpoint') }}</div>
          <PillButton class="my-3" :text="$t('projects.endpoints.create_new')" primary @click="onCreateClicked" />
        </div>
      </div>
    </div>
    <Modal v-if="fetching">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DeleteModal from '@shared/components/shared-modals/DeleteModal.vue';
import CreateProjectEndpointModal from '@/components/project/CreateProjectEndpointModal';

export default {
  name: 'Endpoints',
  data() {
    return {
      fetching: false,
    };
  },
  computed: {
    ...mapState(['projectData']),
    projectId() {
      return this.$route.params.projectId;
    },
    endpoints() {
      if (!this.projectId) return [];
      const endpoints = this.projectData?.[this.projectId]?.endpoints || {};
      return Object.keys(endpoints).map((key) => {
        return {
          id: key,
          ...endpoints[key],
        };
      });
    },
    groupNames() {
      const names = new Set(
        this.endpoints
          .map((endpoint) => endpoint.group_name)
          .filter((e) => e)
          .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())),
      );
      names.add('');
      return [...names];
    },
    instanceUrl() {
      return `https://${this.projectId}.fidsy.cloud/api`;
    },
    endpointHeaders() {
      return [
        { key: 'method', title: this.$t('projects.endpoints.method'), width: '40px' },
        { key: 'path', title: this.$t('projects.endpoints.path') },
        { key: 'actions', title: this.$t('actions'), width: '50px' },
      ];
    },
  },
  methods: {
    ...mapActions(['fetchProjectEndpoints', 'showToastMessage', 'deleteProjectEndpoint']),
    getEndpointsByGroupName(groupName) {
      return this.endpoints.filter((endpoint) => endpoint.group_name === groupName).sort((a, b) => a.path.toLowerCase().localeCompare(b.path.toLowerCase())) || [];
    },
    onCreateClicked() {
      this.$showModal(CreateProjectEndpointModal, { projectId: this.projectId });
    },
    async onCopyClicked(endpoint) {
      try {
        await navigator.clipboard.writeText(`${this.instanceUrl}${endpoint.path}`);
        this.showToastMessage({ message: this.$t('url_copied'), type: 'success' });
      } catch (err) {
        this.showToastMessage({ title: this.$t('url_copy_fail'), type: 'error' });
      }
    },
    onEditClicked(endpoint) {
      this.$showModal(CreateProjectEndpointModal, { projectId: this.projectId, endpoint });
    },
    onDeleteClicked(item) {
      this.$showModal(DeleteModal, {
        subtitle: this.$t('projects.endpoints.remove_endpoint_question'),
        onConfirm: () => this.onDeleteConfirmClicked(item),
      });
    },
    async onDeleteConfirmClicked(item) {
      const response = await this.deleteProjectEndpoint({ project_id: this.projectId, endpoint_id: item.endpoint_id });
      if (response) {
        this.showToastMessage({ message: this.$t('projects.endpoints.removed_successfully'), type: 'success' });
      }
    },
  },
  watch: {
    projectId: {
      async handler() {
        this.fetching = true;
        await this.fetchProjectEndpoints({ projectId: this.projectId });
        this.fetching = false;
      },
      immediate: true,
    },
  },
};
</script>
