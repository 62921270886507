<template>
  <div class="max-w-600 max-h-470" :class="computedClasses">
    <div v-if="title || subtitle" class="px-4 w-full">
      <div v-if="title" class="font-500 text-16">{{ title }}</div>
      <div v-if="subtitle" class="text-13" :class="{ 'mt-3': title }">
        {{ subtitle }}
      </div>
    </div>
    <Divider v-if="title || subtitle" />
    <div
      class="w-full flex"
      :class="{
        'px-4': listingType !== 'button' || title || subtitle,
        'flex-row': !horizontal,
        'flex-col': horizontal,
      }"
    >
      <div
        class="flex-grow"
        :class="{
          'overflow-hidden': !(listingType === 'button' && horizontal),
        }"
        ref="container"
        :style="containerStyle"
        style="scroll-behavior: smooth"
      >
        <div
          class="w-full -mb-2"
          ref="questionslist"
          :class="{
            'flex-wrap justify-end': listingType === 'button' && horizontal,
            flex: horizontal,
          }"
        >
          <div
            v-for="(item, index) in items"
            :key="index"
            class="flex flex-shrink-0 mb-2"
            :class="{ 'mx-1': horizontal, 'w-full': !horizontal }"
            :style="{ height: `${itemHeight}px`, width: itemWidth }"
          >
            <template v-if="listingType === 'button'">
              <PillButton :text="item.title" class="px-5 shadow-input" :class="{ 'my-1': horizontal }" @click="$emit('item-click', item)" />
            </template>
            <template v-else-if="listingType === 'subtitle'">
              <div class="w-full flex flex-col hover:bg-gray-100 bg-white border border-solid border-gray-200 rounded-4 px-4 py-2 cursor-pointer shadow-md" @click="$emit('item-click', item)">
                <div class="font-500 text-14">{{ item.title }}</div>
                <div class="text-13 mt-1 opacity-75 flex-grow overflow-hidden">
                  {{ item.subtitle }}
                </div>
              </div>
            </template>
            <template v-else-if="listingType === 'image-text'">
              <div
                class="w-full flex hover:bg-gray-100 bg-white border border-solid border-gray-200 rounded-4 px-4 py-3 cursor-pointer flex shadow-md"
                :class="{ 'flex-col': horizontal }"
                @click="$emit('item-click', item)"
              >
                <img :src="item.image" :alt="item.title" class="w-20 h-20 flex-shrink-0" :class="{ 'mr-4': !horizontal, 'mb-2': horizontal }" />
                <div class="flex-grow overflow-hidden">
                  <div class="font-500 text-14">{{ item.title }}</div>
                  <div class="text-13 mt-2 opacity-75" :class="{ 'overflow-hidden flex-grow': horizontal }">
                    {{ item.subtitle }}
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div v-if="showScrollButtons" class="flex-shrink-0 ml-3" :class="{ 'pt-1': !horizontal, '-mb-2': horizontal }">
        <div v-if="!horizontal" class="flex flex-col justify-center flex-shrink-0 flex-grow-0 w-9 items-center">
          <div
            :class="{ 'opacity-25 pointer-events-none': pageIndex === 0 }"
            class="w-7 h-7 rounded-full bg-white border border-gray-200 shadow-lg flex justify-center mb-3 cursor-pointer hover:bg-gray-200"
            @click.stop="onScrollUpClicked"
          >
            <Icon name="expand_less" class="w-7 h-7" />
          </div>
          <div
            :class="{
              'opacity-25 pointer-events-none': pageIndex + 1 === pageCount,
            }"
            class="w-7 h-7 rounded-full bg-white border border-gray-200 shadow-lg flex justify-center mb-3 cursor-pointer hover:bg-gray-200"
            @click.stop="onScrollDownClicked"
          >
            <Icon name="expand_more" class="w-7 h-7" />
          </div>
        </div>
        <div v-else class="flex flex-row justify-center flex-shrink-0 flex-grow-0 w-18 items-center ml-auto">
          <div
            :class="{ 'opacity-25 pointer-events-none': pageIndex === 0 }"
            class="w-7 h-7 rounded-full bg-white border border-gray-200 shadow-lg flex justify-center mr-1 mb-3 cursor-pointer hover:bg-gray-200"
            @click.stop="onScrollLeftClicked"
          >
            <Icon name="chevron_left" class="w-7 h-7" />
          </div>
          <div
            :class="{
              'opacity-25 pointer-events-none': pageIndex + 1 === pageCount,
            }"
            class="w-7 h-7 rounded-full bg-white border border-gray-200 shadow-lg flex justify-center ml-1 mb-3 cursor-pointer hover:bg-gray-200"
            @click.stop="onScrollRightClicked"
          >
            <Icon name="chevron_right" class="w-7 h-7" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Prompt',
  props: {
    title: String,
    subtitle: String,
    items: Array,
    disabled: Boolean,
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scrollPosition: 0,
      pageIndex: 0,
    };
  },
  computed: {
    computedClasses() {
      const classes = [];

      if (this.disabled) {
        classes.push('pointer-events-none opacity-50');
      }

      if (!this.title && !this.subtitle && this.items?.length) {
        const [firstItem] = this.items;
        if (!firstItem.image && !firstItem.subtitle) {
          classes.push('text-right');
          return classes;
        }
      }
      classes.push('shadow-md rounded-12 bg-white py-2');
      return classes;
    },
    containerStyle() {
      return {
        maxHeight: `${this.itemsScrollLimit * this.itemHeight}px`,
      };
    },
    itemHeight() {
      if (!this.horizontal) {
        if (this.listingType === 'button') {
          return 40;
        }
        if (this.listingType === 'subtitle') {
          return 90;
        }
        if (this.listingType === 'image-text') {
          return 120;
        }
        return 40;
      }

      if (this.listingType === 'button') {
        return undefined;
      }
      if (this.listingType === 'subtitle') {
        return 208;
      }
      if (this.listingType === 'image-text') {
        return 270;
      }
      return 40;
    },
    itemWidth() {
      if (this.listingType === 'button') {
        return '';
      }
      if (this.listingType === 'subtitle') {
        return this.horizontal ? `calc(${100 / this.itemsScrollLimit}% - 8px)` : '100%';
      }
      if (this.listingType === 'image-text') {
        return this.horizontal ? `calc(${100 / this.itemsScrollLimit}% - 8px)` : '100%';
      }
      return '40px';
    },
    itemsScrollLimit() {
      if (this.listingType === 'button') {
        return 5;
      }
      if (this.listingType === 'subtitle') {
        return this.horizontal ? 3 : 3;
      }
      if (this.listingType === 'image-text') {
        return this.horizontal ? 3 : 3;
      }
      return 2;
    },
    showScrollButtons() {
      if (this.horizontal && this.listingType === 'button') {
        return false;
      }
      return this.items.length > this.itemsScrollLimit;
    },
    listingType() {
      const [item] = this.items;
      if (!item.subtitle && !item.image) {
        return 'button';
      }
      if (item.subtitle && !item.image) {
        return 'subtitle';
      }
      if (item.subtitle && item.image) {
        return 'image-text';
      }
      // button | image-text | image-subtitle |
      return '';
    },
    pageCount() {
      return Math.ceil(this.items.length / this.itemsScrollLimit);
    },
  },
  methods: {
    onScrollLeftClicked() {
      const containerWidth = this.$refs.container.getBoundingClientRect().width;
      if (this.pageIndex > 0) {
        this.pageIndex--;
      }
      const position = -1 * this.pageIndex * containerWidth;
      this.scrollPosition = position;

      this.$refs.container.scrollLeft = this.scrollPosition * -1;
    },
    onScrollRightClicked() {
      const containerWidth = this.$refs.container.getBoundingClientRect().width;
      const listWidth = this.$refs.questionslist.scrollWidth;
      const pageCount = containerWidth >= listWidth ? 1 : Math.ceil(listWidth / containerWidth);
      if (this.pageIndex + 1 < pageCount) {
        this.pageIndex++;
      }
      const position = -1 * this.pageIndex * containerWidth;
      this.scrollPosition = position;

      this.$refs.container.scrollLeft = this.scrollPosition * -1;
    },
    onScrollUpClicked() {
      const containerHeight = this.$refs.container.getBoundingClientRect().height;
      if (this.pageIndex > 0) {
        this.pageIndex--;
      }
      const position = -1 * this.pageIndex * containerHeight;
      this.scrollPosition = position;

      this.$refs.container.scrollTop = this.scrollPosition * -1;
    },
    onScrollDownClicked() {
      const containerHeight = this.$refs.container.getBoundingClientRect().height;
      const listHeight = this.$refs.questionslist.getBoundingClientRect().height;
      const pageCount = containerHeight >= listHeight ? 1 : Math.ceil(listHeight / containerHeight);
      if (this.pageIndex + 1 < pageCount) {
        this.pageIndex++;
      }
      const position = -1 * this.pageIndex * containerHeight;
      this.scrollPosition = position;

      this.$refs.container.scrollTop = this.scrollPosition * -1;
    },
  },
};
</script>
