<template>
  <div class="flex items-center">
    <span class="text-14 lg:text-20 text-primary font-600 select-none cursor-pointer" @click="$router.push({ name: 'project' })"> {{ $t('navigation.projects') }} </span>
    <span class="px-4 text-primary font-600">|</span>
    <ProjectSelectDropdown :active-project="activeProject" :projects="projects" @click="onProjectSelect" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'NoCodeHeader',
  data() {
    return {
      activeItem: null,
    };
  },
  computed: {
    ...mapState(['projects']),
    getProjects() {
      return Object.values(this.projects).map((project) => ({ label: project.name, value: project.project_id, logo: project?.logos?.FAVICON || undefined }));
    },
    activeProject() {
      const activeProject = this.projects?.[this.$route.params.projectId] || {};
      return {
        projectId: this.$route.params.projectId,
        name: activeProject.name,
        icon: activeProject?.logos?.CONVERSATION_BUBBLE || '',
      };
    },
    activeProjectTheme() {
      const activeProject = this.projects?.[this.$route.params.projectId];
      return activeProject?.theme || 'default';
    },
  },
  created() {
    if (this.$route.params.projectId) {
      this.activeItem = this.$route.params.projectId;
    }
  },
  beforeUnmount() {
    document.body.classList.forEach((className) => {
      if (className.startsWith('theme--')) document.body.classList.remove(className);
    });
    document.body.classList.add(`theme--default`);
  },
  methods: {
    onProjectSelect(project) {
      this.$router.push({ name: this.$route.name, params: { projectId: project.id } });
    },
  },
  watch: {
    activeProjectTheme: {
      handler() {
        document.body.classList.forEach((className) => {
          if (className.startsWith('theme--')) document.body.classList.remove(className);
        });
        document.body.classList.add(`theme--${this.activeProjectTheme}`);
      },
      immediate: true,
    },
  },
};
</script>
