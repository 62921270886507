import mitt from 'mitt';
import debounce from '../helpers/debounce';

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export default {
  data() {
    return {
      vid: uuidv4(),
      eventbus: mitt(),
    };
  },
  props: {
    rules: {
      type: [String, Array],
    },
    name: {
      type: String,
    },
    validationDelay: {
      type: Number,
      default: 0,
    },
  },
  inject: {
    validationObserver: { default: undefined },
  },
  created() {
    if (this.validationObserver && this.rules) {
      this.validationObserver.subscribe(this);
      this.eventbus.on('input', this.$onInput);
      this.eventbus.on('blur', this.$onBlur);
    }

    this.debouncedValidate = debounce(function (value) {
      this.validate(value);
    }, this);
  },
  beforeUnmount() {
    if (this.validationObserver) {
      this.validationObserver.unsubscribe(this);
      this.eventbus.on('input', this.$onInput);
      this.eventbus.on('blur', this.$onBlur);
    }
  },
  methods: {
    async $onInput(value) {
      if (this.validationObserver.validateOnInput || this.validationObserver.isDirty()) {
        if (this.validationDelay) {
          this.debouncedValidate(value);
        } else {
          this.validate();
        }
      }
    },
    $onBlur() {
      if (this.validationObserver.validateOnBlur || this.validationObserver.isDirty()) {
        if (this.validationDelay) {
          this.debouncedValidate(this.value);
        } else {
          this.validate();
        }
      }
    },
    validate(value) {
      this.validationObserver.validateField(this.$data.vid, value);
    },
    debouncedValidate: () => {}, // sets in created
  },
};
