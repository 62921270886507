<template>
  <div class="fixed top-16 right-6 bg-white shadow-mini-card rounded-14 select-none">
    <div class="px-6 pt-5 pb-4 font-500">{{ $t('notifications.title') }}</div>
    <divider dense />
    <div class="p-6 text-gray-800 text-13">
      {{ $t('notifications.no_notifications') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Notifications',
};
</script>
