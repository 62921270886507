<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-3 items-center flex-shrink-0 sm:pr-10">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-primary font-600">{{ $t('publishing.title') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <PillButton :text="$t('publishing.publish')" primary @click="showPublishModal = true" />
        </div>
      </div>
      <div class="w-full flex-grow overflow-hidden sm:pr-10 pb-2">
        <template v-if="publishList.length">
          <TableElevated :items="publishList" key="version" :headers="publishHeaders" class="text-13">
            <template v-slot:[`item.version`]="{ item }">
              <div class="font-500 truncate w-100">{{ $t('publishing.version_x', { version: item.version }) }}</div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <IconButton class="text-primary" icon="delete" @click="onDeleteClicked(item)" />
            </template>
          </TableElevated>
        </template>
        <div v-else class="flex flex-col h-full flex-grow items-center justify-center pb-50">
          <div class="my-2 text-22 text-gray-700">{{ $t('publishing.have_no_publish') }}</div>
          <div class="my-2 text-gray-600">{{ $t('publishing.create_first_publish') }}</div>
          <PillButton class="my-3" :text="$t('publishing.create_new')" primary @click="showPublishModal = true" />
        </div>
      </div>
    </div>
    <Modal v-if="fetching">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
    <Modal
      v-if="showPublishModal"
      title="Publish New Version"
      :primary-button="$t('publishing.publish')"
      :secondary-button="$t('cancel')"
      :primary-button-loading="publishing"
      :secondary-button-disabled="publishing"
      close-button
      sheetbelowsm
      @primary="onPublishClick"
      @close="showPublishModal = false"
      @secondary="showPublishModal = false"
    >
      <div class="w-full md:w-600 px-10 my-5" v-html="$t('publishing.new_publish_question', { nextVersion })"></div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import dayjs from 'dayjs';
import DeleteModal from '@shared/components/shared-modals/DeleteModal.vue';
import { apiDeleteProjectPublish, apiGetProjectPublishList, apiPostProjectPublish } from '@/helpers/api';

export default {
  name: 'Publish',
  data() {
    return {
      publishes: {},
      fetching: false,
      publishing: false,
      showPublishModal: false,
    };
  },
  computed: {
    ...mapState(['projectData']),
    publishHeaders() {
      return [
        { key: 'version', title: this.$t('version') },
        { key: 'user', title: this.$t('user') },
        { key: 'dateText', title: this.$t('date') },
        { key: 'actions', title: this.$t('actions'), width: '50px' },
      ];
    },
    publishList() {
      return Object.values(this.publishes)
        .sort((a, b) => b.version - a.version)
        .map((item) => {
          return {
            ...item,
            dateText: dayjs(item.timestamp).format('MMM DD, YYYY HH:mm'),
          };
        });
    },
    nextVersion() {
      const versions = this.publishList.map((r) => parseInt(r.version, 10));
      return Number.isInteger(Math.max(...versions)) ? Math.max(...versions) + 1 : 1;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    modelId() {
      return this.$route.params.modelId;
    },
  },
  methods: {
    ...mapActions(['showToastMessage']),
    dayjs,
    async onPublishClick() {
      this.publishing = true;
      try {
        const response = await apiPostProjectPublish({ project_id: this.projectId, version: this.nextVersion });
        if (response.status === 200) {
          this.showToastMessage({ message: this.$t('publishing.version_published', { version: this.nextVersion }), type: 'success' });
          await this.fetchData();
          this.showPublishModal = false;
        } else {
          this.showToastMessage({ title: response?.data?.message || this.$t('publishing.publish_failed'), type: 'error' });
        }
      } catch (e) {
        this.showToastMessage({ title: this.$t('publishing.publish_failed'), type: 'error' });
      }
      this.publishing = false;
    },
    onDeleteClicked(item) {
      this.$showModal(DeleteModal, {
        title: this.$t('confirm'),
        subtitle: this.$t('publishing.remove_publish_question', { version: item.version }),
        info: this.$t('all_data_will_be_removed'),
        onConfirm: async () => {
          try {
            const response = await apiDeleteProjectPublish({ project_id: this.projectId, version: item.version, user: item.user });
            if (response.status === 200) {
              this.showToastMessage({ message: this.$t('publishing.version_removed', { version: item.version }), type: 'success' });
              await this.fetchData();
            } else {
              this.showToastMessage({ title: response?.data?.message || this.$t('publishing.remove_failed'), type: 'error' });
            }
          } catch (e) {
            this.showToastMessage({ title: this.$t('publishing.remove_failed'), type: 'error' });
          }
        },
      });
    },
    async fetchData() {
      try {
        const response = await apiGetProjectPublishList(this.projectId);
        if (response.status === 200) {
          this.publishes = response.data;
        } else {
          this.showToastMessage({ title: response?.data?.message || this.$t('publishing.failed_to_get_data'), type: 'error' });
        }
      } catch (e) {
        this.showToastMessage({ title: this.$t('publishing.failed_to_get_data'), type: 'error' });
      }
    },
  },
  watch: {
    projectId: {
      async handler() {
        this.fetching = true;
        await this.fetchData();
        this.fetching = false;
      },
      immediate: true,
    },
  },
};
</script>
