<template>
  <Modal
    fullbelowsm
    :title="name"
    :primary-button="$t('save')"
    :secondary-button="$t('cancel')"
    :secondary-button-disabled="loading"
    :primary-button-loading="loading"
    :primary-button-disabled="loading"
    @primary="onSaveClick"
    @secondary="onCancelClick"
  >
    <div class="sm:w-600">
      <DataAdapterConfiguration :type="type" v-model:config="config" ref="configuration" class="px-10" />
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';
import { DATA_ADAPTERS } from '@/constants';
import DataAdapterConfiguration from '@/components/data-adapter/DataAdapterConfiguration.vue';

export default {
  name: 'ConnectDataAdapter',
  components: { DataAdapterConfiguration },
  props: {
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    editData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      connecting: false,
      connected: false,
      config: {},
      dataTypes: {},
      DATA_ADAPTERS,
    };
  },
  created() {
    if (this.type === DATA_ADAPTERS.POSTGRESQL || this.type === DATA_ADAPTERS.MYSQL || this.type === DATA_ADAPTERS.MSSQL) {
      const { enabled = true, user = '', password = '', domain = '', port = '', database = '' } = this.editData?.config || {};
      this.config = { enabled, user, password, domain, port, database };
    } else if (this.type === DATA_ADAPTERS.GOOGLE_ANALYTICS || this.type === DATA_ADAPTERS.XERO) {
      const { enabled = true, client_id = '', client_secret = '' } = this.editData?.config || {};
      this.config = {
        enabled,
        client_id,
        client_secret,
      };
    }
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
  },
  methods: {
    ...mapActions(['createProjectDataAdapter', 'updateProjectDataAdapter']),
    async onSaveClick() {
      const valid = await this.$refs.configuration.validate();
      if (!valid.valid) return;
      this.loading = true;
      if (!this.editData) {
        const response = await this.createProjectDataAdapter({ project_id: this.projectId, adapter_type: this.type, config: this.config });
        if (response) {
          this.$emit('close');
        }
      } else {
        const response = await this.updateProjectDataAdapter({ project_id: this.projectId, adapter_type: this.type, config: this.config });
        if (response) {
          this.$emit('close');
        }
      }
      this.loading = false;
    },
    onCancelClick() {
      this.$emit('close');
    },
  },
};
</script>
