import { BASE_URL } from '@/constants';

export async function post(url = '', data = {}, headers = {}, json = true) {
  // Default options are marked with *
  return fetch(BASE_URL + url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    credentials: 'include', // include, *same-origin, omit
    headers: {
      ...headers,
      'x-xsrf-token': localStorage.getItem('xsrf-token'),
      'Content-Type': 'application/json',
    },
    body: json ? JSON.stringify(data) : data, // body data type must match 'Content-Type' header
  });
}

export async function del(url = '', data = {}, headers = {}) {
  // Default options are marked with *
  return fetch(BASE_URL + url, {
    method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
    credentials: 'include', // include, *same-origin, omit
    headers: {
      ...headers,
      'x-xsrf-token': localStorage.getItem('xsrf-token'),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data), // body data type must match 'Content-Type' header
  });
}

export async function put(url = '', data = {}, headers = {}, json = true) {
  // Default options are marked with *
  return fetch(BASE_URL + url, {
    method: 'PUT', // *GET, POST, PUT, DELETE, etc.
    credentials: 'include', // include, *same-origin, omit
    headers: {
      'x-xsrf-token': localStorage.getItem('xsrf-token'),
      'Content-Type': 'application/json',
      ...headers,
    },
    body: json ? JSON.stringify(data) : data, // body data type must match 'Content-Type' header
  });
}

export async function get(url = '', headers = {}) {
  // Default options are marked with *
  return fetch(BASE_URL + url, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    credentials: 'include', // include, *same-origin, omit
    headers: {
      ...headers,
      'x-xsrf-token': localStorage.getItem('xsrf-token'),
      'Content-Type': 'application/json',
    },
  });
}
