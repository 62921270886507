<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-3 items-center flex-shrink-0 sm:pr-10 h-10">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-primary font-600">{{ $t('projects.monitoring.title') }}</div>
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <div class="my-2 text-12 text-gray-700 text-center font-500">{{ $t('projects.monitoring.coming_soon') }}</div>
        <div class="my-2 text-12 text-gray-700 text-center">We're currently working on creating the monitoring data.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Monitoring',
};
</script>
