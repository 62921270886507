<template>
  <div class="h-full">
    <div class="h-full flex flex-col pr-3 sm:pr-10">
      <div class="flex justify-end w-full"><PillButton outlined icon="send" text="Invite" primary @click="openInviteList" /></div>
      <div class="w-full pr-0.5 flex-grow overflow-auto pl-2 pb-2">
        <div class="mx-1 mb-2 px-3 flex text-12 text-gray-600">
          <div class="flex items-center w-[337px] font-700">{{ $t('email') }}</div>
          <div class="flex items-center col-span-4 font-700">{{ $t('role') }}</div>
        </div>
        <div v-for="user in users" :key="`${user.email}-${user.role}`">
          <ListCard class="flex-row mt-3" height-class="h-14">
            <div class="mr-4 truncate w-300">{{ user.email }}</div>
            <div v-if="user.email !== $store.state.user.email" class="w-26 ml-2">
              <v-select
                :modelValue="user.role"
                :options="getRoles"
                :reduce="(opt) => opt.value"
                :searchable="false"
                :clearable="false"
                class="w-25"
                dense
                text
                @update:modelValue="onRoleChange($event, user)"
              ></v-select>
            </div>
            <div v-else class="text-14 ml-5 opacity-80">
              {{ $t(`projects.roles.${user.role}`) }}
            </div>

            <div class="w-25 ml-auto text-center">
              <Badge v-if="user.status" class="ml-2" info :text="user.status"></Badge>
            </div>
            <PillButton v-if="user.status && user.status === 'expired'" class="mr-2" small text="Resend" icon="send" outlined @click="onResendClick(user.email)"></PillButton>
            <IconButton v-if="user.email !== $store.state.user.email" icon="delete" class="text-primary" @click="onDeleteClick(user)" />
          </ListCard>
        </div>
      </div>
    </div>
    <Modal v-if="fetching">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import TeamInviteModal from '@/components/project/TeamInviteModal.vue';
import { TEAM_MEMBER_ROLES } from '@/constants';
import DeleteModal from '@shared/components/shared-modals/DeleteModal.vue';

export default {
  name: 'AuthenticationTeamMembers',
  data() {
    return {
      fetching: false,
      activeProvider: null,
      providerConfig: {},
      emailPasswordEnabled: false,
    };
  },
  computed: {
    ...mapGetters(['getProjectSecrets', 'getProjectTeamMembers']),
    getRoles() {
      const roles = Object.values(TEAM_MEMBER_ROLES);
      return roles.map((role) => ({ label: this.$t(`projects.roles.${role}`), value: role }));
    },
    users() {
      return Object.values(this.getProjectTeamMembers());
    },
    projectId() {
      return this.$route.params.projectId;
    },
    secrets() {
      return Object.keys(this.getProjectSecrets()).map((i) => ({ label: i, value: i }));
    },
  },
  methods: {
    ...mapActions(['fetchProjectTeamMembers', 'resendProjectInviteEmail', 'changeProjectTeamMemberRole', 'deleteProjectInvitedMember']),
    async onRoleChange(value, user) {
      this.fetching = true;
      const res = await this.changeProjectTeamMemberRole({ project_id: this.projectId, email: user.email, role: value });
      if (res) {
        await this.fetchProjectTeamMembers({ project_id: this.projectId });
      }
      this.fetching = false;
    },
    onResendClick(email) {
      this.resendProjectInviteEmail({ project_id: this.projectId, email: email.trim() });
    },
    onDeleteClick(user) {
      this.$showModal(DeleteModal, {
        title: this.$t('projects.iam.confirm_remove_user'),
        subtitle: this.$t('projects.iam.confirm_remove_user_subtitle'),
        onConfirm: async () => {
          await this.deleteProjectInvitedMember({ project_id: this.projectId, email: user.email });
        },
      });
    },
    openInviteList() {
      this.$showModal(TeamInviteModal, { projectId: this.projectId });
    },
  },
  watch: {
    projectId: {
      async handler() {
        this.fetching = true;
        await this.fetchProjectTeamMembers({ project_id: this.projectId });
        this.fetching = false;
      },
      immediate: true,
    },
  },
};
</script>
