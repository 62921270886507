<template>
  <Modal
    sheetbelowsm
    close-button
    :title="$t('users.add_user')"
    :primary-button="$t('save')"
    :secondary-button="$t('cancel')"
    :primary-button-loading="loading"
    @primary="save"
    @secondary="cancel"
    @close="cancel"
  >
    <ValidationObserver ref="validationObserver" v-slot="{ validate, errors, dirty }" class="w-full">
      <div class="sm:w-600 flex py-7 px-10 flex-wrap">
        <div class="w-full flex mb-3">
          <FormInput v-model="name" name="name" rules="required" :error="errors['name']" :label="$t('name')" class="w-full md:w-1/2 md:mr-2" />
          <FormInput v-model="email" name="email" rules="required|email" :error="errors['email']" :label="$t('email')" class="w-full md:w-1/2 md:ml-2" />
        </div>
        <div class="w-full flex mb-3">
          <FormInput
            v-model="password"
            name="password"
            :rules="[{ validate: arePasswordsEqual }, 'required', 'password']"
            :error="errors['password']"
            :label="$t('login.password')"
            type="password"
            class="w-full md:w-1/2 md:mr-2"
            @update:modelValue="onPasswordInput(dirty, validate)"
          />
          <FormInput
            v-model="confirmPassword"
            name="confirmPassword"
            :rules="[{ validate: arePasswordsEqual }, 'required', 'password']"
            :error="errors['confirmPassword']"
            :label="$t('login.repeat_password')"
            type="password"
            class="w-full md:w-1/2 md:ml-2"
            @update:modelValue="onPasswordInput(dirty, validate)"
          />
        </div>
        <div class="w-full flex mb-3">
          <FormInput v-model="organisation" :label="$t('login.organisation')" class="w-full md:w-1/2 md:mr-2" />
          <FormInput v-model="position" :label="$t('login.position')" class="w-full md:w-1/2 md:ml-2" />
        </div>
        <div class="w-full flex mb-3">
          <FormSelect
            :label="$t('login.country')"
            name="country"
            rules="required"
            :error="errors['country']"
            v-model="country"
            class="w-full md:w-1/2 md:mr-2"
            :options="countries"
            :clearable="false"
            :reduce="(option) => option.value"
            appendToBody
          ></FormSelect>
          <FormSelect
            :label="$t('timezone')"
            name="timezone"
            rules="required"
            :error="errors['timezone']"
            v-model="timezone"
            class="w-full md:w-1/2 md:ml-2"
            :options="TIMEZONES"
            :clearable="false"
            searchable
            appendToBody
          ></FormSelect>
        </div>
        <div class="w-full mb-3">
          <FormSelect
            :label="$t('language')"
            name="language"
            rules="required"
            :error="errors['language']"
            v-model="language"
            :options="languages"
            :clearable="false"
            :reduce="(option) => option.value"
            appendToBody
          ></FormSelect>
        </div>
        <div class="w-full mb-3 pt-2 flex flex-col items-start">
          <Checkbox v-model="flags.rc" :label="$t('flags.rc')" class="mb-3 !text-13"></Checkbox>
          <Checkbox v-model="flags.oc" :label="$t('flags.oc')" class="mb-3 !text-13"></Checkbox>
        </div>
      </div>
    </ValidationObserver>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { COUNTRIES, TIMEZONES } from '@/constants';
import { languages } from '@/plugins/i18n';
import { same } from '../../../../shared/helpers/validators';

export default {
  name: 'AddUserModal',
  data() {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      name: '',
      timezone: '',
      country: '',
      organisation: '',
      position: '',
      language: '',
      orgs: [],
      flags: {
        rc: false,
        oc: false,
      },
      TIMEZONES,
      countries: COUNTRIES.map((c) => ({ label: c.name, value: c.name })),
      loading: false,
    };
  },
  emits: ['add', 'close'],
  computed: {
    ...mapState(['user']),
    languages() {
      return languages.map((lang) => ({ label: this.$t(`languages_alt.${lang}`), value: lang }));
    },
  },
  async created() {
    this.language = this.user?.locale || 'en-US';
    this.timezone = this.$store.state?.timezone;
    this.country = this.user.country;
  },
  methods: {
    ...mapActions(['addUser', 'showToastMessage']),
    onPasswordInput(isDirty, validate) {
      if (isDirty) validate();
    },
    arePasswordsEqual() {
      return same(this.password, this.confirmPassword) || this.$t('login.passwords_must_be_same');
    },
    async save() {
      const valid = await this.$refs.validationObserver.validate();

      if (valid.valid) {
        this.loading = true;
        const { email, password, name, timezone, country, organisation, position, language, orgs, flags } = this;
        const response = await this.addUser({ email, password, name, timezone, country, organisation, position, language, organisations: orgs, flags });

        if (response) {
          this.showToastMessage({ message: this.$t('projects.iam.added_successfully'), type: 'success' });
          this.$emit('add');
          this.$emit('close');
          this.loading = false;
          this.cancel();
        } else {
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
    },
    cancel() {
      this.$emit('close');
    },
  },
};
</script>
