<template>
  <div
    draggable="true"
    :id="id"
    class="border border-solid rounded-4 px-2 pr-3 flex items-start cursor-pointer min-h-10 py-2 text-gray-800"
    :class="{ 'bg-indigo-50': selectedEvaluateItemId === item.id, 'bg-white': selectedEvaluateItemId !== item.id, 'border-alert': invalid }"
    @dragstart.stop="onDragStart"
    @dragend.stop="onDragEnd"
    @click.stop="$emit('item-click', $event, item)"
  >
    <Icon v-if="icon" :name="icon" :class="[iconColor]" class="w-6 flex items-center justify-center flex-shrink-0 mr-2" />
    <component
      :is="component"
      :item="item"
      :selectedEvaluateItemId="selectedEvaluateItemId"
      :evaluate="evaluate"
      class="w-full overflow-hidden"
      :class="{ 'pointer-events-none': dragstart }"
      @item-click.stop="onItemClick"
      @update="onItemUpdate"
      @evaluate-update="$emit('evaluate-update', $event)"
    />
    <div v-if="selectable && !selected" class="rounded-full w-6 h-6 border border-solid flex-shrink-0 flex items-center justify-center cursor-pointer" @click.stop="$emit('select', item.id)"></div>
    <div v-if="selectable && selected" class="rounded-full w-6 h-6 cursor-pointer" @click.stop="$emit('select', item.id)">
      <Icon name="check_circle" class="w-6 h-6 text-primary" />
    </div>
  </div>
</template>

<script>
import { TRANSITION_ICONS } from '@/helpers/flow';
import uuidv4 from '@/helpers/uuid';
import EvaluateCondition from './EvaluateCondition';
import EvaluateStatement from './EvaluateStatement';
import EvaluateTransition from './EvaluateTransition';
import EvaluateApi from './EvaluateApi';
import EvaluateAi from './EvaluateAi';
import EvaluateCapture from './EvaluateCapture';
import EvaluateResponse from './EvaluateResponse';
import EvaluateEmail from './EvaluateEmail';
import EvaluateParallel from './EvaluateParallel';
import EvaluatePrompt from './EvaluatePrompt';

export default {
  name: 'EvaluateItem',
  components: { EvaluateCondition, EvaluateStatement, EvaluateTransition, EvaluateApi, EvaluateResponse, EvaluateCapture, EvaluatePrompt, EvaluateAi, EvaluateEmail, EvaluateParallel },
  data() {
    return {
      id: uuidv4(),
      dragstart: false,
    };
  },
  props: {
    item: Object,
    evaluate: Array,
    selectedEvaluateItemId: String,
    selectable: Boolean,
    selected: Boolean,
    invalid: Boolean,
  },
  computed: {
    icon() {
      if (this.item.type === 'condition') {
        return 'code';
      }
      if (this.item.type === 'statement') {
        return 'variable';
      }
      if (this.item.type === 'api') {
        return 'api';
      }
      if (this.item.type === 'prompt') {
        return 'capture';
      }
      if (this.item.type === 'capture') {
        return 'capture';
      }
      if (this.item.type === 'response') {
        return 'chat';
      }
      if (this.item.type === 'ai') {
        return 'psychology';
      }
      if (this.item.type === 'email') {
        return 'email';
      }
      if (this.item.type === 'parallel') {
        return 'route';
      }
      if (this.item.type === 'transition') {
        return TRANSITION_ICONS[this.item.payload.type];
      }
      return '';
    },
    iconColor() {
      if (this.item.type === 'condition') {
        return 'text-blue-500';
      }
      if (this.item.type === 'statement') {
        return 'text-blue-500';
      }
      if (this.item.type === 'api') {
        return 'text-pink-700';
      }
      if (this.item.type === 'prompt') {
        return 'text-green-500';
      }
      if (this.item.type === 'capture') {
        return 'text-green-500';
      }
      if (this.item.type === 'response') {
        return 'text-primary';
      }
      if (this.item.type === 'transition') {
        return 'text-blue-500';
      }
      if (this.item.type === 'ai') {
        return 'text-purple-500';
      }
      if (this.item.type === 'email') {
        return 'text-yellow-600';
      }
      if (this.item.type === 'parallel') {
        return 'text-yellow-700';
      }
      return '';
    },
    component() {
      if (this.item.type === 'condition') {
        return 'EvaluateCondition';
      }
      if (this.item.type === 'statement') {
        return 'EvaluateStatement';
      }
      if (this.item.type === 'transition') {
        return 'EvaluateTransition';
      }
      if (this.item.type === 'api') {
        return 'EvaluateApi';
      }
      if (this.item.type === 'response') {
        return 'EvaluateResponse';
      }
      if (this.item.type === 'prompt') {
        return 'EvaluatePrompt';
      }
      if (this.item.type === 'capture') {
        return 'EvaluateCapture';
      }
      if (this.item.type === 'ai') {
        return 'EvaluateAi';
      }
      if (this.item.type === 'email') {
        return 'EvaluateEmail';
      }
      if (this.item.type === 'parallel') {
        return 'EvaluateParallel';
      }
      return null;
    },
  },
  methods: {
    onItemUpdate($event) {
      this.$emit('update', JSON.parse(JSON.stringify({ ...this.item, payload: $event })));
    },
    onDragStart(e) {
      e.dataTransfer.setData('id', this.item.id);
      e.dataTransfer.setData('item', JSON.stringify(this.item));
      e.dataTransfer.setData('type', this.component);
      this.dragstart = true;
    },
    onDragEnd() {
      this.dragstart = false;
    },
    onItemClick($event, item) {
      this.$emit('item-click', $event, item);
    },
  },
};
</script>
