<template>
  <div class="h-full flex flex-col">
    <div class="w-full flex-grow overflow-auto pl-2 pb-2">
      <DataAdapterConfiguration v-if="adapter" :type="adapter.adapter_type" :default-config="config" ref="configuration" />
      <div class="mt-3">
        <span class="hover:underline text-gray-500 cursor-pointer mr-5" @click="openDeleteModal">{{ $t('projects.data_adapters.delete') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import DeleteModal from '@shared/components/shared-modals/DeleteModal.vue';
import { DATA_ADAPTERS } from '@/constants';
import DataAdapterConfiguration from '@/components/data-adapter/DataAdapterConfiguration.vue';

export default {
  name: 'DataAdapterSettings',
  components: { DataAdapterConfiguration },
  props: {
    adapter: {
      type: Object,
    },
  },
  data() {
    return {
      name: '',
      type: '',
      saving: false,
      connecting: false,
      connected: false,
      config: {},
      DATA_ADAPTERS,
    };
  },
  created() {
    this.config = this.adapter.config;
  },
  computed: {
    ...mapState(['availableDataAdapters']),
    ...mapGetters(['getProjectDataAdapters']),
    projectId() {
      return this.$route.params.projectId;
    },
  },
  methods: {
    ...mapActions(['updateProjectDataAdapter', 'deleteProjectDataAdapter']),
    openDeleteModal() {
      this.$showModal(DeleteModal, {
        subtitle: this.$t('projects.data_adapters.confirm_delete', { adapter: this.availableDataAdapters[this.adapter.adapter_type].name }),
        info: null,
        onConfirm: async () => {
          await this.deleteProjectDataAdapter({ project_id: this.projectId, name: this.adapter.adapter_type });
          this.$router.push({ name: 'project-data-adapters', params: { projectId: this.projectId } });
        },
      });
    },
    async save() {
      const valid = await this.$refs.configuration.validate();
      if (!valid.valid) return;

      this.saving = true;
      await this.updateProjectDataAdapter({
        project_id: this.projectId,
        adapter_type: this.adapter.adapter_type,
        config: this.config,
      });
      this.saving = false;
    },
  },
};
</script>
