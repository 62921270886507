<template>
  <Modal
    sheetbelowsm
    close-button
    :title="$t('navigation.data_sources')"
    :primary-button="$t('save')"
    :secondary-button="$t('cancel')"
    :primary-button-loading="loading"
    :loading="fetching"
    @primary="save"
    @secondary="cancel"
    @close="cancel"
  >
    <div class="sm:w-screen90 md:w-900 min-h-500 flex py-7 px-10 flex-wrap">
      <div class="w-full">
        <CollapseCard v-for="(dataAdapter, index) in projectActivatedDataAdapters" :key="index" :value="false" :showIcon="false" :scroll="false" class="shadow-card mb-4">
          <template #header>
            <div class="flex justify-between w-full flex-wrap -mt-3">
              <div class="flex items-center mt-3">
                <img :src="dataAdapter.logo" class="w-8 h-8 mr-2" :alt="dataAdapter.name" />
                <div class="flex items-center flex-wrap">
                  <span class="font-600 mr-2">{{ dataAdapter.name }}</span>
                </div>
              </div>

              <div v-if="false" class="flex items-center justify-between flex-grow mt-3">
                <div v-if="dataAdapter.adapter_type in tenantDataSources" class="flex">
                  <Badge v-if="getTenantDatasourceActive(dataAdapter.adapter_type)" :text="$t('datastore.active')" success class="ml-2" />
                  <Badge v-else :text="$t('datastore.inactive')" warn class="ml-2" />
                </div>
                <div class="ml-auto">
                  <PillButton
                    v-if="!(dataAdapter.adapter_type in tenantDataSources)"
                    outlined
                    :text="selectedDataSourceType ? $t('cancel') : $t('datastore.connect')"
                    class="ml-2"
                    @click.stop="onAddAccountClick(dataAdapter.adapter_type)"
                  />
                  <PillButton
                    v-if="dataAdapter.adapter_type in tenantDataSources && getTenantDatasourceActive(dataAdapter.adapter_type)"
                    error
                    :text="$t('datastore.deactivate')"
                    class="ml-2"
                    @click.stop="onDeactivateClick(dataSource)"
                  />
                  <PillButton
                    v-if="dataAdapter.adapter_type in tenantDataSources && !getTenantDatasourceActive(dataAdapter.adapter_type)"
                    outlined
                    :text="$t('datastore.activate')"
                    class="ml-2"
                    @click.stop="onActivateClick(dataSource)"
                  />
                  <PillButton
                    v-if="dataAdapter.adapter_type in tenantDataSources && !getTenantDatasourceActive(dataAdapter.adapter_type)"
                    error
                    :text="$t('delete')"
                    class="ml-2"
                    @click.stop="onDeleteClick(dataSource)"
                  />
                </div>
              </div>
            </div>
          </template>
          <template #content>
            <div v-if="hasMultipleAccounts(dataAdapter.key)">
              <div class="px-4 pb-4">
                <div class="pt-3 px-3">{{ $t('datastore.no_account_selected') }}</div>
              </div>
            </div>
            <div v-else class="pb-5">
              <DataAdapterConfiguration :type="adapter.key" :default-config="config" ref="configuration" class="px-10" />
            </div>
          </template>
        </CollapseCard>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { COUNTRIES, TIMEZONES, LANGUAGES, DATA_ADAPTER_ICONS, DATA_ADAPTERS } from '@/constants';
import DataAdapterConfiguration from '@/components/data-adapter/DataAdapterConfiguration.vue';

export default {
  name: 'EditUserDataSourcesModal',
  components: { DataAdapterConfiguration },
  props: {
    email: String,
  },
  data() {
    return {
      name: '',
      timezone: '',
      country: '',
      organisation: '',
      position: '',
      language: '',
      flags: {
        rc: false,
        oc: false,
      },
      TIMEZONES,
      countries: COUNTRIES.map((c) => ({ label: c.name, value: c.name })),
      loading: false,
      fetching: false,
      config: null,
    };
  },
  emits: ['add', 'close'],
  computed: {
    DATA_ADAPTERS() {
      return DATA_ADAPTERS;
    },
    ...mapState(['availableDataAdapters']),
    ...mapGetters(['getProjectDataAdapters', 'getProjectFeatures']),
    projectActivatedDataAdapters() {
      // const projectActivatedDataAdapters = Object.values(this.getProjectDataAdapters()).filter(projectAdapter => projectAdapter.config.enabled)
      return (this.getProjectFeatures()?.data_sources?.adapters || []).map((item) => {
        return {
          ...this.availableDataAdapters[item],
          logo: DATA_ADAPTER_ICONS[item],
        };
      });
    },
    tenantDataSources() {
      return {};
    },
    languages() {
      return LANGUAGES;
    },
    projectId() {
      return this.$route.params.projectId;
    },
  },
  async created() {
    this.fetching = true;
    this.fetchProjectFeatures({ project_id: this.projectId });
    const response = await this.fetchProjectUser({ email: this.email, project_id: this.projectId });
    if (response) {
      const { name, timezone, country, organisation, position, language, flags } = response;
      this.name = name;
      this.timezone = timezone;
      this.country = country;
      this.organisation = organisation;
      this.position = position;
      this.language = language;
      this.flags = flags;
    }
    this.fetching = false;
  },
  methods: {
    ...mapActions(['updateProjectUser', 'fetchProjectUser', 'showToastMessage', 'fetchProjectFeatures']),
    hasMultipleAccounts(adapterType) {
      return this.availableDataAdapters[adapterType].accounts;
    },
    async save() {
      const valid = await this.$refs.validationObserver.validate();

      if (valid.valid) {
        this.loading = true;
        const { email, name, timezone, country, organisation, position, language, flags } = this;
        const response = await this.updateProjectUser({ project_id: this.projectId, email, name, timezone, country, organisation, position, language, flags });

        if (response) {
          this.showToastMessage({ message: this.$t('projects.iam.added_successfully'), type: 'success' });
          this.$emit('add');
          this.$emit('close');
          this.loading = false;
          this.cancel();
        } else {
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
    },
    cancel() {
      this.$emit('close');
    },
  },
};
</script>
