<template>
  <DefaultLayout class="relative">
    <div class="flex items-center justify-between absolute top-6 left-0 lg:left-28 right-0">
      <div class="flex justify-center items-center font-600 text-primary">
        <IconButton icon="arrow_forward" class="transform rotate-180 mr-2" @click="onBack" />
        <div class="text-primary text-20 font-600 h-12 flex items-center">{{ name }}</div>
      </div>
      <div class="ml-auto mr-4">
        <PillButton v-if="selectedIntentDirty" :loading="flowSaving" :disabled="flowSaving" :text="$t('save')" primary @click="onFlowSaveClicked" />
      </div>
    </div>
    <template v-if="loading">
      <div class="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center text-gray-600">{{ $t('flow.loading_definitions') }}</div>
    </template>
    <template v-else>
      <div id="flow-designer-wrapper" class="absolute left-0 lg:left-26 right-0 top-0 bottom-0 max-lg:bottom-15 overflow-auto" style="top: 60px">
        <FlowDesigner :steps="steps" ref="flowDesigner" @startClick="onStartClick" @addStepItem="onAddStepItem" @dirty="onFlowDesignerDirty" />
      </div>
    </template>
  </DefaultLayout>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import FlowDesigner from '@/components/flow/FlowDesigner';
import uuidv4 from '@/helpers/uuid';
import NoCodeFlowConfigModal from '@/components/project/NoCodeFlowConfigModal';
import StepEditor from '@/components/flow/StepEditorModal.vue';
import { generateStepName } from '@/helpers/flow';

const stepItemObject = {
  id: 'stepId',
  name: 'New Step Item',
  column: 1,
  order: 0,
  evaluate: [],
};

export default {
  name: 'Flow',
  components: { FlowDesigner },
  data() {
    return {
      loading: false,
      modal: null,
      flowSaving: null,
    };
  },
  computed: {
    ...mapState(['projectData', 'bootstrapped', 'selectedIntentDefinition', 'selectedIntentDefinitionStepId', 'selectedIntentDirty']),
    showStepDataModal() {
      return this.stepData && !this.startStepSelected;
    },
    startStepSelected() {
      return this.stepData?.name === 'Start';
    },
    stepData() {
      return this.steps.find((item) => item.id === this.selectedIntentDefinitionStepId);
    },
    flowId() {
      return this.$route.params.flowId;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    name() {
      const endpointPath = this.projectData?.[this.projectId]?.flows?.[this.flowId].name || this.selectedIntentDefinition?.name || '';
      return `${this.projectId} > ${endpointPath}`;
    },
    steps() {
      if (!this.selectedIntentDefinition || !this.selectedIntentDefinition?.steps) {
        return [];
      }
      return JSON.parse(
        JSON.stringify([
          {
            id: '1',
            column: 0,
            order: 0,
            name: 'Start',
            type: 'project',
            transition: this.selectedIntentDefinition?.transition || null,
            request_params: this.selectedIntentDefinition?.request_params || [],
          },
          ...this.selectedIntentDefinition.steps,
        ]),
      );
    },
  },
  methods: {
    ...mapActions([
      'fetchProjectFlow',
      'showToastMessage',
      'fetchFunctions',
      'updateProjectFlow',
      'fetchProjectModels',
      'fetchProjectVariables',
      'fetchProjectDataAdapters',
      'fetchAvailableDataAdapters',
      'fetchProjectAIProviders',
      'fetchProjectAvailableAIProviders',
      'fetchProjectPersonas',
    ]),
    ...mapMutations([
      'ADD_SELECTED_INTENT_DEFINITION_STEP',
      'SET_SELECTED_INTENT_DEFINITION',
      'SET_SELECTED_INTENT_DEFINITION_STEP',
      'REMOVE_SELECTED_INTENT_DEFINITION_STEP',
      'SET_SELECTED_INTENT_DEFINITION_PROP',
      'SET_SELECTED_INTENT_DIRTY',
      'SET_SELECTED_INTENT_DEFINITION_STEP_ID',
    ]),
    onBack() {
      this.$router.back();
    },
    onFlowDesignerDirty() {
      this.SET_SELECTED_INTENT_DIRTY(true);
    },
    async onFlowSaveClicked() {
      this.flowSaving = true;
      const { steps, transition = '', name } = this.selectedIntentDefinition;
      const response = await this.updateProjectFlow({
        project_id: this.projectId,
        flow_id: this.flowId,
        name,
        steps,
        transition,
      });
      if (response) {
        this.SET_SELECTED_INTENT_DIRTY(false);
        this.SET_SELECTED_INTENT_DEFINITION_STEP_ID(null);
        this.flowSaving = false;
        return;
      }
      this.flowSaving = false;
    },
    onFlowCancelClicked() {
      this.$router.back();
    },
    onStepDeleteClicked(stepId) {
      this.REMOVE_SELECTED_INTENT_DEFINITION_STEP(stepId);
      this.SET_SELECTED_INTENT_DEFINITION_STEP_ID(null);
      this.onFlowSaveClicked();
    },
    onStepSaveClicked(step) {
      this.SET_SELECTED_INTENT_DEFINITION_STEP(step);
      this.onFlowSaveClicked();
    },
    onFlowDefinitionSaveClicked(definition) {
      const { requestParameters, transition, method, groupName, path, name } = definition;
      this.SET_SELECTED_INTENT_DEFINITION_PROP({ prop: 'request_params', value: requestParameters });
      this.SET_SELECTED_INTENT_DEFINITION_PROP({ prop: 'transition', value: transition });
      this.SET_SELECTED_INTENT_DEFINITION_PROP({ prop: 'method', value: method });
      this.SET_SELECTED_INTENT_DEFINITION_PROP({ prop: 'group_name', value: groupName });
      this.SET_SELECTED_INTENT_DEFINITION_PROP({ prop: 'path', value: path });
      this.SET_SELECTED_INTENT_DEFINITION_PROP({ prop: 'name', value: name });
      if (this.selectedIntentDirty) {
        this.onFlowSaveClicked();
      }
    },
    onStartClick() {
      this.$showModal(NoCodeFlowConfigModal, {}, { definitionSave: this.onFlowDefinitionSaveClicked });
    },
    onAddStepItem(index) {
      const stepItem = JSON.parse(JSON.stringify(stepItemObject));
      stepItem.id = uuidv4();
      stepItem.column = index;
      stepItem.name = generateStepName(1, this.steps);
      const columnSteps = this.steps.filter((s) => s.column === index);
      stepItem.order = columnSteps.length;
      this.ADD_SELECTED_INTENT_DEFINITION_STEP(stepItem);
    },
  },
  created() {
    window.onbeforeunload = () => {
      if (this.selectedIntentDirty) {
        return this.$t('flow.alert_unsaved_changes');
      }
      return undefined;
    };
  },
  beforeUnmount() {
    this.SET_SELECTED_INTENT_DEFINITION(null);
  },
  beforeRouteLeave(to, from, next) {
    if (this.selectedIntentDirty && window) {
      // eslint-disable-next-line
      const isAggreedLeavingPage = window.confirm(this.$t('flow.alert_unsaved_changes'));
      next(isAggreedLeavingPage);
    } else {
      next();
    }
  },
  watch: {
    showStepDataModal() {
      this.modal?.close();

      if (this.showStepDataModal) {
        this.modal = this.$showModal(
          StepEditor,
          {
            stepData: this.stepData,
          },
          {
            save: this.onStepSaveClicked,
            delete: this.onStepDeleteClicked,
          },
        );
      }
    },
    bootstrapped: {
      async handler() {
        if (this.bootstrapped) {
          try {
            this.loading = true;
            const response = await this.fetchProjectFlow({ projectId: this.projectId, flowId: this.flowId });
            this.fetchProjectModels({ projectId: this.projectId });
            this.fetchProjectDataAdapters({ projectId: this.projectId });
            this.fetchAvailableDataAdapters();
            this.fetchProjectAIProviders({ projectId: this.projectId });
            this.fetchProjectAvailableAIProviders();
            this.fetchProjectPersonas({ projectId: this.projectId });
            this.fetchProjectVariables({ projectId: this.projectId });
            this.fetchFunctions();
            this.SET_SELECTED_INTENT_DEFINITION(response);
            this.loading = false;
          } catch (e) {
            this.showToastMessage({ title: this.$t('projects.endpoints.failed_to_get'), type: 'error' });
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100px);
  opacity: 0;
}
</style>
