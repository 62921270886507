<template>
  <div class="h-full project-model">
    <div class="h-full flex flex-col">
      <div class="flex w-full border-b border-stroke pb-1 border-solid mb-4 border-opacity-70 items-center flex-shrink-0 pl-2" style="box-shadow: 0 0 5px 3px rgb(var(--color-on-tertiary))">
        <div class="text-13 font-500 flex-grow flex items-baseline">
          <div class="text-13 text-primary font-700 cursor-pointer" @click="goToModels">{{ $t('navigation.settings') }}</div>
          <span class="ml-4 mr-5 text-gray-300">|</span>
          <ButtonDropdown :modelValue="selectedTab" :list="tabs" class="lg:hidden" @update:modelValue="onMobileTabChange" />
          <div
            v-for="tab in tabs"
            :key="tab.value"
            :class="{ 'text-gray-800': !tabSelected(tab), 'bg-stroke text-semi-dark-text': tabSelected(tab) }"
            class="cursor-pointer mr-3 py-0.5 rounded-4 px-2 max-lg:hidden"
            @click="onSelectTab(tab)"
          >
            {{ tab.label }}
          </div>
        </div>
      </div>
      <div class="w-full flex-grow overflow-auto pl-2 pb-2">
        <RouterView v-slot="{ Component }">
          <component v-if="this.$route.params.projectId in projects" :is="Component" />
        </RouterView>
      </div>
    </div>
    <Modal v-if="fetching">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { apiGetProjectAuthentication, apiPutProjectAuthentication } from '@/helpers/api';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'SettingsWrapper',
  data() {
    return {
      selectedTab: 'authentication',
      fetching: false,
      activeProvider: null,
      providerConfig: {},
      emailPasswordEnabled: false,
    };
  },
  computed: {
    ...mapState(['projects']),
    ...mapGetters(['getProjectSecrets']),
    tabs() {
      return [
        { label: this.$t('navigation.general'), value: 'general', route: 'project-settings' },
        { label: this.$t('navigation.ui_features'), value: 'ui-features', route: 'project-ui-features' },
        { label: this.$t('navigation.authentication'), value: 'authentication', route: 'project-authentication-config' },
        { label: this.$t('navigation.team_members'), value: 'team-members', route: 'project-team-members' },
        { label: this.$t('navigation.users'), value: 'users', route: 'project-users' },
      ];
    },
    projectId() {
      return this.$route.params.projectId;
    },
    secrets() {
      return Object.keys(this.getProjectSecrets()).map((i) => ({ label: i, value: i }));
    },
  },
  methods: {
    onMobileTabChange(value) {
      const tab = this.tabs.find((t) => t.value === value);
      this.onSelectTab(tab);
    },
    tabSelected(tab) {
      return tab.value === this.selectedTab;
    },
    onSelectTab(tab) {
      this.selectedTab = tab.value;
      this.$router.push({ name: tab.route, params: this.$route.params });
    },
    onSave() {
      apiPutProjectAuthentication({
        project_id: this.projectId,
        provider: this.activeProvider,
        config: this.providerConfig,
      });
    },
  },
  watch: {
    '$route.name': {
      handler() {
        const selectedTab = this.tabs.find((tab) => tab.route === this.$route.name);
        this.selectedTab = selectedTab.value;
      },
      immediate: true,
    },
    projectId: {
      async handler() {
        this.fetching = true;
        const response = await apiGetProjectAuthentication(this.projectId);
        if (response.status === 200 && response.data) {
          if (response?.data?.provider) {
            this.activeProvider = response.data.provider;
            this.providerConfig = response.data.config;
          }
        }
        this.fetching = false;
      },
      immediate: true,
    },
  },
};
</script>
