<template>
  <Modal
    fullbelowsm
    :title="!editData ? $t('projects.ai_providers.create') : $t('projects.ai_providers.edit')"
    :primary-button="$t('save')"
    :secondary-button="$t('cancel')"
    :secondary-button-disabled="loading"
    :primary-button-loading="loading"
    :primary-button-disabled="!provider"
    @primary="onSaveClick"
    @secondary="onCancelClick"
    @opened="onModalOpen"
  >
    <div class="w-600 flex flex-col">
      <div class="w-full flex flex-col justify-start mt-3 py-3 px-10 flex-shrink-0">
        <ValidationObserver ref="validationObserver" v-slot="{ errors }" class="w-full">
          <FormLabel :label="$t('projects.ai_providers.provider')" />
          <div class="font-500">{{ providerName }}</div>
          <FormInput v-model="key" :label="$t('projects.ai_providers.key')" name="key" :error="errors.key" rules="required" class="mt-2" />
          <FormInput v-if="hasUrlField" v-model="url" :label="$t('projects.ai_providers.url')" name="url" :error="errors.url" rules="required" class="mt-2" />
        </ValidationObserver>
      </div>
    </div>

    <template #footer>
      <div class="w-full flex py-4 px-10">
        <PillButton v-if="editData" error :text="$t('delete')" :disabled="loading" @click="onDeleteClick" />
        <PillButton outlined :text="$t('cancel')" :disabled="loading" class="ml-auto" @click="onCancelClick" />
        <PillButton primary :loading="loading" :disabled="loading" class="ml-2" :text="$t('save')" outlined @click="onSaveClick" />
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CreateAIProviderModal',
  props: {
    projectId: {
      type: String,
      required: true,
    },
    provider: {
      type: String,
    },
    providerName: {
      type: String,
    },
    editData: {
      type: Object,
    },
    onDelete: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false,
      key: '',
      url: '',
    };
  },
  created() {
    if (this.editData) {
      const { key, url } = this.editData;
      this.key = key;
      this.url = url;
    }
  },
  computed: {
    ...mapState(['availableAIProviders']),
    hasUrlField() {
      return this.availableAIProviders[this.provider]?.url === true;
    },
    providers() {
      return Object.keys(this.availableAIProviders).map((key) => ({
        value: key,
        label: this.availableAIProviders[key].name,
      }));
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'createProjectAIProvider', 'updateProjectAIProvider']),
    onModalOpen() {
      this.$refs?.name?.focus();
    },
    async onDeleteClick() {
      if (this.onDelete) {
        await this.onDelete();
      }
    },
    async onSaveClick() {
      const valid = await this.$refs.validationObserver.validate();
      if (!valid.valid) return;
      this.loading = true;
      if (this.editData) {
        const response = await this.updateProjectAIProvider({
          project_id: this.projectId,
          provider: this.provider,
          key: this.key,
          url: this.url,
        });
        if (response) {
          this.$emit('close');
        }
      } else {
        const response = await this.createProjectAIProvider({
          project_id: this.projectId,
          provider: this.provider,
          key: this.key,
          url: this.url,
        });
        if (response) {
          this.$emit('close');
        }
      }
      this.loading = false;
    },
    onCancelClick() {
      this.$emit('close');
    },
  },
};
</script>
