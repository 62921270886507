<template>
  <div class="text-700 flex items-center">
    <template v-if="!leftText || !rightText"> {{ $t('evaluate_editor.api') }} </template>
    <template v-else>
      <span><VariableChip :name="leftText" :index="5" class="mr-1" /></span> &nbsp; <span class="text-pink-500">{{ $t('equals') }}</span> &nbsp; <span>{{ rightText }}</span>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'EvaluateApi',
  props: {
    item: Object,
    evaluate: Array,
  },
  computed: {
    ...mapState(['selectedIntentDefinition']),
    leftText() {
      return this.payload.variable;
    },
    rightText() {
      return `Data`;
    },
    payload() {
      return this.item.payload;
    },
  },
};
</script>
