<template>
  <div class="text-700 flex items-center" :class="{ 'text-red-500': transitionText === 'Invalid Step!' }">
    {{ transitionText }}
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getTransitionText } from '@/helpers/flow';

export default {
  name: 'EvaluateTransition',
  props: {
    item: Object,
    evaluate: Array,
  },
  computed: {
    ...mapState(['selectedIntentDefinition']),
    transitionText() {
      return getTransitionText(this.payload, this.selectedIntentDefinition.steps);
    },
    payload() {
      return this.item.payload;
    },
  },
};
</script>
