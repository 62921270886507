<template>
  <Modal
    sheetbelowsm
    :title="endpoint ? $t('projects.endpoints.update') : $t('projects.endpoints.create')"
    :primary-button="$t('save')"
    :secondary-button="$t('cancel')"
    :primary-button-disabled="loading"
    :primary-button-loading="loading"
    close-button
    :loading="fetching"
    @primary="onSaveClick"
    @secondary="onCancelClick"
    @close="onCancelClick"
  >
    <ValidationObserver ref="validationObserver" v-slot="{ errors }">
      <div class="w-full sm:w-700 px-10">
        <div class="w-full flex flex-col justify-start mt-5">
          <FormSelect
            v-model="method"
            :options="methods"
            name="method"
            :error="errors.method"
            rules="required"
            :label="$t('projects.endpoints.method')"
            :reduce="(option) => option.value"
            :searchable="false"
            :clearable="false"
          ></FormSelect>
        </div>
        <div class="w-full flex flex-col justify-start py-2">
          <FormInput :readonly="loading" v-model="path" name="path" :error="errors.path" :rules="['required', { validate: pathValidation }]" :label="$t('projects.endpoints.path')"></FormInput>
        </div>
        <div class="w-full flex flex-col justify-start py-2">
          <FormInput :readonly="loading" v-model="groupName" name="groupName" :error="errors.groupName" rules="required" :label="$t('projects.endpoints.group_name')"></FormInput>
        </div>
        <div class="w-full flex flex-col justify-start py-2">
          <FormSelect
            :options="flowList"
            append-to-body
            :readonly="loading"
            v-model="flowId"
            :reduce="(opt) => opt.value"
            name="flowId"
            :error="errors.flowId"
            rules="required"
            show-add-button
            :label="$t('projects.flows.flow')"
            @add="onAddFlowClick"
          ></FormSelect>
        </div>
        <div class="py-4 w-full">
          <div class="text-15 font-600 mb-3">{{ $t('flow.request_parameters') }}</div>
          <table class="w-full max-sm:block">
            <thead class="max-sm:block">
              <tr class="max-sm:hidden">
                <th class="border-b font-500" width="40%">{{ $t('flow.parameter') }}</th>
                <th class="border-b font-500" width="40%">{{ $t('flow.variable') }}</th>
                <th class="border-b font-500" width="100">{{ $t('flow.required') }}</th>
                <th class="border-b font-500" width="50">{{ $t('flow.action') }}</th>
              </tr>
            </thead>
            <tbody class="max-sm:block">
              <tr v-for="(requestParameter, index) in requestParams" :key="requestParameter.id" class="max-sm:flex max-sm:flex-col max-sm:w-full max-sm:pb-3">
                <td class="max-sm:flex max-sm:items-center max-sm:mb-1 sm:h-10 sm:pt-4 sm:pr-5">
                  <label class="sm:hidden font-500 mr-3">{{ $t('flow.parameter') }}</label>
                  <Input v-model="requestParameter.key" class="w-full" />
                </td>
                <td class="max-sm:flex max-sm:items-center max-sm:mb-1 sm:h-10 sm:pt-4">
                  <label class="sm:hidden font-500 mr-3">{{ $t('flow.variable') }}</label>
                  <VariableSelect v-model="requestParameter.variable" />
                </td>
                <td class="max-sm:flex max-sm:items-center max-sm:mb-1 sm:h-10 sm:pt-4">
                  <label class="sm:hidden font-500 mr-3">{{ $t('flow.required') }}</label>
                  <div class="max-sm:ml-auto max-sm:mr-1 flex justify-center items-center">
                    <Checkbox v-model="requestParameter.required" />
                  </div>
                </td>
                <td class="max-sm:flex max-sm:items-center max-sm:mb-1 sm:h-10 sm:pt-4">
                  <label class="sm:hidden font-500 mr-3">{{ $t('flow.action') }}</label>
                  <div class="max-sm:ml-auto flex justify-center items-center"><IconButton icon="delete" class="text-primary" @click="removeRequestParameter(index)" /></div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex justify-end">
            <PillButton primary class="mt-4" :text="$t('flow.add_parameter')" @click="onAddRequestParameterClick"></PillButton>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import uuidv4 from '@/helpers/uuid';
import CreateProjectFlow from '@/components/project/CreateProjectFlow.vue';

export default {
  name: 'CreateProjectEndpointModal',
  props: {
    projectId: {
      type: String,
      required: true,
    },
    endpoint: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      fetching: false,
      name: '',
      nameError: '',
      method: '',
      methodError: '',
      methods: [
        { label: 'GET', value: 'GET' },
        { label: 'POST', value: 'POST' },
        { label: 'PUT', value: 'PUT' },
        { label: 'DELETE', value: 'DELETE' },
      ],
      path: '',
      groupName: '',
      groupNameError: '',
      flowId: '',
      requestParams: [],
    };
  },
  computed: {
    ...mapState(['projectData']),
    flowList() {
      const flows = this.projectData?.[this.projectId]?.flows || {};
      return Object.values(flows).map((flow) => {
        return {
          label: flow.name,
          value: flow.flow_id,
        };
      });
    },
  },
  methods: {
    ...mapActions(['createProjectEndpoint', 'fetchProjectEndpoint', 'updateProjectEndpoint', 'showToastMessage', 'fetchProjectFlows']),
    onAddFlowClick() {
      this.$showModal(CreateProjectFlow);
    },
    async onSaveClick() {
      const valid = await this.$refs.validationObserver.validate();
      if (!valid.valid) return;
      this.loading = true;

      const requestParams = this.requestParams
        .filter((item) => item.key.trim() && item.variable.trim())
        .map((item) => ({
          key: item.key.trim(),
          variable: item.variable,
          required: item.required,
        }));

      if (!this.endpoint) {
        await this.createProjectEndpoint({
          project_id: this.projectId,
          method: this.method,
          path: this.path,
          group_name: this.groupName,
          flow_id: this.flowId,
          request_params: requestParams,
        });
      } else {
        await this.updateProjectEndpoint({
          endpoint_id: this.endpoint.endpoint_id,
          project_id: this.projectId,
          method: this.method,
          path: this.path,
          group_name: this.groupName,
          flow_id: this.flowId,
          request_params: requestParams,
        });
      }
      this.loading = false;
      this.$emit('close');
    },
    onCancelClick() {
      this.$emit('close');
    },
    pathValidation() {
      return this.path.startsWith('/') || this.$t('projects.endpoints.path_starts_slashes');
    },
    onAddRequestParameterClick() {
      this.requestParams.push({
        id: uuidv4(),
        key: '',
        variable: '',
        required: false,
      });
    },
    removeRequestParameter(index) {
      this.requestParams.splice(index, 1);
    },
  },
  async created() {
    this.fetchProjectFlows({ projectId: this.projectId });

    if (this.endpoint) {
      this.fetching = true;
      const response = await this.fetchProjectEndpoint({ projectId: this.projectId, endpointId: this.endpoint.endpoint_id });
      if (!response) return;
      const { method, path, group_name, flow_id, request_params } = response;
      this.method = method;
      this.path = path;
      this.groupName = group_name;
      this.flowId = flow_id;
      this.requestParams = request_params.map((item) => ({ id: uuidv4(), ...item }));
      this.fetching = false;
    }
  },
};
</script>

<style scoped></style>
