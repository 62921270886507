<template>
  <div class="px-3 pb-2 pt-2">
    <FormInput v-model="text" :label="$t('evaluate.text')" class="w-full" @update:modelValue="emitPayload" />

    <VariableSelect v-model="captureVariable" :label="$t('evaluate.store_prompt_response')" :filter="promptVariableSelectFilter" class="mt-3 w-full" @update:modelValue="emitPayload" />
    <FormSelect
      v-model="prompt.direction"
      :label="$t('evaluate.direction')"
      :placeholder="$t('evaluate.select_direction')"
      class="mt-3"
      :options="directionOptions"
      :clearable="true"
      :searchable="false"
      :reduce="selectReducer"
      @update:modelValue="emitPayload"
    ></FormSelect>
    <VariableSelect
      v-model="prompt.items"
      :label="$t('evaluate.items_datatable')"
      class="mt-3"
      selectBgColor="bg-white"
      :filter-data-type="['DataTable']"
      :showCreate="false"
      @update:modelValue="emitPayload"
    />
    <FormInput v-model="prompt.labelColumn" :label="$t('evaluate.label_column')" class="mt-3 w-full" @update:modelValue="emitPayload" />
    <FormInput v-model="prompt.valueColumn" :label="$t('evaluate.value_column')" class="mt-3 w-full" @update:modelValue="emitPayload" />
    <FormInput v-model="prompt.descriptionColumn" :label="$t('evaluate.description_column')" class="mt-3 w-full" @update:modelValue="emitPayload" />
    <FormInput v-model="prompt.imageUrlColumn" :label="$t('evaluate.image_url_column')" class="mt-3 w-full" @update:modelValue="emitPayload" />
    <FormInput v-model="prompt.imageAltTextColumn" :label="$t('evaluate.image_alt_column')" class="mt-3 w-full" @update:modelValue="emitPayload" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import debounce from '@shared/helpers/debounce';

export default {
  name: 'EvaluateSetPrompt',
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      variables: [],
      text: '',
      prompt: {
        direction: '',
        items: '',
        labelColumn: '',
        valueColumn: '',
        descriptionColumn: '',
        imageUrlColumn: '',
        imageAltTextColumn: '',
      },
      captureVariable: '',
      directionOptions: [
        {
          label: 'Vertical',
          value: 'vertical',
        },
        {
          label: 'Horizontal',
          value: 'horizontal',
        },
      ],
    };
  },
  computed: {
    ...mapState(['selectedIntentDefinition']),
    ...mapGetters(['getProjectDataTypes']),
    payload() {
      const variables = [{ variable: this.captureVariable, value: this.prompt }];
      return {
        id: this.item.id,
        type: this.item.type,
        payload: {
          captureType: 'prompt',
          text: this.text,
          variables,
        },
      };
    },
  },
  methods: {
    // eslint-disable-next-line
    emitPayload: debounce(function () {
      this.$emit('update', this.payload);
    }, 300),
    promptVariableSelectFilter(variable) {
      // Only capture list
      return this.getProjectDataTypes()?.[variable.dataType]?.capture === true || (variable.dataType === 'DataTable' && !variable.readonly);
    },
    selectReducer(option) {
      return option.value;
    },
  },
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.text = this.item?.payload.text;
          const { direction, items, labelColumn, valueColumn, descriptionColumn, imageUrlColumn, imageAltTextColumn } = this.item?.payload?.variables?.[0]?.value || {};
          this.captureVariable = this.item?.payload?.variables?.[0]?.variable || '';
          this.prompt = {
            direction,
            items,
            labelColumn,
            valueColumn,
            descriptionColumn,
            imageUrlColumn,
            imageAltTextColumn,
          };
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
