<template>
  <ValidationObserver ref="validationObserver" v-slot="{ errors }" class="w-full flex flex-col justify-start">
    <div class="flex w-full mt-3">
      <FormInput v-model="config.user" :label="$t('projects.data_adapters.user')" name="user" :error="errors.user" :rules="['required']" class="mr-1 w-full" @update:modelValue="emitPayload" />
      <FormInput
        v-model="config.password"
        :label="$t('projects.data_adapters.password')"
        name="password"
        :error="errors.password"
        :rules="['required']"
        class="ml-1 w-full"
        @update:modelValue="emitPayload"
      />
    </div>
    <div class="flex w-full mt-3">
      <FormInput v-model="config.domain" :label="$t('projects.data_adapters.domain')" name="domain" :error="errors.domain" :rules="['required']" class="mr-1 w-full" @update:modelValue="emitPayload" />
      <FormInput v-model="config.port" :label="$t('projects.data_adapters.port')" name="port" :error="errors.port" :rules="['required']" class="ml-1 w-full" @update:modelValue="emitPayload" />
    </div>
    <FormInput v-model="config.database" :label="$t('projects.data_adapters.database')" name="database" :error="errors.database" :rules="['required']" class="mt-3" @update:modelValue="emitPayload" />
  </ValidationObserver>
</template>

<script>
export default {
  name: 'PostgresqlConfiguration',
  props: {
    defaultConfig: Object,
  },
  data() {
    return {
      config: {
        enabled: true,
        user: '',
        password: '',
        domain: '',
        port: '',
        database: '',
      },
    };
  },
  created() {
    if (this.defaultConfig) {
      this.config.user = this.defaultConfig.user;
      this.config.password = this.defaultConfig.password;
      this.config.domain = this.defaultConfig.domain;
      this.config.port = this.defaultConfig.port;
      this.config.database = this.defaultConfig.database;
    }
  },
  methods: {
    validate() {
      return this.$refs.validationObserver.validate();
    },
    emitPayload() {
      this.$emit('update:defaultConfig', this.config);
    },
  },
};
</script>

<style scoped lang="scss"></style>
