<template>
  <div class="px-3 pb-2 pt-2 h-full">
    <VariableSelect :label="$t('evaluate.store_api_response')" v-model="api.variable" class="w-full" system :filter-data-type="['DataTable']" @update:modelValue="emitPayload" />
    <FormSelect :label="$t('evaluate.data_adapter')" v-model="api.data_adapter" :options="dataAdapters" :reduce="(option) => option.value" class="mt-3 w-full" @update:modelValue="emitPayload" />
    <VariableSelect
      :label="$t('evaluate.request_parameters')"
      v-model="api.value.request_parameters"
      multiple
      class="mt-3 w-full"
      system
      :filter-data-type="['DataTable']"
      @update:modelValue="emitPayload"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import debounce from '@shared/helpers/debounce';
import { EVALUATE_API_PAYLOAD, OPERATORS } from '@/constants';

export default {
  name: 'EvaluateSetApi',
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      api: structuredClone(EVALUATE_API_PAYLOAD.payload),
      operators: OPERATORS,
    };
  },
  computed: {
    ...mapState(['availableDataAdapters']),
    ...mapGetters(['getProjectDataAdapters']),
    dataAdapters() {
      return Object.keys(this.getProjectDataAdapters()).map((key) => {
        return {
          label: this.availableDataAdapters[key].name,
          value: key,
        };
      });
    },
    projectId() {
      return this.$route.params.projectId;
    },
    payload() {
      return {
        id: this.item.id,
        type: this.item.type,
        payload: this.api,
      };
    },
  },
  created() {
    this.itemChangeHandler();
  },
  methods: {
    ...mapActions(['showToastMessage']),
    // eslint-disable-next-line
    emitPayload: debounce(function () {
      this.$emit('update', this.payload);
    }, 300),
    itemChangeHandler() {
      if (this.item) {
        const apiData = JSON.parse(JSON.stringify(this.item.payload));
        this.api = { ...this.api, ...apiData };
      }
    },
  },
  watch: {
    item: {
      handler() {
        this.itemChangeHandler();
      },
      deep: true,
    },
  },
};
</script>
