<template>
  <Modal
    sheetbelowsm
    :title="!model ? $t('datastore.add_model') : $t('datastore.edit_model')"
    :primary-button="$t('save')"
    :primary-button-loading="loading"
    :secondary-button="$t('cancel')"
    close-button
    :loading="fetching"
    @primary="onSaveClicked"
    @secondary="onCancelClicked"
    @close="onCancelClicked"
  >
    <ValidationObserver ref="validationObserver" v-slot="{ errors }">
      <div class="py-5 w-full sm:w-600 px-10">
        <div class="w-full flex mb-3">
          <FormInput v-model="name" name="name" class="w-full" :label="$t('name')" :rules="['required', { validate: validateModelName }]" :error="errors.name"></FormInput>
        </div>
        <div class="w-full flex mb-3">
          <FormSelect
            v-model="type"
            :options="types"
            :reduce="(opt) => opt.value"
            name="type"
            class="w-full"
            :label="$t('flow.type')"
            :rules="['required']"
            :error="errors.type"
            append-to-body
          ></FormSelect>
        </div>
      </div>
    </ValidationObserver>
  </Modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { FINE_TUNING_TYPES } from '@/constants';

export default {
  name: 'CreateProjectModel',
  props: {
    model: {
      type: Object,
    },
  },
  data() {
    return {
      name: '',
      type: '',
      suggestions: [],
      finetunedata: [],
      loading: false,
      fetching: false,
    };
  },
  async created() {
    if (this.model) {
      this.fetching = true;
      const response = await this.fetchProjectModel({ project_id: this.projectId, model_id: this.model.model_id });
      this.fetching = false;
      this.name = response.name;
      this.type = response.type;
      this.suggestions = response.suggestions || [];
      this.finetunedata = response.finetunedata || [];
    }
  },
  computed: {
    ...mapState(['projectData']),
    ...mapGetters(['getProjectModels']),
    projectId() {
      return this.$route.params.projectId;
    },
    types() {
      return Object.keys(FINE_TUNING_TYPES).map((key) => ({ label: this.$t(`projects.models.fine_tune_types.${key}`), value: key }));
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'createProjectModel', 'updateProjectModel', 'fetchProjectModel']),
    validateModelName() {
      let models = Object.values(this.getProjectModels());
      if (this.model) {
        models = models.filter((model) => model.model_id !== this.model.model_id);
      }
      if (models.some((model) => model.name === this.name)) {
        return this.$t('validation.name_unique');
      }
      return true;
    },
    onCancelClicked() {
      this.$emit('close');
    },
    async onSaveClicked() {
      const valid = await this.$refs.validationObserver.validate();
      if (valid.valid) {
        this.loading = true;
        if (!this.model) {
          const response = await this.createProjectModel({ project_id: this.projectId, name: this.name, type: this.type });
          if (response) {
            this.$emit('close');
          }
        } else {
          const response = await this.updateProjectModel({
            project_id: this.projectId,
            name: this.name,
            type: this.type,
            model_id: this.model.model_id,
            finetunedata: this.finetunedata,
            suggestions: this.suggestions,
          });
          if (response) {
            this.$emit('close');
          }
        }
        this.loading = false;
      }
    },
  },
};
</script>
