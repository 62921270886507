<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-3 items-center flex-shrink-0 sm:pr-10">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-primary font-600">{{ $t('navigation.data_types') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <PillButton icon="plus" :text="$t('add_new')" primary @click="onCreateClicked" />
        </div>
      </div>
      <div class="w-full flex-grow overflow-hidden pb-2">
        <TableElevated :items="dataTypes" :headers="dataTypeHeaders" class="text-13 pr-3 sm:pr-10">
          <template v-slot:[`item.name`]="{ value }">
            <span class="text-13 font-500 truncate">{{ value }}</span>
          </template>
          <template v-slot:[`item.entityType`]="{ item }">
            <span v-if="item.entityType === 'system'" class="ml-2 bg-indigo-200 rounded-6 px-1 text-12">system</span>
            <span v-if="item.entityType === 'user'" class="ml-2 bg-green-200 rounded-6 px-1 text-12">user</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div v-if="item.entityType !== 'system'" class="flex flex-row justify-end items-center ml-auto">
              <IconButton icon="edit" class="mr-1 text-primary" @click="onEditClicked(item)" />
              <IconButton class="text-primary" icon="delete" @click="onDeleteClicked(item)" />
            </div>
          </template>
        </TableElevated>
      </div>
    </div>
    <Modal v-if="loading">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import DeleteModal from '@shared/components/shared-modals/DeleteModal.vue';
import CreateProjectDataType from '@/components/project/CreateProjectDataType.vue';

export default {
  name: 'DataTypes',
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['getProjectDataTypes']),
    projectId() {
      return this.$route.params.projectId;
    },
    dataTypes() {
      const dataTypes = this.getProjectDataTypes();
      return Object.keys(this.getProjectDataTypes()).map((dataType) => {
        return {
          name: dataType,
          entityType: dataTypes[dataType].entityType,
        };
      });
    },
    dataTypeHeaders() {
      return [
        { key: 'name', title: this.$t('name') },
        { key: 'entityType', title: this.$t('tags'), align: 'center', width: '110px' },
        { key: 'actions', title: this.$t('actions'), width: '50px' },
      ];
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchProjectDataTypes', 'deleteProjectDatatype']),
    onCreateClicked() {
      this.$showModal(CreateProjectDataType);
    },
    onEditClicked(dataTypeName) {
      this.$showModal(CreateProjectDataType, { dataType: dataTypeName });
    },
    onDeleteClicked(dataTypeName) {
      this.$showModal(DeleteModal, {
        title: this.$t('confirm'),
        subtitle: this.$t('projects.confirm_remove_data_type_message'),
        info: this.$t('all_data_will_be_removed'),
        onConfirm: async () => {
          await this.deleteProjectDatatype({ project_id: this.projectId, name: dataTypeName });
        },
      });
    },
  },
  watch: {
    projectId: {
      async handler() {
        this.loading = true;
        await this.fetchProjectDataTypes({ projectId: this.projectId });
        this.loading = false;
      },
      immediate: true,
    },
  },
};
</script>
