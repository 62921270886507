<template>
  <div class="w-full overflow-auto h-full" @scroll="onScroll">
    <table class="w-full border-spacing-y-3 border-separate px-1">
      <thead class="sticky top-3 z-2 bg-[#fcfcff]">
        <tr :style="{ 'box-shadow': scrolled ? '#fcfcff 0px -6px 0px 6px, 0 2px 10px -2px rgba(35, 17, 131, 0.08)' : undefined, 'padding-bottom': scrolled ? '4px' : undefined }">
          <th
            v-for="(header, index) in headers"
            :key="index"
            :style="{ width: header.width }"
            :class="[header.align === 'center' || header.key === 'actions' ? 'text-center' : '']"
            class="text-left px-3 uppercase text-semi-dark-text text-opacity-50 text-11 tracking-wider pt-3"
          >
            {{ header.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="keyName ? item[keyName] : index" class="shadow-card rounded-10">
          <td v-for="header in headers" :key="header.key" class="">
            <div class="min-h-10 py-0.5 px-3 flex items-center" :class="{ 'justify-center': header.align === 'center' }">
              <slot v-if="`item.${header.key}` in $slots" :name="`item.${header.key}`" :item="item" :value="item[header.key]" />
              <template v-else>{{ item[header.key] }}</template>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import debounce from '@/helpers/debounce';

export default {
  name: 'TableElevated',
  props: {
    items: Array,
    headers: Array,
  },
  data() {
    return {
      keyName: 'key',
      scrolled: false,
    };
  },
  methods: {
    onScroll: debounce(function (e) {
      this.scrolled = e.target.scrollTop > 10;
    }, 1),
  },
};
</script>

<style scoped lang="scss"></style>
