<template>
  <div class="inline-block rounded-full pl-1" :class="containerClass" @click.stop="updateInput">
    <div
      :class="{
        'transform translate-x-5': modelValue,
        'w-5 h-5': !dense,
        'w-4 h-4': dense,
      }"
      class="bg-white rounded-full transition transition-all duration-150 shadow-md"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  emits: ['update:modelValue'],
  props: {
    disabled: Boolean,
    modelValue: {
      type: Boolean,
      default: false,
    },
    valueMode: {
      type: Boolean,
      default: false,
    },
    dense: Boolean,
  },
  computed: {
    containerClass() {
      const classes = [];

      if (!this.disabled) {
        classes.push('cursor-pointer');
      }
      if (this.valueMode || this.modelValue) {
        classes.push('bg-indigo-600');
      } else if (this.disabled) {
        classes.push('bg-gray-200');
      } else {
        classes.push('bg-gray-400');
      }

      if (this.dense) {
        classes.push('h-6 py-1 w-11');
      } else {
        classes.push('h-7 py-1 w-12');
      }
      return classes;
    },
  },
  methods: {
    updateInput() {
      if (!this.disabled) {
        this.$emit('update:modelValue', !this.modelValue);
      }
    },
  },
};
</script>
