<template>
  <Modal
    sheetbelowsm
    :title="$t('projects.models.generate_ai_suggestions')"
    :primary-button="result ? $t('save') : $t('submit')"
    :primary-button-disabled="loading"
    :secondary-button="$t('cancel')"
    :primary-button-loading="loading"
    close-button
    @primary="onSaveClicked"
    @close="onCancelClicked"
    @secondary="onCancelClicked"
  >
    <div class="lg:w-900 px-10 pt-6">
      <ValidationObserver v-show="!result" ref="validationObserver" v-slot="{ errors }" class="w-full">
        <div class="w-full flex mb-3">
          <FormSelect
            v-model="dataAdapter"
            :options="dataAdapters"
            name="dataAdapter"
            rules="required"
            append-to-body
            default-reducer
            :error="errors['dataAdapter']"
            :label="$t('evaluate.data_adapter')"
            class="w-full md:w-1/2 md:mr-2"
          />
          <FormSelect v-model="language" :options="languages" name="language" append-to-body default-reducer :error="errors['language']" :label="$t('language')" class="w-full md:w-1/2 md:mr-2" />
        </div>
        <div class="w-full flex mb-3">
          <FormSelect
            v-model="provider"
            :options="providers"
            name="provider"
            rules="required"
            append-to-body
            default-reducer
            :error="errors['provider']"
            :label="$t('projects.ai_providers.provider')"
            class="w-full md:w-1/2 md:mr-2"
            @option:selected="onProviderChange"
          />
          <FormSelect
            v-model="model"
            :disabled="!showInference"
            :options="inferences"
            name="model"
            :rules="showInference ? 'required' : undefined"
            append-to-body
            default-reducer
            :error="errors['model']"
            :label="$t('projects.ai_providers.model')"
            class="w-full md:w-1/2 md:mr-2"
          />
        </div>
        <div class="w-full flex mb-3">
          <FormInput
            v-model="count"
            name="count"
            min="5"
            max="50"
            type="number"
            rules="required|minValue:5|maxValue:50"
            append-to-body
            default-reducer
            :error="errors['count']"
            :label="$t('projects.ai_providers.count')"
            class="w-full md:w-1/2 md:mr-2"
          />
          <FormSelect v-model="persona" :options="personas" name="persona" append-to-body default-reducer :error="errors['persona']" :label="$t('evaluate.persona')" class="w-full md:w-1/2 md:mr-2" />
        </div>
      </ValidationObserver>
      <div v-show="result && result.length" class="max-h-400 overflow-auto">
        <div v-for="item in result" :key="item.id" class="flex items-center mb-1">
          <FormInput v-model="item.text" class="w-full" />
          <IconButton icon="delete" class="cursor-pointer text-primary" @click="onRemoveSuggestionClicked(item.id)" />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { apiPostProjectModelSuggestions } from '@/helpers/api';
import uuidv4 from '@/helpers/uuid';

export default {
  name: 'GenerateAISuggestions',
  data() {
    return {
      loading: false,
      provider: '',
      model: '',
      persona: '',
      count: 10,
      result: null,
      dataAdapter: '',
      language: '',
    };
  },
  created() {
    this.language = this.user.language;
  },
  computed: {
    ...mapState(['availableAIProviders', 'user', 'availableDataAdapters']),
    ...mapGetters(['getProjectAIProviders', 'getProjectPersonas', 'getProjectDataAdapters']),
    projectId() {
      return this.$route.params.projectId;
    },
    providers() {
      return Object.keys(this.getProjectAIProviders()).map((item) => ({ label: this.availableAIProviders?.[item]?.name || item, value: item }));
    },
    personas() {
      return Object.values(this.getProjectPersonas()).map((item) => ({ label: item.name, value: item.persona_id }));
    },
    inferences() {
      let inferences = [];
      if (this.provider) {
        inferences = this.availableAIProviders[this.provider]?.inference || [];
      }
      return inferences.map((item) => ({ label: item, value: item }));
    },
    showInference() {
      if (this.provider) {
        const inferences = this.availableAIProviders[this.provider]?.inference || [];
        if (!inferences.length && !!this.getProjectAIProviders()[this.provider].key && !!this.getProjectAIProviders()[this.provider].url) {
          return false;
        }
      }
      return true;
    },
    languages() {
      return ['EN', 'TR'].map((lang) => ({ value: lang, label: this.$t(`languages.${lang}`) }));
    },
    dataAdapters() {
      return Object.values(this.getProjectDataAdapters()).map((item) => ({ label: this.availableDataAdapters[item.adapter_type].name, value: item.adapter_type }));
    },
  },
  methods: {
    ...mapActions(['showToastMessage']),
    onRemoveSuggestionClicked(id) {
      this.result = this.result.filter((item) => item.id !== id);
    },
    async onSaveClicked() {
      if (!this.result) {
        const valid = await this.$refs.validationObserver.validate();
        if (!valid.valid) return;
        this.loading = true;
        try {
          const res = await apiPostProjectModelSuggestions({
            project_id: this.projectId,
            adapter_type: this.dataAdapter,
            ai_model: this.model,
            ai_provider: this.provider,
            count: this.count,
            language: this.language,
            persona_id: this.persona,
          });
          if (res.status === 200 && res.data) {
            this.result = res.data.map((item) => ({ id: uuidv4(), text: item }));
          } else {
            this.showToastMessage({ title: res?.data?.message || this.$t('projects.models.failed_to_generate_suggestions'), type: 'error' });
          }
        } catch (e) {
          this.showToastMessage({ title: this.$t('projects.models.failed_to_generate_suggestions'), type: 'error' });
        }
        this.loading = false;
      } else {
        this.loading = true;
        this.$emit('save', this.result);
      }
    },
    onCancelClicked() {
      this.$emit('close');
    },
    async onProviderChange() {
      this.model = '';
      if (this.$refs.validationObserver.dirty) {
        await this.$nextTick();
        await this.$refs.validationObserver.validate();
      }
    },
  },
};
</script>
