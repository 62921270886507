<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-3 items-center flex-shrink-0 sm:pr-10">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-primary font-600">{{ $t('projects.variables.title') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <PillButton :text="$t('add_new')" primary @click="onCreateClicked" />
        </div>
      </div>
      <div class="w-full flex-grow overflow-hidden pb-2">
        <TableElevated :items="variables" key="version" :headers="variableHeaders" class="text-13 pr-3 sm:pr-10">
          <template v-slot:[`item.name`]="{ value: variableName }">
            <span class="text-13 font-500 truncate">{{ variableName }}</span>
          </template>
          <template v-slot:[`item.dataType`]="{ value }">
            <span class="ml-1 bg-gray-200 rounded-6 px-1 text-12">{{ value }}</span>
          </template>
          <template v-slot:[`item.tags`]="{ value: variableName }">
            <span v-if="variableDetails[variableName] && variableDetails[variableName].variableType === VARIABLE_TYPE.SYSTEM" class="ml-1 bg-indigo-200 rounded-6 px-1 text-12">system</span>
            <span v-if="variableDetails[variableName] && variableDetails[variableName].readonly" class="ml-1 bg-red-200 rounded-6 px-1 text-12">readonly</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div v-if="variableDetails[item.name].variableType !== VARIABLE_TYPE.SYSTEM" class="flex flex-row justify-end items-center ml-auto">
              <IconButton :disabled="variableDetails[item.name].variableType === VARIABLE_TYPE.SYSTEM" icon="edit" class="sm:mr-1 w-auto text-primary" @click="onEditClicked(item.name)" />
              <IconButton :disabled="variableDetails[item.name].variableType === VARIABLE_TYPE.SYSTEM" icon="delete" class="text-primary" @click="onDeleteClicked(item.name)" :text="$t('delete')" />
            </div>
          </template>
        </TableElevated>
      </div>
    </div>
    <Modal v-if="fetching">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { VARIABLE_TYPE } from '@/constants';
import CreateProjectVariable from '@/components/project/CreateProjectVariable';
import DeleteModal from '@shared/components/shared-modals/DeleteModal.vue';
import { sortByKey } from '@/helpers/array';

export default {
  name: 'NoCodeVariables',
  data() {
    return {
      VARIABLE_TYPE,
      fetching: false,
    };
  },
  computed: {
    ...mapState(['projectData']),
    ...mapGetters(['getProjectVariables']),
    variableHeaders() {
      return [
        { key: 'name', title: this.$t('name') },
        { key: 'tags', title: this.$t('tags'), width: '140px' },
        { key: 'dataType', title: this.$t('data_type'), width: '100px' },
        { key: 'actions', title: this.$t('actions'), width: '50px' },
      ];
    },
    variables() {
      if (this.projectId && Object.keys(this.projectData).length) {
        return sortByKey(Object.keys(this.getProjectVariables(this.projectId)).map((v) => ({ name: v, dataType: this.variableDetails[v].dataType, tags: v })));
      }
      return [];
    },
    variableDetails() {
      return this.getProjectVariables(this.projectId);
    },
    projectId() {
      return this.$route.params.projectId;
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchProjectVariables', 'deleteProjectVariable']),
    onCreateClicked() {
      const modal = this.$showModal(
        CreateProjectVariable,
        {
          projectId: this.projectId,
        },
        {
          save: () => {
            this.onVariableSaved(modal);
          },
        },
      );
    },
    onEditClicked(variableName) {
      this.$showModal(CreateProjectVariable, {
        projectId: this.projectId,
        variableName,
      });
    },
    onDeleteClicked(variableName) {
      this.$showModal(DeleteModal, {
        subtitle: this.$t('projects.remove_variable', { variableName }),
        info: null,
        onConfirm: async () => {
          await this.deleteProjectVariable({ name: variableName, project_id: this.projectId });
          this.showToastMessage({ message: this.$t('projects.variable_removed_successfully'), type: 'success' });
        },
      });
    },
  },
  watch: {
    projectId: {
      async handler() {
        this.fetching = true;
        await this.fetchProjectVariables({ projectId: this.projectId });
        this.fetching = false;
      },
      immediate: true,
    },
  },
};
</script>
