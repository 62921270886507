<template>
  <div class="h-full project-model">
    <div class="h-full flex flex-col">
      <div class="flex w-full border-b border-stroke pb-1 border-solid mb-4 border-opacity-70 items-center flex-shrink-0 sm:pr-10 pl-2" style="box-shadow: 0 0 5px 3px rgb(var(--color-on-tertiary))">
        <div class="text-13 font-500 flex-grow flex items-baseline">
          <div class="text-13 text-primary font-700 cursor-pointer" @click="goToModels">{{ $t('navigation.data_adapters') }}</div>
          <span class="ml-2 mr-0 text-gray-400">></span>
          <ButtonDropdown v-model="dataAdapter" :list="dataAdapters" class="ml-1" />
          <span class="ml-4 mr-5 text-gray-300">|</span>
          <ButtonDropdown :modelValue="selectedTab" :list="tabs" class="lg:hidden" @update:modelValue="onSelectTab({ value: $event })" />
          <div
            v-for="tab in tabs"
            :key="tab.value"
            :class="{ 'text-gray-800': !tabSelected(tab), 'bg-stroke text-semi-dark-text': tabSelected(tab) }"
            class="cursor-pointer mr-3 py-0.5 rounded-4 px-2 max-lg:hidden"
            @click="onSelectTab(tab)"
          >
            {{ tab.label }}
          </div>
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <RouterView v-slot="{ Component }">
          <component :is="!fetching ? Component : null" :adapter="adapterData" />
        </RouterView>
      </div>
    </div>
    <Modal v-if="fetching">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { DATA_ADAPTERS } from '@/constants';

export default {
  name: 'DataAdapter',
  data() {
    return {
      fetching: false,
      selectedTab: 'settings',
    };
  },
  computed: {
    ...mapState(['availableDataAdapters']),
    ...mapGetters(['getProjectDataAdapters']),
    adapterData() {
      return this.getProjectDataAdapters()?.[this.dataAdapter] || null;
    },
    tabs() {
      const tabs = [{ label: this.$t('navigation.settings'), value: 'settings' }];

      if ([DATA_ADAPTERS.SQLITE, DATA_ADAPTERS.MSSQL, DATA_ADAPTERS.MSSQL, DATA_ADAPTERS.MYSQL, DATA_ADAPTERS.POSTGRESQL].includes(this.dataAdapter)) {
        tabs.push({ label: this.$t('projects.data_adapters.tables'), value: 'tables' });
      }

      return tabs;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    dataAdapter: {
      get() {
        return this.$route.params.dataAdapter;
      },
      set(value) {
        this.$router.push({
          name: this.$route.name,
          params: {
            ...this.$route.params,
            dataAdapter: value,
          },
        });
      },
    },
    dataAdapters() {
      return Object.values(this.getProjectDataAdapters()).map((model) => {
        return {
          label: this.availableDataAdapters?.[model.adapter_type]?.name,
          value: model.adapter_type,
        };
      });
    },
  },
  created() {
    this.fetchAvailableDataAdapters();
    if (this.$route.name === 'project-data-adapter') {
      this.selectedTab = 'settings';
    } else if (this.$route.name === 'project-data-adapter-tables') {
      this.selectedTab = 'tables';
    }
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchAvailableDataAdapters', 'fetchProjectDataAdapter', 'fetchProjectDataAdapters']),
    goToModels() {
      this.$router.push({
        name: 'project-data-adapters',
        params: { projectId: this.$route.params.projectId },
      });
    },
    tabSelected(tab) {
      return tab.value === this.selectedTab;
    },
    onSelectTab(tab) {
      this.selectedTab = tab.value;
      if (tab.value === 'settings') {
        this.$router.push({ name: 'project-data-adapter', params: this.$route.params });
      } else if (tab.value === 'tables') {
        this.$router.push({ name: 'project-data-adapter-tables', params: this.$route.params });
      }
    },
  },
  watch: {
    projectId: {
      handler() {
        this.fetchProjectDataAdapters({ projectId: this.projectId });
      },
      immediate: true,
    },
    dataAdapter: {
      async handler() {
        this.fetching = true;
        await this.fetchProjectDataAdapter({ projectId: this.projectId, adapterType: this.dataAdapter });
        this.fetching = false;
      },
      immediate: true,
    },
  },
};
</script>
<style>
.project-model .vs__open-indicator {
  --vs-controls-size: 0.7;
}
</style>
