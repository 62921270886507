/* eslint-disable no-empty-pattern */
import { createStore } from 'vuex';
import dayjs from 'dayjs';
import i18nLib, { languages, selectedLocale } from '@/plugins/i18n';
import router from '@/plugins/router';
import {
  apiChangeSubscriptionAddress,
  apiDeleteProject,
  apiDeleteProjectDatatype,
  apiDeleteProjectEndpoint,
  apiDeleteProjectFlow,
  apiDeleteProjectModel,
  apiDeleteProjectPersona,
  apiDeleteProjectSecret,
  apiDeleteProjectTemplate,
  apiDeleteProjectVariable,
  apiDelTenant,
  apiGetAddresses,
  apiGetFunctions,
  apiGetPayments,
  apiGetProjectData,
  apiGetProjectDatatype,
  apiGetProjectDatatypes,
  apiGetProjectEndpoint,
  apiGetProjectEndpoints,
  apiGetProjectFlows,
  apiGetProjectModel,
  apiGetProjectModels,
  apiGetProjectPersona,
  apiGetProjectPersonas,
  apiGetProjects,
  apiGetProjectSecrets,
  apiGetProjectTemplate,
  apiGetProjectTemplates,
  apiGetProjectVariable,
  apiGetProjectVariables,
  apiGetTenant,
  apiGetTenantList,
  apiPostCancelSubscription,
  apiPostEmailCardToUser,
  apiPostFeedback,
  apiPostLogout,
  apiPostPauseSubscription,
  apiPostProject,
  apiPostProjectDatatype,
  apiPostProjectEndpoint,
  apiPostProjectFlow,
  apiPostProjectModel,
  apiPostProjectPersona,
  apiPostProjectSecret,
  apiPostProjectTemplate,
  apiPostProjectVariable,
  apiPostResumeSubscription,
  apiPostSendSigninEmailLink,
  apiPostTenant,
  apiPostUpdatePreviewSubscription,
  apiPostUpdateSubscription,
  apiPutAddress,
  apiPutFlag,
  apiPutProfile,
  apiPutProject,
  apiPutProjectDatatype,
  apiPutProjectEndpoint,
  apiPutProjectFlow,
  apiPutProjectModel,
  apiPutProjectPersona,
  apiPutProjectTemplate,
  apiPutProjectVariable,
  apiPutTenantInvite,
  apiPutTenant,
  apiGetUsers,
  apiGetProjectUserList,
  apiPostAddUser,
  apiGetProjectAvailableDataAdapters,
  apiGetProjectDataAdapters,
  apiPostProjectDataAdapter,
  apiPutProjectDataAdapter,
  apiDelProjectDataAdapter,
  apiGetProjectFlow,
  apiPutProjectDataAdapterEnabled,
  apiPutProjectDataAdapterDataTypes,
  apiGetProjectDataAdapter,
  apiGetProjectAvailableAIProviders,
  apiGetProjectAIProviders,
  apiDeleteProjectAIProvider,
  apiPostProjectAIProvider,
  apiPostProjectUser,
  apiPutProjectUser,
  apiGetProjectUser,
  apiDelProjectUser,
  apiGetProjectFeatures,
  apiPutProjectFeatures,
  apiGetProjectLogos,
  apiPutProjectDisabled,
  apiGetProject,
} from '@/helpers/api';
import { BASE_URL, LOGIN_PROVIDERS, SUBSCRIPTION_STATES } from '@/constants';
import delay from '@/helpers/delay';

const i18n = i18nLib.global;

const HelpQuestions = [
  { id: 1, category: 1, text: 'How can I change default payment settings?' },
  { id: 2, category: 1, text: "What will happen to my Free account if I don't log in for a long time?" },
  { id: 3, category: 1, text: 'How do I reset my password?' },
  { id: 4, category: 2, text: 'How can I upgrade my subscription plan?' },
  { id: 5, category: 2, text: 'How can I recover my password?' },
  { id: 6, category: 2, text: 'What is AI and natural language programming?' },
  { id: 7, category: 3, text: 'How does Fidsy simplify data analysis for users?' },
  { id: 8, category: 3, text: 'How does Fidsy help maximize ROI?' },
  { id: 9, category: 3, text: 'What makes Fidsy different from other business intelligence tools?' },
  { id: 10, category: 4, text: 'What are the benefits of using Fidsy?' },
  { id: 11, category: 4, text: 'Can I add additional data sources to Fidsy?' },
];

export default createStore({
  state: {
    bootstrapped: false,
    loginProviders: [LOGIN_PROVIDERS.GOOGLE, LOGIN_PROVIDERS.MAGIC_LINK, LOGIN_PROVIDERS.PASSWORD],
    signupEnabled: true,
    showSidePanel: false,
    toastNotifications: [],
    user: {},
    quota: 0,
    features: [],
    organisations: [],
    timezone: null,
    tenantUpdated: null,
    payments: [],
    subscription: {},
    locale: 'en-US',
    languages,
    designTimeLanguage: null,
    selectedIntentDefinition: {},
    selectedIntentDirty: false,
    selectedIntentDefinitionStepId: null,
    functions: null,
    projects: {},
    projectData: {},
    availableDataAdapters: {},
    availableAIProviders: {},
    users: [],
    addresses: [],
  },
  mutations: {
    SET_BOOTSTRAPPED(state) {
      state.bootstrapped = true;
    },
    ADD_TOAST_MESSAGE(state, payload) {
      // const { title, message, duration } = payload;
      state.toastNotifications.push(payload);
    },
    REMOVE_TOAST_MESSAGE(state, payload) {
      const i = state.toastNotifications.findIndex((n) => payload === n.key);
      clearTimeout(state.toastNotifications[i].timer);
      state.toastNotifications.splice(i, 1);
    },
    SET_SHOW_SIDE_PANEL(state, payload) {
      state.showSidePanel = payload;
    },
    SET_LOCALE(state, payload) {
      state.locale = payload;
      localStorage.setItem('locale', payload);
    },
    SET_DESIGN_TIME_LANGUAGE(state, payload) {
      state.designTimeLanguage = payload;
    },
    SET_TENANT(state, payload) {
      const { subscription = {}, features = [], updated, flags = {}, timezone = '', name, country, email, id, language, locale, organisation, picture, position } = payload;

      state.user = {
        name,
        country,
        email,
        id,
        language,
        locale,
        organisation,
        picture,
        position,
      };
      state.tenantUpdated = updated;
      state.flags = flags;
      state.timezone = timezone;
      state.features = features;
      state.subscription = subscription;
    },
    SET_TENANT_TIMEZONE(state, payload) {
      state.timezone = payload;
    },
    LOGOUT(state) {
      state.user = {};
      localStorage.removeItem('xsrf-token');
    },
    SET_PAYMENTS(state, payload) {
      state.payments = payload;
    },
    ADD_PROJECT_STEP(state, payload) {
      const { projectId, endpointId, stepItem } = payload;
      if (!state.projectData[projectId].endpoints[endpointId].steps.some((s) => s.id === stepItem.id)) {
        state.projectData[projectId].endpoints[endpointId].steps.push(stepItem);
      }
    },
    SET_SELECTED_INTENT_DEFINITION(state, payload) {
      state.selectedIntentDefinition = payload;
      state.selectedIntentDirty = false;
    },
    SET_SELECTED_INTENT_DEFINITION_PROP(state, payload) {
      const { prop, value } = payload;
      state.selectedIntentDefinition[prop] = value;
      state.selectedIntentDirty = true;
    },
    SET_SELECTED_INTENT_DEFINITION_STEP(state, payload) {
      const index = state.selectedIntentDefinition.steps.findIndex((s) => s.id === payload.id);
      state.selectedIntentDefinition.steps[index] = payload;
      state.selectedIntentDirty = true;
    },
    SET_SELECTED_INTENT_DEFINITION_STEP_POSITION(state, payload) {
      const { id, column, order } = payload;
      const index = state.selectedIntentDefinition.steps.findIndex((s) => s.id === id);
      if (index > -1) {
        state.selectedIntentDefinition.steps[index].column = column;
        state.selectedIntentDefinition.steps[index].order = order;
        state.selectedIntentDirty = true;
      }
    },
    ADD_SELECTED_INTENT_DEFINITION_STEP(state, payload) {
      if (!state.selectedIntentDefinition.steps.some((s) => s.id === payload.id)) {
        state.selectedIntentDefinition.steps.push(payload);
        state.selectedIntentDirty = true;
      }
    },
    REMOVE_SELECTED_INTENT_DEFINITION_STEP(state, payload) {
      const index = state.selectedIntentDefinition.steps.findIndex((s) => s.id === payload);
      state.selectedIntentDefinition.steps.splice(index, 1);
      state.selectedIntentDirty = true;
    },
    SET_SELECTED_INTENT_DIRTY(state, payload) {
      state.selectedIntentDirty = payload;
    },
    SET_SELECTED_INTENT_DEFINITION_STEP_ID(state, payload) {
      state.selectedIntentDefinitionStepId = payload;
    },
    SET_FUNCTIONS(state, payload) {
      state.functions = payload;
    },
    SET_PROJECTS(state, payload) {
      state.projects = payload;
    },
    SET_PROJECT_DATA(state, payload) {
      state.projectData[payload.project_id] = payload;
    },
    SET_PROJECT_DATA_PROP(state, payload) {
      const { projectId, data, prop } = payload;
      if (!(projectId in state.projectData)) {
        state.projectData[projectId] = {};
      }
      if (prop) {
        state.projectData[projectId][prop] = data;
      }
    },
    SET_PROJECT_DATA_ADAPTER(state, payload) {
      const { projectId, dataAdapter } = payload;
      if (!(projectId in state.projectData)) {
        state.projectData[projectId] = {};
      }
      if (!('dataAdapters' in state.projectData[projectId])) {
        state.projectData[projectId].dataAdapters = {};
      }
      state.projectData[projectId].dataAdapters[dataAdapter.adapter_type] = dataAdapter;
    },
    SET_PROJECT_DATA_ADAPTERS(state, payload) {
      const { projectId, data } = payload;
      if (!(projectId in state.projectData)) {
        state.projectData[projectId] = {};
      }
      if (!('dataAdapters' in state.projectData[projectId])) {
        state.projectData[projectId].dataAdapters = {};
      }
      Object.keys(data).forEach((key) => {
        if (key in state.projectData[projectId].dataAdapters) {
          state.projectData[projectId].dataAdapters[key].config.enabled = data[key].config.enabled;
        } else {
          state.projectData[projectId].dataAdapters[key] = data[key];
        }
      });
      Object.keys(state.projectData[projectId].dataAdapters).forEach((key) => {
        if (!(key in data)) {
          delete state.projectData[projectId].dataAdapters[key];
        }
      });
    },
    REMOVE_PROJECT(state, payload) {
      delete state.projects[payload];
      delete state.projectData[payload];
    },
    SET_AVAILABLE_DATA_ADAPTERS(state, payload) {
      state.availableDataAdapters = payload;
    },
    SET_AVAILABLE_AI_PROVIDERS(state, payload) {
      state.availableAIProviders = payload;
    },
    SET_USERS(state, payload) {
      if (Array.isArray(payload)) {
        state.users = payload;
      }
    },
    SET_ADDRESSES(state, payload) {
      state.addresses = payload;
    },
    UPDATE_ADDRESS(state, payload) {
      const id = payload.address_id || payload.id;
      const index = state.addresses.findIndex((a) => a.id === id);
      if (index < 0) return;
      if (payload.status === 'archived') {
        state.addresses.splice(index, 1);
      } else {
        state.addresses[index] = { id, ...payload };
      }
    },
  },
  actions: {
    changeLocale({ commit }, payload) {
      i18n.locale = payload;
      commit('SET_LOCALE', payload);
    },
    showToastMessage({ commit }, payload) {
      let args = payload;
      if (typeof args === 'string') {
        args = { message: args };
      }
      if (args.message) {
        args.message = args.message.substring(0, 150);
      }

      args.key = new Date().getTime();
      args.duration = args.duration || 4500;
      args.type = args.type || 'default';
      // Set a timer to kill the notification after the specified duration
      args.timer = setTimeout(() => {
        commit('REMOVE_TOAST_MESSAGE', args.key);
      }, args.duration);
      commit('ADD_TOAST_MESSAGE', args);
    },
    closeToastMessage({ commit }, payload) {
      commit('REMOVE_TOAST_MESSAGE', payload);
    },
    async getTenant({ state, commit, dispatch, getters }) {
      try {
        const response = await apiGetTenant();

        if (response.status === 200) {
          if (state.tenantUpdated !== null) {
            if (state.tenantUpdated > response.data.updated) {
              await delay();
              return await dispatch('getTenant');
            }
          }
          commit('SET_TENANT', response.data);
          if (response.data.locale) {
            dispatch('changeLocale', response.data.locale);
          }
          commit('SET_BOOTSTRAPPED');

          if (!getters.getRegistrationComplete || !getters.getOnboardingComplete) {
            router.push({
              path: '/onboarding',
              query: router.currentRoute.value.query,
            });
            return Promise.resolve('routeChanged');
          }

          if (!response.data.timezone) {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            dispatch('updateTimeZone', timezone);
          }
        }
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(new Error('Failed to retrieve Tenant'));
      }
    },
    async sendSigninEmailLink(store, { email, token }) {
      try {
        return apiPostSendSigninEmailLink({ email, token });
      } catch (e) {
        return { status: 500 };
      }
    },
    async bootstrap({ dispatch }) {
      // let isAssistant = false;
      let xsrfToken = localStorage.getItem('xsrf-token');
      const locale = localStorage.getItem('locale') || selectedLocale;
      dispatch('changeLocale', locale);

      // Make sure router is loaded
      await router.isReady();
      const { path, query, name } = router.currentRoute.value;

      if (name === 'password-reset') {
        dispatch('removeSvgLoader');
      } else if (name === 'accept-invite') {
        dispatch('removeSvgLoader');
      } else if (path === '/login' || window.location.pathname === '/login') {
        if (!xsrfToken && query.xsrfToken) {
          ({ xsrfToken } = query);
          localStorage.setItem('xsrf-token', xsrfToken);
        }
        if (query.reset) {
          await dispatch('getTenant');
          await dispatch('fetchProjects');
          dispatch('removeSvgLoader');
          return;
        }

        if (xsrfToken) {
          try {
            const response = await dispatch('getTenant');
            await dispatch('fetchProjects');
            if (response === 'routeChanged') {
              dispatch('removeSvgLoader');
              return;
            }
            router.push('/');
          } catch (error) {
            console.log('GET TENANT ERROR', error); // eslint-disable-line
          }
        }
        dispatch('removeSvgLoader');
      } else if (path === '/authcallback') {
        // Extract query parameters
        const { status, message, redirect, type } = router.currentRoute.value.query;

        if (status && message && redirect) {
          if (decodeURIComponent(redirect) !== '/login') {
            router.push({
              path: decodeURIComponent(redirect),
              query: { type },
            });
            await dispatch('getTenant');
            await dispatch('fetchProjects');
            dispatch('removeSvgLoader');
            dispatch('showToastMessage', { message, type: status });
            return;
          }
          dispatch('showToastMessage', { message, type: status });
        }

        router.push('/login');
        dispatch('removeSvgLoader');
      } else {
        if (xsrfToken) {
          try {
            await dispatch('getTenant');
            await dispatch('fetchProjects');
          } catch (error) {
            router.push('/login');
            console.log('GET TENANT ERROR', error); // eslint-disable-line
          }
        } else {
          router.push('/login');
        }
        dispatch('removeSvgLoader');
      }
    },
    async updateProfile({ commit }, payload) {
      try {
        const response = await apiPutProfile(payload);
        if (response.status === 200) {
          commit('SET_TENANT', response.data);
          return response.data;
        }
        return response.data;
      } catch (e) {
        return false;
      }
    },
    removeSvgLoader() {
      const svgLoader = document.getElementById('svg_loading');
      if (svgLoader) {
        svgLoader.remove();
      }
      return Promise.resolve();
    },
    async logout({ commit }) {
      try {
        await apiPostLogout();
      } catch (e) {
        // TODO: Handle error
      }

      commit('LOGOUT');

      window.location = '/login';
    },
    // eslint-disable-next-line
    async oAuthRedirect({ commit, dispatch }, { type, onSuccess }) {
      let redirectUrl = `${BASE_URL}/api/v1/datasourceredirect?type=${type}`;
      if (window.location.host === 'local.fidsy.com') {
        redirectUrl += '&local=true';
      }
      window.location = redirectUrl;
    },
    exportToCsv(store, table) {
      let data;

      const [firstRow] = table;
      const columnNames = Object.keys(firstRow).map((item) => item.trim());

      const c = [];
      columnNames.forEach((cell) => {
        if (cell.endsWith('__%')) {
          c.push(`${cell.slice(0, -3)} %`);
        } else if (cell.endsWith('__change')) {
          c.push(`${cell.slice(0, -3)} Change %`);
        } else if (cell.startsWith('Previous_')) {
          c.push(`Previous ${cell.substring(2)}`);
        } else {
          c.push(cell);
        }
      });
      data = `${c.join(',')}\n`;

      if (table) {
        table.forEach((row) => {
          const r = [];
          columnNames.forEach((columnName) => {
            if (columnName in row) {
              r.push(row[columnName]);
            }
          });
          data += `${r.join(',')}\n`;
        });
      }
      const csvContent = `data:text/csv;charset=utf-8,${data}`;

      const encodedUri = encodeURI(csvContent);

      const link = document.getElementById('export');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `fidsy_card_data_${new Date().toISOString().substring(0, 10)}.csv`);
      link.target = '_blank';
      link.click();

      link.setAttribute('href', '');
      link.setAttribute('download', '');
    },
    async emailCardToUser({ dispatch }, data) {
      try {
        const response = await apiPostEmailCardToUser(data);

        if (response.status === 200) {
          dispatch('showToastMessage', { message: i18n.t('collections.email_sent'), type: 'success' });
          return;
        }
        dispatch('showToastMessage', { title: i18n.t('collections.failed_to_send_email'), type: 'error' });
      } catch (err) {
        dispatch('showToastMessage', { title: i18n.t('collections.failed_to_send_email'), type: 'error' });
      }
    },
    async sendFeedback({ dispatch }, payload) {
      try {
        const { sentiment, message, fields, data, phrase, hideNotification } = payload; // eslint-disable-line

        const response = await apiPostFeedback({ sentiment, message, fields, data, phrase });

        if (response.status === 200) {
          if (!hideNotification) {
            dispatch('showToastMessage', { message: i18n.t('feedback.submitted_successfully'), type: 'success' });
          }
          return true;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('feedback.failed_to_send'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('feedback.failed_to_send'), type: 'error' });
        return false;
      }
    },
    async fetchPayments({ commit, dispatch }) {
      try {
        const response = await apiGetPayments();

        if (response.status === 200) {
          commit('SET_PAYMENTS', response.data || []);
          return true;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('subscription.failed_to_get_payments'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('subscription.failed_to_get_payments'), type: 'error' });
        return false;
      }
    },
    async cancelSubscription({ dispatch }) {
      try {
        const response = await apiPostCancelSubscription();

        if (response.status === 200 && response.data.success) {
          return true;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('subscription.failed_to_cancel'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('subscription.failed_to_cancel'), type: 'error' });
        return false;
      }
    },
    async pauseSubscription({ dispatch }, payload) {
      try {
        const { type } = payload;
        const subscriptionId = null;

        const response = await apiPostPauseSubscription({ type, subscription_id: subscriptionId, paused: true });

        return !!(response.status === 200 && response.data.success);
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('subscription.failed_to_pause'), type: 'error' });
        return false;
      }
    },
    async continueSubscription({ dispatch }, payload) {
      try {
        const { type } = payload;
        const subscriptionId = null;

        const response = await apiPostPauseSubscription({ type, subscription_id: subscriptionId, paused: false });

        if (response.status === 200 && response.data.success) {
          return true;
        }
        dispatch('showToastMessage', { title: response.error.message || i18n.t('subscription.failed_to_continue'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('subscription.failed_to_continue'), type: 'error' });
        return false;
      }
    },
    async resumeSubscription({ dispatch }) {
      try {
        const response = await apiPostResumeSubscription();

        if (response.status === 200 && response.data.success) {
          return true;
        }
        dispatch('showToastMessage', { title: response.error.message || i18n.t('subscription.failed_to_resume'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('subscription.failed_to_resume'), type: 'error' });
        return false;
      }
    },
    async updateSubscription({ dispatch }, payload) {
      try {
        const { priceId } = payload;
        const response = await apiPostUpdateSubscription({ price_id: priceId });

        if (response.status === 200 && response.data.success) {
          return true;
        }
        dispatch('showToastMessage', { title: response.error.message || i18n.t('subscription.failed_to_update'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('subscription.failed_to_update'), type: 'error' });
        return false;
      }
    },
    async updatePreviewSubscription({ dispatch }, payload) {
      try {
        const { priceId } = payload;
        const response = await apiPostUpdatePreviewSubscription({ price_id: priceId });

        if (response.status === 200) {
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('subscription.failed_to_get_pricing_preview'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('subscription.failed_to_get_pricing_preview'), type: 'error' });
        return false;
      }
    },
    async fetchFunctions({ commit, dispatch }) {
      try {
        const response = await apiGetFunctions();

        if (response.status === 200 && response.data) {
          commit('SET_FUNCTIONS', response.data);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('flow.failed_to_fet_functions'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('flow.failed_to_fet_functions'), type: 'error' });
        return false;
      }
    },
    async completeOnboarding({ dispatch }) {
      try {
        const response = await dispatch('setFlag', { key: 'oc', value: true });

        // TODO remove this once onboarding is ready
        await dispatch('setFlag', { key: 'rc', value: true });
        return !!response;
      } catch (e) {
        return false;
      }
    },
    async setFlag({ dispatch, commit }, payload) {
      try {
        const response = await apiPutFlag(payload);

        if (response.status === 200) {
          commit('SET_TENANT', response.data);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('failed_to_update_flag'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('failed_to_update_flag'), type: 'error' });
        return false;
      }
    },
    async fetchProjects({ commit, dispatch }) {
      try {
        const response = await apiGetProjects();

        if (response.status === 200 && response.data) {
          commit('SET_PROJECTS', response.data);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_projects'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_projects'), type: 'error' });
        return false;
      }
    },
    async fetchProjectData({ commit, dispatch }, payload) {
      try {
        const response = await apiGetProjectData(payload);

        if (response.status === 200 && response.data) {
          commit('SET_PROJECT_DATA', response.data);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async fetchProject({ commit, dispatch }, payload) {
      try {
        const response = await apiGetProject(payload.projectId);

        if (response.status === 200 && response.data) {
          commit('SET_PROJECT_DATA', response.data);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async createProject({ dispatch }, payload) {
      const { name } = payload;
      try {
        const response = await apiPostProject({ name });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjects');
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_create_project'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_create_project'), type: 'error' });
        return false;
      }
    },
    async updateProject({ dispatch }, payload) {
      const { project_id, name, brand_name, disabled, theme } = payload;
      try {
        const response = await apiPutProject({ project_id, name, brand_name, disabled, theme });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjects');
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_update_project'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_update_project'), type: 'error' });
        return false;
      }
    },
    async updateProjectDisabled({ dispatch }, payload) {
      const { project_id, disabled } = payload;
      try {
        const response = await apiPutProjectDisabled({ project_id, disabled });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjects');
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_update_project'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_update_project'), type: 'error' });
        return false;
      }
    },
    async fetchProjectEndpoints({ dispatch, commit }, payload) {
      const { projectId } = payload;
      try {
        const response = await apiGetProjectEndpoints(projectId);

        if (response.status === 200 && response.data) {
          commit('SET_PROJECT_DATA_PROP', { prop: 'endpoints', projectId, data: response.data });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async fetchProjectEndpoint({ dispatch }, payload) {
      const { projectId, endpointId } = payload;
      try {
        const response = await apiGetProjectEndpoint(projectId, endpointId);

        if (response.status === 200 && response.data) {
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async createProjectEndpoint({ dispatch }, payload) {
      const { project_id, method, path, group_name, flow_id, request_params } = payload;
      try {
        const response = await apiPostProjectEndpoint({ project_id, method, path, group_name, flow_id, request_params });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectEndpoints', { projectId: project_id });
          dispatch('showToastMessage', { message: i18n.t('projects.endpoints.added_successfully'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.endpoints.failed_to_update'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.endpoints.failed_to_update'), type: 'error' });
        return false;
      }
    },
    async updateProjectEndpoint({ dispatch }, payload) {
      const { endpoint_id, project_id, method, path, group_name, flow_id, request_params } = payload;
      try {
        const response = await apiPutProjectEndpoint({ endpoint_id, project_id, method, path, group_name, flow_id, request_params });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectEndpoints', { projectId: project_id });
          dispatch('showToastMessage', { message: i18n.t('projects.endpoints.updated_successfully'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.endpoints.failed_to_update'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.endpoints.failed_to_update'), type: 'error' });
        return false;
      }
    },
    async deleteProjectEndpoint({ dispatch }, payload) {
      try {
        const response = await apiDeleteProjectEndpoint(payload);

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectEndpoints', { projectId: payload.project_id });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.endpoints.failed_to_remove'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.endpoints.failed_to_remove'), type: 'error' });
        return false;
      }
    },
    async fetchProjectPersonas({ dispatch, commit }, payload) {
      const { projectId } = payload;
      try {
        const response = await apiGetProjectPersonas(projectId);

        if (response.status === 200 && response.data) {
          commit('SET_PROJECT_DATA_PROP', { prop: 'personas', projectId, data: response.data });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async fetchProjectPersona({ dispatch }, payload) {
      const { projectId, personaId } = payload;
      try {
        const response = await apiGetProjectPersona(projectId, personaId);

        if (response.status === 200 && response.data) {
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async createProjectPersona({ dispatch }, payload) {
      const { project_id, name, value } = payload;
      try {
        const response = await apiPostProjectPersona({ project_id, name, value });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectPersonas', { projectId: project_id });
          dispatch('showToastMessage', { message: i18n.t('projects.personas.added_successfully'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.personas.failed_to_update'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.personas.failed_to_update'), type: 'error' });
        return false;
      }
    },
    async updateProjectPersona({ dispatch }, payload) {
      const { project_id, persona_id, name, value } = payload;
      try {
        const response = await apiPutProjectPersona({ project_id, persona_id, name, value });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectPersonas', { projectId: project_id });
          dispatch('showToastMessage', { message: i18n.t('projects.personas.updated_successfully'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.personas.failed_to_update'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.personas.failed_to_update'), type: 'error' });
        return false;
      }
    },
    async deleteProjectPersona({ dispatch }, payload) {
      try {
        const { project_id, persona_id } = payload;
        const response = await apiDeleteProjectPersona(project_id, persona_id);

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectPersonas', { projectId: payload.project_id });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.personas.failed_to_remove'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.personas.failed_to_remove'), type: 'error' });
        return false;
      }
    },
    async fetchProjectAvailableAIProviders({ dispatch, commit }) {
      try {
        const response = await apiGetProjectAvailableAIProviders();

        if (response.status === 200 && response.data) {
          commit('SET_AVAILABLE_AI_PROVIDERS', response.data);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async fetchProjectAIProviders({ dispatch, commit }, payload) {
      const { projectId } = payload;
      try {
        const response = await apiGetProjectAIProviders(projectId);

        if (response.status === 200 && response.data) {
          commit('SET_PROJECT_DATA_PROP', { prop: 'aiProviders', projectId, data: response.data });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async createProjectAIProvider({ dispatch }, payload) {
      const { project_id, provider, key, url } = payload;
      try {
        const response = await apiPostProjectAIProvider({ project_id, provider, key, url });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectAIProviders', { projectId: project_id });
          dispatch('showToastMessage', { message: i18n.t('projects.ai_providers.added_successfully'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.ai_providers.failed_to_add'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.ai_providers.failed_to_add'), type: 'error' });
        return false;
      }
    },
    async updateProjectAIProvider({ dispatch }, payload) {
      const { project_id, provider, key, url } = payload;
      try {
        const response = await apiPostProjectAIProvider({ project_id, provider, key, url });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectAIProviders', { projectId: project_id });
          dispatch('showToastMessage', { message: i18n.t('projects.ai_providers.updated_successfully'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.ai_providers.failed_to_update'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.ai_providers.failed_to_update'), type: 'error' });
        return false;
      }
    },
    async deleteProjectAIProvider({ dispatch }, payload) {
      try {
        const { project_id, provider } = payload;
        const response = await apiDeleteProjectAIProvider(project_id, provider);

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectAIProviders', { projectId: payload.project_id });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.ai_providers.failed_to_remove'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.ai_providers.failed_to_remove'), type: 'error' });
        return false;
      }
    },
    async fetchProjectTemplates({ dispatch, commit }, payload) {
      const { projectId } = payload;
      try {
        const response = await apiGetProjectTemplates(projectId);

        if (response.status === 200 && response.data) {
          commit('SET_PROJECT_DATA_PROP', { prop: 'templates', projectId, data: response.data });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async fetchProjectTemplate({ dispatch }, payload) {
      const { projectId, templateId } = payload;
      try {
        const response = await apiGetProjectTemplate(projectId, templateId);

        if (response.status === 200 && response.data) {
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async createProjectTemplate({ dispatch }, payload) {
      const { project_id, name, text, html } = payload;
      try {
        const response = await apiPostProjectTemplate({ project_id, name, text, html });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectTemplates', { projectId: project_id });
          dispatch('showToastMessage', { message: i18n.t('projects.templates.added_successfully'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.templates.failed_to_add'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.templates.failed_to_add'), type: 'error' });
        return false;
      }
    },
    async updateProjectTemplate({ dispatch }, payload) {
      const { project_id, template_id, name, text, html } = payload;
      try {
        const response = await apiPutProjectTemplate({ project_id, template_id, name, text, html });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectTemplates', { projectId: project_id });
          dispatch('showToastMessage', { message: i18n.t('projects.templates.updated_successfully'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.templates.failed_to_update'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.templates.failed_to_update'), type: 'error' });
        return false;
      }
    },
    async deleteProjectTemplate({ dispatch }, payload) {
      try {
        const { project_id, template_id } = payload;
        const response = await apiDeleteProjectTemplate({ project_id, template_id });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectTemplates', { projectId: payload.project_id });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.templates.failed_to_remove'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.templates.failed_to_remove'), type: 'error' });
        return false;
      }
    },
    async fetchProjectFlows({ dispatch, commit }, payload) {
      const { projectId } = payload;
      try {
        const response = await apiGetProjectFlows(projectId);

        if (response.status === 200 && response.data) {
          commit('SET_PROJECT_DATA_PROP', { prop: 'flows', projectId, data: response.data });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async fetchProjectFlow({ dispatch }, payload) {
      const { projectId, flowId } = payload;
      try {
        const response = await apiGetProjectFlow(projectId, flowId);

        if (response.status === 200 && response.data) {
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async createProjectFlow({ dispatch }, payload) {
      const { project_id, name, steps, transition } = payload;
      try {
        const response = await apiPostProjectFlow({ project_id, name, steps, transition });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectFlows', { projectId: project_id });
          dispatch('showToastMessage', { message: i18n.t('projects.flows.added_successfully'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.flows.failed_to_add'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.endpoints.failed_to_add'), type: 'error' });
        return false;
      }
    },
    async updateProjectFlow({ dispatch }, payload) {
      const { project_id, flow_id, name, steps, transition } = payload;
      try {
        const response = await apiPutProjectFlow({ project_id, name, steps, flow_id, transition });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectFlows', { projectId: project_id });
          dispatch('showToastMessage', { message: i18n.t('projects.endpoints.updated_successfully'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.endpoints.failed_to_update'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.endpoints.failed_to_update'), type: 'error' });
        return false;
      }
    },
    async deleteProjectFlow({ dispatch }, payload) {
      try {
        const { project_id, flow_id } = payload;
        const response = await apiDeleteProjectFlow(project_id, flow_id);

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectFlows', { projectId: payload.project_id });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.flows.failed_to_remove'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.flows.failed_to_remove'), type: 'error' });
        return false;
      }
    },
    async deleteProject({ dispatch, commit }, payload) {
      try {
        const response = await apiDeleteProject(payload);

        if (response.status === 200 && response.data) {
          commit('REMOVE_PROJECT', payload);
          dispatch('showToastMessage', { message: i18n.t('projects.project_removed'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_remove_project'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_remove_project'), type: 'error' });
        return false;
      }
    },
    async fetchProjectVariables({ dispatch, commit }, payload) {
      const { projectId } = payload;
      try {
        const response = await apiGetProjectVariables(projectId);

        if (response.status === 200 && response.data) {
          commit('SET_PROJECT_DATA_PROP', { prop: 'variables', projectId, data: response.data });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async fetchProjectVariable({ dispatch }, payload) {
      const { projectId, name } = payload;
      try {
        const response = await apiGetProjectVariable(projectId, name);

        if (response.status === 200 && response.data) {
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async createProjectVariable({ dispatch }, payload) {
      const { project_id, name, dataType, value } = payload;
      try {
        const response = await apiPostProjectVariable({ project_id, name, dataType, value });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectVariables', { projectId: project_id });
          dispatch('showToastMessage', { message: i18n.t('projects.variables.added_successfully'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.variables.failed_to_add'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.variables.failed_to_add'), type: 'error' });
        return false;
      }
    },
    async updateProjectVariable({ dispatch }, payload) {
      const { project_id, name, dataType, value } = payload;
      try {
        const response = await apiPutProjectVariable({ project_id, name, dataType, value });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectVariables', { projectId: project_id });
          dispatch('showToastMessage', { message: i18n.t('projects.endpoints.updated_successfully'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.variables.failed_to_update'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.variables.failed_to_update'), type: 'error' });
        return false;
      }
    },
    async deleteProjectVariable({ dispatch }, payload) {
      try {
        const response = await apiDeleteProjectVariable(payload);

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectVariables', { projectId: payload.project_id });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_remove_variable'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_remove_variable'), type: 'error' });
        return false;
      }
    },
    async fetchProjectDataTypes({ dispatch, commit }, payload) {
      const { projectId } = payload;
      try {
        const response = await apiGetProjectDatatypes(projectId);

        if (response.status === 200 && response.data) {
          commit('SET_PROJECT_DATA_PROP', { prop: 'datatypes', projectId, data: response.data });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async fetchProjectDataType({ dispatch }, { name, projectId }) {
      try {
        const response = await apiGetProjectDatatype(projectId, name);

        if (response.status === 200 && response.data) {
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('collections.failed_to_get_data_type'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('collections.failed_to_get_data_type'), type: 'error' });
        return false;
      }
    },
    async createProjectDatatype({ dispatch }, payload) {
      const { project_id, name, fields, colour } = payload;
      try {
        const response = await apiPostProjectDatatype({ project_id, name, fields, colour });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectDataTypes', { projectId: project_id });
          dispatch('showToastMessage', { message: i18n.t('data_types.added_successfully'), type: 'success' });
          return response.data;
        }
        if (response.status === 400) {
          if (response.data && response.data.body) {
            if (response.data.body.error && response.data.body.duplicate_fields) {
              dispatch('showToastMessage', { title: i18n.t('data_types.duplicate_detected'), message: response.data.body.duplicate_fields.join(' | '), type: 'error', duration: 10000 });
              return false;
            }
          }
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('data_types.failed_to_add'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('data_types.failed_to_add'), type: 'error' });
        return false;
      }
    },
    async updateProjectDatatype({ dispatch }, payload) {
      const { project_id, name, fields, colour } = payload;
      try {
        const response = await apiPutProjectDatatype({ project_id, name, fields, colour });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectDataTypes', { projectId: project_id });
          dispatch('showToastMessage', { message: i18n.t('data_types.updated_successfully'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('data_types.failed_to_update'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('data_types.failed_to_update'), type: 'error' });
        return false;
      }
    },
    async deleteProjectDatatype({ dispatch }, payload) {
      try {
        const response = await apiDeleteProjectDatatype(payload.project_id, payload.name);

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectDataTypes', { projectId: payload.project_id });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('data_types.failed_to_remove'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('data_types.failed_to_remove'), type: 'error' });
        return false;
      }
    },
    async fetchProjectSecrets({ dispatch, commit }, payload) {
      const { projectId } = payload;
      try {
        const response = await apiGetProjectSecrets(projectId);

        if (response.status === 200 && response.data) {
          commit('SET_PROJECT_DATA_PROP', { prop: 'secrets', projectId, data: response.data });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async createProjectSecret({ dispatch }, payload) {
      const { project_id, name, value } = payload;
      try {
        const response = await apiPostProjectSecret({ project_id, name, value });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectSecrets', { projectId: project_id });
          dispatch('showToastMessage', { message: i18n.t('projects.secrets.added_successfully'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.secrets.failed_to_add'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.secrets.failed_to_add'), type: 'error' });
        return false;
      }
    },
    async updateProjectSecret({ dispatch }, payload) {
      const { project_id, name, value } = payload;
      try {
        const response = await apiPostProjectSecret({ project_id, name, value });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectSecrets', { projectId: project_id });
          dispatch('showToastMessage', { message: i18n.t('projects.secrets.updated_successfully'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.secrets.failed_to_update'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.secrets.failed_to_update'), type: 'error' });
        return false;
      }
    },
    async deleteProjectSecret({ dispatch }, payload) {
      try {
        const response = await apiDeleteProjectSecret(payload);

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectSecrets', { projectId: payload.project_id });
          dispatch('showToastMessage', { message: i18n.t('projects.secrets.removed_successfully'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.secrets.failed_to_delete'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.secrets.failed_to_delete'), type: 'error' });
        return false;
      }
    },
    async fetchProjectTeamMembers({ dispatch, commit }, payload) {
      try {
        const { project_id } = payload;
        const response = await apiGetTenantList(project_id);

        if (response.status === 200 && response.data) {
          commit('SET_PROJECT_DATA_PROP', { prop: 'teamMembers', projectId: project_id, data: response.data });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.iam.failed_to_get_users'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.iam.failed_to_get_users'), type: 'error' });
        return false;
      }
    },
    async inviteProjectTeamMember({ dispatch }, payload) {
      try {
        const { project_id, email, role } = payload;
        const response = await apiPostTenant({ project_id, email, role });

        if (response.status === 200 && response.data) {
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.iam.failed_to_add_user'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.iam.failed_to_add_user'), type: 'error' });
        return false;
      }
    },
    async resendProjectInviteEmail({ dispatch }, payload) {
      try {
        const { project_id, email } = payload;
        const response = await apiPutTenantInvite({ project_id, email });

        if (response.status === 200 && response.data) {
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.iam.failed_to_resend'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.iam.failed_to_resend'), type: 'error' });
        return false;
      }
    },
    async changeProjectTeamMemberRole({ dispatch }, payload) {
      try {
        const { project_id, email, role } = payload;
        const response = await apiPutTenant({ project_id, email, role });

        if (response.status === 200 && response.data) {
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.iam.failed_to_resend'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.iam.failed_to_resend'), type: 'error' });
        return false;
      }
    },
    async deleteProjectInvitedMember({ dispatch }, payload) {
      try {
        const { project_id, email } = payload;
        const response = await apiDelTenant({ project_id, email });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectTeamMembers', { projectId: payload.project_id });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.iam.failed_to_remove_invite'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.iam.failed_to_remove_invite'), type: 'error' });
        return false;
      }
    },
    async fetchProjectUsers({ dispatch, commit }, payload) {
      try {
        const { project_id, pagenumber = 0 } = payload;
        const response = await apiGetProjectUserList(project_id, pagenumber);

        if (response.status === 200 && response.data) {
          commit('SET_PROJECT_DATA_PROP', { prop: 'users', projectId: project_id, data: response.data.users });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.iam.failed_to_get_users'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.iam.failed_to_get_users'), type: 'error' });
        return false;
      }
    },
    async fetchProjectUser({ dispatch }, payload) {
      try {
        const { project_id, email } = payload;
        const response = await apiGetProjectUser({ project_id, email });

        if (response.status === 200 && response.data) {
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.iam.failed_to_get_users'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.iam.failed_to_get_users'), type: 'error' });
        return false;
      }
    },
    async createProjectUser({ dispatch }, payload) {
      try {
        const response = await apiPostProjectUser(payload);

        if (response.status === 200 && response.data) {
          dispatch('fetchProjectUsers', { project_id: payload.project_id });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.iam.failed_to_add_user'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.iam.failed_to_add_user'), type: 'error' });
        return false;
      }
    },
    async updateProjectUser({ dispatch }, payload) {
      try {
        const response = await apiPutProjectUser(payload);

        if (response.status === 200 && response.data) {
          dispatch('fetchProjectUsers', { project_id: payload.project_id });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.iam.failed_to_update_user'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.iam.failed_to_update_user'), type: 'error' });
        return false;
      }
    },
    async deleteProjectUser({ dispatch }, payload) {
      try {
        const { project_id, email } = payload;
        const response = await apiDelProjectUser({ project_id, email });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectUsers', { project_id });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.iam.failed_to_remove_invite'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.iam.failed_to_remove_invite'), type: 'error' });
        return false;
      }
    },
    async fetchProjectFeatures({ dispatch, commit }, payload) {
      try {
        const { project_id } = payload;
        const response = await apiGetProjectFeatures({ project_id });

        if (response.status === 200 && response.data) {
          commit('SET_PROJECT_DATA_PROP', { prop: 'features', projectId: project_id, data: response.data });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.iam.failed_to_get_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.iam.failed_to_get_data'), type: 'error' });
        return false;
      }
    },
    async updateProjectFeatures({ dispatch }, payload) {
      try {
        const { project_id, features } = payload;
        const response = await apiPutProjectFeatures({ project_id, features });

        if (response.status === 200 && response.data) {
          dispatch('fetchProjectFeatures', { project_id: payload.project_id });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.iam.failed_to_add_user'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.iam.failed_to_add_user'), type: 'error' });
        return false;
      }
    },
    async fetchProjectLogos({ dispatch, commit }, payload) {
      try {
        const { project_id } = payload;
        const response = await apiGetProjectLogos({ project_id });

        if (response.status === 200 && response.data) {
          commit('SET_PROJECT_DATA_PROP', { prop: 'logos', projectId: project_id, data: response.data });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.iam.failed_to_get_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.iam.failed_to_get_data'), type: 'error' });
        return false;
      }
    },
    async fetchProjectModels({ commit, dispatch }, payload) {
      try {
        const { projectId } = payload;
        const response = await apiGetProjectModels(projectId);

        if (response.status === 200 && response.data) {
          commit('SET_PROJECT_DATA_PROP', { prop: 'models', projectId, data: response.data });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async fetchProjectModel({ dispatch }, payload) {
      try {
        const response = await apiGetProjectModel(payload);

        if (response.status === 200 && response.data) {
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async createProjectModel({ dispatch }, payload) {
      const { project_id, name, type } = payload;
      try {
        const response = await apiPostProjectModel({ project_id, name, type });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectModels', { projectId: project_id });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_create_project'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_create_project'), type: 'error' });
        return false;
      }
    },
    async updateProjectModel({ dispatch }, payload) {
      const { project_id, model_id, name, type, finetunedata, suggestions } = payload;
      try {
        const response = await apiPutProjectModel({ project_id, model_id, name, type, finetunedata, suggestions });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectModels', { projectId: payload.project_id });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_update_project'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_update_project'), type: 'error' });
        return false;
      }
    },
    async deleteProjectModel({ dispatch }, payload) {
      try {
        const { project_id, model_id } = payload;
        const response = await apiDeleteProjectModel(project_id, model_id);

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectModels', { projectId: payload.project_id });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.flows.failed_to_remove'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.flows.failed_to_remove'), type: 'error' });
        return false;
      }
    },
    async fetchAvailableDataAdapters({ commit, dispatch }) {
      try {
        const response = await apiGetProjectAvailableDataAdapters();

        if (response.status === 200 && response.data) {
          commit('SET_AVAILABLE_DATA_ADAPTERS', response.data);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async fetchProjectDataAdapters({ dispatch, commit }, payload) {
      const { projectId } = payload;
      try {
        const response = await apiGetProjectDataAdapters(projectId);

        if (response.status === 200 && response.data) {
          commit('SET_PROJECT_DATA_ADAPTERS', { projectId, data: response.data });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async fetchProjectDataAdapter({ dispatch, commit }, payload) {
      const { projectId, adapterType } = payload;
      try {
        const response = await apiGetProjectDataAdapter(projectId, adapterType);

        if (response.status === 200 && response.data) {
          commit('SET_PROJECT_DATA_ADAPTER', { projectId, dataAdapter: response.data });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async createProjectDataAdapter({ dispatch }, payload) {
      const { project_id, adapter_type, config } = payload;
      try {
        const response = await apiPostProjectDataAdapter({ project_id, adapter_type, config });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectDataAdapters', { projectId: project_id });
          dispatch('showToastMessage', { message: i18n.t('projects.data_adapters.added_successfully'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.data_adapters.failed_to_add'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.data_adapters.failed_to_add'), type: 'error' });
        return false;
      }
    },
    async updateProjectDataAdapter({ dispatch }, payload) {
      const { project_id, adapter_type, config } = payload;
      try {
        const response = await apiPutProjectDataAdapter({ project_id, adapter_type, config });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectDataAdapters', { projectId: project_id });
          dispatch('showToastMessage', { message: i18n.t('projects.data_adapters.updated_successfully'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.data_adapters.failed_to_update'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.data_adapters.failed_to_update'), type: 'error' });
        return false;
      }
    },
    async deleteProjectDataAdapter({ dispatch }, payload) {
      try {
        const response = await apiDelProjectDataAdapter(payload);

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectDataAdapters', { projectId: payload.project_id });
          await dispatch('showToastMessage', { message: i18n.t('projects.data_adapters.removed_successfully'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.data_adapters.failed_to_delete'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.data_adapters.failed_to_delete'), type: 'error' });
        return false;
      }
    },
    async setEnableProjectDataAdapter({ dispatch }, payload) {
      const { project_id, enabled, adapter_type } = payload;
      try {
        const response = await apiPutProjectDataAdapterEnabled({ project_id, adapter_type, enabled });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectDataAdapters', { projectId: project_id });
          dispatch('showToastMessage', { message: i18n.t('projects.data_adapters.enabled_successfully'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.data_adapters.failed_to_update'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.data_adapters.failed_to_update'), type: 'error' });
        return false;
      }
    },
    async updateProjectDataAdapterTables({ dispatch }, payload) {
      const { project_id, tables, adapter_type } = payload;
      try {
        const response = await apiPutProjectDataAdapterDataTypes({ project_id, adapter_type, tables });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectDataAdapter', { projectId: project_id, adapterType: adapter_type });
          dispatch('showToastMessage', { message: i18n.t('projects.data_adapters.updated_successfully'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.data_adapters.failed_to_update'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.data_adapters.failed_to_update'), type: 'error' });
        return false;
      }
    },
    async fetchAddresses({ state, commit, dispatch }, payload) {
      if (!state.subscription.subscription_id) {
        return false;
      }
      try {
        const response = await apiGetAddresses(payload);

        if (response.status === 200 && response.data) {
          commit('SET_ADDRESSES', response.data);

          if (state.subscription.address_id && response.data.length && !response.data.some((a) => a.id === state.subscription.address_id)) {
            apiChangeSubscriptionAddress({ address_id: response.data[0].id });
          }
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('settings.address.failed_to_get_addresses'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('settings.address.failed_to_get_addresses'), type: 'error' });
        return false;
      }
    },
    async updateAddress({ dispatch, commit }, payload) {
      const { address_id, description, first_line, second_line, city, postal_code, region, country_code, status } = payload;
      try {
        const response = await apiPutAddress({ address_id, description, first_line, second_line, city, postal_code, region, country_code, status });

        if (response.status === 200 && response.data) {
          commit('UPDATE_ADDRESS', payload);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('settings.address.failed_to_update_address'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('settings.address.failed_to_update_address'), type: 'error' });
        return false;
      }
    },
    async setDefaultAddress({ dispatch, commit }, payload) {
      const { address_id } = payload;
      try {
        const response = await apiPutAddress({ address_id, custom_data: { lastDefaultDate: Date.now() } });

        if (response.status === 200 && response.data) {
          commit('UPDATE_ADDRESS', response.data);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('settings.address.failed_to_update_address'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('settings.address.failed_to_update_address'), type: 'error' });
        return false;
      }
    },
    async fetchHelpPopularCategories({}) {
      return new Promise((resolve) => {
        resolve([
          { id: 1, text: 'Getting started' },
          { id: 2, text: 'Conversation' },
          { id: 3, text: 'Collection' },
          { id: 4, text: 'Profile' },
        ]);
      });
    },
    async fetchHelpPopularQuestions({}) {
      return new Promise((resolve) => {
        resolve(HelpQuestions);
      });
    },
    async fetchHelpQuestionsByCategory({ dispatch }, { categoryId }) {
      if (!categoryId) {
        return dispatch('fetchHelpPopularQuestions');
      }
      return new Promise((resolve) => {
        resolve(HelpQuestions.filter((item) => item.category === categoryId));
      });
    },
    async fetchHelpQuestion({}, { id }) {
      const res = await fetch(`/help/${id}.md`);
      if (res.status === 200) {
        return res.text();
      }
      return `\\
&nbsp;
## No Content Found!&nbsp;
\\
&nbsp;`;
    },
    async fetchUsers({ commit, dispatch }, payload) {
      try {
        const response = await apiGetUsers(payload);

        if (response.status === 200 && response.data) {
          commit('SET_USERS', response.data);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.iam.failed_to_get_users'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.iam.failed_to_get_users'), type: 'error' });
        return false;
      }
    },
    async addUser({ dispatch }, payload) {
      try {
        const response = await apiPostAddUser(payload);

        if (response.status === 200 && response.data) {
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.iam.failed_to_add_user'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.iam.failed_to_add_user'), type: 'error' });
        return false;
      }
    },
  },
  getters: {
    getOnboardingComplete(state) {
      return state.flags?.oc === true;
    },
    getRegistrationComplete(state) {
      return state.flags?.rc === true;
    },
    getSubscriptionIsExpired(state) {
      const cancellation_effective_date = state.subscription?.scheduled_change?.effective_at;
      const endDate = dayjs(cancellation_effective_date);
      return endDate.isBefore(dayjs(), 'day');
    },
    getSubscriptionWillBeCancelled(state) {
      const action = state.subscription?.scheduled_change?.action;
      return action === 'cancel';
    },
    getSubscriptionWillChange(state) {
      const action = state.subscription?.scheduled_change?.action;
      return action === 'downgrade';
    },
    getSubscriptionCancelled(state) {
      return SUBSCRIPTION_STATES.CANCELLED === state.subscription?.status;
    },
    getDefaultAddress(state) {
      return state.addresses[0];
    },
    getProject: (state) => (projectId) => {
      if (projectId) {
        return state.projectData?.[projectId] || {};
      }
      if (router.currentRoute.value?.params?.projectId) {
        return state.projectData?.[router.currentRoute.value.params.projectId] || {};
      }
      return {};
    },
    getProjectVariables: (state, getters) => (projectId) => {
      return getters.getProject(projectId)?.variables || {};
    },
    getProjectDataTypes: (state, getters) => (projectId) => {
      return getters.getProject(projectId).datatypes || {};
    },
    getProjectModels: (state, getters) => (projectId) => {
      return getters.getProject(projectId).models || {};
    },
    getProjectSecrets: (state, getters) => (projectId) => {
      return getters.getProject(projectId).secrets || {};
    },
    getProjectUsers: (state, getters) => (projectId) => {
      return getters.getProject(projectId).users || [];
    },
    getProjectTeamMembers: (state, getters) => (projectId) => {
      return getters.getProject(projectId).teamMembers || {};
    },
    getProjectDataAdapters: (state, getters) => (projectId) => {
      return getters.getProject(projectId).dataAdapters || {};
    },
    getProjectAIProviders: (state, getters) => (projectId) => {
      return getters.getProject(projectId).aiProviders || {};
    },
    getProjectPersonas: (state, getters) => (projectId) => {
      return getters.getProject(projectId).personas || {};
    },
    getProjectFeatures: (state, getters) => (projectId) => {
      return getters.getProject(projectId).features || {};
    },
    getProjectLogos: (state, getters) => (projectId) => {
      return getters.getProject(projectId).logos || {};
    },
    getProjectEndpoints: (state, getters) => (projectId) => {
      return getters.getProject(projectId).endpoints || {};
    },
    getProjectFlows: (state, getters) => (projectId) => {
      return getters.getProject(projectId).flows || {};
    },
  },
});
