<template>
  <DefaultLayout show-top-bar>
    <template #header>
      <div class="text-17 flex justify-center items-center font-600 text-primary">
        <span class="text-20 select-none">{{ $t('projects.title') }}</span>
        <PillButton v-if="projectList.length" class="ml-5 hidden sm:flex" icon="plus" :text="$t('add')" primary @click="openCreateProjectModal" />
        <IconButton v-if="projectList.length" dense class="ml-5 inline sm:hidden" icon="plus" primary @click="openCreateProjectModal" />
      </div>
    </template>
    <div class="w-full h-full flex flex-col overflow-hidden">
      <div v-if="projectList.length" class="flex-grow overflow-auto">
        <div class="w-full p-3 sm:p-5 grid gap-8 grid-cols-collection-mobile sm:grid-cols-collection mt-3">
          <ListCard class="h-20 py-2 bg-white rounded-20 cursor-pointer select-none" v-for="project in projectList" :key="project.id" @click="goToProject(project)">
            <div class="flex items-center w-full">
              <img v-if="project && project.logos && project.logos.CONVERSATION_BUBBLE" :src="project.logos.CONVERSATION_BUBBLE" class="w-8 h-8 mr-2 flex-shrink-0 rounded-4" :alt="project.name" />
              <img v-else :src="defaultProjectLogo" class="w-8 h-8 mr-2 flex-shrink-0 rounded-4" :alt="project.name" />
              <div class="flex items-center truncate">
                <span class="font-600 mr-2 truncate text-gray-900">{{ project.name }}</span>
              </div>
            </div>
          </ListCard>
        </div>
      </div>
      <div v-else class="flex flex-col items-center h-full justify-center pb-40">
        <Icon name="code_off" class="w-20 h-20 mb-5 opacity-50" />
        <div class="my-2 text-22 text-gray-700">{{ $t('projects.you_have_no_project') }}</div>
        <div class="my-2 text-gray-600">{{ $t('projects.create_first_project') }}</div>
        <PillButton class="my-3" :text="$t('projects.create_new')" primary @click="openCreateProjectModal" />
      </div>
    </div>
    <Modal v-if="fetching">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </DefaultLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CreateProjectModal from '@/components/project/CreateProjectModal';

export default {
  name: 'Projects',
  data() {
    return {
      loading: false,
      fetching: false,
    };
  },
  created() {
    this.fetchProjects();
  },
  computed: {
    ...mapState(['projects']),
    defaultProjectLogo() {
      return process.env.VUE_APP_BUBBLE_ICON;
    },
    projectList() {
      return (
        Object.keys(this.projects).map((key) => {
          return {
            id: key,
            ...this.projects[key],
          };
        }) || []
      );
    },
  },
  methods: {
    ...mapActions(['fetchProjects']),
    goToProject(project) {
      this.$router.push({ name: 'project-endpoints', params: { projectId: project.id } });
    },
    openCreateProjectModal() {
      this.$showModal(CreateProjectModal);
    },
  },
};
</script>
