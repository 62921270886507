<template>
  <Modal
    sheetbelowsm
    :title="title"
    :primary-button="$t('save')"
    :primary-button-disabled="loading"
    :secondary-button="$t('cancel')"
    :primary-button-loading="loading"
    close-button
    :loading="fetching"
    @primary="onSaveClicked"
    @close="onCancelClicked"
    @secondary="onCancelClicked"
  >
    <ValidationObserver ref="validationObserver" v-slot="{ errors }" class="w-full">
      <div class="sm:w-600">
        <div class="flex">
          <div class="w-full flex flex-col justify-start mt-5 pl-10 pr-17">
            <FormInput v-model="name" :label="$t('data_types.name')" name="name" :error="errors.name" :rules="['required', { validate: nameValidation }]" :readonly="mode === 'Edit'" />
          </div>
        </div>
        <div class="w-full flex flex-col justify-start mt-5 px-10">
          <label class="block text-13 mb-1">{{ $t('data_types.color') }}</label>
          <div class="flex">
            <div
              v-for="availableColor in availableColors"
              :key="availableColor"
              :style="{ backgroundColor: availableColor }"
              class="w-6 h-6 rounded-full mr-2 flex items-center justify-center cursor-pointer"
              @click="color = availableColor"
            >
              <Icon v-if="color === availableColor" name="check" class="w-4 h-4 text-white" />
            </div>
          </div>
        </div>
        <div class="w-full flex flex-col justify-start mt-5 px-10">
          <div class="flex w-full">
            <div class="max-md:w-full flex-grow sm:mr-2 mb-1 ml-6">{{ $t('data_types.field') }}</div>
            <div class="sm:mr-2 mb-1 max-md:flex-grow max-md:w-full sm:w-[120px] sm:flex-shrink-0">{{ $t('data_type') }}</div>
            <div class="ml-2 w-4 h-4"></div>
          </div>

          <Draggable
            v-model="fields"
            itemKey="id"
            handle=".drag-handle"
            v-bind="dragOptions"
            :component-data="{ tag: 'div', type: 'transition-group', name: !drag ? 'flip-list' : null }"
            @start="drag = true"
            @end="drag = false"
          >
            <template #item="{ element: item, index }">
              <div class="flex items-center py-1">
                <div class="flex items-center w-full flex-wrap sm:flex-nowrap mb-1 sm:mb-0 -mt-1">
                  <div class="drag-handle mr-2 cursor-grab"><Icon name="drag_indicator" class="w-4 h-4" /></div>
                  <FormInput v-model="item.key" class="max-md:w-full flex-grow sm:mr-2 mb-1" :placeholder="$t('data_types.field')" @update:modelValue="onFieldInput" />
                  <FormSelect
                    v-model="item.dataType"
                    append-to-body
                    :options="fieldDataTypes"
                    :reduce="(opt) => opt.value"
                    class="sm:mr-2 mb-1 max-md:flex-grow max-md:w-full sm:w-[120px] sm:flex-shrink-0"
                    :placeholder="$t('data_type')"
                    @update:modelValue="onFieldInput"
                  />
                </div>
                <Icon :class="{ invisible: item.key === '' }" name="clear" class="ml-2 w-4 h-4 cursor-pointer" @click="removeField(index)" />
              </div>
            </template>
          </Draggable>

          <p v-if="fieldsError.length" class="text-red-600 text-13 text-left pt-2 pl-0">{{ fieldsError }}</p>
        </div>
      </div>
    </ValidationObserver>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CreateProjectDataType',
  props: {
    dataType: String,
  },
  emits: ['close'],
  data() {
    return {
      mode: 'Add',
      originalName: '',
      initialColor: '',
      color: '',
      name: '',
      fields: [],
      loading: false,
      fetching: false,
      drag: false,
      colors: ['#008FFB', '#A5978B', '#ff657c', '#D7263D', '#4ECDC4', '#449DD1', '#238180', '#F86624', '#FF9800', '#3F51B5', '#6000b5', '#b200ea', '#4CAF50'],
    };
  },
  computed: {
    ...mapState(['projectData']),
    projectId() {
      return this.$route.params.projectId;
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'drag-ghost',
      };
    },
    dataTypes() {
      return this.projectData?.[this.projectId]?.datatypes || {};
    },
    fieldDataTypes() {
      return [
        { label: 'Date', value: 'Date' },
        { label: 'Number', value: 'Number' },
        { label: 'Text', value: 'Text' },
        { label: 'YesNo', value: 'YesNo' },
      ];
    },
    availableColors() {
      return this.colors.filter((color) => !Object.values(this.dataTypes).some((entity) => entity.colour === color) || color === this.initialColor);
    },
    title() {
      return this.mode === 'Add' ? this.$t('data_types.add') : this.$t('data_types.edit');
    },
    fieldsError() {
      if (!this.isFieldKeysUnique) {
        return this.$t('data_types.fields_must_be_unique');
      }
      return false;
    },
    isFieldKeysUnique() {
      // Check keys
      const keySet = new Set(this.fields.map((field) => field.key.toLowerCase()));
      if (this.fields.length === keySet.size) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchProjectDataType', 'createProjectDatatype', 'updateProjectDatatype']),
    nameValidation() {
      if (this.mode === 'Edit' && this.name !== this.originalName) {
        if (this.dataTypes[this.name]) {
          return this.$t('validation.name_unique');
        }
      }
      if (this.mode === 'Add') {
        if (this.dataTypes[this.name]) {
          return this.$t('validation.name_unique');
        }
      }
      return true;
    },
    addField() {
      this.fields.push({ key: '', value: '', dataType: '' });
    },
    removeField(index) {
      this.fields.splice(index, 1);
    },
    fieldsToArray() {
      const fields = {};
      this.fields.forEach((field) => {
        if (field.key.trim()) {
          fields[field.key] = {
            data_type: field.dataType,
          };
        }
      });
      return fields;
    },
    onCancelClicked() {
      this.$emit('close');
    },
    async onSaveClicked() {
      const valid = await this.$refs.validationObserver.validate();
      if (valid.valid && this.isFieldKeysUnique) {
        this.loading = true;
        if (this.mode === 'Add') {
          const response = await this.createProjectDatatype({ project_id: this.projectId, name: this.name, fields: this.fieldsToArray(), colour: this.color });
          this.loading = false;
          if (response) {
            this.$emit('close');
          }
        }
        if (this.mode === 'Edit') {
          const response = await this.updateProjectDatatype({ project_id: this.projectId, name: this.name, fields: this.fieldsToArray(), colour: this.color });
          this.loading = false;
          if (response) {
            this.$emit('close');
          }
        }
      }
    },
    async getDefinition() {
      const response = await this.fetchProjectDataType({ name: this.dataType, projectId: this.projectId });

      this.color = response.colour;
      this.initialColor = response.colour;
      const { fields } = response;
      if (typeof fields === 'object' && !Array.isArray(fields) && fields !== null) {
        Object.keys(fields).forEach((key) => {
          this.fields.push({
            key,
            dataType: fields[key].data_type,
          });
        });
      }
      this.checkFieldsForNew();
    },
    onFieldInput() {
      this.checkFieldsForNew();
    },
    checkFieldsForNew() {
      if (this.fields.length) {
        if (this.fields[this.fields.length - 1].key !== '') {
          this.addField();
        }
      } else {
        this.addField();
      }
    },
  },
  async created() {
    this.fetching = true;
    if (this.dataType && this.dataType.length) {
      this.mode = 'Edit';
      this.originalName = this.dataType;
      this.name = this.dataType;
      await this.getDefinition();
    } else {
      this.mode = 'Add';
      this.originalName = '';
      this.name = '';
      this.fields = [{ key: '', synonyms: '' }];
    }
    this.fetching = false;
  },
  watch: {
    'fields.length': {
      handler() {
        this.checkFieldsForNew();
      },
    },
  },
};
</script>
