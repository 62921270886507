<template>
  <div :data-depth="depth">
    <div v-for="(evaluateItem, index) in listItems" :key="index" class="flow-step-item-section">
      <template v-if="evaluateItem.type === 'api'">
        <FlowStepListItem icon="api" icon-class="text-pink-700">
          <span v-if="!evaluateItem.payload.variable">{{ $t('evaluate_editor.api') }}</span>
          <span v-else>{{ evaluateItem.payload.variable }}</span>
        </FlowStepListItem>
      </template>
      <template v-else-if="evaluateItem.type === 'prompt'">
        <FlowStepListItem v-if="evaluateItem.payload.variables.length" icon="capture" iconClass="mt-2 text-green-500" contentClass="-mb-2">
          <div v-for="(variable, index) in evaluateItem.payload.variables" :key="index" class="mt-0.5 inline-flex pb-2">
            <VariableChip :index="index" :name="variable.variable" class="mr-0.5" />
          </div>
        </FlowStepListItem>
      </template>
      <template v-else-if="evaluateItem.type === 'email'">
        <FlowStepListItem v-if="evaluateItem.payload" icon="email" iconClass="text-yellow-600">
          <div>
            Send Email <span class="font-600">{{ evaluateItem.payload.subject }}</span> via <span class="font-600">{{ evaluateItem.payload.email_template }}</span> template
          </div>
        </FlowStepListItem>
      </template>
      <template v-else-if="evaluateItem.type === 'transition'">
        <FlowStepListItem
          :icon="TRANSITION_ICONS[evaluateItem.payload.type]"
          :id="evaluateItem.payload.id"
          icon-class="text-blue-500"
          :invalid="getTransitionText(evaluateItem.payload, steps) === 'Invalid Step!'"
        >
          {{ getTransitionText(evaluateItem.payload, steps) }}
        </FlowStepListItem>
      </template>
      <template v-else-if="evaluateItem.type === 'condition'">
        <FlowStepListItem v-if="depth < 3" icon="code" content-class="w-full" icon-class="text-blue-500">
          <div v-for="(expression, i) in evaluateItem.payload.expressions" :key="expression.id">
            <span class="font-700">{{ getExpressionLabel(i, evaluateItem.payload.expressions.length + 1) }}</span> <span>{{ expression.value }}</span>
            <FlowStepList :depth="depth + 1" :steps="steps" :step="step" :list-items="expression.payload" />
          </div>
          <span class="font-700">{{ getExpressionLabel(1, 2) }}</span>

          <FlowStepList :depth="depth + 1" :steps="steps" :step="step" :list-items="evaluateItem.payload.else" />
        </FlowStepListItem>
      </template>
      <template v-else-if="evaluateItem.type === 'response'">
        <FlowStepListItem icon="chat" icon-class="text-primary" content-class="w-full overflow-hidden">
          <div v-if="evaluateItem.payload.text && evaluateItem.payload.text.length" class="mx-1 pr-4"><ExpressionRenderer :expression="evaluateItem.payload.text[0]" /></div>

          <div v-if="cardExist(evaluateItem)" class="-mt-1">
            <div v-if="evaluateItem.payload.card.title" class="bg-white py-1 px-2 mt-1 rounded-4">
              <ExpressionRenderer class="mr-2" :expression="evaluateItem.payload.card.title" />
            </div>
            <div v-if="evaluateItem.payload.card.text" class="bg-white py-1 px-2 mt-1 rounded-4">
              <ExpressionRenderer :expression="evaluateItem.payload.card.text" />
            </div>
          </div>

          <div v-if="evaluateItem.payload.json">
            <div v-for="(jsonItem, index) in evaluateItem.payload.json" :key="index" class="bg-white py-1 px-2 mt-1 rounded-4" :class="{ truncate: jsonItem.value.length > 100 }">
              <span>{{ jsonItem.key }}</span> :

              <template v-if="jsonItem.type === 'Variable'">
                <variable-chip :name="jsonItem.value"></variable-chip>
              </template>
              <template v-else>
                <span>{{ jsonItem.value }}</span>
              </template>
            </div>
          </div>
        </FlowStepListItem>
      </template>
    </div>
  </div>
</template>

<script>
import FlowStepListItem from '@/components/flow/FlowStepListItem';
import { getTransitionText, getTransitions, TRANSITION_ICONS, getExpressionLabel } from '@/helpers/flow';

export default {
  name: 'FlowStepList',
  props: {
    listItems: {
      type: Array,
    },
    steps: {
      type: Array,
    },
    step: {
      type: Object,
    },
    depth: {
      type: Number,
      default: 1,
    },
  },
  components: { FlowStepListItem },
  data() {
    return {
      TRANSITION_ICONS,
    };
  },
  methods: {
    getExpressionLabel,
    getTransitionText,
    getEvaluateText(text) {
      return text;
    },
    getTransitions(evaluateItem) {
      const transitions = [];
      getTransitions(evaluateItem, transitions, this.step.id);
      return transitions;
    },
    cardExist(evaluateItem) {
      return !!evaluateItem?.payload?.card && (evaluateItem?.payload?.card?.title || evaluateItem?.payload?.card?.text);
    },
  },
};
</script>

<style scoped></style>
