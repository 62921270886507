<template>
  <Modal
    sheetbelowsm
    close-button
    :title="$t('projects.ui_features.enable', { feature: featureName })"
    :primary-button="$t('enable')"
    :secondary-button="$t('cancel')"
    :primary-button-loading="loading"
    @primary="save"
    @secondary="cancel"
    @close="cancel"
  >
    <div class="sm:w-600 flex py-7 px-10 flex-wrap">
      <div class="w-full">
        <div v-if="config && config.endpoints && Object.keys(config.endpoints).length" class="mb-4">Please select the appropriate endpoint for each page.</div>
        <div class="mb-4">{{ description }}</div>

        <template v-if="feature === 'conversations' || feature === 'collections'">
          <FormSelect
            v-for="(item, itemKey) in config.endpoints"
            v-model="config.endpoints[itemKey]"
            default-reducer
            :key="item"
            :options="endpoints"
            :label="$t(`projects.ui_features.endpoints.${itemKey}`)"
            append-to-body
            class="w-full mt-2"
          />
        </template>
        <template v-else-if="feature === 'data_sources'">
          <div v-for="item in projectDataAdapters" :key="item.adapter_type" class="flex items-center w-full mt-2">
            <Checkbox :label="item.name" :model-value="hasConfigDataAdapter(item.adapter_type)" @update:modelValue="onDataAdapterChange($event, item.adapter_type)" />
          </div>
        </template>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

const FeatureConfig = {
  conversations: {
    enabled: true,
    endpoints: {
      intent_query: '',
      get_suggestions: '',
      feedback: '',
      email_card_to_user: '',
    },
  },
  collections: {
    enabled: true,
    endpoints: {
      get_suggestions: '',
      create_collection: '',
      update_collection: '',
      delete_collection: '',
      create_question: '',
      update_question: '',
      delete_question: '',
      move_question: '',
    },
  },
  profile: { enabled: true, config: {} },
  data_sources: {
    enabled: true,
    adapters: [],
  },
};

export default {
  name: 'EnableUIFeatureModal',
  data() {
    return {
      loading: false,
      selected: null,
      config: {},
    };
  },
  emits: ['save', 'close'],
  props: {
    feature: {
      type: String,
      required: true,
    },
    featureName: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    featureConfig: {
      type: Object,
    },
  },
  created() {
    this.config = this.featureConfig ? structuredClone(this.featureConfig) : structuredClone(FeatureConfig[this.feature]);
    this.config = {
      ...structuredClone(FeatureConfig[this.feature]),
      ...structuredClone(this.featureConfig),
    };
  },
  computed: {
    ...mapState(['availableDataAdapters']),
    ...mapGetters(['getProjectEndpoints', 'getProjectDataAdapters']),
    projectDataAdapters() {
      return Object.values(this.getProjectDataAdapters()).map((item) => {
        return {
          ...item,
          name: this.availableDataAdapters[item.adapter_type].name,
        };
      });
    },
    projectId() {
      return this.$route.params.projectId;
    },
    endpoints() {
      return Object.values(this.getProjectEndpoints()).map((endpoint) => ({
        label: endpoint.path,
        value: endpoint.endpoint_id,
      }));
    },
  },
  methods: {
    ...mapActions(['showToastMessage']),
    hasConfigDataAdapter(adapter) {
      return this.config.adapters?.includes(adapter) || false;
    },
    onDataAdapterChange(value, adapter) {
      if (!value) {
        this.config.adapters = this.config.adapters.filter((i) => i !== adapter);
      } else {
        this.config.adapters.push(adapter);
      }
    },
    save() {
      this.loading = true;
      this.$emit('save', this.config);
    },
    cancel() {
      this.$emit('close');
    },
  },
};
</script>
