<template>
  <div class="h-full">
    <div v-if="!fetching" class="h-full flex flex-col">
      <div class="w-full pr-3 flex-grow pl-2 pb-2 max-w-1000">
        <ValidationObserver v-slot="{ validate, errors }">
          <FormInput v-model="name" rules="required" class="w-full" :label="$t('projects.name')" name="projectName" :error="errors.projectName" />

          <FormInput v-model="brandName" rules="required" class="w-full" :label="$t('projects.brand_name')" name="brandName" :error="errors.brandName" />

          <div class="mt-4">
            <FormLabel label="Theme"></FormLabel>

            <div class="flex mt-0.5">
              <div
                v-for="(t, index) in themes"
                :key="index"
                class="mr-2 w-6 h-6 rounded-full cursor-pointer"
                :class="{ 'border-2 border-solid p-0.5 border-primary': theme === t }"
                @click="changeTheme(t)"
              >
                <div class="w-full h-full rounded-full" :style="{ backgroundColor: themeConfigs[t] }"></div>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <FormLabel label="Logo's"></FormLabel>
          </div>
          <div class="mt-2 flex max-md:flex-wrap">
            <div class="w-full md:w-1/2 flex">
              <div class="w-200 h-20 mr-3 border-dashed border-stroke border rounded-8 flex items-center justify-center bg-surface-dark">
                <div class="max-w-[256px] max-h-[64px]">
                  <img :src="logos.PROJECT" class="w-full max-h-8" />
                </div>
              </div>
              <div>
                <div class="font-500 text-12 mt-0.5">Project Logo</div>
                <div class="text-12 text-gray-500">Recommended size is 128x32px</div>
                <input type="file" name="project" ref="projectInput" class="hidden" accept="image/png,image/jpg,image/jpeg,image/svg+xml" @change="onFileInputChange($event, IMAGE_TYPES.PROJECT)" />
                <PillButton outlined icon="upload" text="Upload" class="mt-2" @click="openFile(IMAGE_TYPES.PROJECT)" />
              </div>
            </div>
            <div class="w-full md:w-1/2 flex">
              <div class="flex">
                <div class="w-200 h-20 mr-3 border-dashed border-stroke border rounded-8 flex items-center justify-center bg-primary">
                  <div class="max-w-[120px] max-h-9">
                    <img :src="logos.NAVIGATION" class="w-9 h-9 object-contain" />
                  </div>
                </div>
                <div>
                  <div class="font-500 text-12 mt-0.5">Navigation Logo</div>
                  <div class="text-12 text-gray-500">Recommended size is 36x36px</div>
                  <input
                    type="file"
                    name="navigation"
                    ref="navigationInput"
                    class="hidden"
                    accept="image/png,image/jpg,image/jpeg,image/svg+xml"
                    @change="onFileInputChange($event, IMAGE_TYPES.NAVIGATION)"
                  />
                  <PillButton outlined icon="upload" text="Upload" class="mt-2" @click="openFile(IMAGE_TYPES.NAVIGATION)" />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 flex max-md:flex-wrap">
            <div class="w-full md:w-1/2 flex">
              <div class="w-200 h-20 mr-3 border-dashed border-stroke border rounded-8 flex items-center justify-center bg-surface-dark">
                <div class="max-w-[120px] max-h-200">
                  <img :src="logos.FAVICON" class="w-4 h-4" />
                </div>
              </div>
              <div>
                <div class="font-500 text-12 mt-0.5">Favicon</div>
                <div class="text-12 text-gray-500">Recommended size is 16x16px</div>
                <input type="file" name="favicon" ref="faviconInput" class="hidden" accept="image/png,image/jpg,image/jpeg,image/svg+xml" @change="onFileInputChange($event, IMAGE_TYPES.FAVICON)" />
                <PillButton outlined icon="upload" text="Upload" class="mt-2" @click="openFile(IMAGE_TYPES.FAVICON)" />
              </div>
            </div>
            <div class="w-full md:w-1/2 flex">
              <div class="w-200 h-20 mr-3 border-dashed border-stroke border rounded-8 flex items-center justify-center bg-surface-dark">
                <div class="max-w-[120px] max-h-200">
                  <img :src="logos.CONVERSATION_BUBBLE" class="w-9 h-9 rounded-full" />
                </div>
              </div>
              <div>
                <div class="font-500 text-12 mt-0.5">Conversation Bubble Icon</div>
                <div class="text-12 text-gray-500">Recommended size is 36x36px</div>
                <input
                  type="file"
                  name="bubble_icon"
                  ref="bubbleIconInput"
                  class="hidden"
                  accept="image/png,image/jpg,image/jpeg, image/svg+xml"
                  @change="onFileInputChange($event, IMAGE_TYPES.CONVERSATION_BUBBLE)"
                />
                <PillButton outlined icon="upload" text="Upload" class="mt-2" @click="openFile(IMAGE_TYPES.CONVERSATION_BUBBLE)" />
              </div>
            </div>
          </div>

          <div>
            <PillButton class="mt-8" :text="$t('projects.update_project')" primary @click="onSaveClick(validate)" :disabled="loading" :loading="loading"></PillButton>
          </div>

          <div class="mt-10">
            <div class="text-20 font-500 mb-2">{{ $t('projects.danger_zone') }}</div>
            <div class="border border-solid border-alert border-opacity-50 rounded-8">
              <div class="flex justify-between items-center p-4">
                <div>
                  <div class="font-600">{{ isDisabled ? $t('projects.enable_this') : $t('projects.disable_this') }}</div>
                  <div>{{ isDisabled ? $t('projects.enable_warning') : $t('projects.disable_warning') }}</div>
                </div>
                <PillButton :text="isDisabled ? $t('projects.enable_this') : $t('projects.disable_this')" :error="!isDisabled" :primary="isDisabled" @click="onDisableChange"></PillButton>
              </div>
              <Divider dense />
              <div class="flex justify-between items-center p-4">
                <div>
                  <div class="font-600">{{ $t('projects.delete_this') }}</div>
                  <div>{{ $t('projects.delete_warning') }}</div>
                </div>
                <PillButton :text="$t('projects.delete_this')" error @click="openDeleteProjectModal"></PillButton>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
    <Modal v-if="fetching">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import DeleteModal from '@shared/components/shared-modals/DeleteModal.vue';
import { apiPutProjectLogo } from '@/helpers/api';

export default {
  name: 'Settings',
  data() {
    return {
      name: '',
      brandName: '',
      theme: 'default',
      themes: ['default', 'darkbluegray', 'steelblue', 'orange'],
      themeConfigs: {
        default: '#5B47C2',
        darkbluegray: '#243746',
        steelblue: '#5497C2',
        orange: '#E69B56',
      },
      loading: false,
      fetching: true,
      projectLogo: process.env.VUE_APP_LOGO,
      navigationLogo: process.env.VUE_APP_NAVIGATION_LOGO,
      favicon: process.env.VUE_APP_FAVICON,
      bubbleIcon: process.env.VUE_APP_BUBBLE_ICON,
      IMAGE_TYPES: {
        PROJECT: 'PROJECT',
        NAVIGATION: 'NAVIGATION',
        FAVICON: 'FAVICON',
        CONVERSATION_BUBBLE: 'CONVERSATION_BUBBLE',
      },
      logos: {},
    };
  },
  computed: {
    ...mapState(['projects']),
    ...mapGetters(['getProjectLogos']),
    project() {
      return this.projects[this.$route.params.projectId];
    },
    projectId() {
      return this.$route.params.projectId;
    },
    isDisabled() {
      return this.project.disabled;
    },
  },
  beforeUnmount() {
    this.changeTheme(this.project?.theme || 'default');
  },
  methods: {
    ...mapActions(['fetchProject', 'updateProject', 'deleteProject', 'showToastMessage', 'fetchProjectLogos', 'updateProjectDisabled']),
    changeTheme(theme) {
      this.theme = theme;
      document.body.classList.forEach((className) => {
        if (className.startsWith('theme--')) document.body.classList.remove(className);
      });
      document.body.classList.add(`theme--${theme}`);
    },
    openFile(type) {
      if (type === this.IMAGE_TYPES.CONVERSATION_BUBBLE) {
        this.$refs.bubbleIconInput.click();
      } else if (type === this.IMAGE_TYPES.FAVICON) {
        this.$refs.faviconInput.click();
      } else if (type === this.IMAGE_TYPES.NAVIGATION) {
        this.$refs.navigationInput.click();
      } else {
        this.$refs.projectInput.click();
      }
    },
    onFileInputChange(evt, type) {
      const file = evt.target.files[0];
      if (!file) return;
      const reader = new FileReader();
      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        const response = await apiPutProjectLogo({ project_id: this.projectId, file_type: type, type: file.type, file_name: file.name, file: arrayBuffer });
        if (response.status === 200) {
          this.logos[type] = response.data.url;
        } else {
          this.showToastMessage({ message: this.$t('projects.error_updating_logo'), type: 'error' });
        }
      };
      reader.readAsArrayBuffer(file);
    },
    async onSaveClick(validate) {
      this.loading = true;
      if (validate) {
        await validate();
      }
      const { project_id, disabled } = this.project;
      await this.updateProject({ name: this.name.trim(), brand_name: this.brandName.trim(), project_id, disabled, theme: this.theme });
      this.loading = false;
    },
    openDeleteProjectModal(project) {
      this.$showModal(DeleteModal, {
        subtitle: this.$t('projects.remove_project', { projectName: project.name }),
        info: null,
        onConfirm: () => this.onDeleteClicked(project),
      });
    },
    async onDeleteClicked() {
      this.loading = true;
      const res = await this.deleteProject(this.project.project_id);
      if (res) {
        this.$router.push('/project');
      }
      this.loading = false;
    },
    onDisableChange() {
      const subtitle = !this.project.disabled ? this.$t('projects.disable', { project: this.project.name }) : this.$t('projects.enable', { project: this.project.name });
      this.$showModal(DeleteModal, {
        subtitle,
        type: this.project.disabled ? 'primary' : 'error',
        onConfirm: async () => {
          await this.updateProjectDisabled({ project_id: this.projectId, disabled: !this.project.disabled });
        },
      });
    },
  },
  watch: {
    projectId: {
      async handler() {
        this.name = this.project?.name;
        this.brandName = this.project?.brand_name || this.project?.name || '';
        this.theme = this.project?.theme || this.theme;
        this.changeTheme(this.theme);
        this.fetching = true;
        await this.fetchProject({ projectId: this.projectId });
        // await this.fetchProjectLogos({ project_id: this.projectId });
        this.logos.PROJECT = this.getProjectLogos()?.[this.IMAGE_TYPES.PROJECT] || this.projectLogo;
        this.logos.NAVIGATION = this.getProjectLogos()?.[this.IMAGE_TYPES.NAVIGATION] || this.navigationLogo;
        this.logos.FAVICON = this.getProjectLogos()?.[this.IMAGE_TYPES.FAVICON] || this.favicon;
        this.logos.CONVERSATION_BUBBLE = this.getProjectLogos()?.[this.IMAGE_TYPES.CONVERSATION_BUBBLE] || this.bubbleIcon;
        this.fetching = false;
      },
      immediate: true,
    },
  },
};
</script>
