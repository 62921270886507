<template>
  <div class="min-h-full flex flex-col items-center justify-start bg-surface-dark" v-if="bootstrapped">
    <div class="fixed inset-0 pointer-events-none">
      <div id="circle-top"></div>
      <div id="circle-bottom"></div>
    </div>

    <div class="z-0 flex w-full">
      <div
        class="sidebar absolute min-h-full z-1 max-h-screen overflow-auto left-0 top-0 bottom-0 hidden lg:flex lg:flex-col transition-width ease-in duration-150 py-4 pl-4"
        :class="{ 'lg:w-sidebar--opened pl-4': leftMenuOpened, 'lg:w-sidebar': !leftMenuOpened }"
      >
        <Navigation v-model:open="leftMenuOpened" :navItems="navItems" :logo="navigationLogo" />
      </div>
      <div
        v-if="showTopBar"
        class="top-bar fixed z-10 top-0 right-0 transition-all ease-in duration-150 left-0"
        :class="{ 'lg:left-top-bar--opened': leftMenuOpened, 'lg:left-top-bar': !leftMenuOpened }"
        style="background-color: #f5f3fe"
      >
        <div class="w-full pt-3 pb-3 absolute flex items-center z-0 px-6 min-h-16 bg-surface-light">
          <slot name="header" />
          <div class="flex ml-auto items-center">
            <Quota v-if="showQuota && false" class="mr-2 sm:mr-4" @click="onQuotaClick" />
            <Icon name="faq" class="w-5 h-5 cursor-pointer mr-4" @click="SET_SHOW_SIDE_PANEL(true)" />
            <Icon name="notification" class="w-6 h-6 cursor-pointer" @click="showNotificationPopup = true" v-outside-click="closeNotificationPopup" />

            <Notifications v-if="showNotificationPopup" />
          </div>
        </div>
      </div>
      <div
        class="main-content w-full h-screen flex flex-col"
        :class="{ 'lg:pl-main-content--opened': leftMenuOpened, 'lg:pl-main-content': !leftMenuOpened, 'pt-14 lg:pt-14': showTopBar, 'lg:pt-4': !showTopBar }"
      >
        <div class="flex-grow flex flex-col overflow-auto">
          <slot></slot>
        </div>
        <div class="h-14 inline lg:hidden bottom-navigation">
          <BottomNavigation />
        </div>
      </div>
    </div>

    <SidePanel />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { FEATURES } from '@/constants';
import SidePanel from '@/components/help/SidePanel.vue';

export default {
  name: 'DefaultLayout',
  components: { SidePanel },
  props: {
    showTopBar: Boolean,
    showAccounts: Boolean,
  },
  data() {
    return {
      leftMenuOpened: false,
      showNotificationPopup: false,
      navItems: [
        { label: 'navigation.dashboard', to: '/', icon: 'dashboard' },
        { label: 'navigation.projects', to: '/project', icon: 'code_off', restrictTo: FEATURES.PROJECTS },
        { label: 'navigation.settings', to: '/user/settings', icon: 'settings', restrictTo: FEATURES.SETTINGS },
        { label: 'navigation.admin', to: '/admin', icon: 'admin_panel_settings', restrictTo: FEATURES.ADMIN_DASHBOARD },
        { label: 'navigation.logout', to: '/logout', icon: 'logout' },
      ],
    };
  },
  computed: {
    ...mapState(['bootstrapped', 'features']),
    navigationLogo() {
      return process.env.VUE_APP_NAVIGATION_LOGO;
    },
    showQuota() {
      return this.features.includes(FEATURES.SETTINGS_SUBSCRIPTION);
    },
  },
  methods: {
    ...mapMutations(['SET_SHOW_SIDE_PANEL']),
    onQuotaClick() {
      this.$router.push('/user/subscriptions');
    },
    closeNotificationPopup() {
      this.showNotificationPopup = false;
    },
  },
};
</script>
<style lang="scss" scoped>
#circle-top {
  position: absolute;
  width: 884px;
  height: 884px;
  left: 665px;
  top: -525px;
  background: #f1eefd;
  filter: blur(200px);
}
#circle-bottom {
  position: absolute;
  width: 698px;
  height: 698px;
  left: 674px;
  top: 665px;
  background: #ccd9ff;
  filter: blur(350px);
}
</style>
