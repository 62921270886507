<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-3 items-center flex-shrink-0 sm:pr-10">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-primary font-600">{{ $t('projects.templates.title') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <PillButton :text="$t('add_new')" primary @click="onCreateClicked" />
        </div>
      </div>
      <div class="w-full flex-grow overflow-hidden pb-2">
        <template v-if="templates.length">
          <TableElevated :items="templates" :headers="templateHeaders" class="text-13 pr-3 sm:pr-10">
            <template v-slot:[`item.name`]="{ value }">
              <span class="text-13 font-500 truncate">{{ value }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <IconButton icon="edit" class="mr-1 text-primary" @click="onEditClicked(item)" />
              <IconButton class="text-primary" icon="delete" @click="onDeleteClicked(item)" />
            </template>
          </TableElevated>
        </template>
        <div v-else class="flex flex-col h-full flex-grow items-center justify-center pb-50">
          <div class="my-2 text-22 text-gray-700">{{ $t('projects.templates.have_no_templates') }}</div>
          <div class="my-2 text-gray-600">{{ $t('projects.templates.create_first_template') }}</div>
          <PillButton class="my-3" :text="$t('projects.templates.create_new')" primary @click="onCreateClicked" />
        </div>
      </div>
    </div>
    <Modal v-if="fetching">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CreateTemplateModal from '@/components/project/CreateTemplateModal';
import DeleteModal from '@shared/components/shared-modals/DeleteModal.vue';

export default {
  name: 'EmailTemplates',
  data() {
    return {
      fetching: false,
    };
  },
  computed: {
    ...mapState(['projectData']),
    projectId() {
      return this.$route.params.projectId;
    },
    templates() {
      const templates = this.projectData?.[this.projectId]?.templates || {};
      return Object.values(templates);
    },
    templateHeaders() {
      return [
        { key: 'name', title: this.$t('name') },
        { key: 'actions', title: this.$t('actions'), width: '50px' },
      ];
    },
  },
  methods: {
    ...mapActions(['fetchProjectTemplates', 'showToastMessage', 'deleteProjectTemplate']),
    onCreateClicked() {
      this.$showModal(CreateTemplateModal, { projectId: this.projectId });
    },
    onEditClicked(template) {
      this.$showModal(CreateTemplateModal, { projectId: this.projectId, templateId: template.template_id });
    },
    onDeleteClicked(emailTemplate) {
      this.$showModal(DeleteModal, {
        subtitle: this.$t('projects.email_template.remove_template', { templateName: emailTemplate.name }),
        info: null,
        onConfirm: async () => {
          await this.onDeleteConfirmClicked(emailTemplate);
        },
      });
    },
    async onDeleteConfirmClicked(item) {
      const response = await this.deleteProjectTemplate({ project_id: this.projectId, template_id: item.template_id });
      if (response) {
        await this.fetchProjectTemplates({ projectId: this.projectId });
      }
    },
  },
  watch: {
    projectId: {
      async handler() {
        this.fetching = true;
        await this.fetchProjectTemplates({ projectId: this.$route.params.projectId });
        this.fetching = false;
      },
      immediate: true,
    },
  },
};
</script>
