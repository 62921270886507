<template>
  <Modal
    sheetbelowsm
    :title="$t('projects.data_adapters.table')"
    :primary-button="$t('save')"
    :primary-button-disabled="loading"
    :secondary-button="$t('cancel')"
    :primary-button-loading="loading"
    close-button
    :loading="fetching"
    @primary="onSaveClicked"
    @close="onCancelClicked"
    @secondary="onCancelClicked"
  >
    <ValidationObserver ref="validationObserver" v-slot="{ errors }" class="w-full">
      <div class="sm:w-600">
        <div class="w-full flex justify-start mt-5 pl-10 pr-17 gap-3">
          <FormInput v-model="name" :label="$t('data_types.name')" name="name" :error="errors.name" :rules="['required']" :readonly="mode === 'Edit'" class="w-full" />
        </div>
        <div class="w-full flex flex-col justify-start mt-5 px-10">
          <div class="flex w-full">
            <div class="max-md:w-full flex-grow sm:mr-2 mb-1 ml-6">{{ $t('projects.data_adapters.column_name') }}</div>
            <div class="sm:mr-2 mb-1 max-md:flex-grow max-md:w-full sm:w-[120px] sm:flex-shrink-0">{{ $t('data_type') }}</div>
            <div class="max-md:w-full flex-grow sm:ml-2 mb-1">{{ $t('projects.data_adapters.aggregation') }}</div>
            <div class="ml-2 w-4 h-4"></div>
          </div>

          <Draggable
            v-model="fields"
            itemKey="id"
            v-bind="dragOptions"
            :component-data="{ tag: 'div', type: 'transition-group', name: !drag ? 'flip-list' : null }"
            handle=".drag-handle"
            @start="drag = true"
            @end="drag = false"
          >
            <template #item="{ element: item, index }">
              <div class="flex items-center py-1">
                <div class="flex items-center w-full flex-wrap sm:flex-nowrap mb-1 sm:mb-0 -mt-1">
                  <div class="drag-handle mr-2 cursor-grab"><Icon name="drag_indicator" class="w-4 h-4" /></div>
                  <FormInput v-model="item.key" class="max-md:w-full flex-grow sm:mr-2 mb-1" :placeholder="$t('data_types.field')" @update:modelValue="onFieldInput" />
                  <FormSelect
                    v-model="item.dataType"
                    append-to-body
                    :options="fieldDataTypes"
                    :reduce="(opt) => opt.value"
                    class="sm:mr-2 mb-1 max-md:flex-grow max-md:w-full sm:w-[120px] sm:flex-shrink-0"
                    :placeholder="$t('data_type')"
                    @update:modelValue="onFieldInput"
                  />
                  <FormInput v-model="item.mapping" class="max-md:w-full flex-grow sm:mr-2 mb-1" :placeholder="$t('data_types.mapping')" @update:modelValue="onFieldInput" />
                </div>
                <Icon :class="{ invisible: item.mapping === '' }" name="clear" class="ml-2 w-4 h-4 cursor-pointer" @click="removeField(index)" />
              </div>
            </template>
          </Draggable>

          <p v-if="fieldsError.length" class="text-red-600 text-13 text-left pt-2 pl-0">{{ fieldsError }}</p>
        </div>
      </div>
    </ValidationObserver>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'EditProjectDataAdapterTable',
  props: {
    dataType: Object,
    tables: Object,
    adapterType: String,
  },
  emits: ['close'],
  data() {
    return {
      mode: 'Add',
      originalName: '',
      initialColor: '',
      color: '',
      name: '',
      mapping: '',
      fields: [],
      loading: false,
      fetching: false,
      drag: false,
      colors: ['#008FFB', '#A5978B', '#ff657c', '#D7263D', '#4ECDC4', '#449DD1', '#238180', '#F86624', '#FF9800', '#3F51B5', '#6000b5', '#b200ea', '#4CAF50'],
    };
  },
  computed: {
    ...mapState(['projectData']),
    projectId() {
      return this.$route.params.projectId;
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'drag-ghost',
      };
    },
    dataTypes() {
      return this.projectData?.[this.projectId]?.datatypes || {};
    },
    fieldDataTypes() {
      return [
        { label: 'Date', value: 'Date' },
        { label: 'Number', value: 'Number' },
        { label: 'Text', value: 'Text' },
        { label: 'YesNo', value: 'YesNo' },
      ];
    },
    fieldsError() {
      if (!this.isFieldKeysUnique) {
        return this.$t('data_types.fields_must_be_unique');
      }
      return false;
    },
    isFieldKeysUnique() {
      // Check keys
      const keySet = new Set(this.fields.map((field) => field.key.toLowerCase()));
      if (this.fields.length === keySet.size) {
        return true;
      }
      return false;
    },
    tablesPayload() {
      const tables = structuredClone(this.tables || {});
      tables[this.dataType.m].f = this.fields
        .filter((i) => !!i.key && !!i.mapping)
        .reduce((acc, item) => {
          acc[item.key] = {
            m: item.mapping,
            dt: item.dataType,
          };
          return acc;
        }, {});
      return tables;
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchProjectDataType', 'createProjectDatatype', 'updateProjectDatatype', 'updateProjectDataAdapterTables']),
    addField() {
      this.fields.push({ key: '', value: '', dataType: '' });
    },
    removeField(index) {
      this.fields.splice(index, 1);
    },
    fieldsToArray() {
      const fields = {};
      this.fields.forEach((field) => {
        if (field.key.trim()) {
          fields[field.key] = {
            data_type: field.dataType,
          };
        }
      });
      return fields;
    },
    onCancelClicked() {
      this.$emit('close');
    },
    async onSaveClicked() {
      const valid = await this.$refs.validationObserver.validate();
      if (valid.valid && this.isFieldKeysUnique) {
        this.loading = true;
        await this.updateProjectDataAdapterTables({ project_id: this.projectId, adapter_type: this.adapterType, tables: this.tablesPayload });
        this.loading = false;
        this.$emit('close');
      }
    },
    onFieldInput() {
      this.checkFieldsForNew();
    },
    checkFieldsForNew() {
      if (this.fields.length) {
        if (this.fields[this.fields.length - 1].key !== '') {
          this.addField();
        }
      } else {
        this.addField();
      }
    },
  },
  async created() {
    this.fetching = true;
    if (this.dataType) {
      this.mode = 'Edit';
      this.originalName = this.dataType.m;
      this.name = this.dataType.m;
      this.fields = Object.keys(this.dataType.f).map((key) => ({ key, mapping: this.dataType.f[key].m, dataType: this.dataType.f[key].dt }));
      // await this.getDefinition();
    } else {
      this.mode = 'Add';
      this.originalName = '';
      this.name = '';
      // this.fields = [{ key: '', synonyms: '' }];
    }
    this.fetching = false;
  },
  watch: {
    'fields.length': {
      handler() {
        this.checkFieldsForNew();
      },
    },
  },
};
</script>
