<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-4 items-center flex-shrink-0 pl-2">
        <div class="ml-auto flex flex-row justify-center items-center">
          <Spinner v-show="releasesLoading || publishLoading" class="w-5 mr-3" />
          <PillButton class="ml-auto" :text="polling ? $t('projects.models.build.publish_in_progress') : $t('projects.models.build.build')" :disabled="polling" primary @click="openBuildModal" />
          <PillButton v-if="['Queued', 'Running', 'Validating Files'].includes(lastVersionStatus)" :disabled="releaseCancelling" class="ml-2" :text="$t('cancel')" error @click="cancelRelease" />
        </div>
      </div>
      <div class="w-full flex-grow overflow-auto pr-1 pl-2 pb-2">
        <div class="pt-1 justify-items-center">
          <CollapseCard
            v-for="(release, index) in releases"
            :key="release.version"
            :value="false"
            :showIcon="false"
            dense
            :scroll="false"
            ref="collapseCard"
            class="shadow-card mb-4"
            @change="onCollapseCardChange(release, $event, index)"
          >
            <template #header>
              <div class="flex w-full max-lg:py-2 flex-col lg:flex-row justify-between items-start lg:items-center">
                <div class="flex justify-between items-center w-full lg:w-auto">
                  <span class="font-500 whitespace-nowrap mr-2">{{ $t('publishing.version_x', { version: release.version }) }}</span>
                  <Badge class="inline lg:hidden" :text="release.status" v-bind="{ [getStatusBadgeType(release.status)]: true }" />
                </div>
                <div class="flex items-center flex-wrap max-lg:w-full">
                  <div class="flex flex-shrink-0 items-center justify-between max-lg:w-full">
                    <div class="mr-5 text-12 w-[120px]">{{ dayjs(parseInt(release.timestamp)).format('MMM DD, YYYY HH:mm') }}</div>
                    <div class="w-25 flex-shrink-0 flex items-center justify-center">
                      <Badge class="hidden lg:inline" :text="getStatusText(release.status)" v-bind="{ [getStatusBadgeType(release.status)]: true }" />
                    </div>
                    <IconButton icon="expand_more" small></IconButton>
                  </div>
                </div>
              </div>
            </template>

            <template #content>
              <div class="px-4 flex max-lg:flex-wrap text-12 border-t border-stroke border-solid pt-2 border-opacity-20">
                <div class="flex-grow lg:w-1/2 max-lg:order-1">
                  <div class="font-500">User</div>
                  <div class="truncate">{{ release.user }}</div>
                </div>
                <div class="w-full lg:w-1/2 max-lg:order-3">
                  <div class="font-500">Provider</div>
                  <div>{{ release.provider }}</div>
                </div>
                <div class="w-full lg:w-1/2 max-lg:order-4">
                  <div class="font-500">Base Model</div>
                  <div>{{ release.baseModel }}</div>
                </div>
                <div class="w-[100px] max-lg:order-2 flex items-center justify-end">
                  <IconButton
                    v-if="['Succeeded', 'Failed', 'Cancelled'].includes(release.status)"
                    primary
                    icon="delete"
                    dense
                    size="4"
                    class="ml-2 w-8 text-red-600"
                    :text="$t('delete')"
                    error
                    small
                    @click="onDeleteClick(release)"
                  />
                </div>
              </div>
              <div class="w-[100px] flex justify-end flex-shrink-0 font-500"></div>
              <div class="px-4 mb-3 flex text-12">
                <div class="w-[100px] flex justify-end flex-shrink-0 -mt-3"></div>
              </div>
              <div class="px-4 max-h-40 overflow-auto mb-3 border-t border-stroke border-solid pt-2 border-opacity-20">
                <div v-if="releaseEvents[release.version] && releaseEvents[release.version].loading" class="flex flex-col font-700 items-center justify-center px-20 my-3">
                  <div class="text-12">{{ $t('publishing.fetching_logs') }}</div>
                  <Icon name="loading_dots" class="w-14 mt-2 text-primary" />
                </div>
                <div v-if="releaseEvents[release.version] && releaseEvents[release.version].data">
                  <div v-for="item in releaseEvents[release.version].data" :key="item.id" class="flex text-12 min-h-[23px] items-start">
                    <div class="flex items-center">
                      <div class="w-1 h-1 bg-gray-700 rounded-full mr-3"></div>
                      <div class="mr-2">{{ dayjs(item.created_at * 1000).format('HH:mm') }}</div>
                    </div>
                    <div class="mr-2">{{ item.message }}</div>
                  </div>
                </div>
                <div v-else-if="releaseEvents[release.version] && releaseEvents[release.version].error">
                  <div class="text-center text-12 font-600 my-3">{{ releaseEvents[release.version].error }}</div>
                </div>
              </div>
            </template>
          </CollapseCard>
        </div>
      </div>
    </div>

    <Modal
      v-if="showPublishModal"
      title="Build New Version"
      :primary-button="$t('projects.models.build.build')"
      :secondary-button="$t('cancel')"
      :primary-button-loading="publishLoading"
      :secondary-button-disabled="publishLoading"
      close-button
      sheetbelowsm
      @primary="onPublishClick"
      @close="showPublishModal = false"
      @secondary="showPublishModal = false"
    >
      <ValidationObserver ref="validationObserver" v-slot="{ errors }" class="w-full md:w-600">
        <div class="flex flex-col items-start justify-start mt-5 px-10">
          <div class="flex w-full">
            <FormInput :modelValue="nextVersion" label="Version" disabled class="w-full"></FormInput>
          </div>
          <div class="flex w-full mt-3">
            <FormSelect
              v-model="provider"
              label="Provider"
              :options="providers"
              :reduce="(opt) => opt.value"
              rules="required"
              :error="errors.provider"
              name="provider"
              append-to-body
              class="w-full"
            ></FormSelect>
          </div>
          <div class="flex w-full mt-3">
            <FormSelect
              v-model="baseModel"
              label="Base Model"
              :options="baseModels"
              :reduce="(opt) => opt.value"
              rules="required"
              :error="errors.baseModel"
              name="baseModel"
              append-to-body
              class="w-full"
            ></FormSelect>
          </div>
        </div>
      </ValidationObserver>
    </Modal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapState } from 'vuex';
import { apiDeleteProjectModelBuild, apiGetProjectModelBuild, apiGetProjectModelBuildEvents, apiGetProjectModelBuilds, apiPostProjectModelBuild, apiPostProjectModelBuildCancel } from '@/helpers/api';
import DeleteModal from '@shared/components/shared-modals/DeleteModal.vue';

export default {
  name: 'ModelBuild',
  props: {
    model: {
      type: Object,
    },
  },
  data() {
    return {
      releases: [],
      collapseCardState: {},
      showPublishModal: false,
      trainModel: false,
      polling: false,
      pollingInterval: null,
      publishLoading: false,
      releasesLoading: false,
      releaseCancelling: false,
      releaseEvents: {},
      releaseEventPollingId: null,
      releaseEventPollingInterval: null,
      buildStatusPollingId: null,
      buildStatusPollingInterval: null,
      provider: null,
      baseModel: null,
    };
  },
  computed: {
    ...mapState(['availableAIProviders']),
    providers() {
      const providers = Object.keys(this.availableAIProviders || {}).filter((p) => this.availableAIProviders[p]?.finetune?.length);
      return providers.map((p) => ({ label: p, value: p }));
    },
    baseModels() {
      const models = this.availableAIProviders?.[this.provider]?.finetune || [];
      return models.map((m) => ({ label: m, value: m }));
    },
    nextVersion() {
      const versions = this.releases.map((r) => parseInt(r.version, 10));
      return Number.isInteger(Math.max(...versions)) ? Math.max(...versions) + 1 : 1;
    },
    lastVersionStatus() {
      return this.releases?.[0]?.status;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    modelId() {
      return this.$route.params.modelId;
    },
  },
  async created() {
    this.pollingInterval = setInterval(() => {
      if (this.polling) {
        if (this.releases.length) {
          const release = this.releases[0];
          if (!['Succeeded', 'Failed', 'Cancelled'].includes(release.status)) {
            this.getBuildStatus(release.model_id, release.version);
          }
        }
      }
    }, 5000);
  },
  beforeUnmount() {
    clearInterval(this.pollingInterval);
    clearInterval(this.releaseEventPollingInterval);
    clearInterval(this.buildStatusPollingInterval);
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchProjectAvailableAIProviders']),
    dayjs,
    openBuildModal() {
      this.showPublishModal = true;
      this.provider = null;
      this.baseModel = null;
    },
    async onDeleteClick(release) {
      this.$showModal(DeleteModal, {
        subtitle: this.$t('projects.models.build.confirm_delete', { version: release.version }),
        onConfirm: async () => {
          await this.onDeleteConfirm(release);
        },
      });
    },
    async onDeleteConfirm(release) {
      try {
        const response = await apiDeleteProjectModelBuild({ project_id: this.projectId, model_id: this.modelId, version: release.version });
        if (response.status === 200) {
          await this.fetchReleases();
        } else {
          this.showToastMessage({ title: this.$t('projects.models.build.remove_failed'), type: 'error' });
        }
      } catch (e) {
        this.showToastMessage({ title: this.$t('projects.models.build.remove_failed'), type: 'error' });
      }
    },
    async getBuildStatus(modelId, version) {
      try {
        const response = await apiGetProjectModelBuild({ project_id: this.projectId, model_id: modelId, version });
        if (response.status === 200 && response.data) {
          this.releases[0] = response.data;
        } else {
          this.showToastMessage({ title: response?.data?.message || this.$t('projects.models.build.failed_to_get_build_status'), type: 'error' });
        }
      } catch (e) {
        this.showToastMessage({ title: this.$t('projects.models.build.failed_to_get_build_status'), type: 'error' });
      }
    },
    startEventPolling(build) {
      if (this.releaseEventPollingInterval) {
        clearInterval(this.releaseEventPollingInterval);
      }
      this.releaseEventPollingId = build.version;
      this.getReleaseEvents(build);
      this.releaseEventPollingInterval = setInterval(() => {
        this.getReleaseEvents(build);
      }, 3000);
    },
    onCollapseCardChange(release, open, index) {
      this.collapseCardState[release.timestamp] = !!open;
      if (!open) {
        clearInterval(this.releaseEventPollingInterval);
        this.releaseEventPollingId = null;
      }
      if (open && index === 0 && ['Queued', 'Running', 'Validating Files'].includes(this.lastVersionStatus)) {
        this.startEventPolling(release);
      } else if (open && !this.releaseEvents?.[release.version]?.data) {
        this.getReleaseEvents(release);
      }
    },
    getStatusText(status) {
      return this.$t(`projects.models.build.status.${status}`);
    },
    getStatusBadgeType(status) {
      if (status === 'Failed') {
        return 'error';
      }
      if (['Queued', 'Running', 'Validating Files'].includes(status)) {
        return 'info';
      }
      if (status === 'Succeeded') {
        return 'success';
      }
      return 'warn';
    },
    async onPublishClick() {
      const valid = await this.$refs.validationObserver.validate();
      if (!valid.valid) return;
      this.publishLoading = true;
      apiPostProjectModelBuild({
        project_id: this.projectId,
        model_id: this.modelId,
        version: this.nextVersion,
        provider: this.provider,
        baseModel: this.baseModel,
      })
        .then(async (response) => {
          this.trainModel = false;
          this.publishLoading = false;
          if (response.status === 200) {
            this.showPublishModal = false;
            this.showToastMessage({ message: this.$t('projects.models.build.version_initiated', { version: response.data.version }), type: 'success' });
            await this.fetchReleases();
          } else {
            this.publishLoading = false;
            this.showToastMessage({ title: response.data.message || this.$t('projects.models.build.build_failed'), type: 'error' });
          }
        })
        .catch(() => {
          this.trainModel = false;
          this.publishLoading = false;
          this.showToastMessage({ title: this.$t('projects.models.build.build_failed'), type: 'error' });
        });
    },
    async getReleaseEvents(build) {
      if (!this.releaseEvents?.[build.version]?.data) {
        this.releaseEvents[build.version] = { loading: true, data: null };
      }
      try {
        const response = await apiGetProjectModelBuildEvents({ project_id: this.projectId, model_id: build.model_id, version: build.version });
        if (response.status === 200) {
          this.releaseEvents[build.version] = { loading: false, data: response.data.data };
        } else {
          this.releaseEvents[build.version] = { loading: false, error: response?.data?.message || this.$t('publishing.failed_to_get_logs') };
          this.showToastMessage({ title: response?.data?.message || this.$t('publishing.failed_to_get_logs'), type: 'error' });
        }
      } catch {
        this.showToastMessage({ title: this.$t('publishing.failed_to_get_logs'), type: 'error' });
      }
    },
    async fetchReleases() {
      try {
        this.releasesLoading = true;
        const response = await apiGetProjectModelBuilds(this.projectId, this.modelId);
        this.releases = response?.data || [];
        this.releasesLoading = false;
      } catch {
        this.releasesLoading = false;
      }
    },
    async cancelRelease() {
      this.releaseCancelling = true;
      try {
        const { version } = this.releases?.[0] || {};
        const response = await apiPostProjectModelBuildCancel({
          project_id: this.projectId,
          model_id: this.modelId,
          version,
        });
        if (response.status === 200) {
          this.fetchReleases();
          this.showToastMessage({ message: this.$t('publishing.cancelling'), type: 'success' });
        } else {
          this.showToastMessage({ title: response?.data?.message || this.$t('projects.models.build.failed_to_cancel_release'), type: 'error' });
        }
      } catch {
        this.showToastMessage({ title: this.$t('projects.models.build.failed_to_cancel_release'), type: 'error' });
      }
      this.releaseCancelling = false;
    },
    clearCollapseCardStates() {
      this.releases.forEach((release) => {
        this.collapseCardState[release.timestamp] = false;
      });
    },
  },
  watch: {
    modelId: {
      async handler() {
        this.fetchProjectAvailableAIProviders();
        await this.fetchReleases();
        this.clearCollapseCardStates();
      },
      immediate: true,
    },
    lastVersionStatus: {
      handler() {
        const values = ['Succeeded', 'Failed', 'Cancelled'];
        if (this.lastVersionStatus && !values.includes(this.lastVersionStatus)) {
          this.polling = true;
        } else {
          this.polling = false;
          clearInterval(this.releaseEventPollingInterval);
        }

        if (['Queued', 'Running', 'Validating Files'].includes(this.lastVersionStatus)) {
          if (this.releases?.[0].timestamp && this.collapseCardState[this.releases[0].timestamp]) {
            this.startEventPolling(this.releases[0]);
          }
        }
      },
      immediate: true,
    },
  },
};
</script>
