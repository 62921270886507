<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex flex-row">
        <div class="w-full flex flex-col justify-center items-center">
          <div class="py-10">
            <img :src="logo" class="w-30" :alt="appName" />
          </div>

          <div class="w-full max-sm:mr-4 sm:w-400 flex sm:mt-10">
            <v-select
              class="language-selector ml-auto"
              transparent
              :model-value="locale"
              :searchable="false"
              :options="languages"
              :clearable="false"
              :reduce="(option) => option.value"
              @update:modelValue="onLanguageChange"
            ></v-select>
          </div>
          <div class="flip w-full pb-10 sm:max-w-400 md:max-w-400 min-h-400" style="perspective: 600px">
            <div class="flip-content w-full h-full" :class="{ 'flipped-content': activeView !== VIEW.LOGIN }" style="transition: transform 0.4s; transform-style: preserve-3d">
              <div class="flip-front absolute w-full h-full">
                <Card
                  class="sm:mb-20 w-full pb-10 sm:max-w-500 md:max-w-500"
                  :class="{ 'min-h-[250px]': loginProviders.length === 1, 'min-h-300': loginProviders.length === 2, 'min-h-400': loginProviders.length > 2 }"
                >
                  <div class="w-300 flex flex-col flex-grow justify-between">
                    <h3 class="my-4 mb-1 sm:mt-8 sm:mb-4 px-5 text-20 sm:text-26 text-center">{{ $t('login.title') }}</h3>

                    <ValidationObserver v-if="loginProviders.includes(LOGIN_PROVIDERS.PASSWORD)" v-slot="{ validate, errors }">
                      <FormInput
                        v-model="email"
                        name="email"
                        add-name-attr
                        rules="required|email"
                        :error="errors && errors['email']"
                        :label="$t('email_address')"
                        type="email"
                        :disabled="loading"
                        @keyup.enter.stop="onContinueClick(validate)"
                      />

                      <FormInput
                        v-if="showPasswordField"
                        class="mt-5"
                        id="password"
                        name="password"
                        add-name-attr
                        rules="required"
                        :error="errors && errors['password']"
                        v-model="password"
                        :label="$t('login.password')"
                        type="password"
                        @keyup.enter.stop="onContinueClick(validate)"
                      />

                      <PillButton primary :loading="loading" :disabled="loading" hide-text-on-loading :text="$t('login.continue')" class="mt-2 px-6 w-full" @click="onContinueClick(validate)" />
                    </ValidationObserver>

                    <div v-if="loginProviders.includes(LOGIN_PROVIDERS.PASSWORD)" class="flex justify-center items-center mt-4">
                      <div class="bg-gray-200 flex-grow h-px"></div>
                      <div class="px-2 text-gray-400 text-12">{{ $t('or') }}</div>
                      <div class="bg-gray-200 flex-grow h-px"></div>
                    </div>

                    <PillButton
                      v-if="loginProviders.includes(LOGIN_PROVIDERS.GOOGLE)"
                      outlined
                      @click="googleSignin"
                      icon="google_logo"
                      :text="$t('login.continue_with_google')"
                      class="mt-4 px-6 w-full"
                      :class="{ 'pointer-events-none': loading }"
                    />
                    <template v-if="!isAssistant && loginProviders.includes(LOGIN_PROVIDERS.MAGIC_LINK)">
                      <PillButton
                        outlined
                        :text="$t('login.continue_with_magic_link')"
                        class="mt-4 mb-1 px-6 w-full"
                        :class="{ 'pointer-events-none': loading }"
                        @click="switchForm(VIEW.LOGIN_WITH_LINK)"
                      />
                      <div class="text-12 text-gray-600 mb-1 text-center">{{ $t('login.we_will_send_you_an_email') }}</div>
                    </template>
                    <div
                      class="text-center mt-5 flex items-center px-4"
                      :class="{
                        'justify-between': signupEnabled && loginProviders.includes(LOGIN_PROVIDERS.PASSWORD),
                        'justify-center': !signupEnabled || !loginProviders.includes(LOGIN_PROVIDERS.PASSWORD),
                      }"
                    >
                      <a v-if="loginProviders.includes(LOGIN_PROVIDERS.PASSWORD)" @click="switchForm(VIEW.FORGOT_PASSWORD)" class="no-underline hover:underline cursor-pointer">{{
                        $t('login.forgot_password')
                      }}</a>
                      &nbsp;
                      <a v-if="signupEnabled" @click="switchForm(VIEW.REGISTER)" class="no-underline hover:underline cursor-pointer">{{ $t('login.create_one_for_free') }}</a>
                    </div>
                  </div>
                </Card>
              </div>
              <div v-if="activeView === VIEW.LOGIN_WITH_LINK || activeView === VIEW.LOGIN_WITH_LINK_CONFIRM" class="flip-back absolute w-full h-full">
                <Card class="sm:mb-20 w-full pb-10 sm:max-w-500 md:max-w-500 min-h-400">
                  <div v-if="activeView === VIEW.LOGIN_WITH_LINK" class="w-300 flex flex-col flex-grow justify-between">
                    <h3 class="my-4 mb-1 sm:mt-8 sm:mb-4 px-5 text-20 sm:text-26 text-center">{{ $t('login.login_with_magic_link') }}</h3>

                    <ValidationObserver v-slot="{ validate, errors }">
                      <FormInput
                        v-model="email"
                        name="email"
                        rules="required|email"
                        class="mt-5"
                        :error="errors && errors['email']"
                        :label="$t('email_address')"
                        type="email"
                        :disabled="loading"
                        @keyup.enter="onContinueClick(validate)"
                      />

                      <PillButton primary :loading="loading" :disabled="loading" hideTextOnLoading :text="$t('login.continue')" class="mt-2 px-6 py-2 w-full" @click="onContinueClick(validate)" />
                    </ValidationObserver>

                    <a class="mt-10 text-center cursor-pointer no-underline hover:underline pb-4" :class="{ 'pointer-events-none': loading }" @click="goBackToSigninMethods">
                      {{ $t('login.try_other_ways_to_signin') }}
                    </a>
                  </div>

                  <div v-if="activeView === VIEW.LOGIN_WITH_LINK_CONFIRM" class="flex flex-col justify-start items-center pt-20 px-5 text-center">
                    <h3 class="mb-10 text-20">{{ $t('login.check_your_email_title') }}</h3>
                    <p class="pb-2">
                      <span v-html="$t('login.just_sent_you_an_email', { email })"></span>
                      {{ $t('login.it_has_a_magic_link') }}
                    </p>
                    <p class="pb-2">{{ $t('login.link_expires_in_an_hour') }}</p>
                    <a class="mt-10 cursor-pointer no-underline hover:underline pb-4" :class="{ 'pointer-events-none': loading }" @click="goBackToSigninMethods">
                      {{ $t('login.try_other_ways_to_signin') }}
                    </a>
                  </div>
                </Card>
              </div>
              <div v-else-if="activeView === VIEW.FORGOT_PASSWORD || activeView === VIEW.FORGOT_PASSWORD_CONFIRM" class="flip-back absolute w-full h-full">
                <Card class="sm:mb-20 w-full pb-10 sm:max-w-500 md:max-w-500 min-h-400">
                  <div v-if="activeView === VIEW.FORGOT_PASSWORD" class="w-300 flex flex-col flex-grow">
                    <h3 class="my-4 mb-1 sm:mt-8 sm:mb-4 px-5 text-20 sm:text-26 text-center">{{ $t('login.forgot_password') }}</h3>

                    <ValidationObserver v-slot="{ validate, errors }">
                      <FormInput
                        v-model="email"
                        class="mt-5"
                        rules="required|email"
                        name="email"
                        :error="errors && errors['email']"
                        :label="$t('email_address')"
                        type="email"
                        :disabled="loading"
                        @keyup.enter="onContinueClick(validate)"
                      />
                      <div class="text-12 text-gray-600 mt-5 mb-1 text-center">{{ $t('login.we_will_send_you_an_recovery_link') }}</div>

                      <PillButton
                        primary
                        :loading="loading"
                        hide-text-on-loading
                        :disabled="loading"
                        :text="$t('login.send_recovery_link')"
                        class="mt-2 px-6 py-2 w-full"
                        @click="onContinueClick(validate)"
                      />
                    </ValidationObserver>

                    <a class="text-center cursor-pointer no-underline hover:underline mt-auto" @click="goBackToSigninMethods">
                      {{ $t('login.return_to_login') }}
                    </a>
                  </div>

                  <div v-if="activeView === VIEW.FORGOT_PASSWORD_CONFIRM" class="flex flex-col justify-start items-center pt-20 px-5 text-center">
                    <h3 class="mb-10 text-20">{{ $t('login.check_your_email_title') }}</h3>
                    <p class="pb-2">
                      <span v-html="$t('login.just_sent_you_an_email', { email })"></span>
                    </p>
                    <p class="pb-2">{{ $t('login.link_expires_in_an_hour') }}</p>
                    <a class="mt-10 cursor-pointer no-underline hover:underline pb-4" :class="{ 'pointer-events-none': loading }" @click="goBackToSigninMethods">
                      {{ $t('login.try_other_ways_to_signin') }}
                    </a>
                  </div>
                </Card>
              </div>
              <div v-else-if="activeView === VIEW.RESET_PASSWORD" class="flip-back absolute w-full h-full">
                <Card class="sm:mb-20 w-full pb-10 sm:max-w-500 md:max-w-500 min-h-400">
                  <div class="w-300 flex flex-col flex-grow">
                    <h3 class="my-4 mb-1 sm:mt-8 sm:mb-4 px-5 text-20 sm:text-26 text-center">{{ $t('login.reset_password') }}</h3>

                    <ValidationObserver v-slot="{ validate, errors }">
                      <FormInput class="mt-4" v-model="email" disabled :label="$t('email')" type="email" />
                      <FormInput
                        class="mt-4"
                        v-model="resetPassword"
                        name="resetPassword"
                        :rules="[{ validate: arePasswordsEqual }, 'required', 'password']"
                        :error="errors && errors['resetPassword']"
                        :label="$t('login.password')"
                        type="password"
                      />
                      <FormInput
                        class="mt-4 mb-3"
                        v-model="confirmResetPassword"
                        name="confirmResetPassword"
                        :rules="[{ validate: arePasswordsEqual }, 'required']"
                        :error="errors && errors['confirmResetPassword']"
                        :label="$t('login.repeat_password')"
                        type="password"
                      />

                      <PillButton
                        primary
                        :loading="loading"
                        hide-text-on-loading
                        :disabled="loading"
                        :text="$t('confirm')"
                        class="my-auto px-6 py-2 w-full mt-auto"
                        @click="onContinueClick(validate)"
                      />
                    </ValidationObserver>
                  </div>
                </Card>
              </div>
              <div v-else class="flip-back absolute w-full h-full">
                <Card class="sm:mb-20 w-full pb-10 sm:max-w-500 md:max-w-500 min-h-400">
                  <div v-if="activeView === VIEW.REGISTER" class="w-300 flex flex-col flex-grow justify-between">
                    <h3 class="my-4 mb-1 sm:mt-8 sm:mb-4 px-5 text-20 sm:text-26 text-center">{{ $t('login.create_account') }}</h3>

                    <ValidationObserver v-slot="{ validate, errors }">
                      <FormInput
                        v-model="email"
                        class="mt-5"
                        name="email"
                        rules="email|required"
                        :error="errors && errors['email']"
                        :label="$t('email_address')"
                        type="email"
                        :disabled="loading"
                        @keyup.enter="onContinueClick(validate)"
                      />

                      <FormPassword
                        v-if="showPasswordField"
                        show-password-bar
                        class="mt-5"
                        id="password2"
                        name="password"
                        rules="required|password"
                        :error="errors && errors['password']"
                        v-model="password"
                        :label="$t('login.password')"
                        type="password"
                      />

                      <div class="text-12 text-gray-600 mt-5 mb-1 text-center">{{ $t('login.we_will_send_you_an_email_register') }}</div>

                      <PillButton primary :loading="loading" :disabled="loading" hide-text-on-loading :text="$t('login.continue')" class="mt-2 px-6 py-2 w-full" @click="onContinueClick(validate)" />
                    </ValidationObserver>

                    <div class="flex justify-center items-center mt-5">
                      <div class="bg-gray-200 flex-grow h-px"></div>
                      <div class="px-2 text-gray-400 text-12">{{ $t('or') }}</div>
                      <div class="bg-gray-200 flex-grow h-px"></div>
                    </div>

                    <PillButton
                      v-if="loginProviders.includes(LOGIN_PROVIDERS.GOOGLE)"
                      outlined
                      @click="googleSignin"
                      icon="google_logo"
                      :text="$t('login.continue_with_google')"
                      class="mt-4 px-6 w-full"
                      :class="{ 'pointer-events-none': loading }"
                    />

                    <div class="text-14 mt-8 text-center">
                      {{ $t('login.already_have_an_account') }}&nbsp;<a @click="switchForm(VIEW.LOGIN)" class="cursor-pointer">{{ $t('login.title') }}</a>
                    </div>
                  </div>

                  <div v-if="activeView === VIEW.REGISTER_CONFIRM" class="flex flex-col justify-start items-center pt-20 px-5 text-center">
                    <h3 class="mb-10 text-20">{{ $t('login.check_your_email_title') }}</h3>
                    <p class="pb-2">
                      <span v-html="$t('login.just_sent_you_an_email', { email })"></span>
                      {{ $t('login.it_has_a_magic_link') }}
                    </p>
                    <p class="pb-2">{{ $t('login.link_expires_in_an_hour') }}</p>
                    <a class="mt-10 cursor-pointer no-underline hover:underline pb-4" :class="{ 'pointer-events-none': loading }" @click="goBackToSigninMethods">
                      {{ $t('login.try_other_ways_to_signin') }}
                    </a>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { BASE_URL, LOGIN_PROVIDERS } from '@/constants';
import { same } from '@shared/helpers/validators.js';
import { apiPostForgotPassword, apiPostLoginWithPassword, apiPostResetPassword } from '@/helpers/api';
import { languages } from '@/plugins/i18n';

export default {
  name: 'SignIn',
  data() {
    return {
      email: '',
      isAssistant: false,
      password: '',
      resetPassword: '',
      confirmResetPassword: '',
      inviteCode: '',
      showPasswordField: false,
      showLogin: false,
      showForgotPassword: false,
      loading: false,
      VIEW: {
        LOGIN: 'login',
        LOGIN_WITH_LINK: 'login_with_link',
        LOGIN_WITH_LINK_CONFIRM: 'login_with_link_confirm',
        REGISTER: 'register',
        REGISTER_CONFIRM: 'register_confirm',
        FORGOT_PASSWORD: 'forgot_password',
        FORGOT_PASSWORD_CONFIRM: 'forgot_password_confirm',
        RESET_PASSWORD: 'reset_password',
      },
      activeView: 'login',
      LOGIN_PROVIDERS,
    };
  },
  computed: {
    ...mapState(['locale', 'loginProviders', 'signupEnabled']),
    logo() {
      return process.env.VUE_APP_LOGO;
    },
    appName() {
      return process.env.VUE_APP_COMPANY;
    },
    languages() {
      return languages.map((lang) => ({ label: this.$t(`languages_alt.${lang}`), value: lang }));
    },
  },
  methods: {
    ...mapActions(['sendSigninEmailLink', 'logout', 'showToastMessage', 'getTenant', 'changeLocale']),
    onLanguageChange(lang) {
      this.changeLocale(lang);
    },
    arePasswordsEqual() {
      return same(this.resetPassword, this.confirmResetPassword) || this.$t('login.passwords_must_be_same');
    },
    async onContinueClick(validate) {
      if (validate && typeof validate === 'function') {
        const result = await validate();
        if (!result.valid) return;
      }
      if (this.activeView === this.VIEW.LOGIN) {
        if (this.showPasswordField) {
          this.loginWithPassword();
        } else {
          this.showPasswordField = true;
          this.$nextTick().then(() => {
            if (this.activeView === this.VIEW.LOGIN) {
              document.getElementById('password').focus();
            } else {
              document.getElementById('password2').focus();
            }
          });
        }
      } else if (this.activeView === this.VIEW.RESET_PASSWORD) {
        this.sendResetPassword();
      } else if (this.activeView === this.VIEW.LOGIN_WITH_LINK || this.activeView === this.VIEW.REGISTER) {
        this.emailLinkSignin();
      } else if (this.activeView === this.VIEW.FORGOT_PASSWORD) {
        this.sendRecoveryLink();
      }
    },
    recaptcha() {
      return new Promise((resolve, reject) => {
        grecaptcha.ready(async () => {
          try {
            const token = await grecaptcha.execute(process.env.VUE_APP_RECAPTCHA, { action: 'submit' });
            resolve(token);
          } catch (e) {
            reject(e);
          }
        });
      });
    },
    async sendResetPassword() {
      const token = await this.recaptcha();
      if (token) {
        this.loading = true;
        const response = await apiPostResetPassword({ password: this.resetPassword, email: this.email, token });
        this.loading = false;
        if (response.status === 200) {
          const { xsrfToken } = response.data;
          localStorage.setItem('xsrf-token', xsrfToken);
          await this.getTenant();
          this.showToastMessage({ message: this.$t('login.password_changed_successfully'), type: 'success' });
          this.$router.push('/');
        } else {
          this.showToastMessage({ title: response?.data?.message || this.$t('login.failed_to_reset_password'), type: 'error' });
        }
      }
    },
    async googleSignin() {
      const { query } = this.$router.currentRoute.value;
      let client = 'WEB';
      if (query.client) {
        const { client_id, redirect_uri, scope, response_type, state } = query;
        ({ client } = query);
        if (response_type && response_type === 'code' && scope && scope === 'email') {
          window.location = `${BASE_URL}/api/v1/loginredirect?provider=GOOGLE&client=${client}&client_id=${client_id}&state=${state}&redirect_uri=${redirect_uri}&scope=${scope}&response_type=${response_type}`;
          return;
        }
      }
      let url = `${BASE_URL}/api/v1/loginredirect?provider=GOOGLE&client=${client}`;
      if (window.location.host === 'local.fidsy.com') {
        url += '&local=true';
      }
      window.location = url;
    },
    async loginWithPassword() {
      this.loading = true;
      const token = await this.recaptcha();
      try {
        const response = await apiPostLoginWithPassword({ email: this.email, password: this.password, token });
        const responseData = await response.json();
        if (response.status === 200) {
          window.location = `/login?xsrfToken=${responseData.xsrfToken}`;
        } else {
          this.loading = false;
          this.showToastMessage({ title: responseData?.message || this.$t('login.failed_to_login'), type: 'error' });
        }
      } catch (e) {
        this.loading = false;
        this.showToastMessage({ title: this.$t('login.failed_to_login'), type: 'error' });
      }
    },
    async emailLinkSignin() {
      this.loading = true;

      await this.$nextTick();
      const token = await this.recaptcha();
      const response = await this.sendSigninEmailLink({ email: this.email, token });
      this.loading = false;

      if (response.status === 200) {
        this.activeView = this.VIEW.LOGIN_WITH_LINK_CONFIRM;
        return;
      }
      this.showToastMessage({ title: this.$t('login.couldnt_send_signin_email'), type: 'error' });
      this.loading = false;
    },
    async sendRecoveryLink() {
      this.loading = true;
      const token = await this.recaptcha();
      try {
        const response = await apiPostForgotPassword({ email: this.email, token });
        this.loading = false;
        if (response.status === 200) {
          this.activeView = this.VIEW.FORGOT_PASSWORD_CONFIRM;
        } else {
          this.showToastMessage({ title: response?.data?.message || this.$t('login.failed_to_send_recovery'), type: 'error' });
        }
      } catch (e) {
        this.loading = false;
        this.showToastMessage({ title: this.$t('login.failed_to_send_recovery'), type: 'error' });
      }
    },
    switchForm(type) {
      this.activeView = type;
      this.loading = false;
      this.showPasswordField = false;
    },
    goBackToSigninMethods() {
      this.activeView = this.VIEW.LOGIN;
      this.showPasswordField = false;
      this.email = '';
    },
    createRecaptcha() {
      const script = document.createElement('script');
      script.setAttribute('async', '');
      script.setAttribute('defer', '');
      script.id = 'recaptchaScript';
      script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.VUE_APP_RECAPTCHA}`;
      document.getElementsByTagName('head')[0].appendChild(script);
    },
    removeRecaptcha() {
      const recaptchaScriptSelector = document.getElementById('recaptchaScript');
      if (recaptchaScriptSelector) {
        recaptchaScriptSelector.remove();
      }
      document.querySelector('.grecaptcha-badge')?.remove();
    },
  },
  mounted() {
    this.createRecaptcha();
  },
  async created() {
    if (this.$route.name === 'register') {
      this.activeView = this.VIEW.REGISTER;
    }
    if (this.$route.name === 'password-reset') {
      this.email = this.$route.query.email;
      this.activeView = this.VIEW.RESET_PASSWORD;
    }

    if (this.$route.query.client && this.$route.query.client_id && this.$route.query.redirect_uri && ['Alexa', 'MSBot'].includes(this.$route.query.client)) {
      this.isAssistant = true;
    }
  },
  beforeUnmount() {
    this.removeRecaptcha();
  },
};
</script>

<style>
.flipped-content {
  transform: rotateY(180deg);
  transition: transform 0.3s;
}

.flip-front,
.flip-back {
  backface-visibility: hidden;
}
.flip-back {
  transform: rotateY(180deg);
}

.language-selector {
  --vs-search-input-bg: transparent;
  --vs-border-width: 0;
  --vs-controls-size: 0.7;
  --vs-font-size: 12px;
  --vs-dropdown-option-padding: 3px 12px;
  font-size: 12px;
}
.language-selector.vs--single.vs--open .vs__selected {
  position: relative;
}
</style>
