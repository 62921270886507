<template>
  <ValidationObserver ref="validationObserver" v-slot="{ errors }" class="w-full flex flex-col justify-start">
    <FormInput
      v-model="config.client_id"
      :label="$t('projects.data_adapters.client_id')"
      name="client_id"
      :error="errors.client_id"
      :rules="['required']"
      class="mt-3"
      @update:modelValue="emitPayload"
    />
    <FormInput
      v-model="config.client_secret"
      :label="$t('projects.data_adapters.client_secret')"
      name="client_secret"
      :error="errors.client_secret"
      :rules="['required']"
      class="mt-3"
      @update:modelValue="emitPayload"
    />
  </ValidationObserver>
</template>

<script>
export default {
  name: 'GoogleAnalyticsConfiguration',
  props: {
    defaultConfig: Object,
  },
  data() {
    return {
      config: {
        enabled: true,
        client_id: '',
        client_secret: '',
      },
    };
  },
  created() {
    if (this.defaultConfig) {
      this.config.client_id = this.defaultConfig.client_id;
      this.config.client_secret = this.defaultConfig.client_secret;
    }
  },
  methods: {
    validate() {
      return this.$refs.validationObserver.validate();
    },
    emitPayload() {
      this.$emit('update:defaultConfig', this.config);
    },
  },
};
</script>

<style scoped lang="scss"></style>
