<template>
  <div class="text-700">
    <div v-if="payload.text">{{ payload.text }}</div>
    <div class="inline-flex flex-wrap" v-if="payload && payload.variables && payload.variables.length">
      <VariableChip class="mx-1 text-12 mb-0.5 mt-0.5" v-for="(variable, index) in payload.variables" :key="index" :index="5" :name="variable.variable" />
    </div>
    <template v-else> {{ $t('evaluate_editor.prompt') }} </template>
  </div>
</template>

<script>
export default {
  name: 'EvaluatePrompt',
  props: {
    item: Object,
    evaluate: Array,
  },
  computed: {
    payload() {
      return this.item.payload;
    },
  },
};
</script>
