<template>
  <Modal
    sheetbelowsm
    close-button
    :title="$t('flow.copy_evaluate_items')"
    :primary-button-loading="loading"
    :primary-button-disabled="!(question || (conversation && step) || (project && endpoint))"
    :primary-button="$t('save')"
    :secondary-button="$t('cancel')"
    @primary="onSaveClick"
    @secondary="close"
    @close="close"
  >
    <div class="sm:w-600">
      <div class="w-full flex flex-col justify-start mt-5 px-10">
        <label class="block text-13 mb-1">{{ $t('flow.projects') }}</label>
        <v-select
          v-model="project"
          :options="projectList"
          appendToBody
          :reduce="(item) => item.value"
          :clearable="false"
          @update:modelValue="
            endpoint = null;
            step = null;
          "
        />
      </div>
      <div class="w-full flex flex-col justify-start mt-5 px-10">
        <label class="block text-13 mb-1">{{ $t('flow.endpoints') }}</label>
        <v-select v-model="endpoint" :options="endpoints" appendToBody :reduce="(item) => item.value" :clearable="false" @update:modelValue="step = null" />
      </div>
      <div class="w-full flex flex-col justify-start mt-5 px-10">
        <label class="block text-13 mb-1">{{ $t('flow.step') }}</label>
        <v-select v-model="step" :options="endpointSteps" appendToBody :reduce="(item) => item.value" :clearable="false">
          <template v-if="endpoint" v-slot:list-footer>
            <div
              style="bottom: -1px"
              class="flex sticky justify-center w-full cursor-pointer py-3 bg-gray-100 text-indigo-600 hover:text-indigo-800 border-t border-solid border-gray-200"
              @click="createStep"
            >
              {{ $t('flow.add_step') }}
            </div>
          </template>
        </v-select>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { apiPutProjectEndpointDefinition } from '@/helpers/api';
import uuidv4 from '@/helpers/uuid';
import { addNewStep } from '@/helpers/flow';

export default {
  name: 'EvaluateCopyModal',
  emits: ['close'],
  props: {
    selectedItemIds: {
      type: Array,
      default: () => [],
    },
    evaluateData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      copyTo: null,
      conversation: null,
      question: null,
      step: null,
      project: null,
      endpoint: null,
    };
  },
  computed: {
    ...mapState(['projects', 'projectData', 'selectedIntentDefinition', 'selectedIntentDefinitionStepId']),
    projectList() {
      return Object.keys(this.projects).map((key) => ({ label: this.projects[key].name, value: key })) || [];
    },
    endpoints() {
      if (!this.project) return [];
      const endpoints = this.projectData?.[this.project]?.endpoints || {};
      return Object.keys(endpoints).map((key) => ({ value: key, label: `${endpoints[key].path} [${endpoints[key].method}]` }));
    },
    endpointSteps() {
      if (!this.project) return [];
      const steps = this.projectData?.[this.project]?.endpoints?.[this.endpoint]?.steps || [];
      return steps.map((step) => ({ label: step.name, value: step.id }));
    },
  },
  methods: {
    ...mapActions(['showToastMessage']),
    ...mapMutations(['SET_SELECTED_INTENT_DEFINITION_STEP', 'ADD_CONVERSATION_STEP', 'ADD_SELECTED_INTENT_DEFINITION_STEP', 'ADD_PROJECT_STEP']),
    async createStep() {
      const endpoint = JSON.parse(JSON.stringify(this.projectData?.[this.project]?.endpoints?.[this.endpoint]));
      const steps = endpoint.steps || [];
      const stepItem = addNewStep(steps);

      const { project_id, endpoint_id, method, path, group_name, transition = '', request_params = [] } = endpoint;
      try {
        this.loading = true;
        const response = await apiPutProjectEndpointDefinition({
          project_id,
          endpoint_id,
          method,
          path,
          group_name,
          steps,
          request_params,
          transition,
        });
        this.loading = false;
        if (response.status === 200) {
          this.ADD_PROJECT_STEP({ projectId: project_id, endpointId: endpoint_id, stepItem });
          if (this.selectedIntentDefinition && this.selectedIntentDefinition.endpoint_id === this.endpoint && this.$route.name === 'project-flow') {
            this.ADD_SELECTED_INTENT_DEFINITION_STEP(stepItem);
          }
          return;
        }
        this.showToastMessage({ title: this.$t('flow.failed_to_add_step'), type: 'error' });
      } catch (e) {
        this.loading = false;
        this.showToastMessage({ title: this.$t('flow.failed_to_add_step'), type: 'error' });
      }
    },
    close() {
      this.$emit('close');
    },
    async onSaveClick() {
      let items = this.evaluateData.filter((e) => this.selectedItemIds.includes(e.id));
      items = items.map((item) => {
        return {
          ...item,
          id: uuidv4(),
        };
      });

      if (this.selectedIntentDefinitionStepId === this.step && this.$route.name === 'project-flow') {
        this.$emit('addToCurrent', items);
        this.close();
        return;
      }

      const endpoint = JSON.parse(JSON.stringify(this.projectData?.[this.project]?.endpoints?.[this.endpoint]));
      const steps = endpoint.steps || [];
      const step = steps.find((s) => s.id === this.step);
      step.evaluate = [...step.evaluate, ...items];

      const { project_id, endpoint_id, method, path, group_name, transition = '', request_params = [] } = endpoint;
      try {
        this.loading = true;
        const response = await apiPutProjectEndpointDefinition({
          project_id,
          endpoint_id,
          method,
          path,
          group_name,
          steps,
          request_params,
          transition,
        });
        this.loading = false;
        if (response.status === 200) {
          this.SET_SELECTED_INTENT_DEFINITION_STEP(step);
          this.showToastMessage({ message: this.$t('flow.items_copied'), type: 'success' });
          this.close();
          return;
        }
        this.showToastMessage({ title: this.$t('flow.failed_to_copy_items'), type: 'error' });
      } catch (e) {
        this.loading = false;
        this.showToastMessage({ title: this.$t('flow.failed_to_copy_items'), type: 'error' });
      }
    },
  },
};
</script>
