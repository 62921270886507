<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <div v-if="!activeProvider" class="w-full sm:py-3 grid gap-4 grid-cols-collection-mobile sm:grid-cols-collection">
          <ListCard class="h-20 py-2 bg-white rounded-20 cursor-pointer" v-for="authProvider in authProviders" :key="authProvider.name" @click="onProviderClick(authProvider)">
            <div class="flex w-full h-full items-center">
              <div class="w-11 h-11">
                <img alt="" class="h-11" :src="authProvider.icon" />
              </div>
              <div>
                <div class="ml-2 font-500">{{ authProvider.name }}</div>
              </div>
            </div>
          </ListCard>
        </div>
        <div v-else class="w-full py-1 max-w-800">
          <div class="mt-2">
            <FormInput v-model="providerConfig.firebase_project_id" label="Project Id"></FormInput>
          </div>
          <div class="mt-2">
            <FormInput v-model="providerConfig.firebase_api_key" label="Web API Key"></FormInput>
          </div>
          <div class="mt-2">
            <FormInput v-model="providerConfig.firebase_client_email" label="Client Email"></FormInput>
          </div>
          <div class="mt-2 mb-5">
            <FormInput v-model="providerConfig.firebase_private_key" label="Private Key"></FormInput>
          </div>
          <div class="font-500 mt-3 mb-3 text-13">Sign-in providers</div>
          <CollapseCard :value="false" :showIcon="false" :scroll="false" class="shadow-card mb-4">
            <template #header>
              <div class="flex items-center"><Icon name="email" class="w-4 h-4 text-gray-600 mr-2" /> <span class="text-13">Email/Password</span></div>
              <div class="pr-1">
                <Badge :success="providerConfig.email_signin" :text="providerConfig.email_signin ? 'Enabled' : 'Disabled'"></Badge>
              </div>
            </template>
            <template #content>
              <div class="px-4 py-2 flex border-t border-stroke border-solid border-opacity-20">
                <div>Allow users to sign in using their email address and password.</div>
                <div class="flex items-center ml-auto"><Toggle dense v-model="providerConfig.email_signin" @update:modelValue="onEmailSigninChange" /></div>
              </div>
              <div class="px-4 py-2 flex mb-1">
                <div>Email link (passwordless sign-in)</div>
                <div class="flex items-center ml-auto"><Toggle dense v-model="providerConfig.email_link_signin" /></div>
              </div>
            </template>
          </CollapseCard>
          <CollapseCard :value="false" :showIcon="false" :scroll="false" class="shadow-card mb-4">
            <template #header>
              <div class="flex items-center"><Icon name="google_logo_2" class="w-4 h-4 text-gray-600 mr-2" /> <span class="text-13">Google</span></div>
              <div class="pr-1">
                <Badge :success="providerConfig.google_signin" :text="providerConfig.google_signin ? 'Enabled' : 'Disabled'"></Badge>
              </div>
            </template>
            <template #content>
              <div class="px-4 py-2 flex border-t border-stroke border-solid border-opacity-20">
                <div>Google sign-in</div>
                <div class="flex items-center ml-auto"><Toggle dense v-model="providerConfig.google_signin" /></div>
              </div>
              <div class="px-4 py-2 flex">
                <FormInput v-model="providerConfig.google_client_id" label="Web client ID" class="w-full" />
              </div>
              <div class="px-4 py-2 flex mb-3">
                <FormInput v-model="providerConfig.google_client_secret" label="Web client secret" class="w-full" />
              </div>
            </template>
          </CollapseCard>
          <div class="flex w-full justify-end">
            <PillButton :disabled="saving" :loading="saving" primary text="Save" @click="onSave"></PillButton>
          </div>
        </div>
      </div>
    </div>
    <Modal v-if="fetching">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>
<script>
import { apiGetProjectAuthentication, apiPutProjectAuthentication } from '@/helpers/api';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Authentication',
  data() {
    return {
      fetching: false,
      saving: false,
      activeProvider: null,
      providerConfig: {
        firebase_project_id: '',
        firebase_api_key: '',
        firebase_client_email: '',
        firebase_private_key: '',
        email_signin: false,
        email_link_signin: false,
        google_signin: false,
        google_client_secret: '',
        google_client_id: '',
      },
      emailPasswordEnabled: false,
      authProviders: [
        {
          name: 'Firebase Authentication',
          value: 'FIREBASE',
          icon: '/img/dataadapters/firebase_authentication.png',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['getProjectSecrets']),
    projectId() {
      return this.$route.params.projectId;
    },
    secrets() {
      return Object.keys(this.getProjectSecrets()).map((i) => ({ label: i, value: i }));
    },
  },
  methods: {
    ...mapActions(['showToastMessage']),
    onEmailSigninChange(value) {
      if (!value) {
        this.providerConfig.email_link_signin = false;
      }
    },
    onProviderClick(provider) {
      this.activeProvider = provider.value;
    },
    async onSave() {
      this.saving = true;
      try {
        const response = await apiPutProjectAuthentication({
          project_id: this.projectId,
          provider: this.activeProvider,
          config: this.providerConfig,
        });
        if (response.status === 200) {
          await this.fetchData();
          this.showToastMessage({ message: this.$t('projects.authentication.updated_successfully'), type: 'success' });
        } else {
          this.showToastMessage({ title: response?.data?.message || this.$t('projects.authentication.failed_to_update'), type: 'error' });
        }
      } catch (e) {
        this.showToastMessage({ title: this.$t('projects.authentication.failed_to_update'), type: 'error' });
      }
      this.saving = false;
    },
    async fetchData() {
      const response = await apiGetProjectAuthentication(this.projectId);
      if (response.status === 200 && response.data) {
        if (response?.data?.provider) {
          this.activeProvider = response.data.provider;
          this.providerConfig = response.data.config;
        }
      }
    },
  },
  watch: {
    projectId: {
      async handler() {
        this.fetching = true;
        await this.fetchData();
        this.fetching = false;
      },
      immediate: true,
    },
  },
};
</script>
