<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2 h-14">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-primary font-600">{{ $t('projects.secrets.title') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <PillButton :text="$t('add_new')" primary @click="onCreateClicked" />
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <div v-if="!Object.keys(secrets).length" class="text-center text-gray-800">{{ $t('projects.secrets.no_secret') }}</div>
        <template v-else>
          <ListCard v-for="secret in secrets" :key="secret.name" class="flex-row mt-3">
            <div class="font-500 truncate">{{ secret.name }}</div>
            <div class="italic truncate ml-auto pr-1 sm:mr-10">{{ $t('projects.secrets.value_encrypted') }}</div>
            <div class="flex flex-row justify-end items-center">
              <IconButton icon="edit" class="sm:mr-1 text-primary" @click="onEditClicked(secret)" />
              <IconButton icon="delete" class="text-primary" @click="onDeleteClicked(secret)" :text="$t('delete')" />
            </div>
          </ListCard>
        </template>
      </div>
    </div>
    <Modal v-if="fetching">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { VARIABLE_TYPE } from '@/constants';
import DeleteModal from '@shared/components/shared-modals/DeleteModal.vue';
import CreateProjectSecret from '@/components/project/CreateProjectSecret';

export default {
  name: 'NoCodeSecrets',
  data() {
    return {
      VARIABLE_TYPE,
      fetching: false,
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['getProjectSecrets']),
    secrets() {
      return this.getProjectSecrets();
    },
    projectId() {
      return this.$route.params.projectId;
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'deleteProjectSecret', 'fetchProjectSecrets']),
    onCreateClicked() {
      this.$showModal(CreateProjectSecret, {
        projectId: this.projectId,
      });
    },
    onEditClicked(secret) {
      this.$showModal(CreateProjectSecret, {
        projectId: this.projectId,
        secretName: secret.name,
      });
    },
    onDeleteClicked(secret) {
      this.$showModal(DeleteModal, {
        subtitle: this.$t('projects.remove_secret', { secretName: secret.name }),
        info: null,
        onConfirm: async () => {
          await this.deleteProjectSecret({ name: secret.name, project_id: this.projectId });
        },
      });
    },
  },
  watch: {
    projectId: {
      async handler() {
        this.fetching = true;
        await this.fetchProjectSecrets({ projectId: this.projectId });
        this.fetching = false;
      },
      immediate: true,
    },
  },
};
</script>
