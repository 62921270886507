<template>
  <div>
    <div v-if="fetching">
      <div class="flex-grow flex items-center justify-center h-full">
        <Spinner class="w-8" />
      </div>
    </div>
    <div v-else class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
      <div v-if="projectFeaturesCount" class="pl-1 font-500 mb-2 text-13">Active</div>
      <div v-if="projectFeaturesCount" class="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 pt-1">
        <ListCard v-for="(feature, key) in features" :key="key" class="h-20 py-2 bg-white rounded-20 cursor-pointer" @click="onFeatureClick(key)">
          <div class="flex justify-between items-center w-full flex-wrap -mt-3">
            <div class="flex items-center mt-3 w-full">
              <div class="bg-primary p-2 rounded-6 mr-2">
                <Icon v-if="getIcon(key)" :name="getIcon(key)" class="w-4 h-4 text-white" />
              </div>

              <div class="flex items-center truncate">
                <span class="font-600 mr-2 truncate">{{ getName(key) }}</span>
              </div>
              <Toggle :modelValue="features[key].enabled" dense class="ml-auto flex-shrink-0" @update:modelValue="onEnableChange(key)" />
            </div>
          </div>
        </ListCard>
      </div>
      <div v-if="availableFeaturesCount" class="pl-1 font-500 mb-2 mt-6 text-13">Available</div>
      <div class="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 pt-1">
        <ListCard
          v-for="feature in availableFeatures"
          :key="feature.value"
          :class="{
            hidden: feature.value in features,
          }"
          class="h-20 py-2 bg-white rounded-20 cursor-pointer"
          @click="onFeatureClick(feature.value)"
        >
          <div class="flex justify-between items-center w-full flex-wrap -mt-3">
            <div class="flex items-center mt-3">
              <div class="bg-primary p-2 rounded-6 mr-2">
                <Icon :name="feature.icon" class="w-4 h-4 text-white" />
              </div>
              <div class="flex items-center flex-wrap">
                <span class="font-500 mr-2">{{ feature.label }}</span>
              </div>
            </div>
          </div>
        </ListCard>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import DeleteModal from '@shared/components/shared-modals/DeleteModal.vue';
import EnableUIFeatureModal from '@/components/project/EnableUIFeatureModal.vue';

export default {
  name: 'UIFeatures',
  data() {
    return {
      fetching: false,
    };
  },
  computed: {
    ...mapGetters(['getProjectFeatures']),
    projectId() {
      return this.$route.params.projectId;
    },
    features() {
      const features = structuredClone(this.getProjectFeatures());
      Object.keys(features).forEach((key) => {
        if (!this.availableFeatures.some((f) => f.value === key)) {
          delete features[key];
        }
      });
      return features;
    },
    projectFeaturesCount() {
      return Object.keys(this.features).length;
    },
    availableFeatures() {
      return [
        {
          label: 'Conversations',
          value: 'conversations',
          description: 'Enable conversations to allow users to chat with AI.',
          icon: 'conversations',
        },
        {
          label: 'Collections',
          value: 'collections',
          description: 'Enable collections to allow users to group similar conversations.',
          icon: 'collections',
        },
        {
          label: 'Data Sources',
          value: 'data_sources',
          description: 'Enable data sources to allow to connect data sources.',
          icon: 'hub',
        },
        {
          label: 'Profile',
          value: 'profile',
          description: 'Enable settings to allow users to configure profile settings.',
          icon: 'profile',
        },
      ];
    },
    availableFeaturesCount() {
      return this.availableFeatures.filter((item) => !(item.value in this.features)).length;
    },
  },
  methods: {
    ...mapActions(['fetchProjectFeatures', 'updateProjectFeatures', 'fetchProjectEndpoints']),
    getIcon(key) {
      return this.availableFeatures.find((f) => f.value === key)?.icon;
    },
    getName(key) {
      return this.availableFeatures.find((f) => f.value === key)?.label;
    },
    getDescription(key) {
      return this.availableFeatures.find((f) => f.value === key)?.description;
    },
    onEnableChange(feature) {
      const title = this.features[feature].enabled
        ? this.$t('projects.ui_features.disable_feature', { feature: this.getName(feature) })
        : this.$t('projects.ui_features.enable_feature', { feature: this.getName(feature) });

      const subtitle = this.features[feature].enabled
        ? this.$t('projects.ui_features.disable_feature_description', { feature: this.getName(feature) })
        : this.$t('projects.ui_features.enable_feature_description', { feature: this.getName(feature) });
      this.$showModal(DeleteModal, {
        title,
        subtitle,
        type: this.features[feature].enabled ? 'error' : 'primary',
        onConfirm: async () => {
          const features = structuredClone(this.features);
          features[feature].enabled = !features[feature].enabled;
          await this.updateProjectFeatures({ project_id: this.projectId, features });
        },
      });
    },
    onFeatureClick(feature) {
      const modal = this.$showModal(
        EnableUIFeatureModal,
        { feature, featureName: this.getName(feature), description: this.getDescription(feature), featureConfig: feature in this.features ? this.features[feature] : undefined },
        {
          save: async (config) => {
            const payload = { ...this.features, [feature]: config };
            await this.updateProjectFeatures({ project_id: this.projectId, features: payload });
            modal.close();
          },
        },
      );
    },
  },
  watch: {
    projectId: {
      async handler() {
        this.fetching = true;
        await this.fetchProjectFeatures({ project_id: this.projectId });
        await this.fetchProjectEndpoints({ projectId: this.projectId });
        this.fetching = false;
      },
      immediate: true,
    },
  },
};
</script>
