<template>
  <div class="pr-2 md:pr-10 pl-2">
    <div class="flex items-center pb-4">
      <div class="text-13 mr-auto font-600">{{ total }} users</div>
      <PillButton :text="$t('users.add_user')" icon="plus" primary @click="onAddUserClick" />
    </div>
    <div>
      <div v-if="fetching">
        <div class="flex-grow flex items-center justify-center h-full">
          <Spinner class="w-8" />
        </div>
      </div>
      <div v-else-if="!users.length" class="w-full text-center text-13 text-gray-600">There is no record found!</div>
      <div v-else class="justify-items-center">
        <ListCard v-for="user in users" :key="user.email" class="mb-4 !p-1 sm:p-3 bg-white w-full">
          <div class="cursor-pointer w-6 h-6 flex-shrink-0 flex-grow-0 mr-3">
            <Avatar size="6" :picture="user.photoUrl" />
          </div>
          <div class="overflow-hidden">
            <div class="truncate">
              <span class="font-600">{{ user.displayName }}</span> {{ user.email }}
            </div>
            <div class="text-12">{{ $t('users.last_login') }} {{ user.last_login ? dayjs(user.last_login).format('MMM DD, YYYY HH:mm') : $t('n_a') }}</div>
          </div>
          <div>
            <Badge v-if="user.disabled" warn text="Disabled" class="ml-1 sm:ml-3" />
          </div>
          <div class="ml-auto flex">
            <IconButton :title="$t('navigation.data_sources')" class="text-primary" icon="hub" @click="onDataSourcesClick(user)" />
            <IconButton :title="$t('edit')" class="text-primary" icon="edit" @click="onEditClick(user)" />
            <IconButton class="text-primary" icon="delete" :title="$t('delete')" @click="onDeleteClick(user)" />
          </div>
        </ListCard>
      </div>
      <div v-if="total > 10" class="flex justify-center items-center mt-8">
        <IconButton icon="chevron_left" @click="prev" />
        <span class="px-3">{{ pageNumber + 1 }}</span>
        <IconButton icon="chevron_right" @click="next" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import AddUserModal from '@/components/project/AddUserModal.vue';
import EditUserModal from '@/components/project/EditUserModal.vue';
import DeleteModal from '@shared/components/shared-modals/DeleteModal.vue';
import EditUserDataSourcesModal from '@/components/project/EditUserDataSourcesModal.vue';

export default {
  name: 'Users',
  data() {
    return {
      fetching: false,
      pageNumber: 0,
      total: 0,
    };
  },
  computed: {
    ...mapGetters(['getProjectUsers']),
    projectId() {
      return this.$route.params.projectId;
    },
    users() {
      return this.getProjectUsers();
    },
  },
  methods: {
    ...mapActions(['fetchProjectUsers', 'deleteProjectUser']),
    dayjs,
    async next() {
      const response = await this.getUsers({ page_number: this.pageNumber + 1 });
      if (Array.isArray(response) && response.length) {
        this.pageNumber++;
      }
    },
    async prev() {
      if (this.pageNumber === 0) {
        return;
      }
      const response = await this.getUsers({ page_number: this.pageNumber - 1 });
      if (response.length) {
        this.pageNumber--;
      }
    },
    async getUsers(params) {
      this.loading = true;
      const response = await this.fetchProjectUsers({ ...params, project_id: this.projectId });
      if (response) {
        this.total = response.count;
      }
      this.loading = false;
      return response;
    },
    onAddUserClick() {
      this.$showModal(AddUserModal);
    },
    onEditClick(user) {
      this.$showModal(EditUserModal, { email: user.email });
    },
    onDataSourcesClick(user) {
      this.$showModal(EditUserDataSourcesModal, { email: user.email });
    },
    onDeleteClick(user) {
      this.$showModal(DeleteModal, {
        title: this.$t('projects.iam.confirm_remove_user'),
        subtitle: this.$t('projects.iam.confirm_remove_user_subtitle'),
        onConfirm: async () => {
          await this.deleteProjectUser({ project_id: this.projectId, email: user.email });
        },
      });
    },
  },
  watch: {
    projectId: {
      async handler() {
        this.fetching = true;
        await this.getUsers({ page_number: this.pageNumber + 1 });
        this.fetching = false;
      },
      immediate: true,
    },
  },
};
</script>
