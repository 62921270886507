<template>
  <div class="h-full flex flex-col">
    <div class="w-full flex-grow overflow-auto pl-2 pb-2">
      <div class="flex w-full">
        <PillButton class="ml-auto" :text="$t('import')" text-class="max-sm:hidden" icon-class="max-sm:mr-0" primary @click="showMappingModal" />
        <PillButton class="ml-2" :loading="saving" :disabled="saving" :text="$t('save')" icon="save" text-class="max-sm:hidden" icon-class="max-sm:mr-0" primary @click="save" />
      </div>
      <div class="w-full pr-1 flex-grow overflow-auto pl-1 pb-2">
        <ListCard v-for="(dataType, dataTypeName) in tables" :key="dataTypeName" class="flex-row mt-3">
          <div class="text-14 font-500 truncate">{{ dataTypeName }}</div>
          <div class="flex items-center flex-1">
            <div class="flex flex-row justify-end items-center ml-auto">
              <IconButton icon="edit" class="mr-1 text-primary" @click="onEditClick(dataTypeName)" />
              <IconButton icon="delete" class="text-primary" @click="onDeleteClick(dataTypeName)" />
            </div>
          </div>
        </ListCard>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import DataAdapterTableMapping from '@/components/project/DataAdapterTableMapping.vue';
import EditProjectDataAdapterTable from '@/components/project/EditProjectDataAdapterTable.vue';
import DeleteModal from '@shared/components/shared-modals/DeleteModal.vue';

export default {
  name: 'DataAdapterDataTypes',
  props: {
    adapter: {
      type: Object,
    },
  },
  data() {
    return {
      name: '',
      type: '',
      saving: false,
      connecting: false,
      connected: false,
      config: {},
    };
  },
  created() {
    this.config = this.adapter.config;
  },
  mounted() {
    if (!Object.keys(this.tables).length) {
      this.showMappingModal();
    }
  },
  computed: {
    ...mapState(['availableDataAdapters']),
    ...mapGetters(['getProjectDataAdapters']),
    projectId() {
      return this.$route.params.projectId;
    },
    tables() {
      return this.adapter.tables || {};
    },
  },
  methods: {
    ...mapActions(['updateProjectDataAdapter', 'updateProjectDataAdapterTables']),
    onEditClick(dataTypeName) {
      this.$showModal(EditProjectDataAdapterTable, { dataType: this.tables[dataTypeName], tables: this.tables, adapterType: this.adapter.adapter_type });
    },
    onDeleteClick(dataTypeName) {
      this.$showModal(DeleteModal, {
        subtitle: `Are you sure you want to delete data type ${dataTypeName}?`,
        onConfirm: async () => {
          const tables = structuredClone(this.tables);
          delete tables[dataTypeName];
          await this.updateProjectDataAdapterTables({ project_id: this.projectId, tables, adapter_type: this.adapter.adapter_type });
        },
      });
    },
    showMappingModal() {
      this.$showModal(DataAdapterTableMapping, {
        adapter: this.adapter,
      });
    },
    async save() {
      const valid = await this.$refs.validationObserver.validate();
      if (!valid.valid) return;

      this.saving = true;
      await this.updateProjectDataAdapter({
        project_id: this.projectId,
        adapter_type: this.adapter.adapter_type,
        config: this.config,
      });
      this.saving = false;
    },
  },
};
</script>
