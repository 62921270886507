<template>
  <div class="flex h-full overflow-hidden relative" style="background-color: #f4f4f4">
    <EvaluateEditor
      :hiddenEvaluateTypes="hiddenEvaluateTypes"
      ref="evaluateEditor"
      class="w-full"
      :data="evaluateData"
      :selectedItemIds="selectedItemIds"
      @item-clicked="rightPanelOpened = false"
      @evaluate-update="$emit('update', $event)"
      @select="onEvaluateItemSelect"
      @unselect="onEvaluateItemUnSelect"
      @selectAll="onEvaluateSelectAll"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import EvaluateEditor from '@/components/flow/evaluate/EvaluateEditor';
import { VARIABLE_TYPE } from '@/constants';

export default {
  name: 'StepEvaluate',
  components: { EvaluateEditor },
  props: {
    evaluateData: {
      type: Array,
      required: true,
    },
    hiddenEvaluateTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rightPanelOpened: false,
      tryItLoading: false,
      VARIABLE_TYPE,
      selectedVariable: null,
      tryItVariables: null,
      tryItResponses: null,
      selectedItemId: null,
      selectedItemIds: [],
    };
  },
  computed: {},
  methods: {
    ...mapActions(['showToastMessage']),
    onEvaluateItemSelect(id) {
      if (this.selectedItemIds.includes(id)) {
        this.selectedItemIds = this.selectedItemIds.filter((item) => item !== id);
      } else {
        this.selectedItemIds.push(id);
      }

      if (this.selectedItemIds.length !== this.evaluateData.length) {
        this.$refs.evaluateEditor.selectAll = false;
      } else if (this.selectedItemIds.length === this.evaluateData.length) {
        this.$refs.evaluateEditor.selectAll = true;
      }
    },
    onEvaluateItemUnSelect(id) {
      if (this.selectedItemIds.includes(id)) {
        this.selectedItemIds = this.selectedItemIds.filter((item) => item !== id);
      }
    },
    onEvaluateSelectAll(selectAll) {
      if (selectAll) {
        this.selectedItemIds = this.evaluateData.map((item) => item.id);
      } else {
        this.selectedItemIds = [];
      }
    },
  },
};
</script>
