<template>
  <Modal
    fullbelowsm
    title="Table Mapping"
    :loading="fetching"
    :primary-button="$t('save')"
    :secondary-button="$t('cancel')"
    :secondary-button-disabled="loading"
    :primary-button-loading="loading"
    :primary-button-disabled="loading || !fetched"
    @primary="onSaveClick"
    @secondary="onCancelClick"
  >
    <div class="sm:w-700 max-h-600 px-10 py-5">
      <div class="flex justify-between mb-3">
        <div class="text-13 font-500">Pick tables to create data type mappings</div>
        <div class="text-primary text-13 font-500 cursor-pointer" @click="onSelectAllClick">{{ allTablesSelected ? 'Unselect All' : 'Select All' }}</div>
      </div>
      <ListCard v-for="(table, key) in tables" :key="key" class="flex w-full justify-between my-2">
        <div class="flex">
          <Checkbox v-model="table.selected" />
          <div class="ml-2 select-none cursor-pointer" @click="table.selected = !table.selected">{{ table.table_name }}</div>
        </div>
      </ListCard>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';
import { apiGetProjectDataAdapterSqlTables } from '@/helpers/api';

export default {
  name: 'DataAdapterTableMapping',
  props: {
    adapter: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      fetching: false,
      fetched: false,
      loading: false,
      tables: [],
    };
  },
  created() {
    this.fetchTables();
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    allTablesSelected() {
      return this.tables.every((table) => table.selected);
    },
    dataTypes() {
      const dataTypes = structuredClone(this.adapter.tables || {});
      this.tables
        .filter((table) => table.selected)
        .forEach((table) => {
          dataTypes[table.table_name] = {
            m: table.table_name,
            f: table.columns.reduce((acc, item) => {
              acc[item.name] = {
                m: item.name,
                dt: item.datatype,
              };
              return acc;
            }, {}),
          };
        });
      return dataTypes;
    },
  },
  methods: {
    ...mapActions(['updateProjectDataAdapterTables', 'showToastMessage']),
    onSelectAllClick() {
      const selected = !this.allTablesSelected;
      this.tables.map((table) => {
        table.selected = selected;
        return table;
      });
    },
    async fetchTables() {
      this.fetching = true;
      this.fetched = false;
      try {
        const response = await apiGetProjectDataAdapterSqlTables(this.projectId, this.adapter.adapter_type);
        if (response.status === 200 && response.data) {
          this.tables = response.data.filter((table) => !(table.table_name in this.adapter.tables)).map((table) => ({ ...table, selected: true }));
          this.fetched = true;
        } else {
          this.showToastMessage({ title: response?.data?.message || this.$t('projects.data_adapters.failed_to_get_tables'), type: 'error' });
        }
      } catch (e) {
        this.showToastMessage({ title: this.$t('projects.data_adapters.failed_to_get_tables'), type: 'error' });
      }
      this.fetching = false;
    },
    async onSaveClick() {
      this.loading = true;
      await this.updateProjectDataAdapterTables({ project_id: this.projectId, adapter_type: this.adapter.adapter_type, tables: this.dataTypes });
      this.$emit('close');
      this.loading = false;
    },
    onCancelClick() {
      this.$emit('close');
    },
  },
};
</script>
