<template>
  <PostgresqlConfiguration v-if="type === DATA_ADAPTERS.POSTGRESQL" :default-config="config" ref="configuration" @update:defaultConfig="emitPayload" />
  <GoogleAnalyticsConfiguration v-else-if="type === DATA_ADAPTERS.GOOGLE_ANALYTICS" :default-config="config" ref="configuration" @update:defaultConfig="emitPayload" />
  <XeroConfiguration v-else-if="type === DATA_ADAPTERS.XERO" :default-config="config" ref="configuration" @update:defaultConfig="emitPayload" />
  <MysqlConfiguration v-else-if="type === DATA_ADAPTERS.MYSQL" :default-config="config" ref="configuration" @update:defaultConfig="emitPayload" />
  <MssqlConfiguration v-else-if="type === DATA_ADAPTERS.MSSQL" :default-config="config" ref="configuration" @update:defaultConfig="emitPayload" />
  <SqliteConfiguration v-else-if="type === DATA_ADAPTERS.SQLITE" :default-config="config" ref="configuration" @update:defaultConfig="emitPayload" />
</template>

<script>
import XeroConfiguration from '@/components/data-adapter/xero/Configuration.vue';
import GoogleAnalyticsConfiguration from '@/components/data-adapter/google_analytics/Configuration.vue';
import PostgresqlConfiguration from '@/components/data-adapter/postgresql/Configuration.vue';
import MysqlConfiguration from '@/components/data-adapter/mysql/Configuration.vue';
import MssqlConfiguration from '@/components/data-adapter/mssql/Configuration.vue';
import SqliteConfiguration from '@/components/data-adapter/sqlite/Configuration.vue';
import { DATA_ADAPTERS } from '@/constants';

export default {
  name: 'DataAdapterConfiguration',
  components: { PostgresqlConfiguration, GoogleAnalyticsConfiguration, XeroConfiguration, MysqlConfiguration, MssqlConfiguration, SqliteConfiguration },
  props: {
    type: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      DATA_ADAPTERS,
    };
  },
  methods: {
    validate() {
      return this.$refs.configuration.validate();
    },
    emitPayload(e) {
      this.$emit('update:config', e);
    },
  },
};
</script>
