<template>
  <Modal
    sheetbelowsm
    close-button
    :title="$t('users.add_user')"
    :primary-button="$t('save')"
    :secondary-button="$t('cancel')"
    :primary-button-loading="loading"
    :loading="fetching"
    @primary="save"
    @secondary="cancel"
    @close="cancel"
  >
    <ValidationObserver ref="validationObserver" v-slot="{ errors }" class="w-full">
      <div class="sm:w-600 flex py-7 px-10 flex-wrap">
        <div class="w-full flex mb-3">
          <FormInput v-model="name" name="name" rules="required" :error="errors['name']" :label="$t('name')" class="w-full md:w-1/2 md:mr-2" />
          <FormInput :model-value="email" name="email" readonly :error="errors['email']" :label="$t('email')" class="w-full md:w-1/2 md:ml-2" />
        </div>
        <div class="w-full flex mb-3">
          <FormInput v-model="organisation" :label="$t('login.organisation')" class="w-full md:w-1/2 md:mr-2" />
          <FormInput v-model="position" :label="$t('login.position')" class="w-full md:w-1/2 md:ml-2" />
        </div>
        <div class="w-full flex mb-3">
          <FormSelect
            :label="$t('login.country')"
            name="country"
            rules="required"
            :error="errors['country']"
            v-model="country"
            class="w-full md:w-1/2 md:mr-2"
            :options="countries"
            :clearable="false"
            :reduce="(option) => option.value"
            appendToBody
          ></FormSelect>
          <FormSelect
            :label="$t('timezone')"
            name="timezone"
            rules="required"
            :error="errors['timezone']"
            v-model="timezone"
            class="w-full md:w-1/2 md:ml-2"
            :options="TIMEZONES"
            :clearable="false"
            searchable
            appendToBody
          ></FormSelect>
        </div>
        <div class="w-full mb-3">
          <FormSelect
            :label="$t('language')"
            name="language"
            rules="required"
            :error="errors['language']"
            v-model="language"
            :options="languages"
            :clearable="false"
            :reduce="(option) => option.value"
            appendToBody
          ></FormSelect>
        </div>
        <div class="w-full mb-3 pt-2 flex flex-col items-start">
          <Checkbox v-model="flags.rc" :label="$t('flags.rc')" class="mb-3 !text-13"></Checkbox>
          <Checkbox v-model="flags.oc" :label="$t('flags.oc')" class="mb-3 !text-13"></Checkbox>
        </div>
      </div>
    </ValidationObserver>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';
import { COUNTRIES, TIMEZONES, LANGUAGES } from '@/constants';

export default {
  name: 'AddUserModal',
  props: {
    email: String,
  },
  data() {
    return {
      name: '',
      timezone: '',
      country: '',
      organisation: '',
      position: '',
      language: '',
      flags: {
        rc: false,
        oc: false,
      },
      TIMEZONES,
      countries: COUNTRIES.map((c) => ({ label: c.name, value: c.name })),
      loading: false,
      fetching: false,
    };
  },
  emits: ['add', 'close'],
  computed: {
    languages() {
      return LANGUAGES;
    },
    projectId() {
      return this.$route.params.projectId;
    },
  },
  async created() {
    this.fetching = true;
    const response = await this.fetchProjectUser({ email: this.email, project_id: this.projectId });
    if (response) {
      const { name, timezone, country, organisation, position, language, flags } = response;
      this.name = name;
      this.timezone = timezone;
      this.country = country;
      this.organisation = organisation;
      this.position = position;
      this.language = language;
      this.flags = flags;
    }
    this.fetching = false;
  },
  methods: {
    ...mapActions(['updateProjectUser', 'fetchProjectUser', 'showToastMessage']),
    async save() {
      const valid = await this.$refs.validationObserver.validate();

      if (valid.valid) {
        this.loading = true;
        const { email, name, timezone, country, organisation, position, language, flags } = this;
        const response = await this.updateProjectUser({ project_id: this.projectId, email, name, timezone, country, organisation, position, language, flags });

        if (response) {
          this.showToastMessage({ message: this.$t('projects.iam.added_successfully'), type: 'success' });
          this.$emit('add');
          this.$emit('close');
          this.loading = false;
          this.cancel();
        } else {
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
    },
    cancel() {
      this.$emit('close');
    },
  },
};
</script>
