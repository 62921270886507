<template>
  <div class="px-3 pb-2 pt-2">
    <FormSelect v-model="payload.from" :label="$t('evaluate.email_from')" :options="emailFromList" :reduce="(option) => option.item" :clearable="false" @update:modelValue="emitPayload">
      <template v-slot:option="{ item }">
        <div>{{ item.name }} &lt;{{ item.email }}&gt;</div>
        <div></div>
      </template>
    </FormSelect>
    <PhraseEditor :label="$t('evaluate.email_to_comma')" v-model="emailTo" :items="variables" class="mt-3 w-full" @update:modelValue="onEmailToChange" />
    <FormInput v-model="payload.subject" :label="$t('evaluate.email_subject')" class="mt-3 w-full" @update:modelValue="emitPayload" />
    <FormSelect :label="$t('evaluate.email_template')" class="mt-3 w-full" v-model="payload.email_template" :options="emailTemplates" :clearable="false" @update:modelValue="emitPayload"></FormSelect>
    <VariableSelect
      v-model="payload.data_binding"
      multiple
      :label="$t('evaluate.email_data')"
      class="mt-3 flex-grow"
      selectBgColor="bg-white"
      :filter-data-type="['Text', 'Number', 'Email', 'YesNo']"
      :showCreate="false"
      @update:modelValue="emitPayload"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import debounce from '@shared/helpers/debounce';
import uuidv4 from '@/helpers/uuid';

export default {
  name: 'EvaluateSetEmail',
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      emailTo: '',
      payload: {
        email_template: '',
        from: {
          email: '',
          name: '',
        },
        to: [],
        subject: '',
        data_binding: [],
      },
    };
  },
  computed: {
    ...mapState(['selectedIntentDefinition', 'projectData']),
    variables() {
      let variables = [];
      if (this.projectId && Object.keys(this.projectData).length) {
        variables = Object.keys(this.projectData?.[this.projectId]?.variables || {}).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())) || [];
      }
      variables = variables.map((variable) => ({
        id: uuidv4(),
        value: variable,
      }));
      return variables;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    emailTemplates() {
      const emailTemplates = this.projectData?.[this.projectId]?.templates || {};
      return Object.keys(emailTemplates);
    },
    emailFromList() {
      const emails = this.projectData?.[this.projectId]?.config?.emails || [];
      return emails.map((item) => ({
        label: item.email,
        value: item.email,
        item: { email: item.email, name: item.name },
      }));
    },
  },
  methods: {
    // eslint-disable-next-line
    emitPayload: debounce(function () {
      this.$emit('update', {
        id: this.item.id,
        type: this.item.type,
        payload: this.payload,
      });
    }, 300),
    onEmailToChange(value) {
      const emails = value.trim().split(',');
      this.payload.to = emails;
      this.emitPayload();
    },
  },
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.payload = JSON.parse(JSON.stringify(this.item.payload));
          if (!this.payload?.from?.name && !this.payload?.from?.email) {
            this.payload.from = null;
          }
          this.emailTo = this.item.payload.to.join(',');
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
