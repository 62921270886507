<template>
  <div ref="phraseEditor" class="max-w-full overflow-hidden">
    <FormLabel v-if="label" :label="label" />
    <div ref="selectionContainer" class="phrase-editor bg-white rounded-4 text-black text-13 leading-16 outline-none relative" :class="{ 'h-21': multiline, 'h-9': !multiline }">
      <div v-if="!content" class="text-gray-400 p-2 absolute w-full">
        {{ placeholder }}
      </div>
      <div
        ref="editableContent"
        class="editable-content p-2 relative z-index-100 outline-0 overflow-auto"
        :class="{
          'editable-content--singleline': !multiline,
          'editable-content--multiline leading-22': multiline,
        }"
        contenteditable="true"
        spellcheck="false"
        @input="onInput"
        @blur="onBlur"
        @keydown="onKeydown"
        @keyup="onKeyup"
        @click="onKeyup"
      ></div>
    </div>
    <Teleport to="body">
      <PhraseEditorDropdown ref="dropdown" :show="showDropdown" :value="dropdownValue" :items="items" @click="onEntityClicked" @select="onEntitySelected"></PhraseEditorDropdown>
    </Teleport>
  </div>
</template>

<script>
import PhraseEditorDropdown from './PhraseEditorDropdown';

export default {
  name: 'PhraseEditor',
  emits: ['update:modelValue'],
  components: { PhraseEditorDropdown },
  props: {
    label: String,
    modelValue: String,
    items: Array,
    placeholder: {
      type: String,
      default: 'Enter text, {} to add variables',
    },
    multiline: Boolean,
  },
  data() {
    return {
      content: '',
      dropdownOpener: '{',
      dropdownCloser: '}',
      dropdownValue: null,
      showDropdown: false,
    };
  },
  computed: {
    dropdown() {
      return this.$refs.dropdown;
    },
  },
  mounted() {
    if (this.modelValue) {
      this.formatValue(this.modelValue);
    }
  },
  beforeUnmount() {
    if (this.dropdown) {
      this.showDropdown = false;
    }
  },
  methods: {
    onEntityClicked(entity) {
      this.onEntitySelected(entity);
      this.closeDropdown();
    },
    onEntitySelected(entity) {
      const sel = window.getSelection();
      const offset = sel.anchorOffset || sel.focusOffset;
      const node = sel.anchorNode || sel.focusNode;

      const caretPosition = this.getCursorPosition();

      let newCaretOffset;
      if (!node.previousElementSibling) {
        node.textContent = `{${entity.value}}`;
        newCaretOffset = entity.value.length - offset + 2;
      } else {
        node.textContent = `${entity.value}${node.textContent.slice(0, offset * -1)}}`;
        newCaretOffset = entity.value.length - offset + 1;
      }

      this.formatValue(this.$refs.editableContent.innerText);
      this.emitContent();
      this.setCurrentCursorPosition(caretPosition + newCaretOffset);
    },
    createDropdownElement() {
      this.setupResizeAndScrollEventListeners(this.$refs.selectionContainer, this.adjustMenuOpenDirection);
    },
    getCursorPosition() {
      const sel = document.getSelection();
      sel.modify('extend', 'backward', 'paragraphboundary');
      const pos = sel.toString().length;
      if (sel.anchorNode !== undefined) sel.collapseToEnd();
      return pos;
    },
    onKeydown(e) {
      if (!this.showDropdown) return;
      if (e.key === 'ArrowUp') {
        this.dropdown.decreaseIndex();
        e.preventDefault();
      }
      if (e.key === 'ArrowDown') {
        this.dropdown.increaseIndex();
        e.preventDefault();
      }
      if (e.key === 'Enter') {
        this.dropdown.select();
        e.preventDefault();
      }
    },
    onKeyup() {
      const sel = window.getSelection();
      const offset = sel.anchorOffset || sel.focusOffset;

      const node = sel.anchorNode || sel.focusNode;
      const startText = node.textContent[offset - 1];

      if (node.previousElementSibling?.classList.contains('curly-start')) {
        const coords = this.getCoords(node.previousElementSibling);
        this.openDropdown(coords.left, coords.top);
        const [value] = node.textContent.split(' ');
        this.dropdownValue = value || '';
      } else if (startText === this.dropdownOpener) {
        const coords = this.getCoords(node.parentElement);
        this.openDropdown(coords.left, coords.top);
        this.dropdownValue = '';
      } else if (startText === this.dropdownCloser) {
        this.closeDropdown();
      } else if (node.parentElement.classList.contains('intent-selector')) {
        const coords = this.getCoords(node.parentElement);
        this.openDropdown(coords.left, coords.top);
        this.dropdownValue = '';
      } else if (node.parentElement.classList.contains('curly-start')) {
        const coords = this.getCoords(node.parentElement);
        this.openDropdown(coords.left, coords.top);
        this.dropdownValue = '';
      } else {
        this.closeDropdown();
      }
    },
    createRange(node, chars, range) {
      if (!range) {
        range = document.createRange();
        range.selectNode(node);
        range.setStart(node, 0);
      }

      if (chars.count === 0) {
        range.setEnd(node, chars.count);
      } else if (node && chars.count > 0) {
        if (node.nodeType === Node.TEXT_NODE) {
          if (node.textContent.length < chars.count) {
            chars.count -= node.textContent.length;
          } else {
            range.setEnd(node, chars.count);
            chars.count = 0;
          }
        } else {
          for (let lp = 0; lp < node.childNodes.length; lp++) {
            range = this.createRange(node.childNodes[lp], chars, range);

            if (chars.count === 0) {
              break;
            }
          }
        }
      }
      return range;
    },
    setCurrentCursorPosition(chars) {
      if (chars > 0) {
        const selection = window.getSelection();
        const range = this.createRange(this.$refs.editableContent.parentNode, {
          count: chars,
        });
        if (range) {
          range.collapse(false);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      } else {
        this.setCaretPosition(this.$refs.editableContent.parentNode, 0);
      }
    },
    setCaretPosition(elem, caretPos) {
      let range;
      if (elem.createTextRange) {
        range = elem.createTextRange();
        range.move('character', caretPos);
        range.select();
      } else {
        elem.focus();
        if (elem.selectionStart !== undefined) {
          elem.setSelectionRange(caretPos, caretPos);
        }
      }
    },
    onInput(e) {
      const caretPosition = this.getCursorPosition();

      this.formatValue(e.target.innerText);
      this.emitContent();

      this.setCurrentCursorPosition(caretPosition);
      setTimeout(() => {
        this.setCurrentCursorPosition(caretPosition);
      }, 0);
    },
    formatValue(content) {
      const regEx = /\{(\w+)\}/gim;
      let formattedValue = content.replace(regEx, '<span class="intent-selector bg-indigo-500 text-white p-1 text-12 rounded-4 cursor-pointer">{$1}</span>');
      const curlyStartRegex = /(?<!>)\{/gim;
      formattedValue = formattedValue.replace(curlyStartRegex, '<span class="curly curly-start">{</span>');
      const curlyEndRegex = /\}(?!<)/gim;
      formattedValue = formattedValue.replace(curlyEndRegex, '<span class="curly curly-end">}</span>');
      this.$refs.editableContent.innerHTML = formattedValue;
      this.content = formattedValue;
    },
    emitContent() {
      // eslint-disable-next-line
      const regex = / /gi;
      const text = this.$refs.editableContent.innerText;
      this.$emit('update:modelValue', text.replace(regex, ' '));
    },
    placeCaretAtEnd(el) {
      el.focus();
      if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
        const range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (typeof document.body.createTextRange !== 'undefined') {
        const textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(false);
        textRange.select();
      }
    },
    onBlur() {
      setTimeout(() => {
        this.closeDropdown();
      }, 200);
    },
    openDropdown(left, top) {
      const coords = this.getCoords(this.$refs.phraseEditor);
      const box = this.$refs.phraseEditor.getBoundingClientRect();

      this.showDropdown = true;

      if (this.dropdown) {
        this.dropdown.$el.style.left = `${left}px`;
        this.dropdown.$el.style.top = `${coords.top + box.height}px`;
        if (this.multiline) {
          this.dropdown.$el.style.top = `${top + 20}px`;
        }
      } else {
        this.createDropdownElement();
      }
    },
    closeDropdown() {
      if (this.showDropdown) {
        this.dropdown.clearIndex();
        this.showDropdown = false;
        this.dropdownValue = '';
      }
    },
    getCoords(elem) {
      const box = elem.getBoundingClientRect();

      const body = document?.body;
      const docEl = document.documentElement;

      const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

      const clientTop = docEl.clientTop || body.clientTop || 0;
      const clientLeft = docEl.clientLeft || body.clientLeft || 0;

      const top = box.top + scrollTop - clientTop;
      const left = box.left + scrollLeft - clientLeft;

      return { top: Math.round(top), left: Math.round(left) };
    },
    adjustMenuOpenDirection() {
      if (!this.$refs.selectionContainer || !this.dropdown.$el || !this.showDropdown) {
        // adjustMenuOpenDirection early return
        return;
      }
      const controlRect = this.$refs.selectionContainer.getBoundingClientRect();

      const visibleY = (el) => {
        let rect = el.getBoundingClientRect();
        const { top, height } = rect;
        el = el.parentNode;
        do {
          rect = el.getBoundingClientRect();
          if (!(top <= rect.bottom)) return false;
          // Check if the element is out of view due to a container scrolling
          if (top + height <= rect.top) return false;
          el = el.parentNode;
        } while (el !== document.body);
        // Check its within the document viewport
        return top <= document.documentElement.clientHeight;
      };

      const elem = this.dropdown.$el;

      const dssVisible = visibleY(this.$refs.selectionContainer);
      if (!dssVisible) {
        elem.style.display = 'none';
      } else {
        elem.style.display = 'inline';
      }

      elem.style.left = `${controlRect.x}px`;
      elem.style.top = `${controlRect.bottom}px`;
    },
    setupResizeAndScrollEventListeners($el, listener) {
      function isScrollElment($elem) {
        // Firefox wants us to check `-x` and `-y` variations as well
        const { overflow, overflowX, overflowY } = getComputedStyle($elem);
        return /(auto|scroll|overlay)/.test(overflow + overflowY + overflowX);
      }

      function findScrollParents($elem) {
        const $scrollParents = [];
        let $parent = $elem.parentNode;

        while ($parent && $parent.nodeName !== 'BODY' && $parent.nodeType === document.ELEMENT_NODE) {
          if (isScrollElment($parent)) $scrollParents.push($parent);
          $parent = $parent.parentNode;
        }
        $scrollParents.push(window);

        return $scrollParents;
      }

      const $scrollParents = findScrollParents($el);

      window.addEventListener('resize', listener, { passive: true });
      $scrollParents.forEach((scrollParent) => {
        scrollParent.addEventListener('scroll', listener, { passive: true });
      });

      return function removeEventListeners() {
        window.removeEventListener('resize', listener, { passive: true });
        $scrollParents.forEach(($scrollParent) => {
          $scrollParent.removeEventListener('scroll', listener, {
            passive: true,
          });
        });
      };
    },
  },
};
</script>
<style lang="scss">
.phrase-editor {
  position: relative;
  padding: 1px;
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    height: 100%;
    border: solid 1px var(--color-gray-300);
    border-radius: 4px;
  }
  .editable-content {
    &--singleline {
      white-space: nowrap;
      height: calc(100% + 10px);
    }
    &--multiline {
      height: 100%;
    }
    br {
      display: none;
    }
    * {
      display: inline;
      white-space: nowrap;
    }
  }
}
</style>
