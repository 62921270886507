<template>
  <div class="px-3 pb-2 pt-2">
    <VariableSelect v-model="aiPayload.variable" :filterDataType="['Text']" label="Variable" @update:modelValue="emitPayload" />
    <FormSelect :label="$t('evaluate.provider')" v-model="aiPayload.value.provider" :options="providers" :reduce="(option) => option.value" class="w-full mt-3" @update:modelValue="onProviderChange" />
    <FormSelect
      v-if="showInference"
      :label="$t('evaluate.model')"
      v-model="aiPayload.value.inference"
      :options="inferences"
      :reduce="(option) => option.value"
      class="w-full mt-3"
      @update:modelValue="emitPayload"
    />
    <FormSelect
      :label="$t('evaluate.persona')"
      v-model="aiPayload.value.persona"
      :options="personas"
      :reduce="(option) => option.value"
      class="w-full mt-3"
      show-add-button
      @add="onAddPersonaClick"
      @update:modelValue="emitPayload"
    />
    <FormTextarea :label="$t('evaluate.context')" v-model="aiPayload.value.context" class="w-full mt-3" @update:modelValue="emitPayload" />
    <VariableSelect v-model="aiPayload.value.datatable" multiple :filter-data-type="['DataTable']" :label="$t('evaluate.datatable')" class="mt-3" @update:modelValue="emitPayload" />
    <FormInput :label="$t('evaluate.max_length_token')" v-model="aiPayload.value.max_length" type="number" class="w-full mt-3" @update:modelValue="emitPayload" />
  </div>
</template>

<script>
import debounce from '@shared/helpers/debounce';
import { mapGetters, mapState } from 'vuex';
import CreatePersonaModal from '@/components/project/CreatePersonaModal.vue';

export default {
  name: 'EvaluateSetAi',
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      aiPayload: {
        variable: '',
        value: {
          context: '',
          datatable: [],
          max_length: '100',
          provider: '',
          inference: '',
          persona: '',
        },
      },
      // providers: [
      //   // { label: 'Fidsy 7B Instruct AWQ', value: 'FIDSY_7B_INSTRUCT_AWQ' },
      //   { label: 'Google Gemini Pro', value: 'GOOGLE_GEMINI_PRO' },
      //   { label: 'Google Gemini 1.5 Pro', value: 'GOOGLE_GEMINI_15_PRO' },
      //   { label: 'Microsoft ChatGPT 3.5', value: 'MICROSOFT_CHATGPT' },
      //   { label: 'Microsoft ChatGPT 4o', value: 'MICROSOFT_CHATGPT4' },
      //   { label: 'OpenAI ChatGPT 3.5', value: 'OPENAI_CHATGPT' },
      //   { label: 'OpenAI ChatGPT 4', value: 'OPENAI_CHATGPT4' },
      // ],
      // persona: [
      //   { label: 'Accountant (Xero)', value: 'XERO' },
      //   { label: 'Investment Analyst (Mintus)', value: 'MINTUS_EQUITY_ANALYST' },
      //   { label: 'Property Fund Manager (Mintus)', value: 'MINTUS_FUND_MANAGER' },
      //   { label: 'Wealth Manager (Mintus)', value: 'MINTUS' },
      //   { label: 'Business Analyst (Google Analytics)', value: 'GOOGLE_ANALYTICS' },
      //   { label: 'Business Analyst (QLIK)', value: 'QLIK' },
      //   { label: 'Translator', value: 'TRANSLATE' },
      //   { label: 'Custom', value: 'CUSTOM' },
      // ],
    };
  },
  computed: {
    ...mapState(['availableAIProviders']),
    ...mapGetters(['getProjectAIProviders', 'getProjectPersonas']),
    payload() {
      return {
        id: this.item.id,
        type: this.item.type,
        payload: this.aiPayload,
      };
    },
    providers() {
      return Object.keys(this.getProjectAIProviders()).map((item) => ({ label: this.availableAIProviders?.[item]?.name || item, value: item }));
    },
    personas() {
      return Object.values(this.getProjectPersonas()).map((item) => ({ label: item.name, value: item.persona_id }));
    },
    inferences() {
      let inferences = [];
      if (this.aiPayload.value.provider) {
        inferences = this.availableAIProviders[this.aiPayload.value.provider]?.inference || [];
      }
      return inferences.map((item) => ({ label: item, value: item }));
    },
    showInference() {
      if (this.aiPayload.value.provider) {
        const inferences = this.availableAIProviders[this.aiPayload.value.provider]?.inference || [];
        if (!inferences.length && !!this.getProjectAIProviders()[this.aiPayload.value.provider].key && !!this.getProjectAIProviders()[this.aiPayload.value.provider].url) {
          return false;
        }
      }
      return true;
    },
    projectId() {
      return this.$route.params.projectId;
    },
  },
  methods: {
    // eslint-disable-next-line
    emitPayload: debounce(function () {
      this.aiPayload.value.max_length = this.aiPayload.value.max_length.toString();
      this.$emit('update', this.payload);
    }, 300),
    onProviderChange() {
      this.aiPayload.value.inference = '';
    },
    onAddPersonaClick() {
      this.$showModal(CreatePersonaModal, { projectId: this.projectId });
    },
  },
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.aiPayload = JSON.parse(JSON.stringify(this.item.payload));
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
