<template>
  <div class="px-3 pb-2 pt-2">
    <VariableSelect v-model="variable" :label="$t('variable')" :filter-data-type="['DataTable']" class="w-full" @update:modelValue="emitPayload" />
    <FormSelect v-model="model" :options="models" :reduce="(opt) => opt.value" :label="$t('evaluate.model')" class="mt-3" @update:modelValue="emitPayload" />

    <div class="w-full flex mt-3">
      <FormSelect
        label="Select Data Type"
        class="w-150 mr-3 flex-shrink-0"
        autoSelect
        v-model="inputPhraseType"
        :reduce="(option) => option.value"
        :options="inputPhraseTypes"
        :clearable="false"
        @option:selected="inputPhraseTypeChange"
      ></FormSelect>

      <template v-if="inputPhraseType === 'static'">
        <FormInput v-model="inputPhrase" label="Input Phrase" class="w-full" @update:modelValue="emitPayload" />
      </template>
      <template v-else>
        <VariableSelect label="Variable" class="w-300" curly :filter-data-type="['Text']" v-model="inputPhrase" @update:modelValue="emitPayload" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import debounce from '@shared/helpers/debounce';
import { FINE_TUNING_TYPES } from '@/constants';

export default {
  name: 'EvaluateSetCapture',
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      variable: '',
      model: '',
      inputPhrase: '',
      inputPhraseType: 'static',
    };
  },
  computed: {
    ...mapState(['selectedIntentDefinition']),
    ...mapGetters(['getProjectDataTypes', 'getProjectModels']),
    inputPhraseTypes() {
      return [
        { label: this.$t('variable'), value: 'variable' },
        { label: this.$t('static'), value: 'static' },
      ];
    },
    models() {
      return Object.values(this.getProjectModels())
        .filter((model) => model.type === FINE_TUNING_TYPES.datatype)
        .map((item) => ({ label: item.name, value: item.model_id }));
    },
    payload() {
      return {
        id: this.item.id,
        type: this.item.type,
        payload: {
          variable: this.variable,
          model: this.model,
          inputPhrase: this.inputPhrase,
        },
      };
    },
  },
  methods: {
    // eslint-disable-next-line
    emitPayload: debounce(function () {
      this.$emit('update', this.payload);
    }, 300),
    inputPhraseTypeChange() {
      this.inputPhrase = '';
    },
  },
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.variable = this.item?.payload?.variable || '';
          this.model = this.item?.payload?.model || '';
          this.inputPhrase = this.item?.payload?.inputPhrase || '';
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
