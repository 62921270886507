<template>
  <div class="h-full">
    <div class="flex flex-col w-screen items-center justify-center">
      <div class="w-full flex flex-col justify-center items-center pt-48">
        <Card class="!w-600 pt-10 px-10 pb-15 !shadow-mini-card text-dark-text">
          <div class="py-5">
            <img :src="logo" class="w-20" :alt="appName" />
          </div>
          <div class="text-36 font-500 mb-5">Welcome to {{ appName }}!</div>
          <div class="text-semi-dark-text">You're now a team member for project:</div>
          <div class="text-18 font-500 mt-1 capitalize">{{ projectName }}</div>
          <PillButton :text="continueText" primary class="mt-10 w-200" @click="onContinueClick" />
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AcceptInvite',
  computed: {
    projectName() {
      return this.$route.query.project;
    },
    continueText() {
      return this.$route.query.action === 'register' ? 'Continue to Signup' : 'Continue to Sign in';
    },
    logo() {
      return process.env.VUE_APP_BUBBLE_ICON;
    },
    appName() {
      return process.env.VUE_APP_COMPANY;
    },
  },
  methods: {
    onContinueClick() {
      if (this.$route.query.action === 'register') {
        this.$router.push({ name: 'register' });
      } else {
        this.$router.push({ name: 'login' });
      }
    },
  },
};
</script>
